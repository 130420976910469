import React, { useContext, useEffect, useState } from "react";
import { NotificationContext } from "@contexts/NotificationContext";
import classes from "./index.module.scss";
import { formatCurrency } from "@utils/index";
import { useHistory, useLocation } from "react-router-dom";
import { getAccountSummaries } from "@core/apis/account";
import { WaterDamageOutlined } from "@mui/icons-material";
import {
  DataGrid,
  gridClasses,
  GridColDef,
  GridRenderCellParams,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { Chip, Tooltip, Typography } from "@mui/material";

const columns: GridColDef[] = [
  {
    field: "account_number",
    headerName: "ACCOUNT #",
    editable: false,
    flex: 1,
    filterable: false,
  },
  {
    field: "name",
    headerName: "NAME & SERVICE ADDRESS",
    editable: false,
    flex: 1.5,
    filterable: false,
    valueGetter: (params: any) => {
      return `${params.row.name || ""} ${params.row.address || ""}`;
    },
    renderCell: (params: GridRenderCellParams<any>) => (
      <div className={classes.address_column_container}>
        <p>{params.row.name}</p>
        <p>{params.row.address}</p>
      </div>
    ),
  },
  {
    field: "billing_date",
    headerName: "BILLING DATE",
    editable: false,
    flex: 1,
    filterable: false,
    type: "date",
    valueGetter: ({ value }) => value && new Date(value),
  },
  {
    field: "status",
    headerName: "STATUS",
    editable: false,
    flex: 0.5,
    filterable: false,
    renderCell: (params: GridRenderCellParams<any>) => <Chip label={params.row.status} />,
  },
  {
    field: "balance",
    headerName: "ACCOUNT BALANCE",
    editable: false,
    flex: 1,
    filterable: false,
    type: "number",
    valueFormatter: ({ value }) => formatCurrency(value),
  },
];

const UtilitiesScreen = (...props: any) => {
  const location = useLocation();
  const history = useHistory();
  const { handleError } = useContext(NotificationContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  function CustomToolbar(type: string) {
    return (
      <GridToolbarContainer>
        <Tooltip title="Search by: Account #, Name, and Address">
          <div>
            <GridToolbarQuickFilter />
          </div>
        </Tooltip>
      </GridToolbarContainer>
    );
  }

  async function fetchData(queryOptions: any) {
    try {
      setLoading(true);
      queryOptions.accountType = "utility";
      let data = await getAccountSummaries(queryOptions);
      data.result = data.result.map((summary: any) => {
        return {
          ...summary,
          account_number: summary.accountNumber,
          name_service_address: {
            name: summary.name,
            address: summary.address,
          },
          status: "Active",
          billing_date: summary.lastUpdated,
        };
      });
      setData(data);
    } catch (err: any) {
      handleError(err.response?.data?.message || err?.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData({
      total: true,
      sortBy: "",
      ascending: true,
      filters: {},
      offset: 0,
      limit: 20000,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.styled_container}>
        <div className={classes.fixed_header}>
          <>
            <div className={classes.title_container}>
              <WaterDamageOutlined sx={{ height: "45px", width: "45px", marginRight: "0.5rem" }} />
              <Typography variant={"h3"} className={classes.table_title}>
                {"Utilities"}
              </Typography>
            </div>
          </>
        </div>
        <DataGrid
          loading={loading}
          density={"comfortable"}
          // @ts-ignore
          rows={data?.result || []}
          columns={columns}
          onRowClick={(params) => {
            history.push(`${location.pathname}/${params.id}`);
          }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                address: false,
              },
            },
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10, 25]}
          disableRowSelectionOnClick
          style={{ borderColor: "transparent" }}
          slots={{ toolbar: CustomToolbar }}
          sx={{
            [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
              outline: "none",
            },
            [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
              outline: "none",
            },
          }}
        />
      </div>
      <div className={"secondary-sidebar"} />
    </div>
  );
};

export default UtilitiesScreen;
