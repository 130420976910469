import React from "react";
import styled from "@emotion/styled";
import Avatar from "@mui/material/Avatar";
import { useTheme } from "@mui/material";
import { HowToRegRounded, PersonAddAltRounded } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import { sanitizeUnderscore, truncate } from "@utils/index";
import { REVIEWER_NAME_SEARCH_DROP_DOWN_LENGTH } from "@core/constants/licences";
import Grid from "@mui/material/Unstable_Grid2";
import Chip from "../../Chip";
import { ClickableArea, Row, StyledGrid } from "./StyledComponents";

const StyledAvatar = styled(Avatar)`
  && {
    background-color: ${({ theme, color }) => (color ? color : theme.textColor)};
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 1rem;
    height: 2rem;
    width: 2rem;
    margin: 0 0.5rem;
  }
`;

const AdminItem = ({ dataType = "ADMINS", checkRowSelected, row, props }) => {
  const theme = useTheme();
  return (
    <ClickableArea {...props} component={"li"}>
      <Row>
        {checkRowSelected(row) ? (
          <HowToRegRounded color={"error"} />
        ) : (
          <PersonAddAltRounded id="hover-icon" sx={{ color: theme.palette.primary[200] }} />
        )}

        {dataType === "ADMINS" ? (
          <StyledGrid container alignItems="center" justify="space-between">
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <StyledAvatar color={theme.palette.primary[500]}>
                  {(row.firstName?.[0] || "-") + (row.lastName?.[0] || "-")}
                </StyledAvatar>
                <Typography style={{ flexWrap: "wrap" }}>
                  {truncate(row.fullName, REVIEWER_NAME_SEARCH_DROP_DOWN_LENGTH)}
                </Typography>
              </div>
              <div>
                <Grid container spacing={1}>
                  {row?.roles.length
                    ? row.roles.map((role) => (
                        <Grid key={role.roleName} item style={{ marginLeft: "20px" }}>
                          <Chip
                            label={sanitizeUnderscore(role.roleName)}
                            variant="active"
                            style={{
                              marginLeft: "20px",
                            }}
                          />
                        </Grid>
                      ))
                    : null}
                </Grid>
              </div>
            </div>
          </StyledGrid>
        ) : (
          <StyledGrid>
            <Chip label={sanitizeUnderscore(row.roleName)} variant="contained" />
          </StyledGrid>
        )}
      </Row>
    </ClickableArea>
  );
};

export default AdminItem;
