import React, { useEffect, useState } from "react";
import { CheckCircle, FindInPage } from "@mui/icons-material";
import classes from "./index.module.scss";
import { Skeleton } from "@mui/material";
import { convertUTCToLocalTimeZone } from "@utils/index";
import moment from "moment";
import { isBoolean } from "lodash";

const UtilityStatusCard = ({ isActive, isLinked, linkedDate, hasChanges, prtl, changeDate }) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if ((linkedDate || changeDate) && isBoolean(hasChanges)) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading ? (
        <Skeleton className={classes.loading} variant={"rounded"} />
      ) : (
        <div className={`${classes.container} ${isActive ? classes.active : ""} ${hasChanges ? classes.changes : ""}`}>
          {hasChanges ? (
            <>
              <FindInPage />
              <div>
                <p className={classes.status_title}>Changes under review</p>
                <p className={classes.linked_info}>
                  {prtl
                    ? `You submitted your changes on ${
                        moment(changeDate).isValid() ? moment(convertUTCToLocalTimeZone(changeDate)).format("MMM DD, yyyy") : ""
                      }.`
                    : `Request submitted on ${
                        moment(changeDate).isValid() ? moment(convertUTCToLocalTimeZone(changeDate)).format("MMM DD, yyyy") : "--"
                      }`}
                </p>
              </div>
            </>
          ) : (
            <>
              <CheckCircle />
              <div>
                <p className={classes.status_title}>{isActive ? "Account is active" : "Cancelled"}</p>
                <p className={classes.linked_info}>
                  {prtl
                    ? `${isLinked ? "Your account was linked" : "Created"} since ${
                        moment(linkedDate).isValid() ? moment(convertUTCToLocalTimeZone(linkedDate)).format("MMM DD, yyyy") : "--"
                      }`
                    : `${isLinked ? "Active" : "Created"} since ${
                        moment(linkedDate).isValid() ? moment(convertUTCToLocalTimeZone(linkedDate)).format("MMM DD, yyyy") : "--"
                      }`}
                </p>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default UtilityStatusCard;
