import React from "react";
import styled from "@emotion/styled";

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;

  ${({ aligned }) => aligned && `align-items: center`};
  justify-content: ${({ justifyContent }) => justifyContent};

  &&:focus {
    background-color: ${({ theme }) => theme.palette.primary[50]};
  }
`;

/**
 * Displays elements in a row
 * @param children
 * @param aligned
 * @param justifyContent
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function Row({ children, aligned = false, justifyContent = "normal", ...props }) {
  return (
    <StyledRow {...props} aligned={aligned} justifyContent={justifyContent}>
      {children}
    </StyledRow>
  );
}
