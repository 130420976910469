import Spacer from "@components/ui-kit/Spacer";
import UtilityBanner from "../widgets/UtilityBanner";
import IUtilityAccountResponse from "@core/interfaces/utility/iUtilityAccountResponse";
import typographyClasses from "@assets/styles/typography/typography.module.scss";
import { isDemoEnv } from "utilities";

export default function Index({ account }: { account: IUtilityAccountResponse }) {
  return (
    <>
      <p className={typographyClasses.h1}>Is this your utilities account?</p>
      <p className={`${typographyClasses.body} ${typographyClasses.high_emphasis}`}>
        This utilities account is already linked to a user account. Double-check that it belongs to you. If you didn't link this
        account,{" "}
        {isDemoEnv() ? (
          "contact City Hall"
        ) : (
          <a rel={"noreferrer"} href={"https://www.lacombe.ca/Directory.aspx?DID=42"} target={"_blank"}>
            contact City Hall
          </a>
        )}{" "}
        for assistance.
      </p>
      <Spacer />
      <p className={typographyClasses.overline}>ALREADY LINKED</p>
      <UtilityBanner account={account} />
    </>
  );
}
