import * as yup from "yup";

export const validationSchema = yup.object({
  property_type: yup.string().required(),
  water_metre_number: yup.string().required(),
  water_metre_size: yup.string().required(),
  waste_metre_size: yup.string().when(["property_type"], {
    is: (propertyType) => propertyType !== "residential",
    then: yup.string().required(),
    otherwise: yup.string().notRequired().nullable(),
  }),
  collection_services: yup.string().when("property_type", {
    is: (value) => value === "residential",
    then: yup.string().required(),
    otherwise: yup.string().notRequired().nullable(),
  }),
  solid_waste_general: yup.string().when("property_type", {
    is: (value) => value !== "residential",
    then: yup.string().required(),
    otherwise: yup.string().notRequired().nullable(),
  }),
  solid_waste_cardboard: yup.string().when("property_type", {
    is: (value) => value !== "residential",
    then: yup.string().required(),
    otherwise: yup.string().notRequired().nullable(),
  }),
  solid_waste_size: yup.string().when(["property_type"], {
    is: (propertyType) => propertyType !== "residential",
    then: yup.string().required(),
    otherwise: yup.string().notRequired().nullable(),
  }),
});
