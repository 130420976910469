import base from "./axiosInstance";

export const register_profile = async (cardHolderName, bamboraToken, autoPay) => {
  try {
    const profileData = {
      accountHolderName: cardHolderName,
      tokenId: bamboraToken,
      autoPay,
    };

    const response = await base.post("/payment/profile", profileData);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const get_payment_profiles = async () => {
  try {
    const response = await base.get(`/payment/profile`);
    return response.data;
  } catch (error) {
    return false;
  }
};

export const delete_profile = async (id) => {
  try {
    return await base.delete(`/payment/profile/${id}`);
  } catch (error) {
    return false;
  }
};

export const edit_profile = async (autoPay, bamboraToken, paymentProfileId) => {
  try {
    const profileData = {
      token: bamboraToken,
      autoPay,
    };

    const response = await base.put(`/payment/profile/${paymentProfileId}`, profileData);

    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const get_invoice = async (id) => {
  try {
    return await base.get(`/invoice/${id}`);
  } catch (e) {
    return false;
  }
};

export const pay_with_profile = async ({ amount, invoiceId, profileId, sendEmail = false, withChanges }) => {
  const reqBody = {
    amount,
    invoice_id: invoiceId,
    card_id: profileId,
    sendEmail: sendEmail,
    withChanges: withChanges,
  };
  try {
    return await base.post("/payment/pay", reqBody);
  } catch (error) {
    return error.response;
  }
};
