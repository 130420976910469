import React from "react";

const PropertyAssessmentItem = ({ bold = false, label = "-", value = "-" }) => {
  return (
    <div style={{ margin: "1.5rem 0" }}>
      <p className={"item_label"}>{label}</p>
      <p className={`${bold && "item_value_bold"}`} style={{ margin: 0 }}>
        {value}
      </p>
    </div>
  );
};

export default PropertyAssessmentItem;
