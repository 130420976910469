import React, { useMemo, useState } from "react";
import { CreditScoreRounded, MoreVertRounded } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { Popover } from "@mui/material";
import "./index.scss";

const PaymentMethodItem = ({ card, onEditCreditCard, onDeleteCreditCard }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  /**
   * Memo version of indicator for checking when the popover should be open
   * @type {boolean}
   */
  const isPopoverOpen = useMemo(() => Boolean(anchorEl), [anchorEl]);

  return (
    <>
      <div className={"item"}>
        <div className={"header"}>
          <div className={"type"}>
            <CreditScoreRounded color={"primary"} />
            <p>{card.card.cardType === "VI" ? "Visa" : "Mastercard"}</p>
          </div>
          <IconButton children={<MoreVertRounded />} onClick={(e) => setAnchorEl(e.currentTarget)} />
          <Popover
            className={"module-section-popover"}
            open={isPopoverOpen}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <div
              className={"popover_option"}
              onClick={() => {
                setAnchorEl(null);
                onEditCreditCard(card.id, card.card, card.card.autoPay);
              }}
            >
              <p>Edit details</p>
            </div>
            <div
              className={"popover_option"}
              onClick={() => {
                setAnchorEl(null);
                onDeleteCreditCard(card.id);
              }}
            >
              <p>Delete</p>
            </div>
          </Popover>
        </div>
        <p className={"info card-name"}>{card.card.name}</p>
        <p className={"info"}>{`**** **** **** ${card.card.number}`}</p>
        <div className={"expiry-line"}>
          <p
            className={`info ${card.card.isExpired ? "expired" : ""}`}
          >{`Expiry date: ${card.card.expiryMonth}/${card.card.expiryYear}`}</p>
          {card.card.isExpired && (
            <div className={"expired-container"}>
              <p>Expired</p>
            </div>
          )}
        </div>
        {card.card.autoPay && !card.card.isExpired && (
          <div className={"default-container"}>
            <p>Auto-pay</p>
          </div>
        )}
      </div>
    </>
  );
};

export default PaymentMethodItem;
