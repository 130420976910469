import React, { useEffect, useRef, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { FieldArray, Form, Formik } from "formik";
import TextField from "@components/ui-kit/TextField";
import Grid from "@mui/material/Unstable_Grid2";
import ModalTitle from "@components/ui-kit/modal-title";
import MaskedTextField from "@components/ui-kit/masked-text-field";
import { contactValidators } from "../../validators";
import Spacer from "@components/ui-kit/Spacer";
import { Add } from "@mui/icons-material";
import Alerts from "ui/components/ui-kit/Alert";

interface IFormModal {
  open: boolean;
  handleClose: any;
  onSubmit: any;
  values: any;
}

const ContactFormModal = ({ open = false, handleClose = () => {}, onSubmit, values }: IFormModal) => {
  const formikRef = useRef();
  const [initialValues, setInitialValues] = useState({}) as any;

  useEffect(() => {
    let otherContacts = values;
    if (!otherContacts || otherContacts?.length <= 0) {
      otherContacts = [
        {
          first_name: "",
          last_name: "",
          phone_number: "",
          email: "",
        },
      ];
    }
    setInitialValues({
      other_contacts: otherContacts,
    });
  }, [values]);

  return (
    <Formik
      //@ts-ignore
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={contactValidators}
      validateOnChange={true}
      onSubmit={(values, formikHelpers) => {
        onSubmit(values, formikHelpers, true);
      }}
      enableReinitialize={true}
    >
      {({ resetForm, dirty, values, setFieldValue, handleChange, errors, touched, isValid }) => {
        return (
          <Dialog open={open} fullWidth={true}>
            <ModalTitle
              title={"Share E-bill"}
              handleClose={() => {
                handleClose();
                resetForm();
              }}
            />
            <DialogContent>
              <Form noValidate={true}>
                <Alerts
                  variant={"info"}
                  title={"Share your e-bill with others"}
                  body={"Share your monthly utility e-bills and payment notifications with others."}
                />
                <br />
                <FieldArray
                  name={"other_contacts"}
                  render={({ remove }) => {
                    return (
                      <>
                        {values.other_contacts.map((contact: any, index: number) => (
                          <Grid container spacing={1}>
                            <Grid xs={6}>
                              <TextField
                                name={`other_contacts.${index}.first_name`}
                                error={Boolean(
                                  //@ts-ignore
                                  errors.other_contacts &&
                                    //@ts-ignore
                                    errors["other_contacts"][index] &&
                                    //@ts-ignore
                                    touched.other_contacts &&
                                    //@ts-ignore
                                    touched.other_contacts[index]
                                    ? //@ts-ignore
                                      errors["other_contacts"][index].first_name
                                    : null,
                                )}
                                value={contact.first_name}
                                onChange={handleChange}
                                label={"Firstname"}
                                fullWidth
                              />
                            </Grid>
                            <Grid xs={6}>
                              <TextField
                                name={`other_contacts.${index}.last_name`}
                                error={Boolean(
                                  //@ts-ignore
                                  errors.other_contacts &&
                                    //@ts-ignore
                                    errors["other_contacts"][index] &&
                                    //@ts-ignore
                                    touched.other_contacts &&
                                    //@ts-ignore
                                    touched.other_contacts[index]
                                    ? //@ts-ignore
                                      errors["other_contacts"][index].last_name
                                    : null,
                                )}
                                value={contact.last_name}
                                onChange={handleChange}
                                label={"Lastname"}
                                fullWidth
                              />
                            </Grid>
                            <Grid xs={12}>
                              <TextField
                                name={`other_contacts.${index}.email`}
                                error={Boolean(
                                  //@ts-ignore
                                  errors.other_contacts &&
                                    //@ts-ignore
                                    errors["other_contacts"][index] &&
                                    //@ts-ignore
                                    touched.other_contacts &&
                                    //@ts-ignore
                                    touched.other_contacts[index]
                                    ? //@ts-ignore
                                      errors["other_contacts"][index].email
                                    : null,
                                )}
                                value={contact.email}
                                onChange={handleChange}
                                label={"Email address"}
                                fullWidth
                              />
                            </Grid>
                            <Grid xs={12}>
                              <MaskedTextField
                                type={"PHONE"}
                                name={`other_contacts.${index}.phone`}
                                error={Boolean(
                                  //@ts-ignore
                                  errors.other_contacts &&
                                    //@ts-ignore
                                    errors["other_contacts"][index] &&
                                    //@ts-ignore
                                    touched.other_contacts &&
                                    //@ts-ignore
                                    touched.other_contacts[index]
                                    ? //@ts-ignore
                                      errors["other_contacts"][index].phone
                                    : null,
                                )}
                                value={contact.phone}
                                onChange={handleChange}
                                label={"Phone number (optional)"}
                                optional={true}
                                fullWidth
                              />
                            </Grid>
                            {values?.other_contacts?.length > 0 ? (
                              <>
                                <Grid xs={12} className={"flex_end_container"}>
                                  <Button
                                    size={"small"}
                                    variant={"outlined"}
                                    onClick={() => {
                                      remove(index);
                                    }}
                                  >
                                    Remove
                                  </Button>
                                </Grid>
                                <Grid xs={12}>
                                  <Spacer />
                                </Grid>
                              </>
                            ) : null}
                          </Grid>
                        ))}
                        <Spacer />
                        {values.other_contacts.length < 5 ? (
                          <Button
                            size={"small"}
                            variant={"outlined"}
                            startIcon={<Add color={"primary"} sx={{ width: "20px", height: "20px" }} />}
                            onClick={() => {
                              setFieldValue("other_contacts", [
                                ...values.other_contacts,
                                {
                                  first_name: "",
                                  last_name: "",
                                  phone_number: "",
                                  email: "",
                                },
                              ]);
                            }}
                          >
                            Add contact
                          </Button>
                        ) : null}
                      </>
                    );
                  }}
                />
              </Form>
            </DialogContent>
            <DialogActions>
              <Button
                size={"small"}
                variant={"outlined"}
                onClick={() => {
                  handleClose();
                  resetForm();
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={!isValid || !dirty}
                size={"small"}
                variant={"contained"}
                type={"submit"}
                onClick={() => {
                  if (formikRef.current) {
                    //@ts-ignore
                    formikRef.current.handleSubmit();
                  }
                }}
              >
                Save changes
              </Button>
            </DialogActions>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default ContactFormModal;
