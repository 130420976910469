import { useCallback, useContext, useEffect, useState } from "react";
import { addLicenceTag, getLicenceTags, removeLicenceTag } from "@core/apis/licence";
import { NotificationContext } from "@contexts/NotificationContext";
import ITag from "@core/interfaces/ITag";
import { getCityTags } from "@core/apis/tags";

const useTags = (systemObj: string, id: string) => {
  const [tags, setTags] = useState([] as ITag[]);
  const [tagOptions, setTagOptions] = useState([] as string[]);
  const { handleShow } = useContext(NotificationContext);

  const fetchLicenceTags = useCallback(async () => {
    let result;
    let options;
    try {
      if (systemObj === "licence") {
        result = await getLicenceTags(id);
        options = (await getCityTags("licence/business")) as any;
      }
      if (options?.data?.tags) {
        setTagOptions(options.data.tags);
      }
      if (result?.data?.tags) {
        setTags(result.data.tags);
      } else {
        handleShow("Error getting tags");
      }
    } catch (e) {
      handleShow("Error getting tags");
    }
  }, [systemObj, id, handleShow]);

  const addTag = async (tag: string) => {
    const newTag = {
      tagId: "",
      token: tag,
    };
    try {
      const result = await addLicenceTag(id, [newTag]);
      if (result.status >= 200 && result.status < 300) {
        await fetchLicenceTags();
      }
    } catch (e: any) {
      if (e.response.status === 400) {
        handleShow("Tag name must not exceed 32 characters. Please shorten.");
      } else {
        handleShow("Error adding tags");
      }
    }
  };

  const removeTag = async (tag: ITag) => {
    try {
      const result = await removeLicenceTag(id, [tag]);
      if (result.status >= 200 && result.status < 300) {
        setTags((prev) => prev.filter((prevTag) => prevTag.tagId !== tag.tagId));
      }
    } catch (e: any) {
      if (e.response.status !== 404) {
        handleShow("Error removing tags");
      }
    }
  };

  useEffect(() => {
    fetchLicenceTags().then();
  }, [fetchLicenceTags]);

  return { tags, addTag, removeTag, tagOptions };
};

export default useTags;
