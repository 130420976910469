import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Grid, Skeleton } from "@mui/material";
import HistoryTab from "../../licence-details/history-tab";
import ListItem from "@components/ui-kit/list-item";
import DetailsContainer from "../../../my-city-hall/utilities/utility-details/widgets/details-container";
import Spacer from "@components/ui-kit/Spacer";
import typographyClasses from "@assets/styles/typography/typography.module.scss";
import BarGraph from "@components/ui-kit/bar-graph";
import classes from "./index.module.scss";
import AccountBar from "@components/ui-kit/account-bar";
import LinkUserToAccountModal from "@components/modals/link-user-to-account-modal";
import AccountFormModal from "../../../my-city-hall/utilities/utility-details/widgets/account-form-modal";
import {
  CallOutlined,
  Check,
  FolderOpen,
  HomeOutlined,
  LocalPostOfficeOutlined,
  LocationOnOutlined,
  MailOutlined,
  PersonOutline,
  Tag,
} from "@mui/icons-material";
import { unlinkeUserFromAccount, update_account, updateAccountChangeSet } from "@core/apis/account";
import Alert from "@components/ui-kit/Alert";
import { REVIEWING_GROUP } from "@core/constants/licences";
import { formatPhoneNumber } from "@utils/masked-input-formatters";
import ShareEBill from "@components/ui-kit/ShareEBill";
import { closeDialog, setState as setConfirmationDialogState } from "../../../../redux/slices/confrmation-dialog";
import { useDispatch } from "react-redux";
import moment from "moment";

const UtilityInfoTab = ({
  id,
  fetchHistories,
  fetchData,
  allHistory,
  addComment,
  setTab,
  loading,
  infoData,
  hasCitizenChanges,
  refresher,
  accountConsumptionData,
  linkedUser,
  changesetId,
  changesetStatus,
  otherInfo,
}) => {
  const [isLinkUserModalOpen, setIsLinkUserModalOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [waterConsumptionData, setWaterConsumptionData] = useState([]);
  const [contactItems, setContactItems] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const contacts = otherInfo.find((item) => item.name === "other_contacts");
    if (contacts?.value !== undefined && contacts.value.value !== "-") {
      setContactItems(contacts.value.value || []);
    }
  }, [otherInfo]);

  const unlinkUser = async () => {
    dispatch(closeDialog());
    await unlinkeUserFromAccount(id, linkedUser.id);
    refresher(true);
  };

  const createAccountDataShape = useCallback(() => {
    const accountDetailsModalData = {
      account_holder: "",
      country: "",
      address1: "",
      address2: "",
      city: "",
      province_state: "",
      postal_zip: "",
      phone_number: "",
      billing_method: "",
      is_owner: true,
      mailing_same_as_service_address: false,
    };
    for (let i = 0; i < infoData?.length; i++) {
      if (infoData[i].name === "account_holder") {
        accountDetailsModalData.account_holder = infoData[i].value;
      } else if (infoData[i].name === "mailing_address") {
        accountDetailsModalData.address1 = infoData[i].rawValue.address1;
        accountDetailsModalData.address2 = infoData[i].rawValue.address2;
        accountDetailsModalData.city = infoData[i].rawValue.city;
        accountDetailsModalData.province_state = infoData[i].rawValue.province_state;
        accountDetailsModalData.country = infoData[i].rawValue.country;
        accountDetailsModalData.postal_zip = infoData[i].rawValue.postal_zip;
      } else if (infoData[i].name === "is_owner") {
        accountDetailsModalData.is_owner = infoData[i].value;
      } else if (infoData[i].name === "phone_number") {
        accountDetailsModalData.phone_number = infoData[i].value;
      } else if (infoData[i].name === "billing_method") {
        accountDetailsModalData.billing_method = infoData[i].value;
      }
    }
    return accountDetailsModalData;
  }, [infoData]);

  const onSubmitAccountDetailsChanges = (values, { resetForm }) => {
    let updates = [];
    for (let i = 0; i < infoData?.length; i++) {
      if (infoData[i].name === "account_holder") {
        updates.push({
          id: infoData[i].id,
          value: { value: values.account_holder },
        });
      } else if (infoData[i].name === "mailing_address") {
        updates.push({
          id: infoData[i].id,
          value: {
            value: {
              address1: values.address1,
              address2: values.address2,
              city: values.city,
              province_state: values.province_state,
              country: values.country,
              postal_zip: values.postal_zip,
            },
          },
        });
      } else if (infoData[i].name === "is_owner") {
        updates.push({
          id: infoData[i].id,
          value: { value: values.is_owner.value },
        });
      } else if (infoData[i].name === "phone_number") {
        updates.push({
          id: infoData[i].id,
          value: { value: values.phone_number.replace(/[^a-zA-Z0-9]/g, "") },
        });
      } else if (infoData[i].name === "billing_method") {
        updates.push({
          id: infoData[i].id,
          value: {
            value: values.billing_method?.value || values.billing_method,
          },
        });
      }
    }
    dispatch(
      setConfirmationDialogState({
        open: true,
        title: "Apply changes",
        body: "Your changes will be saved. Do you want to proceed?",
        onConfirm: () => onAdminChanges(resetForm, updates),
      }),
    );
  };

  /**
   * Handles submitting admin changes to the account info when there are no citizen changes
   */
  const onAdminChanges = async (resetForm, updates) => {
    resetForm(true);
    if (REVIEWING_GROUP.includes(changesetStatus)) {
      await updateAccountChangeSet(id, changesetId, updates);
    } else {
      await update_account(id, updates);
    }
    dispatch(closeDialog());
    refresher(true);
  };

  const decorateValue = (name, value) => {
    switch (name) {
      case "phone_number":
        return formatPhoneNumber(value);
      case "is_owner":
        return value ? "I'm the owner" : "I'm a tenant";
      default:
        return value;
    }
  };

  const getOriginalValue = (value, name = "") => {
    if (value !== undefined) {
      if (name === "phone_number") {
        return decorateValue("phone_number", value);
      } else if (name === "is_owner") {
        return decorateValue("is_owner", value);
      }
      if (value.city) {
        return decorateValue("service_address", value);
      } else {
        return value;
      }
    }
    return undefined;
  };

  const getDetailItemIcon = (fieldName) => {
    switch (fieldName) {
      case "account_number":
        return <Tag />;
      case "access_code":
        return <Check />;
      case "batch_number":
        return <FolderOpen />;
      case "account_holder":
        return <PersonOutline />;
      case "service_address":
        return <LocationOnOutlined />;
      case "mailing_address":
        return <MailOutlined />;
      case "is_owner":
        return <HomeOutlined />;
      case "phone_number":
        return <CallOutlined />;
      case "billing_method":
        return <LocalPostOfficeOutlined />;
      default:
        return;
    }
  };

  /**
   * Memo version of changed UT info keys passed to edit modal for highlighting field backgrounds
   * @type {string[]}
   */
  const changedFormKeys = useMemo(() => {
    let _changedFormKeysNames = [];
    infoData?.forEach((e) => {
      if (e.name === "mailing_address") {
        Object.keys(e.originalValueRaw ?? {}).forEach((key) => {
          if (e.originalValueRaw[key] !== undefined && e.rawValue[key] !== e.originalValueRaw[key])
            _changedFormKeysNames.push(key);
        });
      } else {
        if (e.originalValue !== undefined && e.value !== e.originalValue) _changedFormKeysNames.push(e.name);
      }
    });
    return _changedFormKeysNames;
  }, [infoData]);

  useEffect(() => {
    createAccountDataShape();
    const consumption = accountConsumptionData;
    if (consumption?.value !== undefined && consumption.value.value !== "-") {
      let _data = [...[consumption?.value?.value?.latest ?? null], ...(consumption?.value?.value?.previous ?? [])]
        .sort((a, b) => {
          if (moment(a?.periodEnd).isAfter(b?.periodEnd)) return 1;
          if (!moment(a?.periodEnd).isAfter(b?.periodEnd)) return -1;
          return 0;
        })
        .reduce((prev, current) => {
          if (prev[current?.["periodEnd"]] === undefined) prev[current?.["periodEnd"]] = 0;
          prev[current?.["periodEnd"]] = (current?.amount ?? 0) + (prev[current?.["periodEnd"]] ?? 0);
          return prev;
        }, {});
      let __data = {};
      for (const key in _data) {
        const formattedKey = moment(key).format("MMM 'YY");
        __data[formattedKey] = _data[key];
      }
      setWaterConsumptionData(__data);
    }
  }, [accountConsumptionData, createAccountDataShape]);

  return (
    <div className={classes.container}>
      {REVIEWING_GROUP.includes(changesetStatus) && (
        <>
          <Alert
            variant={"info"}
            title={"Please review changes"}
            body={"Review the highlighted modifications below and approve or make any additional edits as necessary."}
          />
          <Spacer />
        </>
      )}
      <Grid container spacing={7}>
        <Grid item xs={5}>
          <DetailsContainer
            loading={loading}
            title={"Account Details"}
            isEditable={true}
            buttonLabel={"Edit details"}
            buttonOnClick={() => setIsEditDialogOpen(true)}
            buttonDisable={false}
          >
            {infoData?.map((item) => {
              return (
                <ListItem
                  key={item.id}
                  variant="detail"
                  label={item.label}
                  subLabel={decorateValue(item.name, item.value)}
                  originalValue={getOriginalValue(item?.originalValue, item.name)}
                  startOrnament={getDetailItemIcon(item.name)}
                />
              );
            })}
          </DetailsContainer>
        </Grid>
        <Grid item xs={7}>
          <AccountBar
            hideLinkButton={linkedUser?.email === undefined}
            variant={linkedUser?.email === undefined ? "LINK" : "USER"}
            name={linkedUser?.email === undefined ? "Link to account..." : linkedUser?.email}
            isLinked={linkedUser?.email !== undefined}
            onUnlink={() => {
              dispatch(
                setConfirmationDialogState({
                  open: true,
                  title: "Unlinking account",
                  body: `${linkedUser?.email}'s account will be unlinked from this utilities account and will lose access to it on MyCityHall. You can re-link the accounts later.`,
                  onConfirm: unlinkUser,
                }),
              );
            }}
            onBarClick={linkedUser?.email ? () => {} : () => setIsLinkUserModalOpen(true)}
            disableBarClick={linkedUser?.email}
          />
          <div className={classes.margin_bottom} />
          {!loading ? (
            <>
              <p className={`${typographyClasses.h2} ${classes.margin_bottom}`}>Water Consumption History</p>
              <BarGraph
                data={waterConsumptionData}
                title={"VOLUME (m³)"}
                labels={Object.keys(waterConsumptionData)}
                bottomTitle={""}
              />
              <Spacer />
            </>
          ) : (
            <Skeleton variant={"rectangular"} style={{ borderRadius: 10 }} height={290} />
          )}
          <ShareEBill
            loading={loading}
            contactItems={contactItems}
            emptyText={"Citizen has no contacts - only they can make edits here."}
          />
        </Grid>
        <Grid item xs={12} style={{ marginBottom: "5rem", paddingTop: "20px" }}>
          <HistoryTab
            fetchHistories={fetchHistories}
            maxToShow={2}
            history={allHistory}
            _addComment={addComment}
            data={infoData}
            setTab={setTab}
            isUTAccount={true}
          />
        </Grid>
      </Grid>
      <AccountFormModal
        open={isEditDialogOpen}
        handleClose={() => setIsEditDialogOpen(false)}
        onSubmit={onSubmitAccountDetailsChanges}
        initValues={createAccountDataShape()}
        helperValues={{
          serviceAddress: infoData?.find((item) => item.name === "service_address").rawValue,
        }}
        adminSide={true}
        showReviewAlert={hasCitizenChanges}
        changedFormKeys={changedFormKeys}
        version="admin"
      />
      <LinkUserToAccountModal
        id={id}
        isUtilityAccount={true}
        accessCode={infoData?.find((e) => e.name === "access_code")?.value}
        accountNumber={infoData?.find((e) => e.name === "account_number")?.value}
        serviceAddress={infoData?.find((e) => e.name === "service_address")?.value}
        open={isLinkUserModalOpen}
        handleClose={() => {
          setIsLinkUserModalOpen(false);
        }}
        data={infoData}
        fetchData={fetchData}
      />
    </div>
  );
};

export default UtilityInfoTab;
