import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import { SyncRounded } from "@mui/icons-material";

const AdminAvatar = ({ id, firstName, lastName, styles, updatable, hoverColor, color }) => {
  const [hover, setHover] = useState(false);

  const getUpperCase = (str) => {
    try {
      return str[0].toUpperCase();
    } catch (e) {
      return "-";
    }
  };

  return (
    <Tooltip title={firstName + " " + lastName}>
      <Avatar
        key={id}
        className={`admin-avatar ${!!hoverColor ? "has-hover-color" : ""}`}
        style={{ "--hover-color": `${hoverColor}`, ...styles }}
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
        hovercolor={hoverColor}
      >
        {updatable ? (
          hover ? (
            <SyncRounded sx={{ color: color ? color : "inherit" }} />
          ) : (
            getUpperCase(firstName) + getUpperCase(lastName)
          )
        ) : (
          getUpperCase(firstName) + getUpperCase(lastName)
        )}
      </Avatar>
    </Tooltip>
  );
};

export default AdminAvatar;
