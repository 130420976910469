import Banner from "@components/ui-kit/Banner";
import React from "react";
import { useTheme } from "@mui/material";
import IUtilityAccountResponse from "@core/interfaces/utility/iUtilityAccountResponse";
import { WaterDamageOutlined } from "@mui/icons-material";

export default function UtilityBanner({
  account,
  hasPadding = true,
}: {
  account: IUtilityAccountResponse;
  hasPadding?: boolean;
}) {
  const theme = useTheme();
  return (
    <Banner
      title={`Account #: ${account?.accountNumber}`}
      body={account?.address}
      styles={{
        backgroundColor: theme.palette.primary[50],
        padding: hasPadding ? "0.5rem" : "0",
      }}
      Icon={<WaterDamageOutlined color={"primary"} sx={{ width: "38px", height: "38px" }} />}
    />
  );
}
