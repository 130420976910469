import React, { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState } from "react";
import Tabs from "@components/ui-kit/tabs";
import Tab from "@components/ui-kit/tab";
import MainContainer from "@components/layout-helpers/triple-column-layout/MainContainer";
import LeftColumn from "@components/layout-helpers/triple-column-layout/LeftColumn";
import RightColumn from "@components/layout-helpers/triple-column-layout/RightColumn";
import StatementBalanceCard from "./widgets/StatementBalanceCard";
import moment from "moment";
import { useHistory, useLocation, useParams } from "react-router-dom";
import useHash from "@hooks/useHash";
import { Tooltip } from "@mui/material";
import TabPanel from "@components/ui-kit/tab-pannel";
import InfoTab from "./tabs/info-tab";
import ServiceTab from "./tabs/service-tab";
import BillingTab from "./tabs/billing-tab";
import { getAccountDetail } from "@core/apis/account";
import { IFormField } from "@core/interfaces/utility/IFormField";
import { CheckCircleOutlineRounded, Home, WarningRounded } from "@mui/icons-material";
import WBCBreadcrumbs from "@components/ui-kit/breadcrumbs";
import classes from "./index.module.scss";
import { routes } from "@routes/routesData";
import { NotificationContext } from "@contexts/NotificationContext";
import Dialog from "@components/ui-kit/Dialog";
import ManageAccountTab from "./tabs/manage-account-tab";

const tabs = [
  { id: "0", label: "Utilities Info" },
  { id: "1", label: "Services" },
  { id: "2", label: "Billing & Payments" },
  { id: "3", label: "Manage Account" },
];
const financialInfoKeyNames = ["balance", "due_date", "last_payment"];
const infoTabFields = [
  "account_number",
  "access_code",
  "batch_number",
  "account_holder",
  "service_address",
  "mailing_address",
  "is_owner",
  "phone_number",
];
const serviceTabFields = [
  "property_type",
  "water_metre_number",
  "water_metre_size",
  "waste_metre_size",
  "solid_waste_general",
  "solid_waste_cardboard",
  "solid_waste_size",
  "collection_services",
];

export default function UtilityDetails() {
  const history = useHistory();
  const location = useLocation();
  const [showServiceWarning, setShowServiceWarning] = useState(false);
  const hash = useHash();
  const { id }: any = useParams();
  const [utilityFullData, setUtilityFullData] = useState<any>(null);
  const [item, setItem] = useState<Record<string, any>>({});
  const [tab, setTab] = useState(hash || tabs[0].id);
  const [infoChangeSetStatus, setInfoChangeSetStatus] = useState();
  const [infoChangeSetId, setInfoChangeSetId] = useState();
  const [servicesChangeSetId, setServicesChangeSetId] = useState("");
  const [servicesChangeSetStatus, setServicesChangeSetStatus] = useState();
  const [serviceData, setServiceData] = useState(null) as any;
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errored, setErrored] = useState(false);
  const [showSuccessLinkModal, setShowSuccessLinkModal] = useState(false);
  const { handleError } = useContext(NotificationContext);

  useLayoutEffect(() => {
    // @ts-ignore
    if (location.state?.showSuccessDialog) {
      setShowSuccessLinkModal(true);
    }
  }, [location.state]);

  const refresher = () => {
    setRefresh(!refresh);
  };
  const changeTab = (e: any, newTab: any) => {
    history.push(`/utility/${id}#${newTab}`);
  };

  const leftColumnWidth = useMemo(() => (tab === "3" ? "1060px" : null), [tab]);

  const accountDetailAccessor = useCallback(
    (detailName: string) => {
      const detail = item[detailName] as IFormField;
      if (detail === undefined) {
        return {
          id: detailName,
          name: "-",
          type: "-",
          label: "-",
          default: {},
          placeholder: "-",
          value: { value: "-" },
          originalValue: { value: "-" },
          required: false,
        } as IFormField;
      }
      if (detail.value.value === "") {
        detail.value.value = "";
      }

      return detail;
    },
    [item],
  );

  const createAccountDetail = (item: any) => {
    let data = {
      info: [],
      service: {
        propertyType: [],
        solidWasteServices: [],
        wasteWaterServices: [],
        waterServices: [],
      },
      consumption: [],
      financial: [],
      otherInfo: [],
    } as any;
    for (let i = 0; i < item.length; i++) {
      if (item[i].name === "consumption") {
        data.consumption = item[i];
      } else if (infoTabFields.includes(item[i].name)) {
        if (item[i].name.includes("address")) {
          data.info.push({
            id: item[i].id,
            label: item[i].label,
            name: item[i].name,
            value:
              item[i].value.value.address2 +
              (item[i].value.value.address2 ? ", " : "") +
              item[i].value.value.address1 +
              ", " +
              item[i].value.value.city +
              ", " +
              (item[i].value.value.province || item[i].value.value.province_state) +
              ", " +
              item[i].value.value.postal_zip,
            rawValue: item[i].value.value,
            originalValue: item[i].originalValue
              ? item[i].originalValue.value.address2 +
                (item[i].originalValue.value.address2 ? ", " : "") +
                item[i].originalValue.value.address1 +
                ", " +
                item[i].originalValue.value.city +
                ", " +
                (item[i].originalValue.value.province || item[i].originalValue.value.province_state) +
                ", " +
                item[i].originalValue.value.postal_zip
              : undefined,
          });
        } else if (item[i].name === "is_owner") {
          data.info.push({
            id: item[i].id,
            label: item[i].label,
            name: item[i].name,
            value: item[i].value.value,
            originalValue:
              item[i]?.originalValue?.value === true ? true : item[i]?.originalValue?.value === false ? false : undefined,
          });
        } else {
          data.info.push({
            id: item[i].id,
            label: item[i].label,
            name: item[i].name,
            value: item[i].value.value,
            originalValue: item[i]?.originalValue?.value || undefined,
          });
        }
      } else if (serviceTabFields.includes(item[i].name)) {
        if (item[i].name === "property_type") {
          data.service.propertyType.push({
            id: item[i].id,
            label: item[i].label,
            name: item[i].name,
            value: { value: item[i].value.value },
            originalValue: item[i]?.originalValue?.value || undefined,
          });
        } else if (item[i].name === "water_metre_number" || item[i].name === "water_metre_size") {
          data.service.waterServices.push({
            id: item[i].id,
            label: item[i].label,
            name: item[i].name,
            value: {
              value: typeof item[i].value.value === "string" ? item[i].value.value : "",
            },
            originalValue:
              (typeof item[i]?.originalValue?.value === "string" ? item[i]?.originalValue?.value : undefined) || undefined,
          });
        } else if (item[i].name === "waste_metre_size") {
          data.service.wasteWaterServices.push({
            id: item[i].id,
            label: item[i].label,
            name: item[i].name,
            value: { value: item[i].value.value },
            originalValue: item[i]?.originalValue?.value || undefined,
          });
        } else if (
          item[i].name === "solid_waste_general" ||
          item[i].name === "solid_waste_cardboard" ||
          item[i].name === "solid_waste_size" ||
          item[i].name === "collection_services"
        ) {
          data.service.solidWasteServices.push({
            id: item[i].id,
            label: item[i].label,
            name: item[i].name,
            value: { value: item[i].value.value },
            originalValue: item[i]?.originalValue?.value || undefined,
          });
        }
      } else if (item[i].name === "info_change_set_id") {
        setInfoChangeSetId(item[i].value.value);
      } else if (item[i].name === "info_change_set_status") {
        setInfoChangeSetStatus(item[i].value.value);
      } else if (item[i].name === "services_change_set_id") {
        setServicesChangeSetId(item[i].value.value);
      } else if (item[i].name === "services_change_set_status") {
        setServicesChangeSetStatus(item[i].value.value);
      } else if (financialInfoKeyNames.includes(item[i].name)) {
        data.financial.push(item[i]);
      } else {
        data.otherInfo.push(item[i]);
      }
    }
    return data;
  };

  const fetchAccount = useCallback(async () => {
    try {
      setLoading(true);
      const results = await getAccountDetail(id);
      const response = createAccountDetail(results);
      setUtilityFullData(response);
      setServiceData(response.service);
      let refractedValues: Record<string, any> = {};
      results.forEach((field: IFormField) => {
        refractedValues[field.name] = field;
      });

      setItem(refractedValues);
      setInfoChangeSetStatus(refractedValues?.info_change_set_status?.value?.value);
      setInfoChangeSetId(refractedValues?.info_change_set_id?.value?.value);
      setServicesChangeSetId(refractedValues?.services_change_set_id?.value?.value);
      setServicesChangeSetStatus(refractedValues?.services_change_set_status?.value?.value);

      if (refractedValues["services_reviewed"] !== undefined) {
        setShowServiceWarning(!refractedValues["services_reviewed"].value.value);
      }
    } catch (e: any) {
      setErrored(true);
      if (e.response.status === 404) {
        history.replace("/404");
      }
      if (e.response.status === 403) {
        history.replace("/403");
      } else {
        handleError("Unable to load account details");
      }
    } finally {
      setLoading(false);
    }
  }, [handleError, history, id]);

  const handleSidebarAction = useCallback(() => {
    history.push(`${routes.CHECKOUT.path}?account=${id}`);
  }, [history, id]);

  useEffect(() => {
    if (!errored) {
      fetchAccount().then();
      const t = hash || tabs[0].id;
      setTab(t);
    }
  }, [errored, fetchAccount, hash, refresh]);

  /**
   * Memo version of last payment date
   */
  const lastPaymentDate = useMemo(() => {
    return !!utilityFullData?.financial?.find((e: any) => e.name === "last_payment")?.value?.value
      ? moment(utilityFullData?.financial?.find((e: any) => e.name === "last_payment")?.value?.value).format("MMM DD, YYYY")
      : undefined;
  }, [utilityFullData?.financial]);

  /**
   * Memo version of due date
   */
  const dueDate = useMemo(() => {
    const balance = utilityFullData?.financial?.find((e: any) => e.name === "due_date")?.value?.value;

    const dueDate =
      balance && utilityFullData?.financial?.find((e: any) => e.name === "due_date")?.value?.value
        ? moment(utilityFullData?.financial?.find((e: any) => e.name === "due_date")?.value?.value).format("MMM DD, YYYY")
        : undefined;

    return dueDate;
  }, [utilityFullData?.financial]);

  const handleClosingSuccessLinkModal = () => {
    setShowSuccessLinkModal(false);
    history.replace(routes.UTILITY_DETAILS.codePath + id);
  };

  return (
    <div>
      <WBCBreadcrumbs
        className={classes.bread_crumbs}
        children={[
          {
            icon: <Home fontSize={"small"} />,
            text: "HOME",
            onClick: () => history.replace("/portal"),
          },
          {
            text: "UTILITIES",
          },
        ]}
      />
      <Tabs onChange={changeTab} value={tab} className={"padding_left"}>
        <Tab value={tabs[0].id} label={tabs[0].label}></Tab>
        <Tab
          icon={
            showServiceWarning ? (
              <Tooltip title={"Action required"} placement="top">
                <div>
                  <WarningRounded color={"warning"} sx={{ paddingRight: "0.5rem" }} />
                </div>
              </Tooltip>
            ) : (
              <></>
            )
          }
          value={tabs[1].id}
          label={tabs[1].label}
        ></Tab>
        <Tab value={tabs[2].id} label={tabs[2].label}></Tab>
        <Tab value={tabs[3].id} label={tabs[3].label}></Tab>
      </Tabs>
      <MainContainer maxWidth={"1440px"}>
        <LeftColumn width={leftColumnWidth}>
          <TabPanel value={tab} index={tabs[0].id}>
            <InfoTab
              id={id}
              showReviewWarning={showServiceWarning}
              accessor={accountDetailAccessor}
              loading={loading}
              changeSetStatus={infoChangeSetStatus}
              changeSetId={infoChangeSetId}
              refresher={refresher}
            />
          </TabPanel>
          <TabPanel value={tab} index={tabs[1].id}>
            <ServiceTab
              id={id}
              loading={loading}
              accessor={accountDetailAccessor}
              changeSetStatus={servicesChangeSetStatus}
              changeSetId={servicesChangeSetId}
              refresher={refresher}
              serviceData={serviceData}
              fullData={utilityFullData}
            />
          </TabPanel>
          <TabPanel value={tab} index={tabs[2].id}>
            <BillingTab id={id} />
          </TabPanel>
          <TabPanel value={tab} index={tabs[3].id}>
            <ManageAccountTab id={id} />
          </TabPanel>
        </LeftColumn>
        <RightColumn className={classes.right_column}>
          <StatementBalanceCard
            id={id}
            onClick={handleSidebarAction}
            loading={loading}
            statementAmount={item["statement_balance"]?.value.value || 0.0}
            accountAmount={item["balance"]?.value.value || 0.0}
            paidAmount={item["paid"]?.value.value || 0.0}
            dueDate={!!dueDate ? `Due date: ${dueDate}` : ""}
            lastPaymentDate={!!lastPaymentDate ? `Last payment: ${lastPaymentDate}` : ""}
          />
        </RightColumn>
      </MainContainer>
      <Dialog
        variant={"updated"}
        open={showSuccessLinkModal}
        handleClose={handleClosingSuccessLinkModal}
        body={
          <div className={classes.flex_column}>
            <CheckCircleOutlineRounded color={"primary"} sx={{ width: "64px", height: "64px" }} />
            <p className={classes.dialog_body_text}>You linked your account successfully</p>
          </div>
        }
        buttonOneText={"Link another account"}
        buttonTwoText={"Continue"}
        buttonTwoVariant={"contained"}
        buttonFlexDirection={"column-reverse"}
        handleButtonOne={() => {
          history.replace(routes.LICENCES.path);
        }}
        handleButtonTwo={handleClosingSuccessLinkModal}
      />
    </div>
  );
}
