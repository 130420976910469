import styled from "@emotion/styled";

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1350px;
  padding-top: 1rem;
  margin-left: 18px;
  margin-right: 18px;
  width: 100%;
`;

export const Content = styled.div`
  max-width: 375px;
  margin: 2rem auto 0;
  display: flex;
  align-items: center;
  flex-direction: column;
`;
