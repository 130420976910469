import React from "react";
import { Bar } from "react-chartjs-2";
import { useTheme } from "@mui/material";
import typographyClasses from "@assets/styles/typography/typography.module.scss";
import { BarElement, CategoryScale, Chart as ChartJS, defaults, Legend, LinearScale, Title, Tooltip } from "chart.js";
import classes from "./index.module.scss";

//Sets the font for the graph
defaults.font.family = "Rubik";

//Must register items before they can be rendered by ChartJS
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarGraph = ({ data = [0], labels = [""], title = "", bottomTitle = "", columnScale = "m³" }) => {
  const theme = useTheme();
  const isEmpty = () => {
    return Array.isArray(data)
      ? Boolean(!data.reduce((previousValue, currentValue) => previousValue + currentValue, 0))
      : !(Object.keys(data).length > 0);
  };

  return (
    <div style={{ position: "relative" }}>
      {isEmpty() ? (
        <div className={classes.empty_state}>
          <p className={classes.empty_text}>No data available</p>
        </div>
      ) : null}
      <Bar
        options={{
          indexAxis: "x" as const,
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: true,
              text: title,
              position: "left",
              color: theme.palette.blacks.BLACK_MEDIUM_EMPHASIS,
              padding: 5,
              font: { weight: "400" },
            },
          },
          scales: {
            y: {
              ticks: {
                callback: function (value: any) {
                  return value > 0 ? value + columnScale : 0;
                },
              },
            },
          },
        }}
        data={{
          labels,
          datasets: [
            {
              data: data,
              backgroundColor: theme.palette.primary[200],
            },
          ],
        }}
      />
      {bottomTitle ? (
        <p style={{ fontSize: 12, textAlign: "center", fontWeight: 700 }} className={typographyClasses.overline}>
          {bottomTitle}
        </p>
      ) : null}
    </div>
  );
};

export default BarGraph;
