import axios from "axios";
import config from "./_config";
import { manageSession } from "@utils/manageSession";

const base = axios.create({
  baseURL: config.baseURL + "/address",
  crossDomain: true,
  headers: {
    "Content-Type": "application/json",
  },
});

export const save_address = async (values, addressType, newUserId) => {
  const userDataInCookie = manageSession.getSessionInfo();
  const userId = newUserId || userDataInCookie?.id;
  const dataToSubmit = {
    userId,
    cityId: config.cityId,
    addressType: addressType,
    address1: values.address1,
    unit: values.unit,
    postalCode: values.code,
    isDefault: values.defaultAddress,
  };

  try {
    base.defaults.headers["Authorization"] = `Bearer ${userDataInCookie?.userToken}`;
    const response = await base.post("/create", dataToSubmit);
    return response.data;
  } catch (error) {}
};
