import { useCallback, useContext, useEffect, useState } from "react";
import { getAccountDetail } from "@core/apis/account";
import { NotificationContext } from "@contexts/NotificationContext";
import { useHistory } from "react-router-dom";

const usePropertyTaxDetails = (id: string) => {
  const [data, setData] = useState({} as any);
  const [assessments, setAssessments] = useState([] as any);
  const [loading, setLoading] = useState(true);
  const { handleShow } = useContext(NotificationContext);
  const history = useHistory();

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const results = await getAccountDetail(id);
      const objectResults = {} as any;
      results.forEach((item) => {
        objectResults[item.name] = item;
      });
      setData(objectResults);
    } catch (e: any) {
      if (e.response.status === 404) {
        history.replace("/404");
      }
      if (e.response.status === 403) {
        history.replace("/403");
      } else {
        handleShow("Unable to load account details");
      }
    } finally {
      setLoading(false);
    }
  }, [id, handleShow, history]);

  useEffect(() => {
    fetchData().then();
  }, [fetchData]);

  useEffect(() => {
    if (!data || !data?.assessment?.value?.value) return;
    setAssessments([data?.assessment?.value?.value?.latest, ...data?.assessment?.value?.value.previous]);
  }, [data]);

  return { assessments, loading, data };
};

export default usePropertyTaxDetails;
