import React, { useState } from "react";
import { Autocomplete, useTheme } from "@mui/material";
import TextField from "@mui/material/TextField";
import { StyleOutlined } from "@mui/icons-material";
import Typography from "../typography";
import styled from "styled-components";
import TagEditorInfoButton from "./widgets/TagEditorInfoButton";
import Dialog from "../Dialog";

const InnerContainer = styled.div`
  align-items: center;
  display: flex;
  max-width: 90%;
`;

/**
 *
 * @param value
 * @param onChange
 * @param options
 * @param addTag
 * @param removeTag
 * @constructor
 */
const TagEditor = ({ value, options, addTag = () => {}, removeTag = () => {} }: any) => {
  const theme = useTheme();
  const [isFocused, setIsFocused] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const tagLabel = () => (
    <InnerContainer>
      <StyleOutlined />
      <Typography variant="h6" margin="0 0 0 0.5rem" whiteSpace="nowrap" color={theme.palette.primary} fontWeight={500}>
        Tags
      </Typography>
    </InnerContainer>
  );

  return (
    <>
      <Autocomplete
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        multiple
        value={value}
        getOptionLabel={(option) => option.token}
        onChange={(event, values, reason, option) => {
          if (reason === "createOption") {
            addTag(option?.option);
          } else if (reason === "removeOption") {
            removeTag(option?.option);
          } else if (reason === "selectOption") {
            addTag(option?.option?.token);
          }
        }}
        options={options}
        freeSolo={true}
        renderInput={({ InputProps, ...params }) => (
          <TextField
            sx={{
              "& .MuiInputBase-root": {
                borderRadius: "10px",
                borderColor: theme.palette.nonPalette.OUTLINE,
                notchedOutline: {
                  borderWidth: "1px",
                  borderColor: "yellow !important",
                  paddingRight: "5px",
                },
                ":hover": {
                  backgroundColor: theme.palette.primary[50],
                },
              },
            }}
            InputProps={{
              ...InputProps,
              endAdornment: !isFocused ? <TagEditorInfoButton onClick={() => setOpenModal(true)} /> : InputProps.endAdornment,
            }}
            onKeyDown={(event: any) => {
              if (event.key === "Backspace") {
                event.stopPropagation();
              }
            }}
            variant={"outlined"}
            {...params}
            label={tagLabel()}
          />
        )}
        fullWidth={true}
      />
      <Dialog
        showCloseButton={true}
        variant={"updated"}
        open={openModal}
        handleClose={() => {
          setOpenModal(false);
        }}
        title={"What are tags?"}
        body={
          <p className={"body_regular"}>
            Tagging a licence serves as a method to label a business licence, providing clear tag for its usage. You will be able
            to reference this tagged licence in your generated reports.
            <br />
            <br />
            To create a tag, just type in the name of the tag and press the 'Enter' key to save it.
          </p>
        }
      />
    </>
  );
};

export default TagEditor;
