import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { get_invoice } from "@core/apis/payment";
import { routes } from "@routes/routesData";
import IconButton from "@mui/material/IconButton";
import { AddRounded, CloseRounded, Description, ExpandMore } from "@mui/icons-material";
import { invoiceStatusFormatter } from "@utils/data-table-format-helpers";
import classes from "./index.module.scss";
import typographyClasses from "@assets/styles/typography/typography.module.scss";
import Button from "@mui/material/Button";
import moment from "moment";
import Spacer from "@components/ui-kit/Spacer";
import { convertUTCToLocalTimeZone, formatCurrency } from "@utils/index";
import { getLicenceInvoicePDF } from "@core/apis/licence";
import FileDownload from "js-file-download";
import TextField from "@mui/material/TextField";
import { getInvoiceHistory, getInvoiceOptions, updateInvoiceInPlace } from "@core/apis/invoice";
import Select from "@components/ui-kit/select";
import uuid from "react-uuid";
import Dialog from "@components/ui-kit/Dialog";
import { Collapse, Typography } from "@mui/material";
import { OPEN } from "@core/constants/invoices";

const InvoiceEditorScreen = () => {
  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const [viewportSize, setViewportSize] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const [data, setData] = useState({
    info: null,
    invoice: null,
  });
  const [editMode, setEditMode] = useState(false);
  const [memo, setMemo] = useState("");
  const [purpose, setPurpose] = useState("");
  const [defaultItems, setDefaultItems] = useState([]);
  const [invoiceItems, setInvoiceItems] = useState([]);
  const [originalInvoiceItems, setOriginalInvoiceItems] = useState([]);
  const [discardChangesOpen, setDiscardChangesOpen] = useState(false);
  const [confirmChangesOpen, setConfirmChangesOpen] = useState(false);
  const [closingOnEdit, setClosingOnEdit] = useState(false);
  const [invoiceHistoryItems, setInvoiceHistoryItems] = useState([]);
  const [expandHistory, setExpandHistory] = useState(false);

  /**
   * With initial render of the screen:
   * - Calls the function for getting invoice full details
   * - Calls the function for getting the default invoice items
   * - Adds a resize event listener to the window
   */
  useEffect(() => {
    getInvoiceFullDetails().then();
    getInvoiceItems().then();
    getInvoiceHistoryItems().then();
    window.addEventListener("resize", updateViewportSize);

    return () => {
      window.removeEventListener("resize", updateViewportSize);
    };
    // eslint-disable-next-line
  }, []);

  /**
   * Call back function passed to the resize handler for updating the [viewportSize] in inner state
   */
  const updateViewportSize = () => {
    setViewportSize({
      height: window.innerHeight,
      width: window.innerWidth,
    });
  };

  /**
   * Calls the api for getting the full details of the invoice based on the url params and state
   */
  const getInvoiceFullDetails = async () => {
    const response = await get_invoice(params.id);
    if (!response || location.state === undefined) {
      history.replace(routes.NOTFOUND.path);
      return;
    }
    setData({
      info: location.state,
      invoice: response.data,
    });
    setPurpose(response.data.purpose);
  };

  /**
   * Calls the api for getting the default invoice items
   * @returns {Promise<void>}
   */
  const getInvoiceItems = async () => {
    const response = await getInvoiceOptions();
    setDefaultItems(
      response.items.map((e) => ({
        ...e,
        price: e.price.toFixed(2),
      })),
    );
  };

  /**
   * Calls the api for getting the invoice history items
   * @returns {Promise<void>}
   */
  const getInvoiceHistoryItems = async () => {
    const response = await getInvoiceHistory(params.id);
    if (!response) return;
    setInvoiceHistoryItems(response);
  };

  /**
   * Navigates user back to BL invoices tab
   */
  const onClose = () => {
    if (editMode) {
      setClosingOnEdit(true);
      setDiscardChangesOpen(true);
      return;
    }
    history.goBack();
  };

  /**
   * Downloads the pdf of the invoice
   * @returns {Promise<void>}
   */
  const downloadPdf = async () => {
    const invoice = await getLicenceInvoicePDF(data.info.id, data.invoice.invoiceNumber);
    if (invoice) {
      FileDownload(invoice, data.info.pdfLink, "application/pdf");
    }
  };

  /**
   * Handles changes in the memo field and sets the [memo] in the inner state
   */
  const onMemoChanged = (e) => {
    setMemo(e.target.value);
  };

  /**
   * Handles changes in the description field and sets the [purpose] in the inner state
   * @param e
   */
  const onDescriptionChanged = (e) => {
    setPurpose(e.target.value);
  };

  /**
   * Enables edit mode by setting [editMode] in inner state to true and populates the [invoiceItems]
   */
  const enableEditMode = () => {
    const defaultItemsLabels = defaultItems?.map((e) => e.label);
    const _invoiceItems = data?.invoice?.detail?.filter((invoice) => defaultItemsLabels.includes(invoice.description));
    setInvoiceItems(
      _invoiceItems.map((e) => ({
        id: uuid(),
        description: e.description,
        price: e.amount.toFixed(2),
      })),
    );
    setOriginalInvoiceItems(
      _invoiceItems.map((e) => ({
        id: uuid(),
        description: e.description,
        price: e.amount.toFixed(2),
      })),
    );
    setMemo("");
    setEditMode(true);
  };

  /**
   * Handles saving the invoice changes
   * @returns {Promise<void>}
   */
  const saveChanges = async () => {
    setConfirmChangesOpen(false);
    await _updateInvoice();
    setEditMode(false);
    await getInvoiceFullDetails();
    await getInvoiceHistoryItems();
  };

  /**
   * Calls the api for updating the invoice full details
   * @returns {Promise<void>}
   * @private
   */
  const _updateInvoice = async () => {
    const update = {
      items: invoiceItems.map((e) => ({
        description: e.description,
        price: parseFloat(e.price),
      })),
      status: data.invoice.status,
      memo: memo,
      purpose: purpose,
    };
    await updateInvoiceInPlace(data.invoice.id, update);
  };

  /**
   * Handles canceling the editing mode
   */
  const handleCancelEditing = () => {
    setInvoiceItems([]);
    setMemo("");
    setEditMode(false);
    setDiscardChangesOpen(false);
  };

  /**
   * Changes an invoice item description in inner state
   * @param event
   * @param invoiceItem
   */
  const changeInvoiceItemDescription = (event, invoiceItem) => {
    setInvoiceItems((prevState) =>
      prevState.map((e) =>
        e.id === invoiceItem.id
          ? {
              ...e,
              description: event.target.value,
              price: defaultItems.find((e) => e.label === event.target.value)?.price,
            }
          : e,
      ),
    );
  };

  /**
   * Changes the price of the invoice item description
   * @param event
   * @param invoiceItem
   * @param blurred
   */
  const onInvoiceItemPriceChange = (event, invoiceItem, blurred) => {
    if (blurred) {
      if (!event.target.value.trim() || event.target.value.trim() === "-") {
        setInvoiceItems((prevState) =>
          prevState.map((e) =>
            e.id === invoiceItem.id
              ? {
                  ...e,
                  price: defaultItems.find((e) => e.label === invoiceItem.description).price,
                }
              : e,
          ),
        );
      } else {
        setInvoiceItems((prevState) =>
          prevState.map((e) =>
            e.id === invoiceItem.id
              ? {
                  ...e,
                  price: parseFloat(event.target.value).toFixed(2),
                }
              : e,
          ),
        );
      }
      return;
    }
    let inputValue = event.target.value.trim();
    const acceptedPattern = /^(-?(0(\.00?)?|\d+(\.\d{0,2})?)?)?$/;
    if (acceptedPattern.test(inputValue)) {
      setInvoiceItems((prevState) =>
        prevState.map((e) =>
          e.id === invoiceItem.id
            ? {
                ...e,
                price: inputValue,
              }
            : e,
        ),
      );
    }
  };

  /**
   * Adds a new invoice item to the invoice
   */
  const addInvoiceItem = () => {
    setInvoiceItems((prevState) => [
      ...prevState,
      {
        id: uuid(),
        description: defaultItems?.[0].label,
        price: defaultItems?.[0]?.price,
      },
    ]);
  };

  /**
   * Removes an invoice item
   * @param invoiceItem
   */
  const removeInvoiceItem = (invoiceItem) => {
    setInvoiceItems((prevState) => prevState.filter((e) => e.id !== invoiceItem.id));
  };

  /**
   * Memo version of indicator for checking if save button is enabled
   * @type {boolean}
   */
  const isSaveDisabled = useMemo(() => {
    // if invoice description has changed
    if (data.invoice?.purpose !== purpose.trim() && purpose?.trim()?.length > 0) {
      return false;
    }
    // if number of invoice items have changed
    if (invoiceItems.length !== originalInvoiceItems.length) {
      return false;
    }
    let _originalItems = originalInvoiceItems.toSorted((a, b) => a.description.localeCompare(b.description));
    let _items = invoiceItems.toSorted((a, b) => a.description.localeCompare(b.description));
    for (let i = 0; i < _items.length; i++) {
      let a = _items[i];
      let b = _originalItems[i];
      if (a.description !== b.description || a.price !== b.price) {
        return false;
      }
    }
    return true;
  }, [data.invoice?.purpose, invoiceItems, originalInvoiceItems, purpose]);

  /**
   * Memo version of the content of the table
   * @type {JSX.Element[]}
   */
  const tableContent = useMemo(() => {
    const defaultItemsLabels = defaultItems?.map((e) => e.label);
    return data?.invoice?.detail
      ?.filter((invoice) => defaultItemsLabels.includes(invoice.description))
      .map((e, index, arr) => (
        <div key={index} className={classes.table_items} style={{ marginBottom: index !== arr.length - 1 ? "1rem" : "" }}>
          <div className={classes.table_description}>
            <p className={typographyClasses.body}>{e?.description ?? ""}</p>
          </div>
          <div className={classes.table_unit}>
            <p className={typographyClasses.body}>1</p>
          </div>
          <div className={classes.table_amount}>
            <p className={typographyClasses.body}>{formatCurrency(e?.amount ?? 0)}</p>
          </div>
        </div>
      ));
  }, [data?.invoice?.detail, defaultItems]);

  /**
   * Memo version of content of the table in edit mode
   * @type {JSX.Element[]}
   */
  const tableContentEditMode = useMemo(() => {
    return invoiceItems.map((invoiceItem, index, arr) => (
      <div key={invoiceItem.id} className={classes.table_items} style={{ marginBottom: index !== arr.length - 1 ? "1rem" : "" }}>
        <div style={{ width: "50%" }} className={classes.table_description}>
          <div style={{ cursor: arr.length === 1 ? "not-allowed" : "" }}>
            <IconButton
              disabled={arr.length === 1}
              children={<CloseRounded />}
              size={"small"}
              sx={{ marginRight: "0.5rem" }}
              onClick={() => removeInvoiceItem(invoiceItem)}
            />
          </div>
          <Select
            menuItemSx={{
              "&.MuiMenuItem-root": {
                fontSize: "14px",
              },
            }}
            sx={{
              ".MuiFilledInput-input": {
                paddingBlock: "unset !important",
                marginTop: "1rem",
              },
              ".MuiFilledInput-root": {
                minHeight: "60px",
              },
              ".MuiInputLabel-filled": {
                top: "4px",
              },
            }}
            classes={{
              paper: {
                width: "100px",
              },
            }}
            label={"Type"}
            value={invoiceItem.description}
            fullWidth
            onChange={(event) => changeInvoiceItemDescription(event, invoiceItem)}
            options={defaultItems.map((e) => ({ value: e.label, label: e.label }))}
          />
        </div>
        <div style={{ width: "30%" }} className={classes.table_unit}>
          <p className={typographyClasses.body}>1</p>
        </div>
        <div style={{ width: "20%" }} className={classes.table_amount}>
          <TextField
            onBlur={(e) => onInvoiceItemPriceChange(e, invoiceItem, true)}
            onChange={(e) => onInvoiceItemPriceChange(e, invoiceItem, false)}
            InputProps={{
              startAdornment: <p style={{ marginLeft: "1rem", marginTop: "1rem" }}>$</p>,
              disableUnderline: true,
            }}
            label={"Amount"}
            value={`${invoiceItem.price}`}
            sx={{
              ".MuiFilledInput-input": {
                paddingBlock: "unset !important",
                paddingLeft: "0 !important",
                marginTop: "1rem",
              },
              ".MuiFilledInput-root": {
                minHeight: "60px",
              },
              ".MuiInputLabel-filled": {
                top: "4px",
              },
            }}
          />
        </div>
      </div>
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultItems, invoiceItems, editMode]);

  return (
    <>
      <div
        className={classes.invoice_editor}
        style={{
          "--width": `${viewportSize.width}px`,
          "--height": `${viewportSize.height}px`,
        }}
      >
        <div className={classes.top_bar}>
          <div className={classes.top_bar_content_wrapper}>
            <div style={{ width: "40px" }} />
            <div className={classes.top_bar_content}>
              <div style={{ display: "flex" }}>
                <div className={classes.top_bar_info}>
                  <p>{`Invoice #${data?.invoice?.invoiceNumber ?? ""}`}</p>
                  <p>{editMode ? "Edit Invoice" : "Preview"}</p>
                </div>
                <div>{invoiceStatusFormatter(data?.invoice?.status ?? "")}</div>
              </div>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                {!editMode && (
                  <>
                    <Button
                      variant={"outlined"}
                      style={{ marginRight: "1rem" }}
                      startIcon={<Description />}
                      onClick={downloadPdf}
                    >
                      Download PDF
                    </Button>
                    <Button variant={"contained"} onClick={enableEditMode} disabled={data?.invoice?.status !== OPEN}>
                      Edit
                    </Button>
                  </>
                )}
                {editMode && (
                  <>
                    <TextField
                      value={purpose}
                      label={"Invoice description"}
                      onChange={onDescriptionChanged}
                      placeholder={"Invoice description goes here"}
                      sx={{
                        marginRight: "1rem",
                        minWidth: "500px",
                        ".MuiFilledInput-input": {
                          paddingBlock: "unset !important",
                          marginTop: "1rem",
                        },
                        ".MuiFilledInput-root": {
                          minHeight: "60px",
                        },
                        ".MuiInputLabel-filled": {
                          top: "4px",
                        },
                      }}
                    />
                    <Button variant={"contained"} onClick={() => setConfirmChangesOpen(true)} disabled={isSaveDisabled}>
                      Save
                    </Button>
                    <p className={classes.cancel_text_button} onClick={() => setDiscardChangesOpen(true)}>
                      Cancel
                    </p>
                  </>
                )}
              </div>
            </div>
            <IconButton style={{ marginRight: "0.5rem" }} children={<CloseRounded />} onClick={onClose} />
          </div>
          {!editMode && (
            <div className={classes.history_section_label_wrapper}>
              <div className={`${classes.history_section_label} ${expandHistory ? classes.open : ""}`}>
                <div onClick={() => setExpandHistory((prevState) => !prevState)}>
                  <p>Change log</p>
                  <ExpandMore />
                </div>
              </div>
            </div>
          )}
          {!editMode && (
            <div className={`${classes.history_section_wrapper} ${expandHistory ? classes.open : ""}`}>
              <Collapse in={expandHistory} style={{ width: "60.5%" }}>
                <div className={classes.history_section}>
                  {invoiceHistoryItems.length > 0 ? (
                    invoiceHistoryItems.map((e) => (
                      <div className={classes.history_item}>
                        <div style={{ display: "flex" }}>
                          <p className={classes.history_details} style={{ marginRight: "0.4rem" }}>
                            {e.data.message.message}
                          </p>
                          {!!e?.data?.message?.memo && (
                            <p className={classes.history_details}>
                              Memo:
                              <span className={classes.memo}>{`"${e?.data?.message?.memo}"`}</span>
                            </p>
                          )}
                        </div>
                        <p className={classes.history_date}>
                          {moment(convertUTCToLocalTimeZone(e.lastUpdated)).format("YYYY-MM-DD")}
                        </p>
                      </div>
                    ))
                  ) : (
                    <div className={classes.history_item}>
                      <p className={classes.history_details}>No history records available</p>
                    </div>
                  )}
                </div>
              </Collapse>
            </div>
          )}
        </div>
        <div className={classes.main_section}>
          <p className={typographyClasses.body} style={{ marginTop: "5rem" }}>
            {moment().format("MMM D, YYYY")}
          </p>
          <p className={classes.page_title}>Business Licence Invoice</p>
          <Spacer amount={5} />
          <div className={classes.second_block}>
            <p className={typographyClasses.bold}>Bill to</p>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "300px",
              }}
            >
              <div className={classes.date_lines}>
                <p className={typographyClasses.bold}>Invoice date:</p>
                <p className={typographyClasses.body}>{moment(data?.invoice?.createdAt).format("MMM D, YYYY")}</p>
              </div>
              <div className={classes.date_lines}>
                <p className={typographyClasses.bold}>Invoice number:</p>
                <p className={typographyClasses.body}>{data?.invoice?.invoiceNumber}</p>
              </div>
            </div>
          </div>
          <div className={classes.second_block}>
            <div>
              <p className={typographyClasses.bolder}>
                {data?.invoice?.billingAddress ? data.invoice.billingAddress.businessName : data?.info?.business_name}
              </p>
              <p className={typographyClasses.body}>
                {data?.invoice?.billingAddress ? data.invoice.billingAddress.street : data?.info?.business_address_street}
              </p>
              {!!data?.invoice?.billingAddress && !!data?.invoice?.billingAddress.street2 ? (
                <p className={typographyClasses.body}>{data?.info?.street2}</p>
              ) : !!data?.info?.business_address_street2 ? (
                <p className={typographyClasses.body}>{data?.info?.business_address_street2}</p>
              ) : (
                <></>
              )}
              <div style={{ display: "flex" }}>
                <p className={typographyClasses.body} style={{ marginRight: "0.2rem" }}>
                  {data?.invoice?.billingAddress ? data?.invoice?.billingAddress.city : data?.info?.business_address_city},
                </p>
                <p className={typographyClasses.body}>
                  {data?.invoice?.billingAddress ? data?.invoice?.billingAddress.province : data?.info?.business_address_province}
                </p>
              </div>
              <p className={typographyClasses.body}>
                {data?.invoice?.billingAddress
                  ? data?.invoice?.billingAddress.postalCode
                  : data?.info?.business_address_postal_code}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "300px",
              }}
            />
          </div>
          <Spacer amount={5} />
          <div className={classes.table_container}>
            <div className={classes.table_header}>
              <div className={classes.table_description}>
                <p className={typographyClasses.bold}>Description</p>
              </div>
              <div className={classes.table_unit}>
                <p className={typographyClasses.bold}>Units</p>
              </div>
              <div className={classes.table_amount}>
                <p className={typographyClasses.bold}>Amount</p>
              </div>
            </div>
            <div className={classes.table_content}>{!editMode ? tableContent : tableContentEditMode}</div>
            {editMode ? (
              <div className={classes.table_footer}>
                {invoiceItems.length === 5 ? (
                  <div />
                ) : (
                  <Button
                    disabled={invoiceItems?.length === 5}
                    variant={"outlined"}
                    size={"small"}
                    startIcon={<AddRounded color={"primary"} fontSize={"large"} />}
                    onClick={addInvoiceItem}
                  >
                    Add item
                  </Button>
                )}
                <p className={typographyClasses.bold}>
                  {`Total: ${formatCurrency(
                    invoiceItems.reduce((a, b) => {
                      return a + (isNaN(parseFloat(b.price)) ? 0 : parseFloat(b.price));
                    }, 0),
                  )}`}
                </p>
              </div>
            ) : (
              <div className={classes.table_footer}>
                <div />
                <p className={typographyClasses.bold}>{`Total: ${formatCurrency(data?.invoice?.amountDue ?? 0)}`}</p>
              </div>
            )}
          </div>
          <Spacer amount={5} />
          <div>
            <p className={typographyClasses.bold}>Want to cancel your licence?</p>
            <Spacer amount={1} />
            <p>If you are no longer operating a business in the City of Lacombe, email us at businesslicense@lacombe.ca</p>
            <Spacer amount={3} />
            <p className={typographyClasses.bold}>Do you need help?</p>
            <Spacer amount={1} />
            <p>If you have any questions or concerns, please email us at businesslicense@lacombe.ca</p>
          </div>
          <Spacer amount={20} />
        </div>
      </div>
      <Dialog
        variant={"updated"}
        open={discardChangesOpen}
        handleClose={() => {
          setDiscardChangesOpen(false);
        }}
        title={`Discard changes`}
        body={`Are you sure you want to discard your changes?`}
        buttonOneText={"Cancel"}
        buttonTwoText={"Discard"}
        buttonTwoVariant={"contained"}
        buttonFlexDirection={"column-reverse"}
        handleButtonOne={() => {
          setDiscardChangesOpen(false);
        }}
        handleButtonTwo={closingOnEdit ? () => history.goBack() : handleCancelEditing}
      />
      <Dialog
        variant={"updated"}
        open={confirmChangesOpen}
        handleClose={() => {
          setConfirmChangesOpen(false);
        }}
        title={`Save changes`}
        body={
          <div style={{ width: "400px" }}>
            <Typography
              variant={"body2"}
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                lineHeight: "18.96px",
              }}
            >
              Are you sure you want to save these changes? Add your memo as well.
            </Typography>
            <Spacer />
            <TextField
              value={memo}
              label={"Memo"}
              onChange={onMemoChanged}
              placeholder={"Your memo goes here"}
              sx={{
                minWidth: "400px",
                ".MuiFilledInput-input": {
                  paddingBlock: "unset !important",
                  marginTop: "1rem",
                },
                ".MuiFilledInput-root": {
                  minHeight: "60px",
                },
                ".MuiInputLabel-filled": {
                  top: "4px",
                },
              }}
            />
          </div>
        }
        buttonOneText={"Cancel"}
        buttonTwoText={"Save"}
        buttonTwoVariant={"contained"}
        buttonFlexDirection={"column-reverse"}
        handleButtonOne={() => {
          setConfirmChangesOpen(false);
        }}
        buttonTwoDisable={memo.trim().length < 1}
        handleButtonTwo={saveChanges}
      />
    </>
  );
};

export default InvoiceEditorScreen;
