import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import Typography from "../typography";
import PlaceholderImg from "@assets/images/document.png";
import { CircularProgress, IconButton, Menu, MenuItem, useTheme } from "@mui/material";
import { MoreVert, UploadRounded } from "@mui/icons-material";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.palette.nonPalette.OUTLINE};
  width: 200px;
  height: 210px;
  border-radius: 10px;
  margin-right: 1rem;

  :hover {
    cursor: pointer;
    filter: brightness(70%);
  }
`;

const StyledDocumentCardBanner = styled.div`
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background-size: cover;
  background-image: url(${PlaceholderImg});
  background-repeat: no-repeat;
  //background: url(${PlaceholderImg}) no-repeat center;
`;

const StyledDocumentCardContent = styled.div`
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 0.5rem;
  align-items: center;
  justify-content: space-between;
`;

/**
 * Document card, displays an uploaded file and offers an onClick function that takes index as a parameter
 * @param title
 * @param subTitle
 * @param onClick
 * @param index
 * @param variant
 * @returns {JSX.Element}
 * @constructor
 */
export default function DocumentThumbnail({
  title,
  subTitle,
  onClick,
  variant,
  onDelete,
  onRename,
  loading,
  onDownload,
  canEdit,
}) {
  //Used to anchor the menu to the component
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuItems, setMenuItems] = useState([]);

  //Checks if the menu is open
  const open = Boolean(anchorEl);

  //Sets the anchor to the pressed component
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  //Removes the anchor for the menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    let newItems = [{ label: "Download", onClick: onDownload }];
    if (canEdit) {
      newItems.push({ label: "Rename", onClick: onRename });
      newItems.push({ label: "Delete", onClick: onDelete });
    }
    setMenuItems(newItems);
  }, [canEdit, onDownload, onDelete, onRename]);

  if (variant === "upload") {
    return <UploadDocument loading={loading} title={title} subTitle={subTitle} onClick={onClick} />;
  }

  return (
    <>
      <Container onClick={onClick}>
        <StyledDocumentCardBanner>{loading && <CircularProgress />}</StyledDocumentCardBanner>
        <StyledDocumentCardContent>
          <Typography
            variant={"body1"}
            fontWeight={"bold !important"}
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "noWrap",
            }}
          >
            {title}
          </Typography>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleClick(e);
            }}
          >
            <MoreVert />
          </IconButton>
        </StyledDocumentCardContent>
      </Container>
      <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
        {menuItems.map((item) => (
          <MenuItem
            key={item.label}
            onClick={() => {
              item.onClick();
              handleClose();
            }}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

const UploadIconContainer = styled.div`
  height: 150px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background: ${({ theme }) => theme.palette.primary[50]};
  justify-content: center;
  align-items: center;
  display: flex;
`;

const UploadDocument = ({ loading, onClick }) => {
  const theme = useTheme();

  return (
    <Container onClick={onClick}>
      <UploadIconContainer>
        {loading ? <CircularProgress /> : <UploadRounded color={"primary"} sx={{ height: "60px", width: "60px" }} />}
      </UploadIconContainer>
      <StyledDocumentCardContent>
        <Typography
          variant={"body1"}
          fontWeight={"bold !important"}
          color={theme.palette.primary[200]}
          style={{ margin: "0 auto" }}
        >
          Upload document
        </Typography>
      </StyledDocumentCardContent>
    </Container>
  );
};
