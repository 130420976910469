import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import React from "react";

const StyledGridItem = styled(Grid)`
  display: flex;
  flex-direction: column;
  max-width: 345px;
  align-items: center;
  padding: 0 15px;
`;

/**
 * Made for single column layout designs. Should be only column present on the screen
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
export default function FormColumn({ children }: any) {
  return <StyledGridItem>{children}</StyledGridItem>;
}
