import * as v from "@utils/yupValidators";
import * as yup from "yup";

export const step1Schema = yup.object({
  businessName: v.businessName,
  //businessType: v.businessType,
  businessDescription: v.businessDescription,
  homeBased: yup.bool(),
  include: yup.bool(),
  isOwner: yup.bool(),
  numVehicles: yup.number().when(["isResident", "hasParking", "homeBased"], {
    is: (isResident, hasParking, homeBased) => isResident && hasParking && homeBased,
    then: () => yup.number().optional(),
  }),
  parkingPlaceType: yup.string().when(["isResident", "hasParking", "homeBased"], {
    is: (isResident, hasParking, homeBased) => isResident && hasParking && homeBased,
    then: () => yup.string().optional(),
  }),
  propertyOwnerName: yup.string().when(["isResident", "homeBased", "isOwner"], {
    is: (isResident, homeBased, isOwner) => isResident && homeBased && !isOwner,
    then: () => yup.string().required("Property owner name is required"),
  }),
});

export const step2Schema = yup.object().shape({
  mailingCountry: yup.string.required,
  businessCountry: yup.string.required,
  businessAddress: v.validAddress,
  businessAddressCity: v.validCity,
  businessAddressProvince: v.validState,
  code: v.validPostalZipCode("code"),
  mailingAddressCheckedAsBusinessAddress: yup.bool(),
  mailingAddress: yup.string().when("mailingAddressCheckedAsBusinessAddress", {
    is: false,
    then: v.validAddress,
  }),
  mailingAddressCity: yup.string().when("mailingAddressCheckedAsBusinessAddress", {
    is: false,
    then: v.validCity,
  }),
  mailingAddressProvince: yup.string().when("mailingAddressCheckedAsBusinessAddress", {
    is: false,
    then: v.validState,
  }),
  mailingCode: yup.string().when("mailingAddressCheckedAsBusinessAddress", {
    is: false,
    then: () => v.validPostalZipCode("mailingCode"),
  }),
  phoneNumber: v.validPhoneFormatted,
  secondPhone: v.validPhoneFormattedOptional,
  faxNumber: v.validPhoneFormattedOptional,
  email: v.validEmail,
  websiteAddress: v.validURLOptional,
});

const ownerManagerYup = yup
  .array()
  .of(
    yup.object().shape({
      name: yup.string().max(35, "Full name is too long.").required("Full name is required."),
      phone: v.validPhoneFormatted,
    }),
  )
  .notRequired()
  .when(["owners", "managers"], {
    is: (managers, owners) => managers?.length < 1 && owners?.length < 1,
    then: () => yup.array().min(1, "Must have at least one owner/manager.").required("Must have at least one owner/manager."),
  });

export const ownerManagerSchema = yup.object().shape(
  {
    owners: ownerManagerYup,
    managers: ownerManagerYup,
  },
  [
    ["owners", "managers"],
    ["owners", "owners"],
    ["managers", "managers"],
  ],
);

export const step4Schema = yup.object({
  isMaxed: yup.bool().oneOf([false], "Max file size exceeded"),
});
