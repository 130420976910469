import React, { useContext, useEffect, useState } from "react";
import { NotificationContext } from "@contexts/NotificationContext";
import { FilterContext } from "@contexts/FilterContext";
import classes from "./index.module.scss";
import { TableSettingContext } from "@contexts/TableSettingContext";
import { getAccountSummaries } from "@core/apis/account";
import { GiteOutlined } from "@mui/icons-material";
import { useHistory, useLocation } from "react-router-dom";
import {
  DataGrid,
  gridClasses,
  GridColDef,
  gridStringOrNumberComparator,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";

const columns: GridColDef[] = [
  {
    field: "property_id",
    headerName: "Property ID",
    editable: false,
    flex: 1,
    filterable: false,
  },
  {
    field: "name_service_address",
    headerName: "Property Owner & Address",
    editable: false,
    flex: 1,
    filterable: false,
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      return gridStringOrNumberComparator(v1.name, v2.name, cellParams1, cellParams2);
    },
    renderCell: (params) => (
      <div className={classes.address_column_container}>
        <p>{params.value.name}</p>
        <p>{params.value.address}</p>
      </div>
    ),
  },
  {
    field: "roll_number",
    headerName: "Roll Number",
    editable: false,
    flex: 1,
    filterable: false,
  },
  {
    field: "access_code",
    headerName: "Access code/Pin",
    editable: false,
    flex: 1,
    filterable: false,
  },
  {
    field: "status",
    headerName: "Status",
    editable: false,
    flex: 1,
    filterable: false,
    renderCell: (params) => (
      <div className={classes.status_container}>
        <p>{params.value}</p>
      </div>
    ),
  },
];

const PropertyTaxesScreen = (...props: any) => {
  const location = useLocation();
  const [tableIdentifier] = useState("propertyTaxesList");
  const { handleError } = useContext(NotificationContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const { filters, setFilters } = useContext(FilterContext);
  const [currentFilters] = useState(
    // @ts-ignore
    filters["propertyTaxes"] || {},
  );
  const { tableSettings, setTableSettings } = useContext(TableSettingContext);
  const [currentTableSettings] = useState(tableSettings[tableIdentifier] || props.initialFilters || {});
  const history = useHistory();

  function CustomToolbar() {
    return (
      <GridToolbarContainer style={{ justifyContent: "space-between" }}>
        <Tooltip title="Search by: Property ID">
          <div>
            <GridToolbarQuickFilter />
          </div>
        </Tooltip>
      </GridToolbarContainer>
    );
  }

  async function fetchData(queryOptions: any) {
    try {
      setLoading(true);
      queryOptions.isPropertyTax = true;
      queryOptions.accountType = "property";
      let data = await getAccountSummaries(queryOptions);
      data.result = data.result.map((summary: any) => {
        return {
          ...summary,
          property_id: summary.folio,
          name_service_address: {
            name: summary.name,
            address: summary.address,
          },
          status: "Active",
          roll_number: summary.accountNumber,
          access_code: summary.accessCode,
        };
      });
      setData(data);
    } catch (err: any) {
      handleError(err.response?.data?.message || err?.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    // @ts-ignore
    filters["propertyTaxes"] = currentFilters;
    setFilters(filters);
    tableSettings[tableIdentifier] = currentTableSettings;
    setTableSettings(tableSettings);
  }, [currentFilters, filters, currentTableSettings, tableSettings, tableIdentifier, setFilters, setTableSettings]);

  useEffect(() => {
    fetchData({
      total: true,
      sortBy: "",
      ascending: true,
      filters: {},
      offset: 0,
      limit: 20000,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.styled_container}>
        <div className={classes.fixed_header}>
          <>
            <div className={classes.title_container}>
              <GiteOutlined
                sx={{
                  height: "45px",
                  width: "45px",
                  marginRight: "0.5rem",
                }}
              />
              <Typography variant={"h3"} className={classes.table_title}>
                Property Tax
              </Typography>
            </div>
          </>
        </div>
        <DataGrid
          loading={loading}
          density={"comfortable"}
          // @ts-ignore
          rows={data?.result || []}
          columns={columns}
          initialState={{
            columns: {
              columnVisibilityModel: {
                address: false,
              },
            },
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10, 25]}
          disableRowSelectionOnClick
          style={{ borderColor: "transparent" }}
          slots={{ toolbar: CustomToolbar }}
          sx={{
            [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
              outline: "none",
            },
            [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
              outline: "none",
            },
          }}
          onRowClick={(params) => {
            history.push(`${location.pathname}/${params.row.id}`);
          }}
        />
      </div>
      <div className={"secondary-sidebar"}></div>
    </div>
  );
};

export default PropertyTaxesScreen;
