import React from "react";
import Button from "@mui/material/Button";
import { IManageAccountServiceContainer } from "@core/interfaces/utility/IManageAccountServicesContainer";

const ManageAccountService = ({ service, servicesDisabled }: IManageAccountServiceContainer) => {
  return (
    <div
      className={"outlined_container"}
      style={{
        maxWidth: 300,
        minHeight: 380,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div>
        <h2>{service.title}</h2>
        <p className={"body high_emphasis"}>{service.description}</p>
        <p className={"body bold high_emphasis"} style={{ margin: 0 }}>
          What you'll need:
        </p>
        <ul style={{ paddingLeft: 25, marginTop: "0.5rem" }}>
          {service.neededItems?.map((item) => <li className={"high_emphasis"}>{item}</li>)}
        </ul>
      </div>
      <div>
        <Button onClick={service.callToAction} size={"small"} variant={"outlined"} disabled={servicesDisabled}>
          {service.callToActionTitle}
        </Button>
      </div>
    </div>
  );
};

export default ManageAccountService;
