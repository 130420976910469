import { Skeleton } from "@mui/material";
import Spacer from "@components/ui-kit/Spacer";

const RightSidebarSkeleton = () => {
  return (
    <div className={"admin-detail-sidebar"}>
      <Skeleton variant={"rectangular"} height={70} />
      <Spacer />
      <Skeleton variant={"rectangular"} height={120} />
      <Spacer />
      <Skeleton variant={"rectangular"} height={60} />
      <Spacer />
      <Skeleton variant={"rectangular"} height={60} />
    </div>
  );
};

export default RightSidebarSkeleton;
