import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";

//language=SCSS prefix=*{ suffix=}
const WidthConstraint = styled.div<any>`
  margin: 0 auto;
  max-width: ${({ maxwidth }) => maxwidth};
`;

//language=SCSS prefix=*{ suffix=}
const StyledMainContainer = styled.div<any>`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  padding: ${({ padding }: any) => padding};

  @media (max-width: 720px) {
    align-items: center;
    flex-direction: ${({ columnDirection }: any) => (columnDirection ? columnDirection : "column")};
  }
`;

/**
 * The main container holds the left and right column for the triple column layout, and handles
 * different layouts depending on screen width
 * @param children
 * @param columnDirection
 * @param padding
 * @param maxWidth
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function MainContainer({
  children = [<></>],
  columnDirection = "column-reverse",
  padding = "1rem",
  maxWidth = "1350px",
  ...props
}) {
  return (
    <WidthConstraint maxwidth={maxWidth}>
      <StyledMainContainer padding={padding} columnDirection={columnDirection} {...props}>
        {children}
      </StyledMainContainer>
    </WidthConstraint>
  );
}

MainContainer.propTypes = {
  columnDirection: PropTypes.oneOf(["column", "column-reverse"]),
};
