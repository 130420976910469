import React, { useEffect, useState } from "react";
import { Button, Unstable_Grid2 as Grid, useTheme } from "@mui/material";
import ListItem from "@components/ui-kit/list-item";
import Typography from "@components/ui-kit/typography";
import Spacer from "@components/ui-kit/Spacer";
import moment from "moment";
import { DRAFT, editStatuses, getDisplayStatus, SAVED } from "@core/constants/statuses";
import { formatPhoneNumber, formatPostalCode } from "@utils/masked-input-formatters";
import { abbreviator } from "@core/constants/province-states";
import {
  AlternateEmailRounded,
  ArticleOutlined,
  DirectionsCarOutlined,
  DriveFileRenameOutline,
  EditRounded,
  EventRounded,
  FaxOutlined,
  FmdGoodOutlined,
  LanguageRounded,
  LocalPhoneOutlined,
  MailOutlineRounded,
  PersonOutlineRounded,
  PersonPinOutlined,
  TagRounded,
} from "@mui/icons-material";
import Chip from "@components/ui-kit/Chip";
import { ACTIVE, prices, RENEWING } from "@core/constants/licences";
import Alerts from "@components/ui-kit/Alert";
import { getExpiryRenewDate } from "./functions/business_licence_functions";

const GeneralTab = ({ status, licence, editPage, canEdit, originalValues, renewalProcess = false }) => {
  const [license] = useState(licence);
  const theme = useTheme();
  const [showOrgManager, setShowOrgManager] = useState(false);
  const [hasAutoPay] = useState(licence.autoPay);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 600);
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getResidencyStatus = (vals) => {
    if (!vals) return null;
    if (vals.isResident !== undefined || vals.homeBased !== undefined) {
      let status = "Non-resident";

      if (vals.isResident) {
        status = "Resident";
      }

      if (vals.homeBased) {
        status = "Home-based";
      }

      return status;
    }
    return undefined;
  };

  const getOriginalAddress = (type) => {
    if (type === "mailing" && originalValues.mailingAddress) {
      if (
        originalValues.mailingAddress !== licence.mailingAddress ||
        originalValues.mailingUnit !== licence.mailingUnit ||
        originalValues.mailingAddressCity !== licence.mailingAddressCity ||
        originalValues.mailingAddressProvince !== licence.mailingAddressProvince ||
        originalValues.mailingCountry !== licence.mailingCountry ||
        formatPostalCode(originalValues.mailingCode) !== formatPostalCode(licence.mailingCode)
      ) {
        return (
          `${originalValues.mailingAddress + ", "} 
        ${originalValues?.mailingUnit !== "" ? originalValues.mailingUnit + ", " : ""}
        ${originalValues.mailingAddressCity + ", "} 
        ${abbreviator(originalValues.mailingAddressProvince)?.abbrev + ", "}
        ${originalValues.mailingCode} 
        ${originalValues.mailingCountry === "CA" ? "Canada" : "USA"}` || "-"
        );
      }
    } else if (type === "business" && originalValues.businessAddress) {
      if (
        originalValues.businessAddress !== licence.businessAddress ||
        originalValues.unit !== licence.unit ||
        originalValues.businessAddressCity !== licence.businessAddressCity ||
        originalValues.businessAddressProvince !== licence.businessAddressProvince ||
        originalValues.businessCountry !== licence.businessCountry ||
        formatPostalCode(originalValues.code) !== formatPostalCode(licence.code)
      ) {
        return (
          `${originalValues.businessAddress + ", "} 
        ${originalValues?.unit !== "" ? originalValues.unit + ", " : ""}
        ${originalValues.businessAddressCity + ", "} 
        ${abbreviator(originalValues.businessAddressProvince)?.abbrev + ", "}
        ${originalValues.code} ${originalValues.businessCountry === "CA" ? "Canada" : "USA"}` || "-"
        );
      }
    }
    return undefined;
  };

  const checkBusinessManagementChange = () => {
    for (const key in originalValues) {
      if (key === "orgManagers") {
        if (
          originalValues.orgManagers.length !== licence.managers.length ||
          !originalValues.orgManagers.every(
            (obj1, index) =>
              obj1.name === licence.managers[index].name &&
              obj1.phone.replace(/\D/g, "") === licence.managers[index].phone.replace(/\D/g, ""),
          )
        ) {
          return false;
        }
      } else if (key === "orgOwners") {
        if (
          originalValues.orgOwners.length !== licence.owners.length ||
          !originalValues.orgOwners.every(
            (obj1, index) =>
              obj1.name === licence.owners[index].name &&
              obj1.phone.replace(/\D/g, "") === licence.owners[index].phone.replace(/\D/g, ""),
          )
        ) {
          return false;
        }
      }
    }
    return true;
  };

  return (
    <>
      {!renewalProcess && <h1 style={{ marginTop: "1rem", marginBottom: 0, marginLeft: "1.5rem" }}>General</h1>}
      {status === RENEWING && !hasAutoPay && (
        <Alerts
          style={{ marginTop: "2rem" }}
          variant={"info"}
          title={"Renew your business licence"}
          body={`Renew your business licence by ${getExpiryRenewDate(
            licence?.expiry,
            false,
          )}. Update your information during renewal. After payment and City Staff review, your new licence will be emailed to you.`}
        />
      )}

      {status === RENEWING && hasAutoPay && (
        <Alerts
          style={{ marginTop: "2rem" }}
          variant={"info"}
          title={"Your licence will be auto-renewed"}
          body={`Your business licence will automatically renew on ${getExpiryRenewDate(licence?.expiry, true)}.`}
        />
      )}

      {(licence.balanceOwing === prices.discountedLocalBusiness || licence.balanceOwing === prices.discountedNonLocalBusiness) &&
        !renewalProcess && (
          <Alerts
            style={{ marginTop: "2rem" }}
            variant={"success"}
            title={"Application discount"}
            body={"Applications issued from July 1st to December 31st will receive a one-time 50% discount off the fee."}
          />
        )}
      <Grid
        container
        pl={1}
        sx={
          renewalProcess
            ? {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: 0,
              }
            : {}
        }
      >
        <Grid
          item
          xs={12}
          md={12}
          lg={renewalProcess ? 12 : 6}
          bg={renewalProcess ? 12 : 6}
          style={renewalProcess ? {} : { marginTop: "2rem", paddingRight: "1rem" }}
        >
          <div className={"outlined_container"}>
            {renewalProcess ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginInline: "1rem",
                  marginBottom: "1rem",
                }}
              >
                <h2 style={{ fontSize: isSmallScreen ? "16px" : "24px" }}>Licence details</h2>
                <Button
                  variant="outlined"
                  size="small"
                  style={{
                    borderColor: theme.palette.nonPalette.OUTLINE,
                    fontSize: "16px",
                    padding: "10px",
                  }}
                  startIcon={<EditRounded color={"primary"} />}
                  onClick={() => editPage(0)}
                >
                  Update
                </Button>
              </div>
            ) : (
              <>
                <h2>Licence details</h2>
                {(status === SAVED || licence.changeSetStatus === DRAFT) && canEdit && editStatuses.find((x) => x === status) ? (
                  <>
                    <Spacer />
                    <Button
                      variant="outlined"
                      size="small"
                      style={{
                        borderColor: theme.palette.nonPalette.OUTLINE,
                        fontSize: "16px",
                        padding: "10px",
                      }}
                      startIcon={<EditRounded color={"primary"} />}
                      onClick={() => editPage(0)}
                    >
                      Edit licence details
                    </Button>
                  </>
                ) : null}
              </>
            )}
            <ListItem
              site={"portal"}
              label="Status"
              subLabel={getDisplayStatus(status, licence?.changeSetStatus) || "-"}
              variant="detail"
              startOrnament={<DriveFileRenameOutline sx={{ height: "32px", width: "32px" }} />}
              characterLimit={80}
            />
            {!renewalProcess && (
              <ListItem
                site={"portal"}
                label="Licence Number"
                subLabel={license?.licenceNumber || "-"}
                originalValue={originalValues?.licenceNumber}
                variant="detail"
                startOrnament={<TagRounded sx={{ height: "32px", width: "32px" }} />}
                characterLimit={80}
              />
            )}
            <ListItem
              site={"portal"}
              label="Business name"
              subLabel={license?.businessName || "-"}
              originalValue={originalValues?.businessName}
              variant="detail"
              startOrnament={<DriveFileRenameOutline sx={{ height: "32px", width: "32px" }} />}
              characterLimit={80}
            />
            <ListItem
              site={"portal"}
              label="Describe business operation"
              subLabel={license?.businessDescription || "-"}
              originalValue={originalValues?.businessDescription}
              variant="detail"
              startOrnament={<ArticleOutlined sx={{ height: "32px", width: "32px" }} />}
              characterLimit={80}
            />
            <ListItem
              site={"portal"}
              label="Residency status"
              subLabel={getResidencyStatus(licence) || "-"}
              originalValue={getResidencyStatus(originalValues)}
              variant="detail"
              startOrnament={<PersonPinOutlined sx={{ height: "32px", width: "32px" }} />}
              characterLimit={80}
            />
            {license.homeBased && (
              <div>
                <ListItem
                  site={"portal"}
                  label="Parking"
                  subLabel={license?.hasParking ? "Parking available" : "Parking unavailable"}
                  originalValue={
                    originalValues?.hasParking !== undefined
                      ? originalValues?.hasParking === true
                        ? "Parking available"
                        : "Parking unavailable"
                      : undefined
                  }
                  variant="detail"
                  startOrnament={<DirectionsCarOutlined />}
                  characterLimit={80}
                />
                <Typography variant="overline" color={theme.palette.blacks.BLACK_MEDIUM_EMPHASIS} style={{ marginLeft: "1rem" }}>
                  Property owner
                </Typography>
                <ListItem
                  site={"portal"}
                  label={license?.propertyOwnerName || "-"}
                  startOrnament={<PersonOutlineRounded sx={{ height: "32px", width: "32px" }} />}
                  originalValue={originalValues?.propertyOwnerName}
                  characterLimit={80}
                />
              </div>
            )}
            {license?.expiry && !renewalProcess && status === ACTIVE ? (
              <ListItem
                site={"portal"}
                label="Auto-renew date"
                subLabel={moment(license.expiry).add(1, "d").format("MMM DD, YYYY") || "-"}
                variant="detail"
                startOrnament={<EventRounded sx={{ height: "32px", width: "32px" }} />}
                characterLimit={80}
              />
            ) : (
              <></>
            )}
          </div>
          <Spacer amount={renewalProcess ? 1 : 2} />
          <div className={"outlined_container"}>
            {renewalProcess ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginInline: "1rem",
                  marginBottom: "1rem",
                }}
              >
                <h2 style={{ fontSize: isSmallScreen ? "16px" : "24px" }}>Business contact information</h2>
                <Button
                  variant="outlined"
                  size="small"
                  style={{
                    borderColor: theme.palette.nonPalette.OUTLINE,
                    fontSize: "16px",
                    padding: "10px",
                  }}
                  startIcon={<EditRounded color={"primary"} />}
                  onClick={() => editPage(1)}
                >
                  Update
                </Button>
              </div>
            ) : (
              <>
                <h2>Business contact information</h2>
                {(status === SAVED || licence.changeSetStatus === DRAFT) && canEdit && editStatuses.find((x) => x === status) ? (
                  <>
                    <Spacer amount={1} />
                    <Button
                      variant="outlined"
                      size="small"
                      style={{
                        borderColor: theme.palette.nonPalette.OUTLINE,
                        fontSize: "16px",
                        padding: "10px",
                      }}
                      startIcon={<EditRounded color={"primary"} />}
                      onClick={() => editPage(1)}
                    >
                      Edit contact information
                    </Button>
                  </>
                ) : null}
                <Spacer />
              </>
            )}

            <ListItem
              site={"portal"}
              label="Business address"
              subLabel={
                `${license.businessAddress + ", "} 
                                 ${license?.unit !== "" ? licence.unit + ", " : ""}
                                 ${license.businessAddressCity + ", "} 
                                  ${abbreviator(license.businessAddressProvince)?.abbrev + ", "}
                                  ${license.code} ${licence.businessCountry === "CA" ? "Canada" : "USA"}` || "-"
              }
              originalValue={getOriginalAddress("business")}
              variant="detail"
              startOrnament={<FmdGoodOutlined sx={{ height: "32px", width: "32px" }} />}
              characterLimit={200}
            />
            <ListItem
              site={"portal"}
              label="Mailing address"
              subLabel={
                `${licence.mailingAddress + ", "} 
                                ${licence?.mailingUnit !== "" ? licence.mailingUnit + ", " : ""}
                                    ${licence.mailingAddressCity + ", "} 
                                ${abbreviator(licence.mailingAddressProvince)?.abbrev + ", "}
            ${licence.mailingCode} 
            ${licence.mailingCountry === "CA" ? "Canada" : "USA"}` || "-"
              }
              originalValue={getOriginalAddress("mailing")}
              variant="detail"
              startOrnament={<MailOutlineRounded sx={{ height: "32px", width: "32px" }} />}
              characterLimit={200}
            />
            <ListItem
              site={"portal"}
              label="Phone number"
              subLabel={formatPhoneNumber(license?.phoneNumber) || "-"}
              originalValue={formatPhoneNumber(originalValues?.phoneNumber)}
              variant="detail"
              startOrnament={<LocalPhoneOutlined sx={{ height: "32px", width: "32px" }} />}
              characterLimit={80}
            />
            <ListItem
              site={"portal"}
              label="Email address"
              subLabel={license?.email || "-"}
              originalValue={originalValues?.email}
              variant="detail"
              startOrnament={<AlternateEmailRounded sx={{ height: "32px", width: "32px" }} />}
              characterLimit={80}
            />
            {license.secondPhone && (
              <ListItem
                site={"portal"}
                label="Secondary phone number"
                subLabel={formatPhoneNumber(license?.secondPhone) || "-"}
                originalValue={formatPhoneNumber(originalValues?.secondPhone)}
                variant="detail"
                startOrnament={<LocalPhoneOutlined sx={{ height: "32px", width: "32px" }} />}
                characterLimit={80}
              />
            )}
            {license.faxNumber && (
              <ListItem
                site={"portal"}
                label="Fax number"
                subLabel={formatPhoneNumber(license?.faxNumber) || "-"}
                originalValue={formatPhoneNumber(originalValues?.faxNumber)}
                variant="detail"
                startOrnament={<FaxOutlined sx={{ height: "32px", width: "32px" }} />}
                characterLimit={80}
              />
            )}
            {license.websiteAddress && (
              <ListItem
                site={"portal"}
                label="Website address"
                subLabel={license?.websiteAddress || "-"}
                originalValue={originalValues?.websiteAddress}
                variant="detail"
                startOrnament={<LanguageRounded sx={{ height: "32px", width: "32px" }} />}
                characterLimit={80}
              />
            )}
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          lg={renewalProcess ? 12 : 6}
          bg={renewalProcess ? 12 : 6}
          style={{ marginTop: renewalProcess ? "1.5rem" : "2rem" }}
        >
          <div className={"outlined_container"}>
            {renewalProcess ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginInline: "1rem",
                  marginBottom: "1rem",
                }}
              >
                <h2 style={{ fontSize: isSmallScreen ? "16px" : "24px" }}>Business management</h2>
                <Button
                  variant="outlined"
                  size="small"
                  style={{
                    borderColor: theme.palette.nonPalette.OUTLINE,
                    fontSize: "16px",
                    padding: "10px",
                  }}
                  startIcon={<EditRounded color={"primary"} />}
                  onClick={() => editPage(2)}
                >
                  Update
                </Button>
              </div>
            ) : (
              <>
                <h2>Business management</h2>
                <Spacer />
                {(status === SAVED || licence.changeSetStatus === DRAFT) && canEdit && editStatuses.find((x) => x === status) ? (
                  <>
                    <Button
                      variant="outlined"
                      size="small"
                      style={{
                        borderColor: theme.palette.nonPalette.OUTLINE,
                        fontSize: "16px",
                        padding: "10px",
                      }}
                      startIcon={<EditRounded color={"primary"} />}
                      onClick={() => editPage(2)}
                    >
                      Edit business management
                    </Button>
                    <Spacer amount={1} />
                  </>
                ) : null}
              </>
            )}
            <div
              style={{
                background: !checkBusinessManagementChange() ? theme.palette.primary[50] : "",
                borderRadius: "10px",
                paddingTop: "0.5rem",
                paddingBottom: "0.5rem",
              }}
            >
              <>
                {licence?.owners?.map((element, i) => (
                  <div key={i}>
                    <Typography
                      variant="caption"
                      color={theme.palette.blacks.BLACK_MEDIUM_EMPHASIS}
                      style={{ marginLeft: "1rem" }}
                    >
                      Owner {i + 1}
                    </Typography>
                    <ListItem
                      site={"portal"}
                      label={element.name || "-"}
                      startOrnament={<PersonOutlineRounded sx={{ height: "32px", width: "32px" }} />}
                      characterLimit={80}
                    />
                    <ListItem
                      site={"portal"}
                      label={formatPhoneNumber(element.phone) || "-"}
                      startOrnament={<LocalPhoneOutlined sx={{ height: "32px", width: "32px" }} />}
                      characterLimit={80}
                    />
                  </div>
                ))}

                {license.managers?.map((element, i) => (
                  <div key={i}>
                    <Typography
                      variant="caption"
                      color={theme.palette.blacks.BLACK_MEDIUM_EMPHASIS}
                      style={{ marginLeft: "1rem" }}
                    >
                      Manager {i + 1}
                    </Typography>
                    <ListItem
                      site={"portal"}
                      label={element.name}
                      startOrnament={<PersonOutlineRounded sx={{ height: "32px", width: "32px" }} />}
                      characterLimit={80}
                    />
                    <ListItem
                      site={"portal"}
                      label={formatPhoneNumber(element.phone)}
                      startOrnament={<LocalPhoneOutlined sx={{ height: "32px", width: "32px" }} />}
                      characterLimit={80}
                    />
                  </div>
                ))}
              </>

              {!checkBusinessManagementChange() && (
                <Typography
                  style={{
                    fontSize: 14,
                    fontWeight: 400,
                    lineHeight: 1,
                    marginTop: "0.5rem",
                    marginLeft: "1rem",
                    display: "inline",
                  }}
                  sx={{
                    color: theme.palette.primary[200],
                    "&:hover": {
                      color: theme.palette.primary[500],
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => {
                    setShowOrgManager(!showOrgManager);
                  }}
                >
                  {showOrgManager ? "Hide original" : "View original"}
                </Typography>
              )}

              {showOrgManager && (
                <div>
                  <Chip
                    label="Original"
                    style={{
                      width: "fit-content",
                      background: theme.palette.greys.GREY_MEDIUM,
                      marginLeft: "0.5rem",
                      marginBottom: "0.25rem",
                      marginTop: "1rem",
                    }}
                    size={"small"}
                  />
                  {originalValues?.orgOwners?.map((element, i) => (
                    <div key={i}>
                      <Typography
                        variant="caption"
                        color={theme.palette.blacks.BLACK_MEDIUM_EMPHASIS}
                        style={{ marginLeft: "1rem" }}
                      >
                        Owner {i + 1}
                      </Typography>
                      <ListItem
                        site={"portal"}
                        label={element.name || "-"}
                        startOrnament={<PersonOutlineRounded sx={{ height: "32px", width: "32px" }} />}
                        characterLimit={80}
                      />
                      <ListItem
                        site={"portal"}
                        label={formatPhoneNumber(element.phone) || "-"}
                        startOrnament={<LocalPhoneOutlined sx={{ height: "32px", width: "32px" }} />}
                        characterLimit={80}
                      />
                    </div>
                  ))}
                  {originalValues.orgManagers?.map((element, i) => (
                    <div key={i}>
                      <Typography
                        variant="caption"
                        color={theme.palette.blacks.BLACK_MEDIUM_EMPHASIS}
                        style={{ marginLeft: "1rem" }}
                      >
                        Manager {i + 1}
                      </Typography>
                      <ListItem
                        site={"portal"}
                        label={element.name}
                        startOrnament={<PersonOutlineRounded sx={{ height: "32px", width: "32px" }} />}
                        characterLimit={80}
                      />
                      <ListItem
                        site={"portal"}
                        label={formatPhoneNumber(element.phone)}
                        startOrnament={<LocalPhoneOutlined sx={{ height: "32px", width: "32px" }} />}
                        characterLimit={80}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default GeneralTab;
