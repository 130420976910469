import base from "./axiosInstance";
import { manageSession } from "@utils/manageSession";

export const addComment = async (id: string, comment: any, isPublic = true) => {
  const token = manageSession.getSessionInfo()?.userToken;
  const body = { comment: comment, public: isPublic };
  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    const response = await base.post(`/license/${id}/comment`, body);
    return response.data;
  } catch (error) {}
};

export const deleteComment = async (licenceId: string, commentId: string) => {
  const token = manageSession.getSessionInfo()?.userToken;

  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    const response = await base.delete(`/license/${licenceId}/comment/${commentId}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const editComment = async (licenceId: string, commentId: string, comment: any, isPublic = true) => {
  const token = manageSession.getSessionInfo()?.userToken;

  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    const response = await base.put(`/license/${licenceId}/comment/${commentId}`, {
      comment: comment,
      public: isPublic,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

//                  ################  Account Comments #############
export const addAccountComment = async (id: string, comment: any, isPublic = true) => {
  const token = manageSession.getSessionInfo()?.userToken;
  const body = { comment: comment, public: isPublic };
  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    const response = await base.post(`/account/${id}/comment`, body);
    return response.data;
  } catch (error) {}
};

export const deleteAccountComment = async (accountId: string, commentId: string) => {
  const token = manageSession.getSessionInfo()?.userToken;

  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    const response = await base.delete(`/account/${accountId}/comment/${commentId}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const editAccountComment = async (accountId: string, commentId: string, comment: any, isPublic = true) => {
  const token = manageSession.getSessionInfo()?.userToken;

  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    const response = await base.put(`/account/${accountId}/comment/${commentId}`, {
      comment: comment,
      public: isPublic,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};
