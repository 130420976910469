import React from "react";
import classes from "./index.module.scss";
import missing from "@assets/images/missing_search.png";

const MissingObject = () => {
  return (
    <>
      <div id={"missing-parent"} className={classes.missing_parent}>
        <div id={"missing-container"} className={classes.missing_container}>
          <div className={classes.missing}>
            <p className={classes.title}>Oops!</p>
            <p>Something went wrong. Please try again. If the error persists contact support.</p>
          </div>
          <div className={classes.missing}>
            <img src={missing} alt={"Not Found"} />
          </div>
        </div>
      </div>
    </>
  );
};

export default MissingObject;
