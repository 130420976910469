import base from "./axiosInstance";
import { manageSession } from "@utils/manageSession";

export const getComments = async (id: string) => {
  const token = manageSession.getSessionInfo()?.userToken;
  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    const response = await base.get(`/license/${id}/comment`);
    return response.data;
  } catch (error) {}
};

export const getHistory = async (id: string, type: string) => {
  const token = manageSession.getSessionInfo()?.userToken;
  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    const response = await base.get(`/${type}/${id}/history`);
    return response.data;
  } catch (error) {}
};

export const getAccountComments = async (id: string) => {
  const token = manageSession.getSessionInfo()?.userToken;
  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    const response = await base.get(`/account/${id}/comment`);
    return response.data;
  } catch (error) {}
};
