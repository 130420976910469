import React from "react";
import { Breadcrumbs } from "@mui/material";
import { NavigateNextRounded } from "@mui/icons-material";
import classes from "./index.module.scss";

interface IBreadcrumbsChildren {
  onClick?: () => void;
  icon?: React.ReactNode;
  text: string;
}

interface IBreadcrumbs {
  children: IBreadcrumbsChildren[];
  separator?: React.ReactNode;
  maxItems?: number;
  selectedIndex?: number;
  className?: string;
}

const WBCBreadcrumbs = ({
  children,
  separator = <NavigateNextRounded fontSize="small" />,
  maxItems = 5,
  selectedIndex,
  className,
}: IBreadcrumbs) => {
  return (
    <div className={`${classes.container} ${!!className ? className : ""}`}>
      <Breadcrumbs aria-label="breadcrumb" maxItems={maxItems} separator={separator}>
        {children.map((e, index, array) => {
          return !!selectedIndex ? (
            index === selectedIndex ? (
              <div key={e.text} className={classes.selected_item}>
                {e.icon}
                <p>{e.text}</p>
              </div>
            ) : (
              <div key={e.text} className={classes.active_item} onClick={e.onClick}>
                {e.icon}
                <p>{e.text}</p>
              </div>
            )
          ) : index === array.length - 1 ? (
            <div key={e.text} className={classes.selected_item}>
              {e.icon}
              <p>{e.text}</p>
            </div>
          ) : (
            <div key={e.text} className={classes.active_item} onClick={e.onClick}>
              {e.icon}
              <p>{e.text}</p>
            </div>
          );
        })}
      </Breadcrumbs>
    </div>
  );
};

export default WBCBreadcrumbs;
