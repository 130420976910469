import styled from "styled-components";
import TableCell from "@mui/material/TableCell";

const DataTableCell = styled(TableCell)`
  && {
    white-space: nowrap;
    border-bottom: 1px solid ${({ theme }) => theme.tableRowBorderColor};
  }

  &:first-child {
    border-bottom: 1px solid ${({ theme }) => theme.tableRowBorderColor};
  }

  &:last-child {
    border-bottom: 1px solid ${({ theme }) => theme.tableRowBorderColor};
  }
`;

export default DataTableCell;
