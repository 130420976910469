import { useEffect } from "react";
import { useFormikContext } from "formik";

export default function SaveValues({ save, canSave = false }) {
  const { values } = useFormikContext();

  useEffect(() => {
    if (canSave) {
      save(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return null;
}
