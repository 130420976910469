import { useCallback, useContext, useEffect, useState } from "react";
import { getAccountInvoices } from "@core/apis/account";
import { NotificationContext } from "@contexts/NotificationContext";
import { useHistory } from "react-router-dom";

const usePropertyTaxNotices = (id: string) => {
  const [notices, setNotices] = useState([]);
  const [loading, setLoading] = useState(true);
  const { handleShow } = useContext(NotificationContext);
  const history = useHistory();

  const fetchNotices = useCallback(async () => {
    try {
      setLoading(true);
      const results = await getAccountInvoices(id, {});
      setNotices(results.invoices);
    } catch (e: any) {
      if (e.response.status === 404) {
        history.replace("/404");
      }
      if (e.response.status === 403) {
        history.replace("/403");
      } else {
        handleShow("Unable to load account details");
      }
    } finally {
      setLoading(false);
    }
  }, [id, handleShow, history]);

  useEffect(() => {
    fetchNotices().then();
  }, [fetchNotices]);

  return { notices, loading };
};

export default usePropertyTaxNotices;
