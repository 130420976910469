export const validationRegex = {
  postalCode: /^[a-zA-Z][0-9][a-zA-Z]\s?[0-9][a-zA-Z][0-9]$/,
  zipCode: /^\d{5}(-\d{4})?$/,
  phone: /^[0-9]{3}\s?[0-9]{3}\s?[0-9]{4}$/,
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  url: /^((https?):\/\/)?(www\.)?[a-zA-Z0-9]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?\/?$/,
};
export const formattingRegex = {
  postalCode: /^([A-Z][0-9][A-Z]) ?([0-9][A-Z][0-9])[\W+\w+]?/,
  zipCode: /^(\d{5})(?:[ -]?(\d{4})[\W+\w+]?)?/,
  phone: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
};

export const oneLowerCase = /(?=.*[a-z])/;
export const oneUpperCase = /(?=.*[A-Z])/;
export const oneNumber = /(?=.*[0-9])/;
// Special characters: !@#$%^&*
// export const oneSpecialCharPreview = '!@#$%^&*';
// export const oneSpecialChar = /(?=.*[!@#$%^&*])/;

// Special characters: !@#$%^&*()_+-=[]\{};':",./<>?
export const oneSpecialCharPreview = "!@#$%^&*()_+";
export const oneSpecialChar = /(?=.*[!@#$%^&*()_+\-=[\]\\{};':",./<>?])/;
