//Formats any string into a legible phone number
export const formatPhoneNumber = (value: any) => {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) return value;

  // clean the input for any non-digit values.
  const phoneNumber = value.replace(/[^\d]/g, "");

  // phoneNumberLength is used to know when to apply our formatting for the phone number
  const phoneNumberLength = phoneNumber.length;

  // we need to return the value with no formatting if its less then four digits
  // this is to avoid weird behavior that occurs if you  format the area code to early

  if (phoneNumberLength < 4) return phoneNumber;

  // if phoneNumberLength is greater than 4 and less the 7 we start to return
  // the formatted number
  if (phoneNumberLength < 7) {
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
  }

  // finally, if the phoneNumberLength is greater then seven, we add the last
  // bit of formatting and return it.
  return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
};

//Removes formatting
export const cleanPhoneNumber = (value: string) => {
  return value.replace(/[^\d]/g, "");
};

//Only allows a user to enter digit values
export const formatCurrency = (value: number, maxAmount: number) => {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) return value;
  let newValue = value.toString();

  if (newValue === "$") {
    return "";
  }

  // clean the input for any non-digit values.
  let currency = newValue.replace(/[^\d]/g, "");

  if (maxAmount && Number(currency) > maxAmount) {
    // @ts-ignore
    currency = maxAmount;
    currency = currency.toString();
  }

  currency = "$" + currency.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");

  return currency;
};

export const formatDate = (value: any) => {
  let formattedValue = value.replace(/[^\d]/g, "");

  if (formattedValue.length < 5) {
    return formattedValue;
  } else if (formattedValue.length >= 5 && formattedValue.length < 7) {
    return `${formattedValue.slice(0, 4)}/${formattedValue.slice(4)}`;
  } else {
    return `${formattedValue.slice(0, 4)}/${formattedValue.slice(4, 6)}/${formattedValue.slice(6, 8)}`;
  }
};

/**
 * Formats the user input into a proper postal code
 * @param value
 * @returns {string}
 */
export function formatPostalCode(value: any) {
  if (!value) return value;

  //Replaces any space with an empty string
  let code = value.replace(/\s/g, "");
  code = code.replace(/[^a-zA-Z0-9]+/, "");

  //Length of current input
  const codeLength = code.length;

  let validCode = "";

  //Determines if the character is a digit or letter to verify a correct postal pattern is in use
  //Values can be 'char' or 'digit'
  let validNextValue = "char";
  for (let i = 0; i < codeLength; i++) {
    let isANumber = /^\d+$/.test(code[i]);

    if (!isANumber && validNextValue === "char") {
      validCode += code[i];
      validNextValue = "digit";
    } else if (isANumber && validNextValue === "digit") {
      validCode += code[i];
      validNextValue = "char";
    }
  }

  //If less than 4 characters, returns without adding a space
  if (validCode.length < 4) {
    return validCode.toUpperCase();
  }

  //Adds a space and turns all letters to uppercase
  return `${validCode.slice(0, 3)} ${validCode.slice(3, 6)}`.toUpperCase();
}

export const capitalize = (val?: string) => {
  if (!val) {
    return "";
  }
  val = val.toLowerCase();
  return (val.charAt(0).toUpperCase() + val.slice(1)).split(/[-_]+/).join(" ");
};

export function formatZipCode(value: any) {
  if (!value) return value;

  //Replaces any space with an empty string
  let code = value.replace(/\s/g, "");
  code = code.replace(/[^a-zA-Z0-9]+/, "");

  //Length of current input
  const codeLength = code.length;

  let validCode = "";

  for (let i = 0; i < codeLength; i++) {
    let isANumber = /^\d+$/.test(code[i]);

    if (isANumber) {
      validCode += code[i];
    }
  }

  if (validCode.length > 5) return `${validCode.slice(0, 5)}-${validCode.slice(5, 9)}`;
  else return `${validCode.slice(0, 5)}`;
}

export const formatNumber = (value: string) => {
  if (!value) return value;

  // clean the input for any non-digit values.
  const number = value.replace(/[^\d]/g, "");

  return number;
};

export const formatCreditCard = (value: string) => {
  if (!value) return value;
  value = value.toString();

  let code = value.replace(/\s/g, "");
  code = code.replace(/[^\d]/g, "");

  if (code.length < 5) {
    return code;
  }

  if (code.length < 9) {
    return `${code.slice(0, 4)} ${code.slice(4)}`;
  }

  if (code.length < 13) {
    return `${code.slice(0, 4)} ${code.slice(4, 8)} ${code.slice(8, 12)}`;
  }

  return `${code.slice(0, 4)} ${code.slice(4, 8)} ${code.slice(8, 12)} ${code.slice(12, 16)}`;
};

export const formatExpiry = (value: string) => {
  let formattedValue = value.replace(/[^\d]/g, "");

  if (formattedValue.length < 3) {
    if (value.length > formattedValue.length) {
      return formattedValue + "/";
    }
    return formattedValue;
  }

  return `${formattedValue.slice(0, 2)}/${formattedValue.slice(2, 4)}`;
};
