import { useEffect, useState } from "react";
import useHash from "./useHash";

/**
 * Gets the current tab from the anchor in the url
 */
const useTab = () => {
  const hash = useHash();
  const [tab, setTab] = useState(hash || "0");

  useEffect(() => {
    setTab(hash || "0");
  }, [hash]);

  return { tab, setTab };
};

export default useTab;
