import React, { useCallback } from "react";
import Alerts from "../Alert";

const EditingAlerts = ({ editing = false, newLicence = false, data = {} }) => {
  const getTitle = useCallback(() => {
    return newLicence ? "New licence" : "Editing mode";
  }, [newLicence]);
  const getBody = useCallback(() => {
    return newLicence ? (
      <>
        When you’re done, submit your changes on the right sidebar. That will start the reviewing process. If you close the window
        without saving your changes, all your changes will be lost.
        <br />
        <br />
        This licence will not be linked to any citizen account. The applicant should create an account and link it from their
        side. We’ll include an option for admins to be able to link licences from this page in the future.
      </>
    ) : (
      "When you’re done with the changes, press ‘Apply changes’ on the right side. " +
        (data.status === "active"
          ? "The system will let you decide if the applicant will be charged with an amendment fee ($20) or not."
          : "") +
        " If you close the window without saving your " +
        "changes, the licence will remain unedited."
    );
  }, [newLicence, data.status]);
  return <>{editing && <Alerts variant={"info"} title={getTitle()} body={getBody()} />}</>;
};

export default EditingAlerts;
