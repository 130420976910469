import React, { useContext, useEffect, useState } from "react";
import { getInvoiceReports } from "@core/apis/report";
import {
  DataGrid,
  gridClasses,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { CheckBoxOutlined } from "@mui/icons-material";
import { Box, FormControl, InputAdornment, MenuItem, OutlinedInput, SelectChangeEvent, Skeleton } from "@mui/material";
import { BLUE, PRIMARY_200, PRIMARY_50 } from "mui-theme/theme";
import Select from "@mui/material/Select";
import { formatCurrency } from "@utils/index";
import { NotificationContext } from "ui/contexts/NotificationContext";

const columns: GridColDef[] = [
  {
    field: "invoice_recipient",
    headerName: "RECIPIENT",
    editable: false,
    flex: 1,
    filterable: false,
  },
  {
    field: "invoice_number",
    headerName: "INVOICE NUMBER",
    editable: false,
    flex: 1,
    filterable: false,
  },
  {
    field: "invoice_date",
    headerName: "INVOICE DATE",
    editable: false,
    flex: 1,
    type: "date",
    renderCell: (params) => {
      if (params.value) {
        const date = new Date(params.value);
        const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(
          date.getDate(),
        ).padStart(2, "0")}`;
        return formattedDate;
      }
      return null;
    },
    valueGetter: ({ value }) => value && new Date(value),
  },
  {
    field: "invoice_status",
    headerName: "INVOICE STATUS",
    editable: false,
    flex: 1,
  },
  {
    field: "amount_due",
    headerName: "INVOICE AMOUNT",
    editable: false,
    flex: 1,
    filterable: false,
    valueFormatter: ({ value }) => formatCurrency(value),
  },
  {
    field: "transaction_type",
    headerName: "TRANSACTION TYPE",
    editable: false,
    flex: 1,
    filterable: false,
  },
];

const Report = () => {
  const [data, setData] = useState([]);
  const [dataLoading, setDataLoadng] = useState(false);
  const [statusValue, setStatusValue] = useState<any>([]);
  const { handleError } = useContext(NotificationContext) || {};

  const [filters, setFilters] = useState({
    total: true,
    sortBy: "invoice_date",
    ascending: false,
    filters: {},
    offset: 0,
    limit: 1000000,
  });
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    setFilters((prevState) => ({
      ...prevState,
      filters: {
        ...(statusValue.length === 0 ? {} : { invoice_status: statusValue }),
      },
    }));
    setRefresh(!refresh);
    //eslint-disable-next-line
  }, [statusValue]);

  function CustomToolbar() {
    const handleStatusChange = (event: SelectChangeEvent<unknown>) => {
      if (event.target.value === "any") {
        setStatusValue([]);
        return;
      } else if (event.target.value === "open") {
        setStatusValue(["open"]);
        return;
      } else if (event.target.value === "closed") {
        setStatusValue(["closed"]);
        return;
      } else if (event.target.value === "paid") {
        setStatusValue(["paid"]);
        return;
      }

      setStatusValue(event.target.value as any);
    };

    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton
          style={{
            color: PRIMARY_200,
          }}
        />
        <Box>
          <FormControl
            sx={{
              ".MuiOutlinedInput-root": {
                paddingLeft: "0.3rem !important",
              },
            }}
          >
            <Select
              size="small"
              input={
                <OutlinedInput
                  startAdornment={
                    <InputAdornment position="start">
                      <CheckBoxOutlined
                        sx={{
                          color: BLUE,
                        }}
                      />
                    </InputAdornment>
                  }
                />
              }
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  border: 0,
                },

                ".MuiInputBase-input ": {
                  padding: "0.3rem !important",
                  color: BLUE,
                },
                ".MuiSelect-iconOutlined": {
                  display: "none",
                },
                ":hover": {
                  backgroundColor: PRIMARY_50,
                },
              }}
              value={statusValue.length === 0 ? "Status" : statusValue.length > 1 ? "inactive" : statusValue}
              labelId="demo-simple-select-label"
              id="demo-select-small"
              onChange={handleStatusChange}
            >
              <MenuItem disabled value="Status">
                Status
              </MenuItem>
              <MenuItem value={"open"}>Open</MenuItem>
              <MenuItem value={"paid"}>Paid</MenuItem>
              <MenuItem value={"closed"}>Closed</MenuItem>
              <MenuItem value={"any"}>All</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <GridToolbarFilterButton
          style={{
            color: PRIMARY_200,
          }}
        />
        <GridToolbarExport
          style={{
            color: PRIMARY_200,
          }}
          csvOptions={{
            fileName: "Cocoflo Invoice Report",
          }}
        />
      </GridToolbarContainer>
    );
  }

  const fetchData = async () => {
    try {
      setDataLoadng(true);
      setData(await getInvoiceReports(filters));
    } catch (error) {
      handleError("Something went wrong.");
    } finally {
      setDataLoadng(false);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchData();
    })();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  return dataLoading ? (
    <Skeleton variant={"rectangular"} height={500} />
  ) : (
    <DataGrid
      loading={dataLoading}
      rows={data ? data : []}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 10,
          },
        },
      }}
      pageSizeOptions={[10, 25]}
      disableRowSelectionOnClick
      style={{ borderColor: "transparent" }}
      slots={{ toolbar: CustomToolbar }}
      localeText={{
        toolbarFilters: "Time",
      }}
      sx={{
        [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
          outline: "none",
        },
        [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
          outline: "none",
        },
      }}
    />
  );
};

export default Report;
