import base from "./axiosInstance";
import { IFormField } from "../interfaces/utility/IFormField";
import { manageSession } from "@utils/manageSession";

export const findUtilityAccount = async (data: { accountNumber: string; accessCode: string }) => {
  try {
    return await base.get(
      `/account/link?accountNumber=${data.accountNumber.trim()}&accessCode=${data.accessCode.trim()}&accountType=utility`,
    );
  } catch (e: any) {
    return e;
  }
};

export const linkUtilityAccount = async (id: string, userId: string | null) => {
  const response = await base.put(`/account/${id}/link?userId=${userId}`);
  return response.data;
};

export const getAccountSummaries = async (param: any) => {
  try {
    const params = { ...param, filters: JSON.stringify(param.filters) };
    const response = await base.get(`/account/summary`, { params });
    return response.data;
  } catch (error) {}
};

export const getAccountDetail = async (id: string): Promise<IFormField[]> => {
  let response = { data: [] };
  response = await base.get(`/account/${id}`);
  return Promise.resolve(response.data);
};

export const getAccountInvoices = async (id: string, params: any) => {
  const result = await base.get(`/account/${id}/invoices`, { params });
  return result.data;
};

export const get_account_reviewer = async (accountId: string, section: string) => {
  const token = manageSession.getSessionInfo()?.userToken;
  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    const response = await base.get(`/account/${accountId}/reviewer?section=${section}`);
    return response.data;
  } catch (error) {}
};

export const update_account_reviewer_status = async (id: string, status: string, section: string) => {
  const token = manageSession.getSessionInfo()?.userToken;
  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    const response = await base.put(`/account/${id}/reviewer`, {
      status: status,
      section: section,
    });
    return response.data;
  } catch (error) {}
};

export const get_account_issuer = async (accountId: string, section: string) => {
  const token = manageSession.getSessionInfo()?.userToken;
  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    const response = await base.get(`/account/${accountId}/issuer?section=${section}`);
    return response.data;
  } catch (error) {}
};

export const createAccountChangeSet = async (accountId: string, step: string) => {
  try {
    const dataScheme = {
      step: step,
    };
    return await base.post(`/account/${accountId}/change`, dataScheme);
  } catch (e) {
    return e;
  }
};

export const updateAccountChangeSet = async (accountId: string, changeId: string, data: object, email: string) => {
  const dataScheme = {
    updates: data,
    sendEmail: email,
  };
  try {
    const response = await base.put(`/account/${accountId}/change/${changeId}`, dataScheme);
    return response.data;
  } catch (error) {}
};

export const updateAccountChangeSetStatus = async (
  accountId: string,
  changeId: string,
  data: any,
  sendEmail: any,
  skipAction = false,
) => {
  const dataScheme = {
    status: data,
    sendEmail: sendEmail,
    skipAction: skipAction,
  };
  try {
    const response = await base.put(`/account/${accountId}/change/${changeId}/status`, dataScheme);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const applyChangesToAccount = async (accountId: string, changesId: string, sendEmail: any) => {
  try {
    const params = new URLSearchParams();
    params.append("sendEmail", sendEmail);
    const response = await base.get(`/account/${accountId}/change/${changesId}/apply?${params.toString()}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAccountTransactions = async (accountId: string, param: any) => {
  try {
    const params = { ...param, filters: JSON.stringify(param.filters) };
    const response = await base.get(`/account/${accountId}/transactions`, {
      params,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getAccountInvoicePdf = async (url: string) => {
  try {
    const response = await base.get(url);
    if (response.data.downloadLink !== undefined) {
      return response.data.downloadLink;
    }
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const update_account = async (accountId: string, data: any, waive: boolean | null = false || null, email?: string) => {
  const token = manageSession.getSessionInfo()?.userToken;

  try {
    const payload: any = {
      updates: data,
      waiveFees: waive,
      sendEmail: email,
    };
    if (waive === null) {
      delete payload["waiveFees"];
    }
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    const response = await base.put(`/account/${accountId}`, payload);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getAccountLinkedUsers = async (accountId: string) => {
  const token = manageSession.getSessionInfo()?.userToken;
  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    const response = await base.get(`/account/${accountId}/linkedUsers`);
    return response.data;
  } catch (error) {}
};

export const unlinkeUserFromAccount = async (accountId: string, userId: string) => {
  const token = manageSession.getSessionInfo()?.userToken;
  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    const response = await base.delete(`/account/${accountId}/link?userId=${userId}`);
    return response.data;
  } catch (error) {}
};

export const linkeUserToAccount = async (accountId: string, userId: string) => {
  const token = manageSession.getSessionInfo()?.userToken;
  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    const response = await base.put(`/account/${accountId}/link?userId=${userId}`);
    return response.data;
  } catch (error) {}
};

export const getAccountStatus = async (id: string): Promise<any> => {
  let response = { data: [] };
  response = await base.get(`/account/${id}/status`);
  return Promise.resolve(response.data);
};

//                        ####### Property Tax ########

export const findPropertyAccount = async (data: { accountNumber: string; accessCode: string }) => {
  const response = await base.get(
    `/account/link?accountNumber=${data.accountNumber}&accessCode=${data.accessCode}&accountType=property`,
  );
  return response;
};

// TODO: duplicate form of link utility account - remove the utility one and replace codebase
export const linkAccount = async (id: string, userId: string | null) => {
  const response = await base.put(`/account/${id}/link?userId=${userId}`);
  return response.data;
};
