import React from "react";
import Spacer from "@components/ui-kit/Spacer";
import PropertyTaxDropdown from "@components/property-tax/property-tax-dropdown";
import Grid from "@mui/material/Unstable_Grid2";
import { Skeleton } from "@mui/material";
import AnnualPropertyAssessmentGraph from "ui/components/property-tax/annual-property-assessment-graph";
import moment from "moment/moment";
import PropertyAssessmentItem from "@components/property-tax/property-assessment-item";
import { capitalize, currency } from "@utils/index";

const AssessmentTab = ({ loading, assessments }) => {
  return (
    <div>
      <h1>Assessment</h1>
      <Spacer />
      <Grid container spacing={2}>
        <Grid xs={12} md={12} lg={6}>
          {!loading ? (
            assessments ? (
              <>
                {assessments.map((assessment, index) => (
                  <PropertyTaxDropdown
                    label={`${moment(assessment.periodStart).year().toString()} Property Assessment`}
                    subLabel={`Property value as of ${moment(assessment.periodStart).format("MMM DD, YYYY")}`}
                    initialOpen={index === 0}
                    BodyComponent={() => (
                      <>
                        <PropertyAssessmentItem bold={true} label={"Total Assessed Value"} value={currency(assessment.amount)} />
                        <PropertyAssessmentItem
                          label={"Assessment Class"}
                          value={assessment.propertyClass ? capitalize(assessment.propertyClass) : "-"}
                        />
                        <PropertyAssessmentItem label={"Improvement"} value={currency(assessment.improvementAmount)} />
                        <PropertyAssessmentItem label={"Land"} value={currency(assessment.landAmount)} />
                        <PropertyAssessmentItem
                          label={"Final date of Complaint"}
                          value={moment(assessment.periodEnd).format("MMMM DD, YYYY").toString()}
                        />
                      </>
                    )}
                  />
                ))}
              </>
            ) : (
              <h2>No data found</h2>
            )
          ) : (
            <Skeleton variant={"rectangular"} height={80} />
          )}
        </Grid>
        <Grid xs={12} md={12} lg={6}>
          <AnnualPropertyAssessmentGraph loading={loading} assessments={assessments} />
        </Grid>
      </Grid>
    </div>
  );
};

export default AssessmentTab;
