import typographyClasses from "@assets/styles/typography/typography.module.scss";
import { truncate } from "@utils/index";
import { DataFormat } from "@core/constants/enums";
import LinkButton from "@components/ui-kit/link-button";
import moment from "moment";
import { getAccountInvoicePdf } from "@core/apis/account";
import { download } from "utilities/files";

export const fields = [
  {
    id: "date",
    label: "Invoice date",
    disableSort: false,
    format: (val: any) => {
      return (
        <p className={`${typographyClasses.body}`} style={{ fontSize: 14 }}>
          {moment(val).format("YYYY-MM-DD")}
        </p>
      );
    },
  },
  {
    id: "title",
    label: "Description",
    disableSort: true,
    format: (val: string, { payment, subTitle, detailLink, isRefund, invoiceNumber }: any) => (
      <>
        {payment ? (
          <p className={`${typographyClasses.body} green`} style={{ fontSize: 14 }}>
            {val}
          </p>
        ) : (
          <>
            <LinkButton
              tooltip={isRefund ? `Refund for invoice #${invoiceNumber}` : val}
              color={"blue"}
              onClick={async (e: any) => {
                const invoiceLink = await getAccountInvoicePdf(detailLink);
                if (invoiceLink) {
                  await download(invoiceLink, "Invoice");
                }
                e.stopPropagation();
              }}
            >
              {truncate(isRefund ? `Refund for invoice #${invoiceNumber}` : val, 35)}
            </LinkButton>
            <p className={`${typographyClasses.body2} ${typographyClasses.small_font}`}>
              {isRefund ? `Invoice #${invoiceNumber}` : subTitle}
            </p>
          </>
        )}
      </>
    ),
  },
  {
    id: "payment",
    disableSort: true,
    label: "Payment",
    format: (val: boolean, { amount }: any) => (
      <p className={`${typographyClasses.body} green`} style={{ fontSize: 14 }}>
        {val ? DataFormat.CURRENCY(amount) : ""}
      </p>
    ),
  },
  {
    id: "amount",
    disableSort: true,
    label: "Amount",
    format: (val: any, { payment }: any) => (
      <p className={`${typographyClasses.body}`} style={{ fontSize: 14 }}>
        {!payment ? DataFormat.CURRENCY(val) : ""}
      </p>
    ),
  },
];
