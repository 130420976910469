import { configureStore } from "@reduxjs/toolkit";
import confirmationDialogSlice from "./slices/confrmation-dialog";

// Upon adding more slices, implement the combine reducers and refactor slice names into enums

const ReduxStore = configureStore({
  reducer: {
    confirmationDialog: confirmationDialogSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [confirmationDialogSlice.actions.setState.type],
        ignoredPaths: ["confirmationDialog"],
      },
    }).concat([]),
});

export default ReduxStore;
