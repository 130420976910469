import React, { useEffect, useRef, useState } from "react";
import classes from "./index.module.scss";
import LoadingLogo from "@assets/gifs/logo-loading-transparent.gif";
import {
  AlternateEmail,
  DescriptionOutlined,
  ForwardToInboxOutlined,
  GiteOutlined,
  PersonOutline,
  SmsOutlined,
  Tag,
} from "@mui/icons-material";
import { Skeleton } from "@mui/material";
import Tabs from "@components/ui-kit/tabs";
import Tab from "@components/ui-kit/tab";
import TabPanel from "@components/ui-kit/tab-pannel";
import MissingObject from "@components/ui-kit/missing-object";
import { useHistory, useLocation, useParams } from "react-router-dom";
import typographyClasses from "@assets/styles/typography/typography.module.scss";
import Spacer from "@components/ui-kit/Spacer";
import { ReactComponent as WorkInProgress } from "@assets/images/work-in-progress.svg";
import OverviewTab from "../../my-city-hall/property-tax/property-tax-details/tabs/overview";
import useTab from "@hooks/useTab";
import AssessmentTab from "../../my-city-hall/property-tax/property-tax-details/tabs/assessment";
import usePropertyTaxDetails from "@hooks/property-tax/usePropertyTaxDetails";
import TaxNoticeTab from "../../my-city-hall/property-tax/property-tax-details/tabs/tax-notice";
import usePropertyTaxNotices from "@hooks/property-tax/usePropertyTaxNotices";

const tabs = [
  { id: "0", label: "Overview" },
  { id: "1", label: "Assessment" },
  { id: "2", label: "Tax Notices" },
  { id: "3", label: "Documents" },
];

const PropertyTaxesDetailScreen = () => {
  const history = useHistory();
  const location = useLocation();
  const { tab } = useTab();
  const { id } = useParams();
  const { assessments, loading } = usePropertyTaxDetails(id);
  const mainContainerRef = useRef();
  const { loading: loadingNotices, notices } = usePropertyTaxNotices(id);
  const [pendingData] = useState(false);
  const [propertyInfo] = useState({
    propertyId: "987-654-321",
    accountHolderInfo: [
      {
        id: 1,
        label: "Property Owner",
        subLabel: "Ryan Clark",
        icon: <PersonOutline />,
      },
      {
        id: 2,
        label: "Property Address",
        subLabel: "5432 56 Avenue Lacombe, AB, T4L 1E9",
        icon: <ForwardToInboxOutlined />,
      },
      {
        id: 3,
        label: "Roll Number",
        subLabel: "194 010024941",
        icon: <AlternateEmail />,
      },
      {
        id: 4,
        label: "Access Code/PIN",
        subLabel: "27557",
        icon: <Tag />,
      },
      {
        id: 5,
        label: "Legal Description",
        subLabel: "27557",
        icon: <DescriptionOutlined />,
      },
      {
        id: 6,
        label: "Communication Preference",
        subLabel: "Paperless (By Email)",
        icon: <SmsOutlined />,
      },
    ],
  });

  const scrollToTop = () => {
    if (mainContainerRef.current) {
      mainContainerRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    scrollToTop();
  }, [tab]);

  const onTabChanged = (_, newTab) => {
    history.push(`${location.pathname}#${newTab}`);
  };

  return (
    <>
      <div className={"admin-detail"}>
        {loading || pendingData ? (
          <div className={classes.loading_component_wrapper}>
            <img src={LoadingLogo} alt={"Loading..."} />
          </div>
        ) : !!propertyInfo && !loading ? (
          <>
            <div ref={mainContainerRef} className={"admin-detail-main"}>
              <div className={"admin-content-container"}>
                <div className={"page_title"}>
                  <GiteOutlined sx={{ height: "48px", width: "48px" }} />
                  <p style={{ marginRight: "0.2rem" }}>Property ID - </p>
                  {loading ? <Skeleton variant={"rounded"} width={300} height={40} /> : <p>{propertyInfo.propertyId}</p>}
                </div>
                <div className={"tab_bar"}>
                  <Tabs onChange={onTabChanged} value={tab}>
                    <Tab value={tabs[0].id} label={tabs[0].label} />
                    <Tab value={tabs[1].id} label={tabs[1].label} />
                    <Tab value={tabs[2].id} label={tabs[2].label} />
                    <Tab value={tabs[3].id} label={tabs[3].label} />
                  </Tabs>
                </div>
                <TabPanel value={tab} index={tabs[0].id}>
                  <OverviewTab
                    id={id}
                    loading={loading}
                    accountDetailsItem={propertyInfo.accountHolderInfo}
                    assessmentInfo={{
                      date: "2024-06-30",
                      assessedValue: 353000,
                      assessmentClass: "Residential",
                      improvement: 206000,
                      land: 158000,
                      complaintDate: "2023-08-23",
                    }}
                    taxNotice={{
                      dueDate: "2024-06-30",
                      items: [
                        {
                          description: "Municipal Taxes",
                          rate: 8.6448,
                          amount: 3146.71,
                        },
                        {
                          description: "Provincial Taxes",
                          rate: 0.0838,
                          amount: 962.49,
                        },
                        {
                          description: "Other Taxes",
                          rate: 2.6442,
                          amount: 26.42,
                        },
                        {
                          description: "Prepayment Arrears",
                          rate: null,
                          amount: -1810.99,
                        },
                      ],
                    }}
                    assessments={assessments}
                  />
                </TabPanel>
                <TabPanel value={tab} index={tabs[1].id}>
                  <AssessmentTab loading={loading} assessments={assessments} />
                </TabPanel>
                <TabPanel value={tab} index={tabs[2].id}>
                  <TaxNoticeTab loading={loadingNotices} notices={notices} />
                </TabPanel>
                <TabPanel value={tab} index={tabs[3].id}>
                  <>
                    <p className={typographyClasses.h1}>Documents</p>
                    <Spacer />
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <WorkInProgress />
                    </div>
                  </>
                </TabPanel>
              </div>
            </div>
            <div className={"admin-detail-sidebar"}></div>
          </>
        ) : (
          <MissingObject />
        )}
      </div>
    </>
  );
};

export default PropertyTaxesDetailScreen;
