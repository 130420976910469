import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { findUtilityAccount, linkUtilityAccount } from "@core/apis/account";
import Step1 from "./step1";
import Step2 from "./step2";
import AlreadyLinkedScreen from "./already-linked-screen";
import { AuthContext } from "@contexts/AuthContext";
import classes from "./index.module.scss";
import { useHistory } from "react-router-dom";
import { routes } from "@routes/routesData";
import Spacer from "@components/ui-kit/Spacer";
import WizardStepTracker from "@components/ui-kit/wizard-step-tracker";
import { closeDialog, setState as setConfirmationDialogState } from "../../../../redux/slices/confrmation-dialog";
import { useDispatch } from "react-redux";
import { EditingContext } from "@contexts/EditingContext";

const steps = [
  { id: "step1", label: "Link your account" },
  { id: "step2", label: "Confirm your account" },
];

const LinkUtility = () => {
  const history = useHistory();
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [account, setAccount] = useState(null) as any;
  const [userAlreadyLinked, setUserAlreadyLinked] = useState(false);
  const { user } = useContext(AuthContext);
  const dispatch = useDispatch();
  const { setIsEditing, setModalData } = useContext(EditingContext);

  const getForm = useCallback(
    (stepId: string) => {
      switch (stepId) {
        case "step1":
          return Step1;
        case "step2":
          return userAlreadyLinked ? AlreadyLinkedScreen : Step2;
        default:
          return null;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [account, userAlreadyLinked],
  );

  const CurrentPage = useMemo(() => getForm(steps[currentPageIndex].id), [currentPageIndex, getForm]);

  useEffect(() => {
    if (account) {
      setCurrentPageIndex(1);
    }
  }, [account]);

  const onCheckUtilityAvailable = async (values: { accountNumber: string; accessCode: string }) => {
    const result = await findUtilityAccount(values);
    // Already linked account
    if (result?.response?.status === 409) {
      setAccount({
        accountNumber: values.accountNumber,
        address: result.response.data?.["service_address"],
      });
      setUserAlreadyLinked(true);
      return;
    } else if ([404, 403].includes(result?.response?.status)) {
      return false;
    } else if (result?.response?.status === 406) {
      const _data = result.response?.data?.account;
      setAccount({
        accountId: _data?.accountId,
        accountNumber: _data?.accountNumber,
        address: _data?.address,
        isInactive: true,
      });
      return;
    } else if (result?.data?.account) {
      const _data = result?.data?.account;
      setAccount({
        accountId: _data?.accountId,
        accountNumber: _data?.accountNumber,
        address: _data?.address,
      });
      setUserAlreadyLinked(false);
    }
  };

  const linkAccount = async () => {
    try {
      // @ts-ignore
      await linkUtilityAccount(account.accountId, user.id);
      history.push(routes.UTILITY_DETAILS.codePath + account.accountId, {
        showSuccessDialog: true,
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (currentPageIndex === 0) {
      setAccount(null);
    }
  }, [currentPageIndex]);

  const onWizardClose = () => {
    if (currentPageIndex === 1) {
      dispatch(
        setConfirmationDialogState({
          open: true,
          title: "You have unsaved changes",
          body: "Closing this will result in losing all your changes. Are you sure you want to close?",
          onConfirm: _onWizardClosedWithChangeSet,
        }),
      );
      return;
    }
    history.replace("/portal");
  };

  /**
   * Callback passed to the confirmation dialog
   * @returns void
   * @private
   */
  const _onWizardClosedWithChangeSet = () => {
    dispatch(closeDialog());
    history.replace("/portal");
  };

  useEffect(() => {
    if (currentPageIndex === 1) {
      setModalData({
        title: "You have unsaved changes",
        body: "Closing this will result in losing all your changes. Are you sure you want to close?",
        actionButtonText: "Confirm",
        variant: "updated",
        buttonTwoVariant: "contained",
        buttonTwoColor: undefined,
      });
      setIsEditing(true);
    } else {
      setIsEditing(false);
    }
  }, [currentPageIndex, setIsEditing, setModalData]);

  return (
    <div style={{ position: "relative" }}>
      <WizardStepTracker
        steps={steps}
        activeStep={currentPageIndex}
        setActiveStep={setCurrentPageIndex}
        onClose={onWizardClose}
      />
      <Spacer amount={2} />
      <div className={classes.inner_container}>
        {/* @ts-ignore */}
        <CurrentPage
          account={account}
          onCheckUtilityAvailable={onCheckUtilityAvailable}
          linkAccount={linkAccount}
          alreadyLinked={setUserAlreadyLinked}
        />
      </div>
    </div>
  );
};

export default LinkUtility;
