import React, { createContext, useCallback, useMemo, useState } from "react";
import tokenize from "@core/apis/bambora";
import { delete_profile, edit_profile, get_payment_profiles, register_profile } from "@core/apis/payment";
import { LOCAL_STORAGE_KEYS } from "@core/constants/local-storage";

export const PaymentProfileContext = createContext(null);

export const PaymentProfileContextProvider = (props) => {
  const [getProfileError, setGetProfileError] = useState(false);
  const [paymentProfiles, setPaymentProfiles] = useState(null);
  const [newCardAdded, setNewCardAdded] = useState(false);

  const findPaymentProfile = useCallback(
    (paymentProfileId) => {
      if (!paymentProfileId) return null;
      return paymentProfiles.find((profile) => profile.id === paymentProfileId);
    },
    [paymentProfiles],
  );

  const getPaymentProfiles = async () => {
    const getPaymentProfilesRes = await get_payment_profiles();
    if (!getPaymentProfilesRes) return;
    setGetProfileError(false);
    const profiles = getPaymentProfilesRes?.cards?.length
      ? getPaymentProfilesRes?.cards.sort((card1, card2) => {
          return card1.card.autoPay ? -1 : card2.card.autoPay ? 1 : 0;
        })
      : null;
    localStorage.setItem(LOCAL_STORAGE_KEYS.acceptedCreditCards, JSON.stringify(getPaymentProfilesRes?.acceptableCC));
    setPaymentProfiles(profiles);
  };

  const getDefaultValue = useCallback(() => {
    if (!paymentProfiles) return "";

    let defaultValue = "";
    const defaultPaymentProfiles = paymentProfiles.filter((paymentProfile) => paymentProfile.card.autoPay);
    if (defaultPaymentProfiles.length && !newCardAdded) {
      defaultValue = defaultPaymentProfiles[0];
    } else {
      setNewCardAdded(false);
      return paymentProfiles[paymentProfiles.length - 1];
    }
    return defaultValue;
  }, [newCardAdded, paymentProfiles]);

  const createProfile = useCallback(async (cardInfo) => {
    //Tokenizing
    const tokenizationRes = await tokenize(cardInfo);
    const bamboraToken = tokenizationRes.data.token;
    // Creating payment profile
    setNewCardAdded(true);
    return await register_profile(cardInfo.name, bamboraToken, cardInfo.autoPay);
  }, []);

  const deleteProfile = useCallback(async (paymentProfileId) => {
    const response = await delete_profile(paymentProfileId);
    setPaymentProfiles((prev) => prev.filter((profile) => profile.id !== paymentProfileId));
    return response;
  }, []);

  const editProfile = useCallback(async (cardInfo, autopay, paymentProfileId) => {
    const tokenizationRes = await tokenize(cardInfo);
    const bamboraToken = tokenizationRes.data.token;
    return await edit_profile(autopay, bamboraToken, paymentProfileId);
  }, []);

  const paymentProfileContext = useMemo(() => {
    return {
      findPaymentProfile,
      getProfileError,
      paymentProfiles,
      getPaymentProfiles,
      createProfile,
      deleteProfile,
      editProfile,
      getDefaultValue,
    };
  }, [createProfile, findPaymentProfile, getDefaultValue, getProfileError, paymentProfiles, deleteProfile, editProfile]);

  return <PaymentProfileContext.Provider value={paymentProfileContext}>{props.children}</PaymentProfileContext.Provider>;
};
