import React, { useContext, useMemo, useState } from "react";
import classes from "./index.module.scss";
import { KeyboardArrowDown } from "@mui/icons-material";
import { Popover } from "@mui/material";
import { ModuleTypes } from "@core/constants/enums";
import { ACTIVE, APPROVED, DRAFT, EXPIRING, ISSUED, RENEWING, REVIEWING, SAVED, SUBMITTED } from "@core/constants/licences";
import moment from "moment/moment";
import { useHistory } from "react-router-dom";
import ModuleItemCard from "../module-item-card";
import { capitalize, formatCurrency } from "@utils/index";
import LoadingGif from "@assets/gifs/logo-loading-transparent.gif";
import { routes } from "@routes/routesData";
import { updateStatusDiscarded } from "@core/apis/licence";
import { NotificationContext } from "@contexts/NotificationContext";
import { useDispatch } from "react-redux";
import { closeDialog, setState as setConfirmationDialogState } from "../../../../redux/slices/confrmation-dialog";

const ModuleSectionCard = ({
  actions,
  actionsLabel,
  title,
  icon,
  backgroundImage,
  items,
  setItems,
  moduleType,
  loading,
  hasAutoPay,
}) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showAll, setShowAll] = useState(false);
  const { handleSuccess } = useContext(NotificationContext);
  const dispatch = useDispatch();

  /**
   * Memo version of indicator for showing the "show More" section at the bottom of module items
   * @type {boolean}
   */
  const hasShowMore = useMemo(() => items?.length > 4, [items]);

  /**
   * Memo version of the indicator for when the popover is open (for drop down)
   * @type {boolean}
   */
  const isPopoverOpen = useMemo(() => Boolean(anchorEl), [anchorEl]);

  /**
   * Opens the popover for showing the actions
   * @param event
   */
  const handlePopoverClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Closes the popover
   */
  const handleClosePopOver = () => {
    setAnchorEl(null);
  };

  /**
   * Navigates user to the licence screen:
   * - if the status is draft navigates user to form screen
   * - otherwise, navigates user to licence details screen
   * @param id
   * @param status
   */
  const navigateToLicenceDetailsScreen = (id, status = "") => {
    if (status !== DRAFT) {
      history.push(`business-licence/${id}`);
    } else {
      history.push(`business-licence/form/${id}`);
    }
  };

  const navigateToUtilityFullDetails = (id) => {
    history.push(`${routes.UTILITY_DETAILS.codePath + id}`);
  };

  const navigateToPropertyTaxFullDetails = (id) => {
    history.push(`${routes.PROPERTY_TAX.codePath + id}`);
  };

  /**
   * Navigates user to check out screen
   */
  const navigateToPayDues = (e, id, renewFirst, withAutpPay) => {
    e.preventDefault();
    e.stopPropagation();
    if (renewFirst) {
      history.push(`/business-licence/${id}`, { renewalMode: !withAutpPay });
    } else {
      history.push(`/checkout?licence=${id}`, {
        id: null,
        licenceId: id,
        changesId: null,
      });
    }
  };

  /**
   * navigates user to the utilities checkout scree
   * @param e
   * @param id
   */
  const navigateToUtilitiesPayDues = (e, id) => {
    e.stopPropagation();
    history.push(`${routes.CHECKOUT.path}?account=${id}`);
  };

  const navigateToPropertyTaxPayDues = (e, id) => {
    e.stopPropagation();
    // TODO: navigate to property tax pay screen
  };

  /**
   * Discards draft licence and removes the discarded licence from the list
   */
  const handleDiscardDraftLicence = async (id) => {
    dispatch(closeDialog());
    if (!id) {
      return;
    }

    setItems((prevState) => prevState.map((item) => (item.id === id ? { ...item, isBeingRemoved: true } : item)));
    await updateStatusDiscarded(id);
    setTimeout(() => {
      setItems((prevState) => prevState.filter((e) => e.id !== id));
      handleSuccess("Business licence discarded");
    }, 500);
  };

  /**
   * Toggles the value of the [showAll] in inner state to its opposite boolean value
   */
  const toggleShowMore = () => {
    setShowAll((prevState) => !prevState);
  };

  /**
   * Memo version of the items as content of the module section card
   * @type {JSX.Element}
   */
  const renderChildren = useMemo(() => {
    return loading ? (
      <div
        style={{
          padding: "2rem",
        }}
      >
        <div className={"loading-container"}>
          <img src={LoadingGif} alt="Loading..." />
        </div>
      </div>
    ) : items?.length < 1 ? (
      <p className={classes.place_holder}>
        {moduleType === ModuleTypes.businessLicence
          ? "Add an existing business licence or create a new one here"
          : moduleType === ModuleTypes.propertyTaxes
            ? "Link your existing property tax account here"
            : "Link your existing utilities account here"}
      </p>
    ) : (
      items?.map((e, index) => {
        const renderVoid = index > 3 && hasShowMore && !showAll;
        const isPropertyTaxItem = moduleType === ModuleTypes.propertyTaxes;
        return renderVoid ? null : moduleType === ModuleTypes.businessLicence ? (
          e.status === DRAFT || e.status === SAVED ? (
            <ModuleItemCard
              isBeingRemoved={e?.isBeingRemoved ?? false}
              key={e.id}
              businessName={e.name}
              onClick={() => {
                navigateToLicenceDetailsScreen(e.id, e.status);
              }}
              description={`Application created on ${moment(e.createdAt).format("MMM DD, YYYY")}`}
              id={e.id}
              isActionable={false}
              canDiscard={true}
              onDiscard={(event) => {
                event.preventDefault();
                event.stopPropagation();
                dispatch(
                  setConfirmationDialogState({
                    open: true,
                    title: "Discarding draft",
                    body: "All the information contained in this draft will be deleted. A new application will require all information to be re-input from the beginning.",
                    onConfirm: () => handleDiscardDraftLicence(e.id),
                  }),
                );
              }}
            />
          ) : e.status === SUBMITTED || e.status === REVIEWING || e.status === APPROVED ? (
            <ModuleItemCard
              key={e.id}
              businessName={e.name}
              onClick={() => {
                navigateToLicenceDetailsScreen(e.id);
              }}
              description={`Application submitted on ${moment(e.submit).format("MMM DD, YYYY")}`}
              id={e.id}
              status={e.status.charAt(0).toUpperCase() + e.status.slice(1)}
              isActionable={false}
              canDiscard={false}
            />
          ) : (
            <ModuleItemCard
              key={e.id}
              isActionable={
                (e.balance > 0 && e.changeSetStatus !== "submitted") ||
                (e.status === RENEWING && e.changeSetStatus !== "submitted")
              }
              actionToken={e.status === RENEWING ? "Review" : "Pay Dues"}
              onTakeAction={(event) => navigateToPayDues(event, e.id, e.status === RENEWING, hasAutoPay)}
              businessName={e.name}
              onClick={() => {
                navigateToLicenceDetailsScreen(e.id);
              }}
              description={
                e.status === ISSUED
                  ? "Business licence issued"
                  : e.status === ACTIVE
                    ? `Licence: #${e.ref}`
                    : e.status === RENEWING
                      ? hasAutoPay
                        ? `Auto-renews on ${moment(e.expiry).add(1, "d").format("MMM DD, YYYY")}`
                        : "Renew your business licence"
                      : e.status === EXPIRING
                        ? `Set to expire`
                        : null
              }
              id={e.id}
              status={capitalize(e.status)}
              balance={e.balance}
              canDiscard={false}
              autoRenewMessage={
                e.status === ACTIVE ? `Auto-renews on ${moment(e.expiry).add(1, "d").format("MMM DD, YYYY")}` : null
              }
            />
          )
        ) : (
          <ModuleItemCard
            key={e.id}
            id={e.id}
            description={e?.address?.split(",")?.[0]}
            balance={e.owing}
            isActionable={e.balance > 0}
            onTakeAction={
              isPropertyTaxItem
                ? (event) => navigateToPropertyTaxPayDues(event, e.id)
                : (event) => navigateToUtilitiesPayDues(event, e.id)
            }
            onClick={isPropertyTaxItem ? () => navigateToPropertyTaxFullDetails(e.id) : () => navigateToUtilityFullDetails(e.id)}
            canDiscard={false}
            autoRenewMessage={!(e.balance > 0) ? `Statement Balance: ${formatCurrency(0)}` : null}
            status={!(e.balance > 0) ? "Active" : "PaymentNeeded"}
            businessName={isPropertyTaxItem ? `Roll #: ${e.folio}` : `Account #${e.accountNumber}`}
            totalDueText={"Statement Balance: "}
          />
        );
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, moduleType, loading, showAll, hasShowMore]);

  return (
    <>
      <div className={classes.container}>
        <div className={classes.background_image}>
          <img src={backgroundImage} alt="module background" />
        </div>
        <div className={classes.header}>
          <div className={classes.title_section}>
            {icon}
            <p className={classes.title}>{title}</p>
          </div>
          <div className={`${classes.drop_down} ${isPopoverOpen ? classes.open : ""}`} onClick={handlePopoverClick}>
            <p>{actionsLabel}</p>
            <KeyboardArrowDown />
          </div>
          {actions?.length > 0 && (
            <Popover
              className={"module-section-popover"}
              open={isPopoverOpen}
              anchorEl={anchorEl}
              onClose={handleClosePopOver}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              {actions?.map((e) => (
                <div className={"popover_option"} onClick={e.onClick} key={e.label}>
                  {e.icon}
                  <p>{e.label}</p>
                </div>
              ))}
            </Popover>
          )}
        </div>
        <div className={classes.content}>
          <>
            {renderChildren}
            {hasShowMore && (
              <div className={classes.show_more_container}>
                <div />
                <div className={showAll ? classes.open : ""} onClick={toggleShowMore}>
                  <p>{showAll ? "Show less" : `Show ${items.length - 4} more`}</p>
                  <KeyboardArrowDown />
                </div>
              </div>
            )}
          </>
        </div>
      </div>
    </>
  );
};

export default ModuleSectionCard;
