import React from "react";
import { Tooltip } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";

const TagEditorInfoButton = ({ onClick = () => {} }) => {
  return (
    <Tooltip title={"What are tags?"} style={{ position: "absolute", right: 4 }}>
      <IconButton
        onClick={(event) => {
          event.stopPropagation();
          onClick();
        }}
      >
        <InfoOutlined color={"primary"} />
      </IconButton>
    </Tooltip>
  );
};

export default TagEditorInfoButton;
