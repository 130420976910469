import * as yup from "yup";
import * as v from "@utils/yupValidators";
import { validationRegex } from "@utils/regexes";
import { AddressCountry } from "@core/constants";

// TODO: needs to be double checked with actual data as this component is used in admin side as well
export const accountValidators = yup.object({
  account_holder: yup.string().trim().required("Name is required"),
  address1: yup.string().required(),
  address2: yup.string().nullable().notRequired(),
  city: yup.string().required(),
  province_state: yup.string().required(),
  phone_number: v.validPhoneFormattedUtilities,
  is_owner: yup.string().required(),
  postal_zip: yup
    .string()
    .required("Postal/ZIP code is required.")
    .max(10, "Postal/ZIP code is too long.")
    .test({
      name: "POSTAL_CODE_MATCH_COUNTRY",
      test: function (value: any) {
        const postalCodeInput = value;
        const fieldName = "postal_zip";
        const countryValue = this.parent["country"];
        const isCA = countryValue !== AddressCountry.US; // Thus when no value, default to IsCA: true
        const regex = isCA ? validationRegex.postalCode : validationRegex.zipCode;
        const match = regex.exec(postalCodeInput);
        return match
          ? true
          : this.createError({
              message: isCA ? "Invalid postal code." : "Invalid ZIP code.",
              path: fieldName,
            });
      },
    }),
});

export const contactValidators = yup.object({
  other_contacts: yup
    .array()
    .of(
      yup.object().shape({
        first_name: yup.string().max(35, "First name is too long").required("First name is required"),
        last_name: yup.string().max(35, "Last name is too long").required("Last name is required"),
        phone_number: v.validPhoneFormattedOptional,
        email: v.validEmail,
      }),
    )
    .test("Duplicate email", "Emails need te be unique", (contacts) => {
      const emails = contacts?.map((contact) => contact?.email);
      return new Set(emails).size === contacts?.length;
    }),
});
