import React from "react";
import classes from "./index.module.scss";
import { formatCurrency } from "@utils/index";
import moment from "moment";
import { Skeleton } from "@mui/material";

const AdminUtilityBalanceCard = ({ loading, balance, accountBalance, lastPayment, dueDate }) => {
  return (
    <>
      {loading ? (
        <Skeleton variant="rounded" className={classes.loading} />
      ) : (
        <div className={classes.container}>
          <p>STATEMENT BALANCE</p>
          <p>{formatCurrency(balance)}</p>
          {!!dueDate && <p>{`Account Balance: ${formatCurrency(accountBalance)}`}</p>}
          {!!dueDate && <p>{`Payment received: ${formatCurrency(lastPayment)}`}</p>}
          {!!dueDate && <p>{`Due date: ${moment(dueDate).format("MMM DD, yyyy")}`}</p>}
        </div>
      )}
    </>
  );
};

export default AdminUtilityBalanceCard;
