import React, { forwardRef, useEffect, useState } from "react";
import Cleave from "cleave.js/react";
import { default as MuiTextField } from "@mui/material/TextField";
import { customFormat, getCleaveFormatOptions } from "@utils/cleaveOptions";

export function CleaveInput(props) {
  const options = getCleaveFormatOptions(props.format);

  return <Cleave {...props} options={options} />;
}

const MaskedTextField = forwardRef((props, ref) => {
  const { form, value, ...rest } = props;
  const { format, onChange } = rest;
  if (!getCleaveFormatOptions(format)) {
    return (
      <input
        {...rest}
        value={value}
        ref={ref}
        onChange={(e) => {
          e.target.value = customFormat(format, e.target.value, value);
          onChange(e);
        }}
      />
    );
  }
  return <CleaveInput {...rest} htmlRef={ref} value={value} />;
});

const PASSWORD = "password";
const TEXT = "text";

/**
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<any> & React.RefAttributes<unknown>>}
 */
const TextField = forwardRef((props, ref) => {
  const {
    form,
    format,
    label,
    value,
    mask,
    required = true,
    InputProps,
    inputProps,
    readOnly,
    type,
    variant,
    ...otherProps
  } = props;
  const [passwordType, setPasswordType] = useState(TEXT);
  const fieldLabel = label && (required ? label : `${label} (optional)`);
  const isPassword = type === PASSWORD;

  const getInputProps = () => {
    switch (variant) {
      case "outlined":
        return {
          style: { fontWeight: 700 },
          inputComponent: MaskedTextField,
          readOnly: readOnly,
          ...InputProps,
        };
      case "filled":
        return {
          style: { fontWeight: 700 },
          inputComponent: MaskedTextField,
          disableUnderline: true,
          readOnly: readOnly,
          ...InputProps,
        };
      default:
        return {
          style: { fontWeight: 700 },
          disableUnderline: true,
          inputComponent: MaskedTextField,
          readOnly: readOnly,
          ...InputProps,
        };
    }
  };

  useEffect(() => {
    if (mask != null) {
      setPasswordType(mask);
    }
  }, [mask, passwordType]);

  return (
    <MuiTextField
      {...props}
      {...otherProps}
      inputRef={ref}
      value={value}
      type={isPassword ? passwordType : type}
      label={fieldLabel}
      InputProps={getInputProps()}
      inputProps={{
        form,
        format,
        autoComplete: isPassword ? "off" : null,
        ...inputProps,
      }}
    />
  );
});

export default TextField;
