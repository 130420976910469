import React, { useContext, useEffect, useMemo, useState } from "react";
import styled from "@emotion/styled";
import { NotificationContext } from "@contexts/NotificationContext";
import Typography from "@components/ui-kit/typography";
import Box from "@mui/material/Box";
import { useHistory, useLocation, useParams } from "react-router-dom";

import {
  createChangeSet,
  getLicence,
  getLicenceSummary,
  getStatus,
  updateChangeSetStatus,
  updateStatusCancelled,
  updateStatusDiscarded,
  updateStatusExpiring,
  updateStatusSubmitted,
} from "@core/apis/licence";
import Tab from "@components/ui-kit/tab";
import Tabs from "@components/ui-kit/tabs";
import MainContainer from "@components/layout-helpers/triple-column-layout/MainContainer";
import LeftColumn from "@components/layout-helpers/triple-column-layout/LeftColumn";
import RightColumn from "@components/layout-helpers/triple-column-layout/RightColumn";
import Spacer from "@components/ui-kit/Spacer";
import GeneralTab from "../BusinessLicenceDetailsGeneralTab";
import Hero from "@components/ui-kit/mch/Hero";
import DocumentThumbnail from "@components/ui-kit/mch/DocumentThumbnail";
import Grid from "@mui/material/Unstable_Grid2";
import SideBar from "../DetailsScreen/SideBar";
import CircularProgress from "@mui/material/CircularProgress";
import { mapInto } from "../mappings/mapping";
import { editAttachment, getAttachment, uploadFile } from "@core/apis/attachment";
import { ACTIVE, editStatuses, RENEWING, SUBMITTED } from "@core/constants/statuses";
import { DISCARDED, MAX_FILES_SIZE, REVIEWING_GROUP, SAVED } from "@core/constants/licences";
import useQuery from "@hooks/useQuery";
import useHash from "@hooks/useHash";
import UploadFileModal from "@components/modals/UploadFileModal";
import { saveFormValues } from "../functions/business_licence_functions";
import MaxDocumentDisplay from "@components/ui-kit/MaxDocumentDisplay";
import { roundMb } from "@utils/index";
import { Button, useTheme } from "@mui/material";
import RenameModal from "@components/modals/RenameModal";
import classes from "./index.module.scss";
import WBCBreadcrumbs from "@components/ui-kit/breadcrumbs";
import { Home } from "@mui/icons-material";
import { routes } from "@routes/routesData";
import { handleDownloadDoc, handlePreviewDoc } from "@utils/attachmentHelpers";
import config from "@core/apis/_config";
import WizardStepTracker from "@components/ui-kit/wizard-step-tracker";
import Checkout from "../../../payment/checkout";
import { useDispatch } from "react-redux";
import { closeDialog, setState as setConfirmationDialogState } from "../../../../../redux/slices/confrmation-dialog";
import { EditingContext } from "@contexts/EditingContext";
import BLInvoicePaymentTab from "./invoice-payment-tab";

//Constants
//Sidebar
const DRAFT = "draft";

const Container = styled.div`
  padding-bottom: 4rem;
`;

const LargeScreenSideBar = styled.div`
  display: flex;
  width: 375px;
  position: sticky;
  top: 0;
  height: 100%;

  @media (max-width: 720px) {
    display: none;
  }
`;

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 15%;
`;

const tabs = ["General", "Documents", "Invoices & Payments"];

const renewalSteps = [
  { id: "step1", label: "Review your details" },
  { id: "step2", label: "Pay renewal fees" },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const SmallScreenSideBar = styled.div`
  display: none;
  @media (max-width: 720px) {
    display: block;
  }
`;

/**
 * Displays either a draft or licence for business licences
 * @returns {JSX.Element}
 * @constructor
 */
const LicenseDetails = () => {
  const theme = useTheme();
  const query = useQuery();
  const location = useLocation();
  const { handleSuccess } = useContext(NotificationContext);
  const [item, setItem] = useState({});
  const { id } = useParams();
  const [documentsData, setDocumentsData] = useState({
    documents: [],
    error: null,
    size: 0,
  });
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [renameLoading, setRenameLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [uploadDocumentModalOpen, setUploadDocumentModalOpen] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [tab, setTab] = useState(query.get("tabName") || tabs[0]);
  const [status, setStatus] = useState(DRAFT);
  const [canEditLicense, setCanEdit] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [originalValues, setOriginalValues] = useState({});
  const [childStatus, setChildStatus] = useState("singleton");
  const history = useHistory();
  const { state } = useLocation();
  const hash = useHash();
  const { setIsEditing } = useContext(EditingContext);

  // Renewal flow state
  const [isRenewalMode, setIsRenewalMode] = useState(location?.state?.renewalMode ?? false);
  const [currentRenewalStep, setCurrentRenewalStep] = useState(0);
  const onUpdateAndRenew = async () => {
    history.replace(`/business-licence/${id}`, { renewalMode: true });
  };

  // Redux
  const dispatch = useDispatch();

  const [modalData, setModalData] = useState(null);
  const renameModalOpen = Boolean(modalData?.type === "rename");

  useEffect(() => {
    setIsRenewalMode(location?.state?.renewalMode ?? false);
    setRefresh(!refresh);
    //eslint-disable-next-line
  }, [location.state]);

  const onModalOpen = (name, id, type) => {
    setModalData({
      name,
      id,
      type,
    });
  };

  /**
   * Handles renaming of attachments
   * @param attachmentId
   * @param fileName
   * @returns {Promise<void>}
   */
  const onRename = async (attachmentId, fileName) => {
    setRenameLoading(id);
    try {
      await editAttachment(attachmentId, fileName, id);
      await getFiles(item.attachments);
    } catch (e) {
      console.log(e.message);
      handleError("Unable to rename file, please try again.");
    } finally {
      setRenameLoading(false);
    }
  };

  const closeModal = () => {
    setModalData(null);
  };

  const changeTab = (e, newTab) => {
    history.push(`/business-licence/${id}#${newTab}`);
  };

  useEffect(() => {
    let t = hash || tabs[0];
    t = t.replaceAll("%20", " ");
    setTab(t);
  }, [hash]);

  /**
   * Adds newly uploaded files to the business licence.
   * @param files
   * @returns {Promise<void>}
   */
  const addFiles = async (files) => {
    setUploadInProgress(true);
    let fileArray = item.attachments;
    if (!fileArray) {
      fileArray = [];
    }
    for (let i = 0; i < files.length; i++) {
      fileArray.push(await uploadFile(files[i].file));
    }

    let newValues = { attachments: fileArray };
    await saveFormValues(id, newValues);
    await getFiles(fileArray);
    setUploadInProgress(false);
  };

  const getFiles = async (ids) => {
    if (!ids) return;
    let newFiles = [];
    let size = 0;
    for (let i = 0; i < ids.length; i++) {
      const newFile = await getAttachment(ids[i], id);
      size += newFile?.fileSize;
      newFiles.push(newFile);
    }
    let error = null;
    if (roundMb(size) > MAX_FILES_SIZE) {
      error = `Total filesize is too big. (Upload ${MAX_FILES_SIZE} MB or less)`;
    }
    setDocumentsData({ documents: newFiles, size: size, error: error });
  };

  const { handleError } = useContext(NotificationContext);

  const onAcknowledge = () => {};

  const onDiscard = async () => {
    dispatch(closeDialog());
    let newStatus = await updateStatusDiscarded(id);
    handleSuccess("Business licence discarded");
    setStatus(newStatus.newState);
    history.push("/");
  };

  /**
   * Throws an error if a user attempts to upload more than the max
   * file size of all current files.
   */
  const isMaxedFilesSize = () => {
    if (roundMb(documentsData.size) > MAX_FILES_SIZE) {
      throw new Error("Max file size exceeded");
    }
  };

  const onSubmit = async () => {
    dispatch(closeDialog());
    setInitialLoading(true);
    if (uploadInProgress) {
      handleSuccess("File upload in progress");
      setRefresh(!refresh);
      return;
    }
    try {
      isMaxedFilesSize();
      let newStatus = await updateStatusSubmitted(id);
      setStatus(newStatus.newState);
      if (item.prevLicenceId) {
        handleSuccess("Your changes are submitted. City staff might contact you if they need more information.");
      }
      setCanEdit(false);
    } catch (e) {
      handleError(e.message);
    } finally {
      setInitialLoading(false);
      setRefresh(!refresh);
    }
  };

  const onPayDues = () => {
    history.push(`/checkout?licence=${id}`, {
      id: item.invoiceId,
      licenceId: id,
      changesId: item.changeSetId,
    });
  };

  const onCancelLicence = async () => {
    try {
      await updateStatusCancelled(id);
      history.push("/", { cancelledSuccess: true });
    } catch (e) {
      handleError("Could not cancel the licence, please try again later");
    }
  };

  const onExpireLicence = async () => {
    try {
      await updateStatusExpiring(id);
      history.push("/", { expiredSuccess: true });
    } catch (e) {
      handleError("Could not set licence to expire, please try again later");
    }
  };

  const editPage = async (page) => {
    try {
      history.push(`/business-licence/form/${id}#${page + 1}`, {
        pageEdit: page,
        changesId: item.changeSetId,
        ...(isRenewalMode ? { renewalMode: true } : {}),
      });
    } catch (e) {
      console.log(e.message);
    }
  };

  const editActivePage = async () => {
    try {
      if (status === ACTIVE || status === RENEWING) {
        await createChangeSet(id, "business_licence/edit");
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  /**
   * Gets the licence information by calling the api regarding this action
   * @returns {Promise<void>}
   */
  const fetchLicence = async () => {
    try {
      setInitialLoading(true);
      const addressKeys = [
        "unit",
        "code",
        "businessAddress",
        "businessAddressCity",
        "businessAddressProvince",
        "businessCountry",
        "mailingUnit",
        "mailingCode",
        "mailingAddress",
        "mailingAddressCity",
        "mailingAddressProvince",
        "mailingCountry",
      ];
      const results = await getLicence(id);
      let refractedValues = {};
      let refractedOriginalValues = {};
      results.forEach((val) => {
        let newVal = mapInto(val);
        if (!newVal) return;
        if (newVal.property === "tmpOwners") {
          if (newVal.value?.orgOwners.length !== 0 || newVal.value?.orgManagers.length !== 0) {
            refractedOriginalValues["orgOwners"] = newVal.value?.orgOwners ? newVal.value?.orgOwners : [];
            refractedOriginalValues["orgManagers"] = newVal.value?.orgManagers ? newVal.value?.orgManagers : [];
          }
          refractedValues["owners"] = newVal.value?.owners ? newVal.value?.owners : [];
          refractedValues["managers"] = newVal.value?.managers ? newVal.value?.managers : [];
        } else if (newVal.property === "businessType") {
          refractedValues[newVal.property] = {
            value: newVal.value,
            categories: newVal.categories,
          };
        } else if (addressKeys.includes(newVal.property)) {
          refractedValues[newVal.property] = newVal.value;
          if (newVal.originalValue !== undefined) {
            refractedOriginalValues[newVal.property] = newVal.originalValue;
          }
        } else {
          refractedValues[newVal.property] = newVal.value;
          if (newVal.originalValue !== undefined && newVal.value !== newVal.originalValue) {
            refractedOriginalValues[newVal.property] = newVal.originalValue;
          }
        }
      });
      getFiles(refractedValues.attachments).then();
      setCanEdit(editStatuses.find((x) => x === refractedValues["currentStatus"]));
      if (refractedValues["previousLicenceId"] !== "") {
        const originalSummary = await getLicenceSummary(refractedValues["previousLicenceId"]);
        if (originalSummary) {
          refractedValues["original"] = originalSummary;
          setChildStatus("duplicate");
        }
      }
      if (refractedValues["updatedLicenceId"] !== "") {
        const updatedStatus = await getStatus(refractedValues["updatedLicenceId"]);
        if (updatedStatus) {
          setCanEdit(editStatuses.find((x) => x === state));
          setChildStatus("original");
        }
      }
      setItem(refractedValues);
      setOriginalValues(refractedOriginalValues);
      setStatus(refractedValues["currentStatus"]);
    } catch (e) {
      if (e.response.status === 404) {
        history.replace("/404");
      }
      if (e.response.status === 403) {
        history.replace("/403");
      } else {
        handleError("Unable to load licence details");
      }
    } finally {
      setInitialLoading(false);
    }
  };

  const handleUploadDocumentModalOpen = () => {
    setUploadDocumentModalOpen(true);
  };

  const handleUploadDocumentModalClose = () => {
    setUploadDocumentModalOpen(false);
  };

  /**
   * With each change in the refresh property in inner state as well as the initial render:
   * - Calls the fetchLicence callback
   */
  useEffect(() => {
    fetchLicence().then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  //Initial load
  useEffect(() => {
    if (state?.showPaymentNotification) {
      handleSuccess(state.showPaymentNotification);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onConfirmRenewalStepOne = async () => {
    if (!!item.changeSetId && item.changeSetStatus === "draft") {
      await updateChangeSetStatus(id, item.changeSetId, SUBMITTED, "false");
      setItem((prevState) => ({
        ...prevState,
        changeSetStatus: SUBMITTED,
      }));
    }
    setCurrentRenewalStep(1);
  };

  /**
   * Handles closing the renewal flow wizard:
   * - if changeset is present, dispatches redux action for showing confirmation dialog
   * - otherwise, resets the location state by setting [renewalMode] to false
   */
  const onWizardClosed = () => {
    if (item.changeSetId && currentRenewalStep === 0) {
      dispatch(
        setConfirmationDialogState({
          open: true,
          title: "You have unsaved changes",
          body: "Closing this will result in losing all your changes. Are you sure you want to close?",
          onConfirm: _onWizardClosedWithChangeSet,
        }),
      );
      return;
    }
    history.replace(`/business-licence/${id}`, { renewalMode: false });
  };

  const _onWizardClosedWithChangeSet = async () => {
    dispatch(closeDialog());
    await updateChangeSetStatus(id, item.changeSetId, DISCARDED, "false");
    history.replace(`/business-licence/${id}`, { renewalMode: false });
    setCurrentRenewalStep(0);
  };

  const _onDeleteFile = async (fileId) => {
    setDeleteLoading(fileId);
    try {
      let fileArray = item.attachments;
      fileArray = fileArray.filter((x) => x !== fileId);
      let newValues = { attachments: fileArray };
      await saveFormValues(id, newValues);
      await fetchLicence();
    } catch (e) {
      console.log(e.message);
      handleError("Unable to delete file, please try again.");
    } finally {
      setDeleteLoading(false);
      dispatch(closeDialog());
    }
  };

  const canEditDocuments = useMemo(
    () => item.balanceOwing <= 0 || REVIEWING_GROUP.includes(item.currentStatus),
    [item.balanceOwing, item.currentStatus],
  );

  const renewalFlowContent = useMemo(() => {
    return currentRenewalStep === 0 ? (
      <div>
        <WizardStepTracker
          steps={renewalSteps}
          activeStep={currentRenewalStep}
          setActiveStep={setCurrentRenewalStep}
          onClose={onWizardClosed}
        />
        {initialLoading ? (
          <LoadingWrapper>
            <CircularProgress size={100} style={{ marginTop: "1rem" }} />
          </LoadingWrapper>
        ) : (
          <div className={classes.renewal_flow_info_wrapper}>
            <div className={classes.renewal_flow_title_info}>
              <h2 style={{ marginTop: "3rem" }}>Review your details before renewing</h2>
              <p>Please review your information carefully before renewing your business licence and making payment.</p>
              <p>
                <span style={{ fontWeight: 500 }}>Please note:</span> Any updates to your information will be forwarded to the
                City Staff for their review.
              </p>
            </div>
            <div className={classes.renewal_flow_info_content}>
              <GeneralTab
                renewalProcess={true}
                editPage={editPage}
                status={SAVED}
                licence={item}
                canEdit={true}
                originalValues={originalValues}
              />
              <Spacer amount={2} />
              <Button variant="contained" onClick={onConfirmRenewalStepOne} fullWidth={true}>
                Confirm & Pay fees
              </Button>
              <Spacer amount={10} />
            </div>
          </div>
        )}
      </div>
    ) : (
      <div>
        <WizardStepTracker
          steps={renewalSteps}
          activeStep={currentRenewalStep}
          setActiveStep={setCurrentRenewalStep}
          onClose={onWizardClosed}
        />
        <Checkout isRenewalFlow={true} licenceId={id} renewalWithChanges={!!item.changeSetId} />
      </div>
    );
    // eslint-disable-next-line
  }, [currentRenewalStep, history, id, initialLoading, item, originalValues]);

  useEffect(() => {
    if (isRenewalMode && item.changeSetId && currentRenewalStep === 0) {
      setModalData({
        title: "You have unsaved changes",
        body: "Closing this will result in losing all your changes. Are you sure you want to close?",
        actionButtonText: "Confirm",
        variant: "updated",
        buttonTwoVariant: "contained",
        buttonTwoColor: undefined,
      });
      setIsEditing(true, async () => {
        await updateChangeSetStatus(id, item.changeSetId, DISCARDED, "false");
      });
    } else {
      setIsEditing(false);
    }
  }, [isRenewalMode, setIsEditing, item.changeSetId, id, currentRenewalStep]);

  return (
    <>
      {isRenewalMode ? (
        renewalFlowContent
      ) : (
        <Container>
          <div className={classes.top_bar}>
            <WBCBreadcrumbs
              children={[
                {
                  icon: <Home fontSize={"small"} />,
                  text: "HOME",
                  onClick: () => history.replace(routes.LICENCES.path),
                },
                {
                  text: "BUSINESS LICENCE",
                },
              ]}
            />
            <div />
          </div>
          <Hero
            backPathName={"/portal"}
            close
            img={`https://images.cloudcityhall.com/${config.cityId}/interface/stock_01.jpg`}
            name={item?.businessName}
            title={"Business Licence"}
          />
          <Tabs className={"padding_left"} onChange={changeTab} value={tab}>
            <Tab style={{ minWidth: 55 }} label={tabs[0]} value={tabs[0]} />
            <Tab style={{ minWidth: 55 }} label={tabs[1]} value={tabs[1]} />
            <Tab style={{ minWidth: 55 }} label={tabs[2]} value={tabs[2]} />
          </Tabs>
          <MainContainer padding={"7px"}>
            {initialLoading ? (
              <LoadingWrapper>
                <CircularProgress size={100} style={{ marginTop: "1rem" }} />
              </LoadingWrapper>
            ) : (
              <>
                <LeftColumn>
                  <>
                    <Spacer amount={1} />
                    <TabPanel value={tab} index={tabs[0]}>
                      <Grid item xs={12}>
                        <SmallScreenSideBar>
                          <SideBar
                            status={status}
                            onAcknowledge={onAcknowledge}
                            onSubmit={onSubmit}
                            onCancel={onCancelLicence}
                            onDiscard={onDiscard}
                            onPayDues={onPayDues}
                            onExpire={onExpireLicence}
                            totalDues={item.balanceOwing}
                            versionStatus={childStatus}
                            canEditLicense={canEditLicense}
                            licence={item}
                            id={id}
                            originalValues={originalValues}
                            editPage={editActivePage}
                            refresh={refresh}
                            setRefresh={setRefresh}
                            onUpdateAndRenew={onUpdateAndRenew}
                          />
                        </SmallScreenSideBar>
                      </Grid>
                      <GeneralTab
                        editPage={editPage}
                        status={status}
                        licence={item}
                        canEdit={canEditLicense}
                        originalValues={originalValues}
                      />
                    </TabPanel>
                    <TabPanel value={tab} index={tabs[1]}>
                      <h1
                        style={{
                          marginTop: "1rem",
                          marginBottom: 0,
                        }}
                      >
                        Documents
                      </h1>
                      {/*<TransactionsInvoicesTab />*/}
                      <Grid container spacing={0} style={{ marginTop: "3rem" }}>
                        <Grid item xs={12}>
                          <div style={{ marginBottom: "1rem" }}>
                            <h2
                              style={{
                                marginTop: "auto",
                                marginBottom: "auto",
                              }}
                            >
                              Supporting documents
                            </h2>
                          </div>
                        </Grid>
                        <Grid container spacing={2} mt={2}>
                          {canEditDocuments ? (
                            <>
                              <Grid item xs={12}>
                                <MaxDocumentDisplay
                                  maxFileSize={MAX_FILES_SIZE}
                                  fileLength={documentsData.documents.length}
                                  fileSize={documentsData.size}
                                  justifyContent={"left"}
                                />
                                {documentsData.error ? (
                                  <Typography variant={"body2"} color={theme.palette.nonPalette.RED}>
                                    {documentsData.error}
                                  </Typography>
                                ) : null}
                              </Grid>
                              <Grid item>
                                <DocumentThumbnail
                                  onClick={handleUploadDocumentModalOpen}
                                  variant={"upload"}
                                  loading={uploadInProgress}
                                  style={{ marginBottom: "1rem" }}
                                />
                              </Grid>
                            </>
                          ) : null}
                          {documentsData.documents.map((doc) => {
                            let loading = false;
                            if (deleteLoading && deleteLoading === doc?.attachmentId) {
                              loading = true;
                            }
                            if (renameLoading && renameLoading === doc?.attachmentId) {
                              loading = true;
                            }
                            return (
                              <Grid item key={doc?.attachmentId}>
                                <DocumentThumbnail
                                  loading={loading}
                                  onClick={() => handlePreviewDoc(doc?.attachmentId, id, handleError)}
                                  canEdit={canEditDocuments}
                                  title={doc?.fileLabel || "-"}
                                  style={{ marginBottom: "1rem" }}
                                  onDownload={() => handleDownloadDoc(doc?.attachmentId, doc?.fileLabel || "-", id, handleError)}
                                  onDelete={() => {
                                    onModalOpen(doc?.fileLabel, doc?.attachmentId, "delete");
                                    dispatch(
                                      setConfirmationDialogState({
                                        open: true,
                                        title: "Delete file",
                                        body: `Are you sure you want to delete this file? ${doc?.fileLabel}`,
                                        onConfirm: () => _onDeleteFile(doc?.attachmentId),
                                      }),
                                    );
                                  }}
                                  onRename={() => {
                                    onModalOpen(doc?.fileLabel, doc?.attachmentId, "rename");
                                  }}
                                />
                              </Grid>
                            );
                          })}
                        </Grid>
                      </Grid>
                    </TabPanel>
                    <TabPanel value={tab} index={tabs[2]}>
                      <h1
                        style={{
                          marginTop: "1rem",
                          marginBottom: 0,
                        }}
                      >
                        Invoices & Payments
                      </h1>
                      <Spacer amount={5} />
                      <BLInvoicePaymentTab licenceId={id} />
                      <Spacer />
                    </TabPanel>
                    <RenameModal open={renameModalOpen} data={modalData} handleClose={closeModal} renameFile={onRename} />
                  </>
                </LeftColumn>
                <LargeScreenSideBar>
                  <RightColumn>
                    <SideBar
                      onUpdateAndRenew={onUpdateAndRenew}
                      showChangesMessage={Boolean(item.prevLicenceId)}
                      status={status}
                      onAcknowledge={onAcknowledge}
                      onSubmit={onSubmit}
                      onCancel={onCancelLicence}
                      onDiscard={onDiscard}
                      onPayDues={onPayDues}
                      onExpire={onExpireLicence}
                      totalDues={item.balanceOwing}
                      versionStatus={childStatus}
                      licence={item}
                      id={id}
                      canEditLicense={canEditLicense}
                      editPage={editActivePage}
                      refresh={refresh}
                      setRefresh={setRefresh}
                      originalValues={originalValues}
                      marginTop={"6.4rem"}
                    />
                  </RightColumn>
                </LargeScreenSideBar>
              </>
            )}
          </MainContainer>
          <UploadFileModal
            fileTitle={"File Attachment"}
            files={documentsData.documents}
            handleClose={handleUploadDocumentModalClose}
            modalOpen={uploadDocumentModalOpen}
            addFiles={addFiles}
          />
        </Container>
      )}
    </>
  );
};

export default LicenseDetails;
