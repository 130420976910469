import React from "react";
import { useLocation } from "react-router-dom";

const useHash = () => {
  const { hash } = useLocation();

  return React.useMemo(() => hash.substring(1), [hash]);
};

export default useHash;
