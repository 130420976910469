import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as yup from "yup";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getAccountDetail } from "@core/apis/account";
import { closeDialog, setState as setConfirmationDialogState } from "../../../../redux/slices/confrmation-dialog";
import WizardStepTracker from "@components/ui-kit/wizard-step-tracker";
import Spacer from "@components/ui-kit/Spacer";
import UtilityBanner from "../link-utility/widgets/UtilityBanner";
import { Formik } from "formik";
import moment from "moment/moment";
import DatePicker from "@components/ui-kit/DatePicker";
import Button from "@mui/material/Button";
import ListItem from "@components/ui-kit/list-item";
import { EventOutlined, LocationOnOutlined } from "@mui/icons-material";
import { CheckboxCard } from "@components/ui-kit/CheckboxCard";
import LinkButton from "@components/ui-kit/LinkButton";
import Modal from "@components/ui-kit/Modal";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { routes } from "@routes/routesData";

const wizardSteps = [
  { id: "step1", label: "Cancellation date" },
  { id: "step2", label: "Review and submit" },
];

const validation = yup.object().shape({
  date: yup
    .date()
    .typeError("Invalid date format")
    .required("Date is required")
    .min(moment().subtract(1, "day").toISOString(), "Date must be the later than current date"),
});

const CancelUtilityAccountWizard = () => {
  const history = useHistory();
  const { id } = useParams();
  const [currentStep, setCurrentStep] = useState(0);
  const [data, setData] = useState(null);
  const [updateInfo, setUpdateInfo] = useState({ date: null });
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showTOSModal, setShowTOSModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    getAccountInformation().then();
    //eslint-disable-next-line
  }, []);

  const getAccountInformation = async () => {
    const results = await getAccountDetail(id);
    setData(results);
  };

  const onWizardClosed = () => {
    if (currentStep !== 0) {
      dispatch(
        setConfirmationDialogState({
          open: true,
          title: "You have unsaved changes",
          body: "Closing this will result in losing all your changes. Are you sure you want to close?",
          onConfirm: _handleConfirmationDialogAction,
        }),
      );
      return;
    }
    history.goBack();
  };

  const _handleConfirmationDialogAction = () => {
    dispatch(closeDialog());
    history.goBack();
  };

  const onSubmit = (values) => {
    console.log(values);
    setUpdateInfo(values);
    setCurrentStep(1);
  };

  const stepOne = useMemo(() => {
    return (
      <>
        <Formik
          initialValues={{
            date: updateInfo.date ?? null,
          }}
          validationSchema={validation}
          onSubmit={onSubmit}
          validateOnChange={true}
          validateOnBlur={false}
          enableReinitialize={true}
        >
          {({ dirty, values, errors, validateForm, setFieldValue, submitForm, isValid }) => {
            return (
              <>
                <h2>Request of cancellation</h2>
                <p className={"body"}>To cancel your utilities services, please select the cancellation date.</p>
                <div style={{ width: "100%" }}>
                  <p className={"overline_light"}>Your service address</p>
                  <UtilityBanner
                    account={{
                      accountNumber: data?.find((e) => e.name === "account_number")?.value?.value ?? "",
                      address: data?.find((e) => e.name === "service_address")?.value?.value ?? "",
                    }}
                    hasPadding={false}
                  />
                </div>
                <Spacer amount={2} />
                <p className={"overline_light"}>cancellation date</p>
                <DatePicker
                  name={"date"}
                  textFieldError={Boolean(errors.date)}
                  textFieldHelperText={errors.date}
                  sx={{ width: "100%" }}
                  label={"Add date"}
                  onChange={(value) => {
                    setFieldValue("date", value);
                  }}
                  value={values.date}
                  minDate={moment()}
                />
                <Spacer />
                <p className={"body_regular"}>
                  <span className={"bold"}>Please note:</span> If you're moving to a new service address, please proceed to{" "}
                  <span
                    className={"text_button"}
                    onClick={() => history.replace(routes.UTILITY_MOVING_CITY.path.replace(":id", id))}
                  >
                    Move Service Address
                  </span>{" "}
                  to submit your request.
                </p>
                <Spacer />
                <Button
                  fullWidth
                  size={"large"}
                  variant="contained"
                  type="submit"
                  disabled={!dirty || !isValid}
                  onClick={async () => {
                    const validationResults = await validateForm();
                    console.log(validationResults);
                    const err = Object.keys(validationResults);
                    if (err.length) {
                      console.log(`#${err[0]}`);
                      const input = document.querySelector(`#${err[0]}`) || document.querySelector(`input[name='${err[0]}']`);
                      if (input !== null) {
                        input.scrollIntoView({
                          behavior: "smooth",
                          block: "center",
                          inline: "start",
                        });
                      }
                      return;
                    }
                    await submitForm();
                  }}
                >
                  Next
                </Button>
              </>
            );
          }}
        </Formik>
      </>
    );
  }, [data, history, id, updateInfo.date]);

  const confirmCancellation = () => {
    setShowConfirmationModal(true);
  };

  const handleConfirmModalClose = useCallback(() => {
    setShowConfirmationModal(false);
    history.goBack();
  }, [history]);

  const stepTwo = useMemo(() => {
    return (
      <>
        <h2>Review before cancelling</h2>
        <p className={"body"}>
          Before canceling your utilities account, thoroughly review your information, as this action cannot be undone.
        </p>
        <Spacer />
        <Spacer />
        <p className={"overline_light"}>Review information</p>
        <div className={"outlined_container"}>
          <ListItem
            startOrnament={<LocationOnOutlined />}
            variant={"detail"}
            label={"Your current service address"}
            subLabel={data?.find((e) => e.name === "service_address")?.value?.value}
          />
          <ListItem
            startOrnament={<EventOutlined />}
            variant={"detail"}
            label={"Cancel service date"}
            subLabel={moment(updateInfo.date).format("MMMM D, YYYY")}
          />
        </div>
        <Spacer />
        <p className={"body_regular"}>
          <span className={"bold"}>Please note:</span> City Staff will review your request before processing the cancellation
          request. You'll receive an email once your request is approved. For any questions, call City Hall at (403) 782-6666.
        </p>
        <Spacer />
        <p className={"overline_light"}>Service Agreement</p>
        <CheckboxCard
          label={
            <div style={{ display: "flex" }}>
              <p>I agree to the </p>
              <LinkButton
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setShowTOSModal(true);
                }}
              >
                Terms of Service
              </LinkButton>
            </div>
          }
          value={termsAccepted}
          onChange={(e) => {
            e.stopPropagation();
            setTermsAccepted((prevState) => !prevState);
          }}
        />
        <Spacer />
        <Button onClick={confirmCancellation} variant={"contained"} disabled={!termsAccepted} size={"large"} fullWidth>
          Request cancellation
        </Button>
        <Spacer />
        <Modal open={showTOSModal} title={"Term of Service"} onClose={() => setShowTOSModal(false)} maxWidth={"700px"}>
          <div style={{ paddingInline: "1.3rem", paddingBlock: "1rem" }}>
            <p
              style={{
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "140%",
              }}
            >
              THE UNDERSIGNED HEREBY REPRESENTS that he/she is the Owner or an authorized agent for the Owner, and that he/she has
              the authority to sign this contract for utility services consisting of Water, Wastewater and Solid Waste Collection.
              The Owner agrees to comply with all provisions of all applicable Bylaws and Policies of the City of Lacombe,
              including payment for all utilities services supplied by the City to the above service address until such time as
              the Owner or authorized agent requests in writing that the contract be terminated. It is understood that in the
              event of late payment or non-payment, in addition to other remedies the City of Lacombe may have, the amount owing
              for utilities services shall bear a penalty charge in accordance with the Municipal Government Act and City of
              Lacombe Bylaws and that non-compliance with the provisions of said Bylaws may result in transfer to tax account in
              accordance with the City of Lacombe Bylaws. The Owner acknowledges that the failure to receive or the loss of a
              utility bill will not be accepted as a reason for non-payment. THE OWNER HEREBY AGREES to abide by the terms and
              conditions specified in the City of Lacombe Bylaws.
            </p>
          </div>
        </Modal>
        <Modal open={showConfirmationModal} onClose={() => setShowConfirmationModal(false)}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              maxWidth: 750,
              width: "100%",
              boxSizing: "border-box",
              padding: "1.5rem 3rem",
            }}
          >
            <div
              style={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                maxWidth: 453,
                width: "100%",
              }}
            >
              <CheckCircleOutlineIcon style={{ fontSize: 72, marginBottom: "1rem" }} color={"primary"} />
              <h2>Your cancellation request has been sent</h2>
              <p className={"body"} style={{ lineHeight: "22.4px" }}>
                Your cancellation request has been submitted to City Hall. Please allow a few days for review and processing. Once
                approved, you'll receive a confirmation email. Thank you for your patience!
              </p>
              <Button style={{ width: 300 }} size={"large"} variant={"contained"} onClick={handleConfirmModalClose}>
                Continue
              </Button>
            </div>
          </div>
        </Modal>
      </>
    );
  }, [data, handleConfirmModalClose, showConfirmationModal, showTOSModal, termsAccepted, updateInfo.date]);

  return (
    <>
      <WizardStepTracker steps={wizardSteps} activeStep={currentStep} setActiveStep={setCurrentStep} onClose={onWizardClosed} />
      <Spacer amount={2} />
      <div
        style={{
          maxWidth: 360,
          margin: "0 auto",
        }}
      >
        {currentStep === 0 ? stepOne : stepTwo}
      </div>
    </>
  );
};

export default CancelUtilityAccountWizard;
