import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { primaryLight } from "@mui-theme/theme";

export const StyledGrid = styled.div`
  margin: 0 1.4rem;
  box-sizing: border-box;
  width: 100%;
`;

export const Row = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  height: 3rem;
  padding: 0.65rem 0.5rem;
  text-transform: capitalize;
`;

export const ClickableArea = styled(Box)`
  && {
    border-radius: 0;
    font-size: inherit;
    padding-left: 0.8rem;

    #hover-icon {
      visibility: hidden;
    }
  }

  &&:hover {
    background: ${primaryLight};

    #hover-icon {
      visibility: visible;
    }
  }

  &&:focus-visible {
    background: ${primaryLight};

    #hover-icon {
      visibility: visible;
    }
  }
`;
