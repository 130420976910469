import React from "react";
import { Container, Content, InnerContainer } from "@components/layout-helpers/pageLayout";
import welcomeImg from "@assets/images/welcome.svg";
import { routes as navigator } from "@routes/routesData";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import CloseButton from "@components/ui-kit/CloseButton";
import Spacer from "@components/layout-helpers/Spacer";
import { useHistory } from "react-router-dom";

const Welcome = () => {
  const theme = useTheme();
  const history = useHistory();
  const handleClose = () => {
    history.push(navigator.DEFAULT.path);
  };
  const handleLink = () => {
    history.push(navigator.LINK_LICENCE.path);
  };

  return (
    <Container>
      <InnerContainer>
        <Box p={1}>
          <CloseButton onClick={handleClose} />
          <Content>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <img alt={"Welcome page"} src={welcomeImg} />
              </Grid>
              <Grid item xs={12}>
                <Typography variant={"h4"}>Manage your licences easily</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant={"body1"} color={theme.palette.blacks.BLACK_HIGH_EMPHASIS}>
                  Have an existing licence? Link it now. Need a new licence? Press skip.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Spacer />
                <Button onClick={handleLink} size={"large"} fullWidth variant={"contained"}>
                  link a licence
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button onClick={handleClose} size={"large"} fullWidth variant={"text"}>
                  skip
                </Button>
              </Grid>
            </Grid>
          </Content>
        </Box>
      </InnerContainer>
    </Container>
  );
};

export default Welcome;
