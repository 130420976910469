import React, { createContext, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTheme } from "@mui/material";
import Dialog from "@components/ui-kit/Dialog";
import { useLocation } from "react-router-dom";

export const EditingContext = createContext({
  isEditing: false,
  setIsEditing: (prev: any) => {},
  handleClickWrapper: (func: any) => {},
  setModalData: (state: any) => {},
});

/**
 * This provider manages editing mode in the admin application.
 * Wrapping functions with handleClickWrapper ensures the user
 * receives a warning before leaving a page while in editing mode.
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
export const EditingProvider = ({ children }: any) => {
  const theme = useTheme();
  const location = useLocation();
  const [isEditing, setIsEditing] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState({
    title: "Discarding Changes",
    body: "All the changes will be discarded and the licence will remain unchanged.",
    actionButtonText: "Discard Changes",
    variant: "original",
    buttonOneText: "Back",
    buttonTwoColor: theme.palette.nonPalette.RED,
    buttonTwoVariant: undefined,
    buttonFlexDirection: undefined,
  });
  const functionToExecute = useRef(null);
  const cleanupFunction = useRef(null);

  /**
   * Wrap functions that may cause data loss when activated while in
   * editing mode
   * @type {(function(*): void)|*}
   */
  const handleClickWrapper = useCallback(
    (func: any) => {
      if (isEditing) {
        setIsModalOpen(true);
        // @ts-ignore
        functionToExecute.current = () => {
          func();
          if (cleanupFunction.current) {
            // @ts-ignore
            cleanupFunction.current();
          }
          setIsEditing(false);
        };
      } else {
        func();
      }
    },
    [isEditing],
  );

  const _setModalData = (newState: any) => {
    setModalData((prev) => ({ ...prev, ...newState }));
  };

  const _setIsEditing = (value: boolean, _cleanupFunction = null) => {
    if (_cleanupFunction) {
      cleanupFunction.current = _cleanupFunction;
    } else {
      cleanupFunction.current = null;
    }
    setIsEditing(value);
  };

  useEffect(() => {
    _setIsEditing(false);
  }, [location.pathname]);

  const context = useMemo(
    () => ({
      isEditing,
      setIsEditing: _setIsEditing,
      handleClickWrapper,
      setModalData: _setModalData,
    }),
    [isEditing, handleClickWrapper],
  );

  return (
    <EditingContext.Provider value={context}>
      {children}
      <Dialog
        variant={modalData.variant}
        title={modalData.title}
        body={modalData.body}
        open={isModalOpen}
        handleClose={() => {
          setIsModalOpen(false);
        }}
        buttonOneText={modalData.buttonOneText}
        handleButtonOne={() => {
          setIsModalOpen(false);
        }}
        buttonTwoText={modalData.actionButtonText}
        handleButtonTwo={() => {
          // @ts-ignore
          functionToExecute.current();
          setIsEditing(false);
          setIsModalOpen(false);
        }}
        buttonTwoColor={modalData.buttonTwoColor}
        buttonTwoVariant={modalData.buttonTwoVariant}
        buttonFlexDirection={modalData.buttonFlexDirection}
      />
    </EditingContext.Provider>
  );
};
