import React, { useCallback, useContext, useEffect, useState } from "react";
import { Popover, useTheme } from "@mui/material";
import { NotificationContext } from "@contexts/NotificationContext";
import { delay, truncate } from "@utils/index";
import { PRIMARY_500, WHITE, WHITE_LOW_EMPHASIS } from "@mui-theme/theme";
import Typography from "@mui/material/Typography";
import { BUSINESS_NAME_LENGTH } from "@core/constants/general";
import Grid from "@mui/material/Grid";
import styled from "@emotion/styled";
import { PersonRounded, StorefrontOutlined } from "@mui/icons-material";
import AutocompleteRelative from "./autocomplete-relative/AutocompleteRelative";
import { AuthContext } from "@contexts/AuthContext";

const headerBackground = "rgba(255, 255, 255, 0.4)";

const SearchHeader = styled.div`
  background: ${PRIMARY_500};
  box-shadow: ${({ theme }) => theme.buttonShadow1};
  display: flex;
  flex-wrap: wrap;
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 1rem;
  padding: 1.2rem;
`;

const getSelectedRows = (selected) => {
  if (Array.isArray(selected)) return selected;
  if (selected && typeof selected === "object" && Object.keys(selected).length) return [selected];
  return [];
};

const SearchDropDown = (props) => {
  const {
    searchBarPlaceholder,
    fetchData,
    refresh,
    anchorEl,
    setAnchorEl,
    onSelect,
    selected,
    multiSelect,
    titleLabel,
    title,
    categories,
  } = props;
  // Default dataType = "ADMINS"
  let dataType = props.dataType;
  if (!dataType || !["ROLES", "ADMINS"].includes(dataType)) {
    dataType = "ADMINS";
  }
  const [data, setData] = useState([]);
  const { user } = useContext(AuthContext);
  const [filteredData, setFilteredData] = useState(data);
  const [loading, setLoading] = useState(false);
  const { handleError } = useContext(NotificationContext) || {};
  const [unSelectedCategories, setUnSelectedCategories] = useState(categories);
  const selectedRows = getSelectedRows(selected);
  const theme = useTheme();
  const checkRowSelected = useCallback((rowToCheck) => {
    if (!rowToCheck) return false;
    const selectedRowIds = selectedRows.map((row) => row.id);
    return selectedRowIds.includes(rowToCheck.id);
    //eslint-disable-next-line
  }, []);

  const clearState = () => {
    setData([]);
    if (loading) setLoading(false);
  };

  const handleClose = async () => {
    setAnchorEl(null);
    await delay();
    clearState();
  };

  const handleSelectedCategory = async (name) => {
    // let addedCategory = unSelectedCategories[selectedRow];
    const selectedRow = unSelectedCategories.indexOf(name);
    let tmpCategory = unSelectedCategories.filter(function (value) {
      return value !== unSelectedCategories[selectedRow];
    });
    setUnSelectedCategories(tmpCategory);
    onSelect(unSelectedCategories[selectedRow]);
  };

  const handleSelect = async (selectedRow) => {
    const isRemove = checkRowSelected(selectedRow);
    try {
      if (multiSelect) {
        if (isRemove) {
          const filteredRows = selectedRows.filter((row) => row.id !== selectedRow.id);
          await onSelect(filteredRows);
        } else {
          await onSelect([...selectedRows, selectedRow]);
        }
      } else {
        if (isRemove) {
          await onSelect(null);
        } else {
          onSelect(selectedRow);
        }
      }
      if (refresh) await refresh();
      if (!multiSelect) await handleClose();
    } catch (err) {
      handleError("Something went wrong.");
    }
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    setUnSelectedCategories(categories);
  }, [categories]);

  useEffect(() => {
    if (open) {
      (async () => {
        try {
          setLoading(true);
          let fetched = await fetchData();

          if (fetched[0]?.fullName) {
            fetched.sort((a, b) => (a.fullName > b.fullName ? 1 : b.fullName > a.fullName ? -1 : 0));
          }

          let loggedInUserIndex = -1;
          fetched.forEach((item, index) => {
            if (item?.id === user.id) {
              loggedInUserIndex = index;
            }
          });

          //Shows the logged-in user at the top of the list
          if (loggedInUserIndex >= 0) {
            const user = fetched.splice(loggedInUserIndex, 1)[0];
            fetched.unshift(user);
          }

          setData(fetched);
          setFilteredData(fetched);
        } catch (err) {
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [open, fetchData, user.id]);

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      PaperProps={{
        style: {
          borderRadius: "1rem",
        },
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      style={{ marginTop: "0.5rem" }}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={"search-drop-down-wrapper"}>
        {categories ? (
          <>
            <SearchHeader>
              <div
                style={{
                  display: "flex",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <StorefrontOutlined
                  sx={{
                    color: WHITE_LOW_EMPHASIS,
                    marginTop: "auto",
                    marginBottom: "3px",
                    marginRight: "1rem",
                    marginLeft: "0.5rem",
                    height: "40px",
                    width: "40px",
                  }}
                />
                <div
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {titleLabel ? (
                    <div>
                      <Typography variant={"overline"} color={WHITE_LOW_EMPHASIS} style={{ font: "overline" }}>
                        {titleLabel}
                      </Typography>
                    </div>
                  ) : null}
                  <div
                    style={{
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                  >
                    <Typography fontWeight="550" fontSize="1.1rem" color={WHITE}>
                      {truncate(title || "Search and Select", BUSINESS_NAME_LENGTH)}
                    </Typography>
                  </div>
                </div>
              </div>
            </SearchHeader>
            <AutocompleteRelative onChange={handleSelectedCategory} placeholder={searchBarPlaceholder} options={categories} />
          </>
        ) : (
          <>
            <SearchHeader>
              <Grid container alignItems="center" spacing={1}>
                <Grid item style={{ justifyContent: "center" }}>
                  <PersonRounded
                    sx={{
                      color: headerBackground,
                      marginBottom: "auto",
                      marginTop: "auto",
                      height: "48px",
                      width: "48px",
                    }}
                  />
                </Grid>
                <Grid
                  item
                  style={{
                    height: "100%",
                  }}
                >
                  <Grid container direction="column">
                    {titleLabel ? (
                      <Grid item>
                        <Typography variant={"overline"} fontSize="10px" color={theme.palette.whites.WHITE_MEDIUM_EMPHASIS}>
                          {titleLabel}
                        </Typography>
                      </Grid>
                    ) : null}
                    <Grid item>
                      <Typography variant="h5" color={theme.palette.whites.WHITE} style={{ marginTop: "-5px" }}>
                        {title || "Search and Select"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </SearchHeader>
            <AutocompleteRelative
              onChange={handleSelect}
              placeholder={searchBarPlaceholder}
              options={filteredData}
              checkRowSelected={checkRowSelected}
              dataType={dataType}
              type={"ADMINS"}
            />
            {/*<SearchFieldWrapper>*/}
            {/*  <SearchBar*/}
            {/*    fullWidth*/}
            {/*    placeholder={searchBarPlaceholder}*/}
            {/*    handleSubmit={handleSubmit}*/}
            {/*    loading={loading}*/}
            {/*    textValue={searchVal}*/}
            {/*    handleChange={(e) => {*/}
            {/*      handleSearchBarChange(e.target.value);*/}
            {/*    }}*/}
            {/*    handleClear={() => {*/}
            {/*      setSearchValue("");*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</SearchFieldWrapper>*/}
            {/*<RowList gap={1}>*/}
            {/*  {Boolean(selectedRows.length) && (*/}
            {/*    <>*/}
            {/*      <Typography fontWeight="bold" style={{ paddingLeft: "1rem" }}>*/}
            {/*        Selected:*/}
            {/*      </Typography>*/}
            {/*      {selectedRows.map((row, i) => (*/}
            {/*        <ClickableArea key={i} onClick={() => handleSelect(row)}>*/}
            {/*          <Row item xs={12}>*/}
            {/*            <>*/}
            {/*              <HowToRegRounded color={"primary"} />*/}
            {/*              {dataType === "ADMINS" ? (*/}
            {/*                <StyledGrid*/}
            {/*                  container*/}
            {/*                  alignItems="center"*/}
            {/*                  justify="space-between"*/}
            {/*                >*/}
            {/*                  <Grid item>*/}
            {/*                    <Grid container alignItems="center">*/}
            {/*                      <Grid item>*/}
            {/*                        <StyledAvatar*/}
            {/*                          color={theme.palette.primary[500]}*/}
            {/*                        >*/}
            {/*                          {(row.firstName?.[0] || "-") +*/}
            {/*                            (row.lastName?.[0] || "-")}*/}
            {/*                        </StyledAvatar>*/}
            {/*                      </Grid>*/}

            {/*                      <Grid item>*/}
            {/*                        <Typography>{row.fullName}</Typography>*/}
            {/*                      </Grid>*/}
            {/*                    </Grid>*/}
            {/*                  </Grid>*/}

            {/*                  <Grid item>*/}
            {/*                    <Grid container spacing={1}>*/}
            {/*                      {row?.roles?.length*/}
            {/*                        ? row.roles.map((role) => (*/}
            {/*                            <Grid item>*/}
            {/*                              <Chip*/}
            {/*                                label={sanitizeUnderscore(*/}
            {/*                                  sanitizeUnderscore(role.roleName)*/}
            {/*                                )}*/}
            {/*                                variant="contained"*/}
            {/*                              />*/}
            {/*                            </Grid>*/}
            {/*                          ))*/}
            {/*                        : null}*/}
            {/*                    </Grid>*/}
            {/*                  </Grid>*/}
            {/*                </StyledGrid>*/}
            {/*              ) : (*/}
            {/*                <StyledGrid>*/}
            {/*                  <Chip*/}
            {/*                    label={sanitizeUnderscore(row.roleName)}*/}
            {/*                    variant="contained"*/}
            {/*                  />*/}
            {/*                </StyledGrid>*/}
            {/*              )}*/}
            {/*            </>*/}
            {/*          </Row>*/}
            {/*        </ClickableArea>*/}
            {/*      ))}*/}
            {/*      <Grid item xs={12}>*/}
            {/*        <StyledDivider />*/}
            {/*      </Grid>*/}
            {/*    </>*/}
            {/*  )}*/}
            {/*  {!data?.length && !loading ? (*/}
            {/*    <Grid item xs={12}>*/}
            {/*      <NoResults>No results found.</NoResults>*/}
            {/*    </Grid>*/}
            {/*  ) : (*/}
            {/*    filteredData?.map((row, i) => {*/}
            {/*      if (checkRowSelected(row)) return null;*/}
            {/*      return (*/}
            {/*        <ClickableArea*/}
            {/*          key={i}*/}
            {/*          onClick={(e) => {*/}
            {/*            e.stopPropagation();*/}
            {/*            handleSelect(row);*/}
            {/*          }}*/}
            {/*        >*/}
            {/*          <Row item xs={12}>*/}
            {/*            <>*/}
            {/*              {checkRowSelected(row) ? (*/}
            {/*                <HowToRegRounded color={"error"} />*/}
            {/*              ) : (*/}
            {/*                <PersonAddAltRounded*/}
            {/*                  id="hover-icon"*/}
            {/*                  sx={{ color: theme.palette.primary[200] }}*/}
            {/*                />*/}
            {/*              )}*/}

            {/*              {dataType === "ADMINS" ? (*/}
            {/*                <StyledGrid*/}
            {/*                  container*/}
            {/*                  alignItems="center"*/}
            {/*                  justify="space-between"*/}
            {/*                >*/}
            {/*                  <Grid item>*/}
            {/*                    <Grid container alignItems="center">*/}
            {/*                      <Grid item>*/}
            {/*                        <StyledAvatar*/}
            {/*                          color={theme.palette.primary[500]}*/}
            {/*                        >*/}
            {/*                          {(row.firstName?.[0] || "-") +*/}
            {/*                            (row.lastName?.[0] || "-")}*/}
            {/*                        </StyledAvatar>*/}
            {/*                      </Grid>*/}
            {/*                      <Grid item>*/}
            {/*                        <Typography style={{ flexWrap: "wrap" }}>*/}
            {/*                          {truncate(*/}
            {/*                            row.fullName,*/}
            {/*                            REVIEWER_NAME_SEARCH_DROP_DOWN_LENGTH*/}
            {/*                          )}*/}
            {/*                        </Typography>*/}
            {/*                      </Grid>*/}
            {/*                    </Grid>*/}
            {/*                  </Grid>*/}
            {/*                  <Grid*/}
            {/*                    item*/}
            {/*                    style={{*/}
            {/*                      position: "absolute",*/}
            {/*                      marginLeft: "250px",*/}
            {/*                    }}*/}
            {/*                  >*/}
            {/*                    <Grid container spacing={1}>*/}
            {/*                      {row?.roles.length*/}
            {/*                        ? row.roles.map((role) => (*/}
            {/*                            <Grid*/}
            {/*                              key={role.roleName}*/}
            {/*                              item*/}
            {/*                              style={{ marginLeft: "20px" }}*/}
            {/*                            >*/}
            {/*                              <Chip*/}
            {/*                                label={sanitizeUnderscore(*/}
            {/*                                  role.roleName*/}
            {/*                                )}*/}
            {/*                                variant="active"*/}
            {/*                                style={{*/}
            {/*                                  marginLeft: "20px",*/}
            {/*                                }}*/}
            {/*                              />*/}
            {/*                            </Grid>*/}
            {/*                          ))*/}
            {/*                        : null}*/}
            {/*                    </Grid>*/}
            {/*                  </Grid>*/}
            {/*                </StyledGrid>*/}
            {/*              ) : (*/}
            {/*                <StyledGrid>*/}
            {/*                  <Chip*/}
            {/*                    label={sanitizeUnderscore(row.roleName)}*/}
            {/*                    variant="contained"*/}
            {/*                  />*/}
            {/*                </StyledGrid>*/}
            {/*              )}*/}
            {/*            </>*/}
            {/*          </Row>*/}
            {/*        </ClickableArea>*/}
            {/*      );*/}
            {/*    })*/}
            {/*  )}*/}
            {/*</RowList>*/}
          </>
        )}
      </div>
    </Popover>
  );
};

export default SearchDropDown;
