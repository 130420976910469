// import { get_bylaw_list } from "@core/apis/bylaw";
import { create_business_license, get_licenses_summaries } from "@core/apis/licence";
import React, { useContext, useEffect, useState } from "react";
import { NotificationContext } from "@contexts/NotificationContext";
import classes from "./index.module.scss";
import { formatCurrency } from "@utils/index";
import { useHistory, useLocation } from "react-router-dom";
import { AddOutlined, ApprovalOutlined, CheckBoxOutlined, LocationOnOutlined, PersonPinOutlined } from "@mui/icons-material";
import {
  DataGrid,
  gridClasses,
  GridColDef,
  GridRenderCellParams,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import {
  Box,
  Button,
  Chip,
  FormControl,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography,
} from "@mui/material";
import { BLUE, OUTLINE, PRIMARY_50 } from "@mui-theme/theme";
import { routes } from "@routes/routesData";
import {
  ACTIVE,
  APPROVED,
  CANCELLED,
  DECLINED,
  EXPIRED,
  EXPIRING,
  ISSUED,
  RENEWING,
  REVIEWING,
  REVOKED,
  ROOT_LICENCE_TYPES,
  SUBMITTED,
} from "@core/constants/licences";

const columns: GridColDef[] = [
  {
    field: "ref",
    headerName: "LIC. #",
    editable: false,
    flex: 1,
    filterable: false,
  },
  {
    field: "name",
    headerName: "APPLICANT",
    editable: false,
    flex: 1.5,
    filterable: false,
    valueGetter: (params: any) => {
      return `${params.row.name || ""} ${params.row.address || ""}`;
    },
    renderCell: (params: GridRenderCellParams<any>) => (
      <div className={classes.address_column_container}>
        <p>{params.row.name}</p>
        <p>{params.row.address}</p>
      </div>
    ),
  },
  {
    field: "status",
    headerName: "STATUS",
    editable: false,
    flex: 1,
    filterable: false,
    renderCell: (params: GridRenderCellParams<any>) => (
      <Chip label={params.row.status.charAt(0).toUpperCase() + params.row.status.slice(1)} />
    ),
  },
  {
    field: "lastUpdated",
    headerName: "UPDATED",
    editable: false,
    flex: 1,
    filterable: false,
    type: "date",
    valueGetter: ({ value }) => value && new Date(value),
  },
  {
    field: "balance",
    headerName: "BALANCE",
    editable: false,
    flex: 1,
    filterable: false,
    type: "number",
    valueFormatter: ({ value }) => formatCurrency(value),
  },
];

const AdminLicences = (...props: any) => {
  const location = useLocation();
  const history = useHistory();
  const { handleError } = useContext(NotificationContext);
  const [loading, setLoading] = useState(true);
  const [residencyValue, setResidencyValue] = useState("");
  const [homebasedValue, setHomebasedValue] = useState("");
  const [statusValue, setStatusValue] = useState<any>([]);
  const [data, setData] = useState({});
  const [filters, setFilters] = useState({
    total: true,
    sortBy: "lastUpdated",
    ascending: false,
    filters: {},
    balance: "all",
    offset: 0,
    limit: 1000000,
  });
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    setFilters((prevState) => ({
      ...prevState,
      balance: "all",
      filters: {
        is_resident: residencyValue,
        is_homebased: homebasedValue,
        ...(statusValue.length === 0 ? {} : { status: statusValue }),
      },
    }));
    setRefresh(!refresh);
    //eslint-disable-next-line
  }, [residencyValue, homebasedValue, statusValue]);

  const handleOnCreate = async () => {
    try {
      const licenseId = await create_business_license();
      history.push(routes.LICENCES_DETAIL.path.replace(":id", licenseId), {
        newLicence: true,
      });
    } catch (e) {
      handleError("Sorry, there was an issue creating your licence.");
    }
  };

  function CustomToolbar(type: string) {
    const getStatusValue = (value: any) => {
      if (value.length === 0) {
        return "Status";
      } else if (Array.isArray(statusValue) && value.includes("revoked")) {
        return "inactive_group";
      } else if (Array.isArray(statusValue) && value.includes("active")) {
        return "active_group";
      } else if (Array.isArray(statusValue) && value.includes("submitted")) {
        return "reviewing_group";
      }
      return value;
    };

    const handleStatusChange = (event: SelectChangeEvent<unknown>) => {
      if (event.target.value === "any") {
        setStatusValue([]);
        return;
      } else if (event.target.value === "inactive_group") {
        setStatusValue(ROOT_LICENCE_TYPES.inactive);
        return;
      } else if (event.target.value === "active_group") {
        setStatusValue(ROOT_LICENCE_TYPES.active);
        return;
      } else if (event.target.value === "reviewing_group") {
        setStatusValue(ROOT_LICENCE_TYPES.reviewing);
        return;
      }
      setStatusValue(event.target.value as any);
    };

    const handleHomebasedChange = (event: SelectChangeEvent<unknown>) => {
      if (event.target.value === "any") {
        setHomebasedValue("");
        return;
      }
      setHomebasedValue(event.target.value as string);
    };

    const handleResidencyChange = (event: SelectChangeEvent<unknown>) => {
      if (event.target.value === "any") {
        setResidencyValue("");
        return;
      }
      setResidencyValue(event.target.value as string);
    };

    return (
      <GridToolbarContainer>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Tooltip title="Searching by: Business name, Licence number, and Bylaw">
            <div>
              <GridToolbarQuickFilter />
            </div>
          </Tooltip>
          <div style={{ display: "flex", marginTop: "1rem", width: "100%" }}>
            <Box>
              <FormControl
                sx={{
                  ".MuiOutlinedInput-root": {
                    paddingLeft: "0.3rem !important",
                  },
                }}
              >
                <Select
                  size="small"
                  input={
                    <OutlinedInput
                      startAdornment={
                        <InputAdornment position="start">
                          <CheckBoxOutlined
                            sx={{
                              color: BLUE,
                            }}
                          />
                        </InputAdornment>
                      }
                    />
                  }
                  sx={{
                    ".MuiOutlinedInput-notchedOutline": {
                      border: 0,
                    },

                    ".MuiInputBase-input ": {
                      padding: "0.3rem !important",
                      color: BLUE,
                    },
                    ".MuiSelect-iconOutlined": {
                      display: "none",
                    },
                    ":hover": {
                      backgroundColor: PRIMARY_50,
                    },
                  }}
                  value={getStatusValue(statusValue) || ""}
                  labelId="demo-simple-select-label"
                  id="demo-select-small"
                  onChange={handleStatusChange}
                >
                  <MenuItem disabled value="Status">
                    Status
                  </MenuItem>
                  <MenuItem value={"reviewing_group"}>Reviewing Group</MenuItem>
                  <MenuItem value={APPROVED}>Approved</MenuItem>
                  <MenuItem value={REVIEWING}>Reviewing</MenuItem>
                  <MenuItem value={SUBMITTED}>Submitted</MenuItem>
                  <MenuItem value={"active_group"}>Active Group</MenuItem>
                  <MenuItem value={ACTIVE}>Active</MenuItem>
                  <MenuItem value={ISSUED}>Issued</MenuItem>
                  <MenuItem value={RENEWING}>Renewing</MenuItem>
                  <MenuItem value={EXPIRING}>Expiring</MenuItem>
                  <MenuItem value={"inactive_group"} title="Test">
                    Inactive Group
                  </MenuItem>
                  <MenuItem value={CANCELLED}>Canceled</MenuItem>
                  <MenuItem value={REVOKED}>Revoked</MenuItem>
                  <MenuItem value={DECLINED}>Declined</MenuItem>
                  <MenuItem value={EXPIRED}>Expired</MenuItem>
                  <MenuItem value={"any"}>All</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box>
              <FormControl
                sx={{
                  ".MuiOutlinedInput-root": {
                    paddingLeft: "0.3rem !important",
                  },
                }}
              >
                <Select
                  size="small"
                  input={
                    <OutlinedInput
                      startAdornment={
                        <InputAdornment position="start">
                          <PersonPinOutlined
                            sx={{
                              color: BLUE,
                            }}
                          />
                        </InputAdornment>
                      }
                    />
                  }
                  sx={{
                    ".MuiOutlinedInput-notchedOutline": {
                      border: 0,
                    },

                    ".MuiInputBase-input ": {
                      padding: "0.3rem !important",
                      color: BLUE,
                    },
                    ".MuiSelect-iconOutlined": {
                      display: "none",
                    },
                    ":hover": {
                      backgroundColor: PRIMARY_50,
                    },
                  }}
                  value={residencyValue === "" ? "Residency" : residencyValue}
                  labelId="demo-simple-select-label"
                  id="demo-select-small"
                  onChange={handleResidencyChange}
                >
                  <MenuItem disabled value="Residency">
                    Residency
                  </MenuItem>
                  <MenuItem value={"true"}>Resident</MenuItem>
                  <MenuItem value={"false"}>Non-resident</MenuItem>
                  <MenuItem value={"any"}>any</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box>
              <FormControl
                sx={{
                  ".MuiOutlinedInput-root": {
                    paddingLeft: "0.3rem !important",
                  },
                }}
              >
                <Select
                  size="small"
                  input={
                    <OutlinedInput
                      startAdornment={
                        <InputAdornment position="start">
                          <LocationOnOutlined
                            sx={{
                              color: BLUE,
                            }}
                          />
                        </InputAdornment>
                      }
                    />
                  }
                  sx={{
                    ".MuiOutlinedInput-notchedOutline": {
                      border: 0,
                    },

                    ".MuiInputBase-input ": {
                      padding: "0.3rem !important",
                      color: BLUE,
                    },
                    ".MuiSelect-iconOutlined": {
                      display: "none",
                    },
                    ":hover": {
                      backgroundColor: PRIMARY_50,
                    },
                  }}
                  value={homebasedValue === "" ? "Home-based" : homebasedValue}
                  labelId="demo-simple-select-label"
                  id="demo-select-small"
                  onChange={handleHomebasedChange}
                >
                  <MenuItem disabled value="Home-based">
                    Location
                  </MenuItem>
                  <MenuItem value={"true"}>Homebased</MenuItem>
                  <MenuItem value={"false"}>Storefront</MenuItem>
                  <MenuItem value={"any"}>any</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>
        </div>
        <Box sx={{ flexGrow: 1 }} />
        <Button
          size="medium"
          style={{ color: BLUE, borderColor: OUTLINE }}
          variant="outlined"
          startIcon={
            <InputAdornment position="start">
              <AddOutlined
                sx={{
                  color: BLUE,
                }}
              />
            </InputAdornment>
          }
          onClick={handleOnCreate}
        >
          NEW LICENCE
        </Button>
      </GridToolbarContainer>
    );
  }

  async function fetchData(queryOptions: any) {
    try {
      setLoading(true);
      let data = await get_licenses_summaries(filters);
      // const bylaws = await get_bylaw_list();
      data.result = data.result.map((summary: any) => {
        return {
          ...summary,
          applicant: `${summary.name}<br/>${summary.address}`,
        };
      });
      setData(data);
    } catch (err: any) {
      handleError(err.response?.data?.message || err?.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  return (
    <div className={classes.container}>
      <div className={classes.styled_container}>
        <div className={classes.fixed_header}>
          <>
            <div className={classes.title_container}>
              <ApprovalOutlined sx={{ height: "45px", width: "45px", marginRight: "0.5rem" }} />

              <Typography variant={"h3"} className={classes.table_title}>
                {"Licences"}
              </Typography>
            </div>
          </>
        </div>
        <DataGrid
          loading={loading}
          density={"comfortable"}
          // @ts-ignore
          rows={data?.result || []}
          columns={columns}
          onRowClick={(params) => {
            history.push(`${location.pathname}/${params.id}`);
          }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                address: false,
              },
            },
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10, 25]}
          disableRowSelectionOnClick
          style={{ borderColor: "transparent" }}
          slots={{ toolbar: CustomToolbar }}
          sx={{
            [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
              outline: "none",
            },
            [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
              outline: "none",
            },
          }}
        />
      </div>
      <div className={"secondary-sidebar"} />
    </div>
  );
};

export { AdminLicences };
