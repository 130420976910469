import Banner from "@components/ui-kit/Banner";
import React from "react";
import { useTheme } from "@mui/material";
import { GiteRounded } from "@mui/icons-material";

const PropertyTaxInfoCard = ({ account, hasPadding = true }) => {
  const theme = useTheme();
  return (
    <Banner
      title={`Roll Number: ${account?.accountNumber}`}
      body={account?.address}
      styles={{
        backgroundColor: theme.palette.primary[50],
        padding: hasPadding ? "0.5rem" : "0",
      }}
      Icon={<GiteRounded color={"primary"} sx={{ width: "38px", height: "38px" }} />}
    />
  );
};

export default PropertyTaxInfoCard;
