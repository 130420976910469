import React, { createRef, useCallback } from "react";
import styled from "styled-components";
import { default as MuiCheckbox } from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";

const StyledFormControlLabel = styled(FormControlLabel)`
  && {
    margin: 0;
    padding-right: 1rem;
    && .MuiTypography-root {
      margin-left: 10px;
    }

    &:focus {
      box-shadow:
        0 6px 10px rgba(1, 18, 32, 0.1),
        0 1px 18px rgba(1, 18, 32, 0.08),
        0 3px 5px rgba(1, 18, 32, 0.1);
      border-width: 2px;
      border-color: ${({ theme }) => theme.palette.primary[200]};
    }
  }
`;

export function CheckboxCard({ value, onChange, label, ...rest }) {
  const checkRef = createRef();
  const controlRef = createRef();

  const handleOnClick = useCallback(() => {
    const node = checkRef.current;
    const control = controlRef.current;
    if (node && control) {
      node.click();
      control.focus();
    }
  }, [checkRef, controlRef]);

  return (
    <FormGroup>
      <StyledFormControlLabel
        classes={rest.labelClasses}
        ref={controlRef}
        onKeyDown={(e) => {
          if (e.keyCode === 13 || e.keyCode === 32) {
            e.preventDefault();
            handleOnClick();
          }
        }}
        onMouseDown={(e) => {
          e.preventDefault();
        }}
        tabIndex={0}
        control={
          <MuiCheckbox
            ref={checkRef}
            tabIndex={-1}
            {...rest}
            checked={value}
            onChange={(e) => {
              onChange(e);
            }}
          />
        }
        label={label}
      />
    </FormGroup>
  );
}
