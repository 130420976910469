import React from "react";
import tClasses from "@assets/styles/typography/typography.module.scss";
import classes from "./index.module.scss";
import { Skeleton } from "@mui/material";
import ServiceItem from "./service-item";
import { IFormField } from "@core/interfaces/utility/IFormField";

interface IServiceRow {
  title: string;
  services: any;
  loading: boolean;
  accessor: any;
  isAdminSide?: boolean;
  setIsMissingData?: any;
}

const ServiceRow = ({
  title = "",
  services = [],
  loading = false,
  isAdminSide = false,
  accessor = (el: string): IFormField => {
    return {} as IFormField;
  },
  setIsMissingData,
}: IServiceRow) => {
  return (
    <div className={classes.service_row_container}>
      {!loading ? (
        <>
          <p className={`${tClasses.h3} ${tClasses.uppercase}`}>{title}</p>
          <div className={classes.row}>
            {services.map((service: any, index: number) => (
              <ServiceItem
                key={index}
                service={accessor(service)}
                isAdminSide={isAdminSide}
                setIsMissingData={setIsMissingData}
              />
            ))}
          </div>
        </>
      ) : (
        <Skeleton height={80} width={140} />
      )}
      <div className={"divider"}></div>
    </div>
  );
};

export default ServiceRow;
