import React, { useEffect, useMemo, useState } from "react";
import { DeleteOutline, Info, MailOutline, WaterDamageOutlined } from "@mui/icons-material";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Tabs from "@components/ui-kit/tabs";
import Tab from "@components/ui-kit/tab";
import useHash from "@hooks/useHash";
import UtilityStatusCard from "@components/ui-kit/admin-utility-status-card";
import AdminUtilityBalanceCard from "@components/ui-kit/admin-utility-balance-card";
import { Divider, FormControlLabel, Radio, RadioGroup, Skeleton, Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import TabPanel from "@components/ui-kit/tab-pannel";
import UtilityInfoTab from "./info-tab";
import Typography from "@mui/material/Typography";
import Spacer from "@components/ui-kit/Spacer";
import Dialog from "@components/ui-kit/Dialog";
import ServicesTab from "./services-tab";
import ApprovalSection from "./right-sidebar/approval-section";
import BillingTab from "./billing-payment-tab";
import {
  applyChangesToAccount,
  get_account_issuer,
  get_account_reviewer,
  getAccountDetail,
  getAccountLinkedUsers,
  update_account_reviewer_status,
  updateAccountChangeSetStatus,
} from "@core/apis/account";
import { getAccountComments, getHistory } from "@core/apis/history";
import { add_issuer, add_reviewer, remove_issuer, remove_reviewer } from "@core/apis/licence";
import { REVIEWING_GROUP } from "@core/constants/licences";
import { addAccountComment } from "@core/apis/comment";
import HistoryTab from "../licence-details/history-tab";
import OnContactCustomerModal from "@components/modals/onContactCustomerModal";
import MissingObject from "@components/ui-kit/missing-object";
import config from "@core/apis/_config";
import { formatUtilityAccountNumber } from "@utils/formatter";
import RightSidebarSkeleton from "./skeletons/RightSidebarSkeleton";
import UtilityDetailSkeleton from "./skeletons/UtilityDetailSkeleton";
import { tabs } from "@core/constants/utility";
import { abbreviator } from "@core/constants/province-states";

const financialInfoKeyNames = ["balance", "due_date", "last_payment", "paid", "statement_balance"];
const infoTabFields = [
  "account_number",
  "access_code",
  "batch_number",
  "account_holder",
  "service_address",
  "mailing_address",
  "is_owner",
  "phone_number",
  "billing_method",
];
const serviceTabFields = [
  "property_type",
  "water_metre_number",
  "water_metre_size",
  "waste_metre_size",
  "solid_waste_general",
  "solid_waste_cardboard",
  "solid_waste_size",
  "collection_services",
];

const UtilitiesDetailScreen = (props) => {
  const history = useHistory();
  const location = useLocation();
  const hash = useHash();
  const { id } = useParams();
  const [reloadData, setReload] = useState(false);
  const [utilityInfo, setUtilityInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState(hash || tabs[0]);
  const [assignedAdmins, setAssignedAdmins] = useState([]);
  const [assignedServicesAdmins, setAssignedServicesAdmins] = useState([]);
  const [issuer, setIssuer] = useState({});
  const [servicesIssuer, setServicesIssuer] = useState({});
  const [approveChangeDialogOpen, setApproveChangeDialogOpen] = useState(false);
  const [discardChangesDialogOpen, setDiscardChangesDialogOpen] = useState(false);
  const [sendApprovalEmail, setSendApprovalEmail] = useState(null);
  const [sendDeclineEmail, setSendDeclineEmail] = useState(null);
  const [allHistory, setAllHistory] = useState([]);
  const [infoData, setInfoData] = useState(null);
  const [serviceData, setServiceData] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [infoChangeSetId, setInfoChangeSetId] = useState();
  const [infoChangeSetStatus, setInfoChangeSetStatus] = useState();
  const [servicesChangeSetId, setServicesChangeSetId] = useState();
  const [servicesChangeSetStatus, setServicesChangeSetStatus] = useState();
  const [linkedUser, setLinkedUser] = useState();
  const [onContactModalOpen, setOnContactModalOpen] = useState(false);
  const [pendingData, setPendingData] = useState(true);

  /**
   * With each change in the url params:
   * - Calls the function for getting the utility information
   */
  useEffect(() => {
    if (utilityInfo === null || reloadData) {
      setLoading(true);
      fetchUtilityInformation().then();
      fetchReviewer().then();
      fetchHistories().then();
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, refresh, reloadData]);

  useEffect(() => {
    let t = hash || tabs[0];
    setSelectedTab(t.replaceAll("%20", " "));
  }, [hash]);

  const createAccountDetail = (item) => {
    let data = {
      info: [],
      service: {
        propertyType: [],
        solidWasteServices: [],
        wasteWaterServices: [],
        waterServices: [],
      },
      consumption: [],
      financial: [],
      otherInfo: [],
    };
    for (let i = 0; i < item.length; i++) {
      if (item[i].name === "consumption") {
        data.consumption = item[i];
      } else if (infoTabFields.includes(item[i].name)) {
        if (item[i].name.includes("mailing_address")) {
          data.info.push({
            id: item[i].id,
            label: item[i].label,
            name: item[i].name,
            value:
              (item[i].value.value.address2 || "") +
              (item[i].value.value.address2 ? ", " : "") +
              (item[i].value.value.address1 ? item[i].value.value.address1 + ", " : "") +
              item[i].value.value.city +
              ", " +
              abbreviator(item[i].value.value.province || item[i].value.value.province_state)?.abbrev +
              ", " +
              item[i].value.value.postal_zip,
            rawValue: item[i].value.value,
            originalValue: item[i].originalValue
              ? item[i].originalValue.value.address2 +
                (item[i].originalValue.value.address2 ? ", " : "") +
                item[i].originalValue.value.address1 +
                ", " +
                item[i].originalValue.value.city +
                ", " +
                abbreviator(item[i].originalValue.value.province || item[i].originalValue.value.province_state)?.abbrev +
                ", " +
                item[i].originalValue.value.postal_zip
              : undefined,
            originalValueRaw: item[i].originalValue?.value ?? undefined,
          });
        } else if (item[i].name === "is_owner") {
          data.info.push({
            id: item[i].id,
            label: item[i].label,
            name: item[i].name,
            value: item[i].value.value,
            originalValue:
              item[i]?.originalValue?.value === true ? true : item[i]?.originalValue?.value === false ? false : undefined,
          });
        } else {
          data.info.push({
            id: item[i].id,
            label: item[i].label,
            name: item[i].name,
            value: item[i].value.value,
            originalValue: item[i]?.originalValue?.value,
          });
        }
      } else if (serviceTabFields.includes(item[i].name)) {
        if (item[i].name === "property_type") {
          data.service.propertyType.push({
            id: item[i].id,
            label: item[i].label,
            name: item[i].name,
            value: { value: item[i].value.value },
            originalValue: item[i]?.originalValue?.value || undefined,
          });
        } else if (item[i].name === "water_metre_number" || item[i].name === "water_metre_size") {
          data.service.waterServices.push({
            id: item[i].id,
            label: item[i].label,
            name: item[i].name,
            value: { value: item[i].value.value },
            originalValue: item[i]?.originalValue?.value || undefined,
          });
        } else if (item[i].name === "waste_metre_size") {
          data.service.wasteWaterServices.push({
            id: item[i].id,
            label: item[i].label,
            name: item[i].name,
            value: { value: item[i].value.value },
            originalValue: item[i]?.originalValue?.value || undefined,
          });
        } else if (
          item[i].name === "solid_waste_general" ||
          item[i].name === "solid_waste_cardboard" ||
          item[i].name === "solid_waste_size" ||
          item[i].name === "collection_services"
        ) {
          data.service.solidWasteServices.push({
            id: item[i].id,
            label: item[i].label,
            name: item[i].name,
            value: { value: item[i].value.value },
            originalValue: item[i]?.originalValue || undefined,
          });
        }
      } else if (item[i].name === "info_change_set_id") {
        setInfoChangeSetId(item[i].value.value);
      } else if (item[i].name === "info_change_set_status") {
        setInfoChangeSetStatus(item[i].value.value);
      } else if (item[i].name === "services_change_set_id") {
        setServicesChangeSetId(item[i].value.value);
      } else if (item[i].name === "services_change_set_status") {
        setServicesChangeSetStatus(item[i].value.value);
      } else if (financialInfoKeyNames.includes(item[i].name)) {
        data.financial.push(item[i]);
      } else {
        data.otherInfo.push(item[i]);
      }
    }
    return data;
  };

  /**
   * Gets the utility full details from server by calling the api regarding this action
   * @returns {Promise<void>}
   */
  const fetchUtilityInformation = async () => {
    setPendingData(true);
    try {
      const raw = await getAccountDetail(id);
      const response = await createAccountDetail(raw);
      const linked = await getAccountLinkedUsers(id);
      setLinkedUser(linked);
      setInfoData(response.info);
      setServiceData(response.service);
      setUtilityInfo(response);
    } catch (e) {
      console.log(e);
    } finally {
      setPendingData(false);
    }
  };

  /**
   * Gets the list of reviewers and supervisors from the server by calling related api
   * @returns {Promise<void>}
   *
   */
  const fetchReviewer = async () => {
    let section;
    if (selectedTab.includes("Services")) {
      section = "services";
    } else {
      section = "info";
    }
    let assignedSupervisors = await get_account_issuer(id, section);
    let assignedServicesSupervisors = await get_account_issuer(id, section);
    let assignedServicesReviewers = await get_account_reviewer(id, section);
    let assignedReviewers = await get_account_reviewer(id, section);
    setIssuer(assignedSupervisors[0]);
    setServicesIssuer(assignedServicesSupervisors[0]);
    setAssignedAdmins(
      assignedReviewers.map((admin) => ({
        id: admin.userId,
        first: admin.profile.firstName || "-",
        last: admin.profile.lastName || "-",
        approvalStatus: admin.status,
        lastUpdated: admin.lastUpdated,
      })),
    );
    setAssignedServicesAdmins(
      assignedServicesReviewers.map((admin) => ({
        id: admin.userId,
        first: admin.profile.firstName || "-",
        last: admin.profile.lastName || "-",
        approvalStatus: admin.status,
        lastUpdated: admin.lastUpdated,
      })),
    );
  };

  const fetchHistories = async () => {
    try {
      const comments = await getAccountComments(props.match.params.id);
      let histories = await getHistory(props.match.params.id, "account");
      histories = histories.filter(
        (item) =>
          "Review" in item.data.historyType ||
          "StatusChange" in item.data.historyType ||
          "Submitted" in item.data.historyType ||
          "Payment" in item.data.historyType ||
          "Linked" in item.data.historyType ||
          "Unlinked" in item.data.historyType ||
          "Renew" in item.data.historyType ||
          "Marked" in item.data.historyType ||
          "CitizenChanges" in item.data.historyType ||
          "DeclineChanges" in item.data.historyType,
      );
      let stuff = comments.concat(histories);
      stuff = stuff.sort(function (a, b) {
        let c = new Date(a.lastUpdated);
        let d = new Date(b.lastUpdated);
        return c - d;
      });

      setAllHistory(stuff);
    } catch (err) {
      console.log(err.response?.data?.message || err?.message);
    }
  };

  /**
   * Handles tab changes and sets the url hash
   * @param e
   * @param newTab
   */
  const onTabChanged = (e, newTab) => {
    history.push(`${location.pathname}#${newTab}`);
    setSelectedTab(newTab);
    refresher(["Account Info", "Services"].indexOf(newTab) !== -1 ? true : false);
  };

  const onContactApplicant = () => {
    if (linkedUser?.email) {
      window.location.href = `mailto:${linkedUser?.email}?subject=City of ${config.cityName} - Your utilities account`;
    } else {
      setOnContactModalOpen(true);
    }
  };

  /**
   * Handles adding reviewer to the utility account
   * @param e
   */
  const handleAssignReviewer = async (e) => {
    await add_reviewer(e.id, id, "utility_account", "info");
    refresher(true);
  };

  /**
   * Handles adding reviewer to the utility account for services section
   * @param e
   */
  const handleAssignServicesReviewer = async (e) => {
    await add_reviewer(e.id, id, "utility_account", "services");
    refresher(true);
  };

  const unassignReviewer = async (e) => {
    await remove_reviewer(e, id, "utility_account", "info");
    refresher(true);
  };
  const unAssignServicesReviewer = async (e) => {
    await remove_reviewer(e, id, "utility_account", "services");
    refresher(true);
  };

  const onUndoReviewerAction = async () => {
    await update_account_reviewer_status(id, "assigned", "info");
    refresher(true);
  };

  const onUndoServicesReviewerAction = async () => {
    await update_account_reviewer_status(id, "assigned", "services");
    refresher(true);
  };

  const onReviewerApprove = async () => {
    await update_account_reviewer_status(id, "approved", "info");
    refresher(true);
  };

  const onServicesReviewerApprove = async () => {
    await update_account_reviewer_status(id, "approved", "services");
    refresher(true);
  };

  const onReviewerDeclined = async () => {
    await update_account_reviewer_status(id, "denied", "info");
    refresher(true);
  };

  const onServicesReviewerDeclined = async () => {
    await update_account_reviewer_status(id, "denied", "services");
    refresher(true);
  };

  const handleAssignIssuer = async (e) => {
    await add_issuer(e.id, id, "utility_account", "info");
    refresher(true);
  };
  const handleAssignServicesIssuer = async (e) => {
    await add_issuer(e.id, id, "utility_account", "services");
    refresher(true);
  };

  const unAssignIssuer = async (e) => {
    await remove_issuer(e, id, "utility_account", "info");
    refresher(true);
  };
  const unAssignServicesIssuer = async (e) => {
    await remove_issuer(e, id, "utility_account", "services");
    refresher(true);
  };

  const onApproveButtonClick = () => {
    setApproveChangeDialogOpen(true);
  };

  const onDeclineChangesButtonClick = () => {
    setDiscardChangesDialogOpen(true);
  };

  /**
   * Closes the approval dialog and submits the changes to server
   * - Also sends email to user if option is selected
   * @return Promise<void>
   */
  const onApproveChanges = async () => {
    if (selectedTab.includes("Services")) {
      await applyChangesToAccount(id, servicesChangeSetId, sendApprovalEmail);
    } else {
      await applyChangesToAccount(id, infoChangeSetId, sendApprovalEmail);
    }
    setSendApprovalEmail(null);
    setApproveChangeDialogOpen(false);
    if (selectedTab.includes("Services")) {
      setServicesChangeSetStatus(null);
    } else {
      setInfoChangeSetStatus(null);
    }
    refresher(true);
  };

  const handleCloseDeclineChangesDialog = () => {
    setSendDeclineEmail(null);
    setDiscardChangesDialogOpen(false);
  };

  const onDeclineChanges = async () => {
    try {
      if (selectedTab.includes("Services")) {
        await updateAccountChangeSetStatus(id, servicesChangeSetId, "declined", sendDeclineEmail);
      } else {
        await updateAccountChangeSetStatus(id, infoChangeSetId, "declined", sendDeclineEmail);
      }
      setSendDeclineEmail(null);
      setDiscardChangesDialogOpen(false);
      if (selectedTab.includes("Services")) {
        setServicesChangeSetStatus(null);
      } else {
        setInfoChangeSetStatus(null);
      }
      refresher(true);
    } catch (e) {
      console.log(e);
    }
  };

  const refresher = (fullRefresh = false) => {
    setReload(fullRefresh);
    setRefresh(!refresh);
  };

  /**
   * Indicates if the account has client changes
   * @type {boolean}
   */
  const hasClientInfoChanges = useMemo(() => {
    return REVIEWING_GROUP.includes(infoChangeSetStatus) && selectedTab === tabs[0];
  }, [selectedTab, infoChangeSetStatus]);

  /**
   * Indicates if the account has client changes
   * @type {boolean}
   */
  const hasClientServicesChanges = useMemo(() => {
    return REVIEWING_GROUP.includes(servicesChangeSetStatus) && selectedTab === tabs[1];
  }, [selectedTab, servicesChangeSetStatus]);

  /**
   * Indicates if the account has full admin approvals
   * @type {boolean}
   */
  const hasFullApproval = useMemo(() => {
    return assignedAdmins.filter((e) => e.approvalStatus === "approved").length > 0 && assignedAdmins.length > 0;
  }, [assignedAdmins]);

  /**
   * Indicates if the account has full admin approvals
   * @type {boolean}
   */
  const servicesHasFullApproval = useMemo(() => {
    return assignedServicesAdmins.filter((e) => e.approvalStatus === "approved").length > 0 && assignedServicesAdmins.length > 0;
  }, [assignedServicesAdmins]);

  /**
   * Sets the value of the [sendApprovalEmail] in inner state based on the selected radio box
   * @param event
   */
  const toggleSendEmail = (event) => {
    setSendApprovalEmail(event.target.value);
  };

  const toggleSendEmailDecline = (event) => {
    setSendDeclineEmail(event.target.value);
  };

  /**
   * Memo version of the content of the approval dialog
   * @type {JSX.Element}
   */
  const approvalDialogContent = useMemo(() => {
    return (
      <div style={{ width: "400px" }}>
        <Typography fontSize={"24px"} style={{ marginLeft: "0.5rem", marginBottom: "2rem" }}>
          Approve changes
        </Typography>
        <Typography fontWeight={500} style={{ marginLeft: "0.5rem" }}>
          Email Notification
        </Typography>
        <RadioGroup
          defaultValue={sendApprovalEmail}
          name="radio-buttons-group"
          style={{
            display: "flex",
            flexDirection: "row",
            background: "transparent",
            marginTop: "-1rem",
            marginBottom: "-0.5rem",
          }}
          onChange={toggleSendEmail}
        >
          <FormControlLabel value={"noEmail"} control={<Radio />} label="No email" style={{ background: "transparent" }} />
          <FormControlLabel
            value={"send"}
            control={<Radio />}
            label="Send an email"
            style={{ background: "transparent", marginLeft: "30px" }}
          />
        </RadioGroup>
      </div>
    );
  }, [sendApprovalEmail]);

  const declineDialogContent = useMemo(() => {
    return (
      <div style={{ width: "400px" }}>
        <Typography fontSize={"24px"} style={{ marginLeft: "0.5rem", marginBottom: "2rem" }}>
          Decline changes
        </Typography>
        <Typography style={{ marginLeft: "0.5rem" }}>
          You are about to decline the changes, which will revert back to the original information.
          <br />
          <br />
          Do you want to send an email to the customer notifying their changes have been declined?
        </Typography>
        <Spacer />
        <RadioGroup
          defaultValue={sendDeclineEmail}
          name="radio-buttons-group"
          style={{
            display: "flex",
            flexDirection: "row",
            background: "transparent",
            marginTop: "-1rem",
            marginBottom: "-0.5rem",
          }}
          onChange={toggleSendEmailDecline}
        >
          <FormControlLabel value={"noEmail"} control={<Radio />} label="No, don’t Email" style={{ background: "transparent" }} />
          <FormControlLabel
            value={"send"}
            control={<Radio />}
            label="Yes, send an Email"
            style={{ background: "transparent", marginLeft: "30px" }}
          />
        </RadioGroup>
      </div>
    );
  }, [sendDeclineEmail]);

  /**
   * Memo version of indicator for showing if citizen needs to update their info in services tab
   * @type {boolean}
   */
  const waitingForCitizenUpdatesInServicesTab = useMemo(() => {
    return !!!utilityInfo?.otherInfo?.find((e) => e.name === "services_reviewed")?.value?.value;
  }, [utilityInfo?.otherInfo]);

  /**
   * Memo version of indicator for showing the alert on top of the section in services tab
   * @type {boolean}
   */
  const showAlertInServicesTab = useMemo(() => {
    return waitingForCitizenUpdatesInServicesTab || REVIEWING_GROUP.includes(servicesChangeSetStatus);
  }, [servicesChangeSetStatus, waitingForCitizenUpdatesInServicesTab]);

  /**
   * memo version of account name
   * @type {string | undefined}
   */
  const accountName = useMemo(() => {
    return utilityInfo?.info?.find((e) => e.name === "account_number")?.value;
  }, [utilityInfo?.info]);

  /**
   * Memo version of financial information
   * @type {object | undefined}
   */
  const accountFinancialInfo = useMemo(() => {
    const balance = utilityInfo?.financial?.find((e) => e.name === "statement_balance")?.value?.value;
    const accountBalance = utilityInfo?.financial?.find((e) => e.name === "balance")?.value?.value;
    const lastPayment = utilityInfo?.financial?.find((e) => e.name === "paid")?.value?.value;
    const dueDate = balance && utilityInfo?.financial?.find((e) => e.name === "due_date")?.value?.value;
    return {
      balance,
      dueDate,
      accountBalance,
      lastPayment,
    };
  }, [utilityInfo?.financial]);

  const _addComment = async (comment, setCommentFieldLoading, setComment) => {
    setCommentFieldLoading(true);
    try {
      await addAccountComment(props.match.params.id, comment);
      setComment("");
      await fetchHistories();
    } catch (err) {
      console.log(err.response?.data?.message || err?.message);
    } finally {
      setCommentFieldLoading(false);
      if (selectedTab.includes("Account Info")) {
        setTimeout(() => {
          const historySection = document.querySelector("#__history-section");
          if (historySection) {
            historySection.scrollIntoView({ behavior: "smooth" });
          }
        }, 100);
      }
    }
  };

  /**
   * Returns the email we should contact the applicant at. We should
   * email the linked account, and if there is no linked
   * account, we use the email from the form.
   * @type {string}
   */
  const emailsToUse = useMemo(() => {
    const emails = [linkedUser?.email];
    const otherContacts = utilityInfo?.otherInfo?.find((item) => item.name === "other_contacts");

    otherContacts?.value?.value &&
      otherContacts?.value?.value?.forEach((contact) => {
        emails.push(contact.email);
      });

    return emails.filter(Boolean);
  }, [linkedUser, utilityInfo]);

  return (
    <div className={"admin-detail"}>
      {!utilityInfo && !loading && !pendingData ? (
        <MissingObject />
      ) : (
        <>
          <div className={"admin-detail-main"}>
            <div className={"admin-content-container"}>
              {loading || pendingData ? (
                <UtilityDetailSkeleton tab={selectedTab} />
              ) : (
                <>
                  <div className={"page_title"}>
                    <WaterDamageOutlined sx={{ height: "48px", width: "48px" }} />
                    <p style={{ marginRight: "0.2rem" }}>Utilities - </p>
                    {loading ? (
                      <Skeleton variant={"rounded"} width={300} height={40} />
                    ) : (
                      <p>{`${formatUtilityAccountNumber(accountName) ?? ""}`}</p>
                    )}
                  </div>
                  <div className={"tab_bar"}>
                    <Tabs onChange={onTabChanged} value={selectedTab}>
                      <Tab label={tabs[0]} value={tabs[0]} />
                      <Tab
                        icon={
                          showAlertInServicesTab && !waitingForCitizenUpdatesInServicesTab ? (
                            <Tooltip title={"Approval required"} placement={"top"}>
                              <Info color={"info"} />
                            </Tooltip>
                          ) : (
                            <></>
                          )
                        }
                        label={tabs[1]}
                        value={tabs[1]}
                      />
                      <Tab label={tabs[2]} value={tabs[2]} />
                      <Tab label={tabs[3]} value={tabs[3]} />
                    </Tabs>
                  </div>
                  <TabPanel value={selectedTab} index={tabs[0]}>
                    <UtilityInfoTab
                      fetchData={fetchUtilityInformation}
                      id={id}
                      loading={loading}
                      allHistory={allHistory}
                      addComment={_addComment}
                      fetchHistories={fetchHistories}
                      setTab={setSelectedTab}
                      infoData={infoData}
                      refresher={refresher}
                      accountConsumptionData={utilityInfo?.consumption}
                      hasCitizenChanges={hasClientInfoChanges}
                      changesetId={infoChangeSetId}
                      changesetStatus={infoChangeSetStatus}
                      linkedUser={linkedUser}
                      otherInfo={utilityInfo.otherInfo}
                    />
                  </TabPanel>
                  <TabPanel value={selectedTab} index={tabs[1]}>
                    <ServicesTab
                      id={id}
                      loading={loading}
                      data={serviceData}
                      showAlert={showAlertInServicesTab}
                      waitingForCitizenUpdates={waitingForCitizenUpdatesInServicesTab}
                      hasCitizenChanges={false}
                      changesetId={servicesChangeSetId}
                      changesetStatus={servicesChangeSetStatus}
                      refresher={refresher}
                    />
                  </TabPanel>
                  <TabPanel value={selectedTab} index={tabs[2]}>
                    <BillingTab id={id} loading={loading} emailsToUse={emailsToUse} />
                  </TabPanel>
                  <TabPanel value={selectedTab} index={tabs[3]}>
                    <HistoryTab
                      fetchHistories={fetchHistories}
                      history={allHistory}
                      _addComment={_addComment}
                      data={utilityInfo}
                      setTab={setSelectedTab}
                      isUTAccount={true}
                    />
                  </TabPanel>
                </>
              )}
            </div>
          </div>
          {/*TODO: refactor the right sidebar to its own file*/}
          <div>
            {loading || pendingData ? (
              <RightSidebarSkeleton />
            ) : (
              <div className={"admin-detail-sidebar"}>
                <UtilityStatusCard
                  loading={loading}
                  isActive={true}
                  linkedDate={utilityInfo?.otherInfo?.find((e) => e.name === "linked_date")?.value?.value}
                  changeDate={utilityInfo?.otherInfo?.find((e) => e.name === "info_change_set_creation_date")?.value?.value}
                  isLinked={true}
                  hasChanges={!!infoChangeSetStatus || !!servicesChangeSetStatus}
                />
                <AdminUtilityBalanceCard
                  loading={loading}
                  balance={accountFinancialInfo?.balance}
                  accountBalance={accountFinancialInfo?.accountBalance}
                  lastPayment={accountFinancialInfo?.lastPayment}
                  dueDate={accountFinancialInfo?.dueDate}
                />
                {!loading && (hasClientInfoChanges || hasClientServicesChanges) && (
                  <>
                    <Button
                      disabled={
                        (selectedTab === tabs[0] && !hasFullApproval) || (selectedTab === tabs[1] && !servicesHasFullApproval)
                      }
                      onClick={onApproveButtonClick}
                      variant={"contained"}
                      fullWidth
                    >
                      Approve
                    </Button>
                    <Spacer amount={1} />
                    <Button startIcon={<DeleteOutline />} onClick={onDeclineChangesButtonClick} variant={"text"}>
                      Decline changes
                    </Button>
                    <Divider />
                  </>
                )}
                <Button
                  disabled={loading}
                  startIcon={<MailOutline />}
                  onClick={() => {
                    onContactApplicant();
                  }}
                  variant={"text"}
                >
                  Contact customer
                </Button>
                <Divider />
                {hasClientInfoChanges && selectedTab === "Account Info" && (
                  <ApprovalSection
                    assignedAdmins={assignedAdmins}
                    unAssignIssuer={unAssignIssuer}
                    handleAssignIssuer={(e) => {
                      handleAssignIssuer(e);
                    }}
                    handleAssignReviewer={(e) => {
                      handleAssignReviewer(e);
                    }}
                    issuer={issuer}
                    onReviewerApprove={onReviewerApprove}
                    unAssignReviewer={(e) => {
                      unassignReviewer(e);
                    }}
                    utilityInfo={utilityInfo}
                    onUndoReviewerAction={onUndoReviewerAction}
                    onReviewerDeclined={onReviewerDeclined}
                  />
                )}
                {hasClientServicesChanges && selectedTab === "Services" && (
                  <ApprovalSection
                    assignedAdmins={assignedServicesAdmins}
                    unAssignIssuer={unAssignServicesIssuer}
                    handleAssignIssuer={(e) => {
                      handleAssignServicesIssuer(e);
                    }}
                    handleAssignReviewer={(e) => {
                      handleAssignServicesReviewer(e);
                    }}
                    issuer={servicesIssuer}
                    onReviewerApprove={onServicesReviewerApprove}
                    unAssignReviewer={(e) => {
                      unAssignServicesReviewer(e);
                    }}
                    utilityInfo={utilityInfo}
                    onUndoReviewerAction={onUndoServicesReviewerAction}
                    onReviewerDeclined={onServicesReviewerDeclined}
                  />
                )}
              </div>
            )}
          </div>
          <Dialog
            variant={"updated"}
            open={approveChangeDialogOpen}
            handleClose={() => setApproveChangeDialogOpen(false)}
            BodyComponent={approvalDialogContent}
            buttonOneText={"Cancel"}
            buttonTwoText={"CONFIRM"}
            buttonTwoVariant={"contained"}
            buttonFlexDirection={"column-reverse"}
            handleButtonOne={() => setApproveChangeDialogOpen(false)}
            handleButtonTwo={onApproveChanges}
            buttonTwoDisable={sendApprovalEmail === null}
          />
          <Dialog
            variant={"updated"}
            open={discardChangesDialogOpen}
            handleClose={handleCloseDeclineChangesDialog}
            BodyComponent={declineDialogContent}
            buttonOneText={"Cancel"}
            buttonTwoText={"CONFIRM"}
            buttonTwoVariant={"contained"}
            buttonFlexDirection={"column-reverse"}
            handleButtonOne={handleCloseDeclineChangesDialog}
            handleButtonTwo={onDeclineChanges}
            buttonTwoDisable={sendDeclineEmail === null}
          />
          <OnContactCustomerModal open={onContactModalOpen} setOpen={setOnContactModalOpen} />
        </>
      )}
    </div>
  );
};

export default UtilitiesDetailScreen;
