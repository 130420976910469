export const LOCALE = "en-CA";
export const CURRENCY = "CAD";
export const DATE_FORMAT = "LL";
export const DATE_SHORT = "MMM DD, YYYY";
export const FULL_FORMAT = "MMMM DD, YYYY";
export const USER_PROFILE_KEY = "CCF_PROFILE";
export const AUTH_TOKEN_KEY = "CCF_AUTH_TOKEN";
export const BUSINESS_NAME_LENGTH = 30;

//Empty data-table variants
export const EMPTY_TABLE_VARIANTS = {
  FILTERS: "filters",
  BASIC: "basic",
};
