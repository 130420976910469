import React, { useContext, useEffect, useMemo, useState } from "react";
import typographyClasses from "@assets/styles/typography/typography.module.scss";
import classes from "./index.module.scss";
import InfoButton from "@components/ui-kit/InfoButton";
import ServiceRow from "../../widgets/service-row";
import Button from "@mui/material/Button";
import {
  propertyType,
  residentialSolidWasteServices,
  solidWasteServices,
  wasteWaterServices,
  waterServices,
} from "./serviceItems";
import Modal from "@components/ui-kit/Modal";
import Link from "@mui/material/Link";
import { useTheme } from "@mui/material";
import { Check } from "@mui/icons-material";
import { IFormField } from "@core/interfaces/utility/IFormField";
import { createAccountChangeSet, getAccountStatus, update_account } from "@core/apis/account";
import { SUBMITTED } from "@core/constants/statuses";
import { DRAFT, REVIEWING_GROUP } from "@core/constants/licences";
import Spacer from "@components/ui-kit/Spacer";
import Alert from "@components/ui-kit/Alert";
import config from "@core/apis/_config";
import { NotificationContext } from "ui/contexts/NotificationContext";
import { useHistory } from "react-router-dom";
import { routes } from "@routes/routesData";
import { isDemoEnv } from "utilities";
import { LINK_CONTACT_CITY_HALL } from "core/constants/links";

const ServiceTab = ({
  id = "0",
  accessor = (_: string): IFormField => {
    return {} as IFormField;
  },
  loading = false,
  changeSetStatus = undefined,
  changeSetId,
  refresher,
  serviceData,
  fullData,
}: {
  id?: string;
  accessor?: (el: string) => IFormField;
  loading?: boolean;
  changeSetStatus?: any;
  changeSetId?: string;
  refresher: any;
  serviceData: any;
  fullData: any;
}) => {
  const history = useHistory();
  const theme = useTheme();
  const [openWhereToFindDialog, setOpenWhereToFindDialog] = useState(false);
  const { handleError } = useContext(NotificationContext) || {};
  const [servicesChangeset, setServicesChangeset] = useState({
    id: changeSetId,
    status: changeSetStatus,
  });
  const [data, setData] = useState({
    propertyType: propertyType,
    solidWasteServices: solidWasteServices,
    wasteWaterServices: wasteWaterServices,
    waterServices: waterServices,
  });
  const [isMissingData, setIsMissingData] = useState(false);
  const [servicesReviewed, setServiceReviewed] = useState(accessor("services_reviewed")?.value?.value);

  /**
   * With each change in the services tab [changeSetStatus] or [changeSetId]:
   * updates the [servicesChangeset] in the inner state
   */
  useEffect(() => {
    setServicesChangeset({
      id: changeSetId,
      status: changeSetStatus,
    });
  }, [changeSetStatus, changeSetId]);

  const type = useMemo(() => accessor("property_type").value.value.toLowerCase(), [accessor]);

  useEffect(() => {
    if (accessor("property_type").value.value.toLowerCase() === "residential") {
      setData((data) => ({
        ...data,
        solidWasteServices: residentialSolidWasteServices,
      }));
    } else {
      setData((data) => ({
        ...data,
        solidWasteServices: solidWasteServices,
      }));
    }
  }, [type, accessor]);

  /**
   * If 'look-good' button is clicked, updates the 'services_reviewed' value of UT account and updates the
   * status of the changeset
   */
  const onEditServices = async (servicesDataConfirmed: boolean) => {
    const changeStatus = String(await getStatus());
    if (!changeSetId || changeStatus === DRAFT || changeStatus === SUBMITTED || servicesDataConfirmed) {
      try {
        if (!servicesChangeset.id) {
          const response = (await createAccountChangeSet(id, "utility_account/edit_services")) as any;
          setServicesChangeset({
            id: response.data.id,
            status: response.data.status,
          });
        }
      } finally {
        if (servicesDataConfirmed) {
          const _updateData = [
            {
              id: fullData.otherInfo.find((e: any) => e.name === "services_reviewed").id,
              value: {
                value: true,
              },
            },
          ];
          await update_account(id, _updateData);
          refresher();
          setServiceReviewed(true);
        } else {
          history.push(routes.UTILITY_EDIT_SERVICES.path.replace(":id", id), {
            values: {
              property_type: accessor("property_type").value.value,
              water_metre_number: accessor("water_metre_number").value.value,
              water_metre_size: accessor("water_metre_size").value.value,
              waste_metre_size: accessor("waste_metre_size").value.value,
              collection_services: accessor("collection_services").value.value,
              solid_waste_general: accessor("solid_waste_general").value.value,
              solid_waste_cardboard: accessor("solid_waste_cardboard").value.value,
              solid_waste_size: accessor("solid_waste_size").value.value,
            },
            servicesChangesetId: servicesChangeset?.id,
            serviceData: serviceData,
            fullData: fullData,
          });
        }
      }
    } else {
      handleError("This licence is being reviewed, editing disabled");
      refresher();
    }
  };

  const getStatus = async () => {
    const statuses = await getAccountStatus(id);
    return statuses?.serviceChangesStatus;
  };

  const showButtons = useMemo(() => {
    return !REVIEWING_GROUP.includes(servicesChangeset.status) || servicesChangeset.status === SUBMITTED;
  }, [servicesChangeset.status]);

  return (
    <div>
      <p className={typographyClasses.h1}>Services</p>
      {REVIEWING_GROUP.includes(changeSetStatus) && (
        <>
          <Spacer />
          <Alert
            variant={"info-check"}
            title={"Submitted and under review"}
            body={
              <p
                style={{
                  marginTop: "0.5rem",
                  marginBottom: "0",
                }}
              >
                Your service information has been confirmed and submitted for review.{" "}
                {isDemoEnv() ? (
                  "Contact City Hall"
                ) : (
                  <a rel={"noreferrer"} href={LINK_CONTACT_CITY_HALL} target={"_blank"}>
                    Contact City Hall
                  </a>
                )}{" "}
                if you require assistance.
              </p>
            }
          />
        </>
      )}
      <div className={classes.services_details_container}>
        <p className={typographyClasses.h2}>Confirm your services</p>
        <p className={`${typographyClasses.body} ${typographyClasses.high_emphasis}`}>
          When it comes to your services, size and recurrence does matter. An incorrect size can result in overpaying for your
          services. Take a look at your information below and let us know if it’s up to date.
        </p>
        <InfoButton title={"Where do I find the RF serial number?"} onClick={() => setOpenWhereToFindDialog(true)} />
        <div className={"divider_bold"}></div>
        <ServiceRow
          setIsMissingData={setIsMissingData}
          accessor={accessor}
          loading={loading}
          title={"Property Type"}
          services={data.propertyType}
        />
        <ServiceRow
          setIsMissingData={setIsMissingData}
          accessor={accessor}
          loading={loading}
          title={"Water Services"}
          services={data.waterServices}
        />
        {accessor("property_type").value.value !== "residential" ? (
          <ServiceRow
            setIsMissingData={setIsMissingData}
            accessor={accessor}
            loading={loading}
            title={"WasteWater Services"}
            services={data.wasteWaterServices}
          />
        ) : null}
        <ServiceRow
          setIsMissingData={setIsMissingData}
          accessor={accessor}
          loading={loading}
          title={"Solid Waste services"}
          services={data.solidWasteServices}
        />

        {showButtons && (
          <div className={"flex_end_container"}>
            <Button
              className={"margin_right"}
              size={"small"}
              variant={"outlined"}
              onClick={() => onEditServices(false)}
              disabled={REVIEWING_GROUP.includes(changeSetStatus) && changeSetStatus !== SUBMITTED}
            >
              Update information
            </Button>
            {!isMissingData && !servicesReviewed ? (
              <Button startIcon={<Check />} size={"small"} variant={"contained"} onClick={() => onEditServices(true)}>
                Looks good
              </Button>
            ) : null}
          </div>
        )}
      </div>
      <Modal
        open={openWhereToFindDialog}
        onClose={() => setOpenWhereToFindDialog(false)}
        title={"Where do I find the RF serial number?"}
      >
        <div className={classes.modal_body}>
          <h2>RF (Radio Frequency) Serial Number</h2>
          <div className={classes.modal_flex_group}>
            <p className={`${typographyClasses.body} ${typographyClasses.high_emphasis}`} style={{ maxWidth: 370 }}>
              <span>Locate the water metre</span>
              <br />
              Your water metre is typically located in a utility area such as a basement, metre box, or mechanical room.
              <br />
              <br />
              <span>Identify the water metre</span>
              <br />
              Once you’ve identified the water metre, examine in closely. Look for any labels or markings that contain the RF
              serial number.
              <br />
              <br />
              <span>RF serial number</span>
              <br />
              The RF serial number is a 9 or 10 digit number located on the front of the device. It is displayed in the picture as
              the number on the top right corner.
              <br />
              <br />
              If you are having difficulty finding the RF serial number or if it’s not visible on the module,{" "}
              <Link
                style={{ color: theme.palette.primary[200] }}
                rel={"noreferrer"}
                target={"_blank"}
                href={"https://lacombe.ca/Directory.aspx?DID=42"}
              >
                contact us
              </Link>
            </p>
            <img
              className={classes.modal_img}
              src={`https://images.cloudcityhall.com/${config.cityId}/interface/water_metre.png`}
              alt={"water metre"}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ServiceTab;
