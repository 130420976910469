import React, { useCallback, useContext } from "react";
import { Context } from "../index";
import WizardStepTracker from "@components/ui-kit/wizard-step-tracker";
import { IStep } from "@components/ui-kit/wizard-step-tracker/interfaces";
import { useHistory } from "react-router-dom";
import Spacer from "@components/ui-kit/Spacer";
import Step1 from "./Step1";
import Step2 from "./Step2";
import { closeDialog, setState as setConfirmationDialogState } from "../../../../../redux/slices/confrmation-dialog";
import { useDispatch } from "react-redux";

const steps = [{ label: "Disconnection Dates" }, { label: "Review and Submit" }] as IStep[];

const TemporaryDisconnectDisplay = () => {
  const { activeStep, setActiveStep, setFormValues } = useContext(Context);
  const history = useHistory();
  const dispatch = useDispatch();

  const onClose = () => {
    if (activeStep !== 0) {
      dispatch(
        setConfirmationDialogState({
          open: true,
          title: "You have unsaved changes",
          body: "Closing this will result in losing all your changes. Are you sure you want to close?",
          onConfirm: _handleConfirmationDialogAction,
        }),
      );
      return;
    }
    history.goBack();
  };

  const _handleConfirmationDialogAction = () => {
    dispatch(closeDialog());
    history.goBack();
  };

  const CurrentPage = useCallback(() => {
    switch (activeStep) {
      case 0:
        return <Step1 />;
      case 1:
        return <Step2 />;
    }
  }, [activeStep]);

  const handleSetActiveStep = (step: number) => {
    if (activeStep === 1 && step === 0) {
      setFormValues({ startDate: null, endDate: null });
    }
    setActiveStep(step);
  };

  return (
    <div>
      <WizardStepTracker activeStep={activeStep} setActiveStep={handleSetActiveStep} steps={steps} onClose={onClose} />
      <Spacer amount={2} />
      <div
        style={{
          maxWidth: 360,
          margin: "0 auto",
        }}
      >
        {CurrentPage()}
      </div>
    </div>
  );
};

export default TemporaryDisconnectDisplay;
