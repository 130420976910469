import React, { useCallback } from "react";
import { SearchRounded } from "@mui/icons-material";
import { SearchButton, SearchTextField } from "../SearchBar";
import InputAdornment from "@mui/material/InputAdornment";
import { Autocomplete, autocompleteClasses } from "@mui/material";
import styled from "@emotion/styled";
import CategoryItem from "./widgets/CategoryItem";
import AdminItem from "./widgets/AdminItem";

const SearchFieldWrapper = styled.div`
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
  margin-bottom: 1rem !important;
`;

const StyledAutocompletePopper = styled("div")(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: "none",
    borderRadius: 0,
    margin: 0,
    color: "inherit",
    width: 500,
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: "relative",
  },
}));

function PopperComponent(props) {
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} />;
}

const AutocompleteRelative = ({
  onChange,
  options = [],
  placeholder = "",
  type = "CATEGORIES",
  checkRowSelected,
  dataType = "ADMINS",
}) => {
  const RenderOption = useCallback(
    (props) => {
      switch (type) {
        case "CATEGORIES":
          return <CategoryItem props={props} option={props.option} state={props.state} {...props} />;
        case "ADMINS":
          return (
            <AdminItem props={props} checkRowSelected={checkRowSelected} row={props.option} dataType={dataType} {...props} />
          );
        default:
          return <></>;
      }
    },
    //eslint-disable-next-line
    [type],
  );

  return (
    <Autocomplete
      open
      getOptionLabel={(option) => {
        if (option.fullName) {
          return option.fullName;
        }
        return option;
      }}
      autoFocus
      fullWidth
      PopperComponent={PopperComponent}
      onChange={(e, value) => {
        if (value) {
          onChange(value);
        }
      }}
      options={options}
      renderOption={(props, option, state) => {
        return <RenderOption option={option} state={state} {...props} />;
      }}
      renderInput={(params) => {
        const { value, InputProps, ...rest } = params;
        return (
          <SearchFieldWrapper style={{ marginRight: "auto" }}>
            <SearchTextField
              placeholder={placeholder}
              InputProps={{
                ...InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchButton>
                      <SearchRounded />
                    </SearchButton>
                  </InputAdornment>
                ),
              }}
              variant={"outlined"}
              {...rest}
            />
          </SearchFieldWrapper>
        );
      }}
    />
  );
};

export default AutocompleteRelative;
