import Typography from "@mui/material/Typography";
import styled, { css } from "styled-components";
import { Chip, useTheme } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import { Form, Formik } from "formik";
import { object } from "yup";
import { licenceValidations } from "@core/constants/validations";
import TextField from "../TextField";
import { CheckboxCard } from "../CheckboxCard";
import TextArea from "../text-area";
import { formatPhoneNumber } from "@utils/masked-input-formatters";
import config from "@core/apis/_config";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  padding: 0.5rem;
  margin-bottom: 0.1rem;
  background: ${({ background, theme, site }) =>
    background !== undefined
      ? site !== "admin"
        ? theme.palette.primary[50]
        : theme.palette.nonPalette.YELLOW_BACKGROUND
      : "transparent"};
  overflow: hidden;

  width: ${({ fullWidth }) => (fullWidth ? "100%" : "unset")};

  > * {
    color: ${({ theme }) => theme.palette.primary[500]};
  }

  ${({ isFormField }) =>
    !isFormField &&
    `
    &&&:focus-visible {
      border: 0 solid #0c55b5;
      box-shadow: 0 6px 10px rgba(1, 18, 32, 0.1),
        0 1px 18px rgba(1, 18, 32, 0.08), 0 3px 5px rgba(1, 18, 32, 0.1);
      color: ${({ theme }) => theme.palette.primary[200]};
  
      > * {
        color: ${({ theme }) => theme.palette.primary[200]};
      }
  
      && > * > * > * {
        color: ${({ theme }) => theme.palette.primary[200]};
      }
    }
  `}

  ${({ disabled, theme }) => {
    return (
      disabled &&
      css`
        &&:hover {
          background-color: ${({ theme }) => theme.palette.whites.WHITE};
          cursor: unset;
        }

        .material-symbols-rounded {
          color: ${theme.palette.blacks.BLACK_MEDIUM_EMPHASIS};
        }

        .label {
          color: ${theme.palette.blacks.BLACK_MEDIUM_EMPHASIS};
        }
      `
    );
  }}
`;

const StartOrnamentContainer = styled.div`
  display: flex;
  align-self: flex-start;

  .material-symbols-rounded {
    font-size: 32px;
  }
`;

const EndOrnamentContainer = styled.div`
  display: flex;
  margin-top: auto;
  margin-bottom: auto;

  .material-symbols-rounded {
    font-size: 32px;
  }
`;

const ReadMoreLink = styled(Link)`
  && {
    color: ${({ theme }) => theme.BLACK_HIGH_EMPHASIS};
    cursor: pointer;
    font-weight: 500;
    text-decoration: none;

    :hover {
      text-decoration: underline;
    }
  }
`;

/**
 * List item component
 * @param disabled
 * @param variant
 * @param endOrnament
 * @param label
 * @param onClick
 * @param startOrnament
 * @param subLabel
 * @param originalValue
 * @param characterLimit
 * @param style
 * @param fullWidth
 * @param labelStyles
 * @param subLabelStyles
 * @param positiveIntegerOnly
 * @param isFormField
 * @param props
 * @param type
 * @param onSave
 * @param license
 * @param isBusinessManager
 * @param validationProps
 * @param error
 * @param setError
 * @param editing
 * @returns {JSX.Element}
 * @constructor
 */
const ListItem = ({
  disabled = false,
  variant,
  site = "admin",
  endOrnament,
  label,
  onClick,
  startOrnament,
  subLabel,
  originalValue,
  characterLimit,
  style,
  fullWidth,
  labelStyles,
  subLabelStyles,
  positiveIntegerOnly = false,
  isFormField,
  props,
  type,
  onSave,
  license,
  isBusinessManager,
  validationProps,
  error,
  setError,
  editing,
}) => {
  const theme = useTheme();
  const [editable, setEditable] = useState(false);
  const [localErrors, setLocalErrors] = useState(error);
  const [showAll, setShowAll] = useState(false);
  const [showOriginal, setShowOriginal] = useState(false);
  const handleClick = disabled || !onClick ? null : onClick;
  const handleSave = async (values) => {
    await onSave(values);
    setEditable(false);
  };

  useEffect(() => {
    setLocalErrors(error);
  }, [error]);

  const onCancel = () => setEditable(false);

  /**
   * memo version of array of errors
   * @type {any[]|[unknown]}
   */
  const arrayOfErrors = useMemo(() => (Array.isArray(localErrors) ? [...localErrors] : [localErrors]), [localErrors]);

  return (
    <>
      <Container
        background={originalValue === subLabel || originalValue === undefined ? undefined : true}
        site={site}
        isFormField={props}
        fullWidth={fullWidth}
        disabled={disabled}
        tabIndex={disabled ? null : 0}
        onMouseDown={
          isFormField
            ? null
            : (e) => {
                e.stopPropagation();
              }
        }
        onClick={isFormField ? null : handleClick}
        onKeyPress={
          isFormField
            ? null
            : (e) => {
                e.preventDefault();
                if (e.key === "Enter") {
                  handleClick && handleClick();
                }
              }
        }
        style={style}
        theme={theme}
      >
        {editable ? (
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              width: "100%",
              gap: 15,
            }}
          >
            <EndOrnamentContainer style={{ paddingTop: 20 }}>{startOrnament}</EndOrnamentContainer>
            <EditableInputField
              editing={editing}
              setError={setError}
              error={error}
              label={label}
              subLabel={subLabel}
              license={license}
              type={type}
              props={props}
              isBusinessManager={isBusinessManager}
              handleSave={handleSave}
              handleClose={onCancel}
              setLocalErrors={setLocalErrors}
              validation={validationProps}
              positiveIntegerOnly={positiveIntegerOnly}
            />
          </div>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <StartOrnamentContainer>{startOrnament}</StartOrnamentContainer>
              <div
                style={{
                  display: "flex",
                  overflow: "hidden",
                  flexDirection: "column",
                  marginLeft: "10px",
                  wordBreak: "break-word",
                  wordWrap: "break-word",
                }}
              >
                {variant === "detail" ? (
                  <>
                    <Typography className={"label"} variant="caption" color={theme.palette.blacks.BLACK_MEDIUM_EMPHASIS}>
                      {label}
                    </Typography>
                    <Typography
                      color={theme.palette.primary[700]}
                      variant="body1"
                      style={{
                        ...subLabelStyles,
                      }}
                    >
                      {!characterLimit || !subLabel || subLabel?.length < characterLimit ? (
                        subLabel
                      ) : !showAll ? (
                        <>
                          {subLabel.substring(0, characterLimit - 15)}
                          <ReadMoreLink
                            onClick={() => {
                              setShowAll(!showAll);
                            }}
                          >
                            ...read more
                          </ReadMoreLink>
                        </>
                      ) : (
                        <>
                          {subLabel}
                          <ReadMoreLink
                            onClick={() => {
                              setShowAll(!showAll);
                            }}
                          >
                            ...read less
                          </ReadMoreLink>
                        </>
                      )}
                    </Typography>
                    {originalValue !== subLabel && originalValue !== undefined && (
                      <Typography
                        style={{
                          fontSize: 14,
                          fontWeight: 400,
                          lineHeight: 1,
                          ...subLabelStyles,
                          marginBottom: "0.1rem",
                          marginTop: "0.1rem",
                        }}
                        sx={{
                          "&:hover": {
                            color: theme.palette.primary[500],
                            cursor: "pointer",
                          },
                        }}
                        color={theme.palette.primary[200]}
                        onClick={() => {
                          setShowOriginal(!showOriginal);
                        }}
                      >
                        {showOriginal ? "Hide original" : "View original"}
                      </Typography>
                    )}
                    {showOriginal && (
                      <>
                        <br />
                        <Chip
                          label="Original"
                          style={{
                            width: "fit-content",
                            background: theme.palette.greys.GREY_MEDIUM,
                            marginBottom: "0.25rem",
                          }}
                          size="small"
                        />
                        <Typography className={"label"} variant="caption" color={theme.palette.blacks.BLACK_MEDIUM_EMPHASIS}>
                          {label}
                        </Typography>
                        <Typography color={theme.palette.primary[700]} variant="body1">
                          {!characterLimit || !originalValue || originalValue?.length < characterLimit ? (
                            originalValue || "-"
                          ) : !showAll ? (
                            <>
                              {originalValue.substring(0, characterLimit - 15)}
                              <ReadMoreLink
                                onClick={() => {
                                  setShowAll(!showAll);
                                }}
                              >
                                ...read more
                              </ReadMoreLink>
                            </>
                          ) : (
                            <>
                              {originalValue}
                              <ReadMoreLink
                                onClick={() => {
                                  setShowAll(!showAll);
                                }}
                              >
                                ...read less
                              </ReadMoreLink>
                            </>
                          )}
                        </Typography>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Typography className={"label"} variant="body1" style={{ fontWeight: 400, ...labelStyles }}>
                      {label}
                    </Typography>
                    {originalValue !== label && originalValue !== undefined && (
                      <Typography
                        style={{
                          fontSize: 14,
                          fontWeight: 400,
                          lineHeight: 1,
                          ...subLabelStyles,
                          marginBottom: "0.1rem",
                          marginTop: "0.1rem",
                        }}
                        sx={{
                          "&:hover": {
                            color: theme.palette.primary[500],
                            cursor: "pointer",
                          },
                        }}
                        color={theme.palette.primary[200]}
                        onClick={() => {
                          setShowOriginal(!showOriginal);
                        }}
                      >
                        {showOriginal ? "Hide original" : "View original"}
                      </Typography>
                    )}
                    {showOriginal && (
                      <>
                        <br />
                        <Chip
                          label="Original"
                          style={{
                            width: "fit-content",
                            background: theme.palette.greys.GREY_MEDIUM,
                            marginBottom: "0.25rem",
                          }}
                          size="small"
                        />
                        <Typography className={"label"} variant="body1" style={{ fontWeight: 400, ...labelStyles }}>
                          {originalValue}
                        </Typography>
                      </>
                    )}
                    {Array.isArray(subLabel) ? (
                      subLabel.map((label) => {
                        return (
                          <Typography
                            key={label}
                            fontSize={14}
                            color={theme.palette.blacks.BLACK_MEDIUM_EMPHASIS}
                            style={{ lineHeight: 1.5, fontWeight: 400 }}
                          >
                            {label}
                          </Typography>
                        );
                      })
                    ) : (
                      <Typography
                        style={{
                          fontWeight: 400,
                          lineHeight: 1,
                          ...subLabelStyles,
                        }}
                        color={theme.palette.blacks.BLACK_MEDIUM_EMPHASIS}
                        variant="caption"
                      >
                        {subLabel}
                      </Typography>
                    )}
                  </>
                )}
              </div>
            </div>
            <EndOrnamentContainer>{endOrnament}</EndOrnamentContainer>
            {!editable && isFormField && (
              <Button
                onClick={() => {
                  setEditable(true);
                }}
                size="small"
                style={{
                  minWidth: 82,
                  height: 33,
                  padding: "8px 12px",
                  fontFamily: "Rubik",
                  fontWeight: 400,
                  fontSize: 16,
                }}
                variant="outlined"
              >
                Change
              </Button>
            )}
          </>
        )}
      </Container>
      {localErrors && arrayOfErrors.length > 0 ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {arrayOfErrors.map((err, index) =>
            err ? (
              <Typography
                className={!!err ? "bl-error-message" : null}
                key={index}
                style={{ marginLeft: "1rem", marginBottom: "0.5rem" }}
                variant={"body2"}
                color={theme.palette.nonPalette.RED}
              >
                {err}
              </Typography>
            ) : null,
          )}
        </div>
      ) : null}
    </>
  );
};

const FIELD_TYPE = {
  TEXT_FIELD: 0,
  TEXT_AREA: 1,
  BOOLEAN: 2,
};

const EditableInputField = ({
  label,
  subLabel,
  props,
  license,
  type,
  isBusinessManager,
  handleSave,
  handleClose,
  validation = [],
  setLocalErrors,
  editing,
  positiveIntegerOnly,
}) => {
  const ref = useRef(null);
  const formRef = useRef(null);
  const [validationSchema, setValidationSchema] = useState({});
  const [validationOnChange, setValidationOnChange] = useState(false);
  // TODO: What does below lines of code do?
  const [value] = useState(() => {
    const state = {};
    if (Array.isArray(props)) {
      props.concat(validation);
      props.map((prop) => (state[prop] = license[prop]));
    } else {
      state[props] = isBusinessManager ? subLabel : license[props];
      validation.forEach((name) => {
        state[name] = license[name];
      });
    }
    return state;
  });

  //Set the validation schema based on the props
  useEffect(() => {
    let schema = {};
    Object.entries(value).forEach(([prop]) => {
      schema[prop] = licenceValidations[prop];
    });
    setValidationSchema(object(schema));
    if (!editing) {
      handleClose();
    }
    // eslint-disable-next-line
  }, [value, ref, editing]);

  const capitalize = (text) => {
    const tmpLabel = text.replace(/_/g, " ");
    const [firstLetter, ...restOfWord] = tmpLabel;
    return firstLetter.toUpperCase() + restOfWord.join("");
  };

  const handleSubmit = async (values) => {
    setLocalErrors([]);
    if (handleSave) {
      handleSave(values);
    }
  };

  /**
   * Handles keydown when the field is positive integer only to prevent any non-positive integer value
   * @type {(function(*): (boolean))|*}
   */
  const handleIntegerOnlyKeyDown = useCallback((event) => {
    // Allow numbers and backspace/delete keys
    if (
      (event.key >= "0" && event.key <= "9") ||
      event.key === "Backspace" ||
      event.key === "Delete" ||
      event.key === "ArrowLeft" ||
      event.key === "ArrowRight"
    ) {
      return true;
    }

    // Prevent all other keystrokes
    event.preventDefault();
    return false;
  }, []);

  const checkChanges = (current, prop, initial) => {
    const ignoredProps = ["secondary_phone_number", "fax_number", "website_address", ["is_resident", "is_homebased"]];
    if (
      !prop.includes("is_homebased") &&
      !prop.includes("is_resident") &&
      !ignoredProps.includes(prop) &&
      current[prop].trim().length === 0
    )
      return true;

    if (prop.includes("is_homebased") || prop.includes("is_resident")) {
      if (initial === "Resident (Home-based)") {
        if (current["is_homebased"] !== true || current["is_resident"] !== true) {
          return false;
        }
      } else if (initial === "Resident") {
        if (current["is_homebased"] !== false || current["is_resident"] !== true) {
          return false;
        }
      } else {
        if (current["is_homebased"] !== false || current["is_resident"] !== false) {
          return false;
        }
      }
    } else if (initial === "-") {
      if (current[prop] !== "") {
        return false;
      }
    } else if (prop.includes("number")) {
      if (formatPhoneNumber(current[prop]) !== formatPhoneNumber(initial)) {
        return false;
      }
    } else if (current[prop] !== initial) {
      return false;
    }
    return true;
  };

  const arrayOfProps = Array.isArray(props) ? [...props] : [props];
  return (
    <div
      ref={ref}
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 10,
      }}
    >
      <Formik
        innerRef={formRef}
        initialValues={value}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnChange={validationOnChange}
      >
        {({ values, handleChange, errors, setFieldValue }) => {
          return (
            <Form>
              {arrayOfProps.map((prop, index) => {
                if (prop === "is_homebased" && !values["is_resident"]) return null;
                return (
                  <div key={index} style={{ marginTop: index > 0 ? 10 : 0 }}>
                    {type === FIELD_TYPE.TEXT_FIELD && (
                      <TextField
                        error={Boolean(errors[prop])}
                        helperText={errors[prop]}
                        name={prop}
                        key={prop}
                        id={prop}
                        autoFocus={index === 0}
                        fullWidth
                        format={prop.indexOf("phone") > -1 ? "PHONE" : prop.indexOf("fax") > -1 ? "PHONE" : null}
                        label={arrayOfProps.length > 1 ? capitalize(prop) : label}
                        value={values[prop]}
                        onClick={() => {
                          let input = document.getElementById(prop);
                          input.focus();
                        }}
                        onChange={handleChange}
                        {...(positiveIntegerOnly
                          ? {
                              onKeyDown: handleIntegerOnlyKeyDown,
                            }
                          : {})}
                      />
                    )}
                    {type === FIELD_TYPE.TEXT_AREA && (
                      <TextArea
                        error={Boolean(errors[prop])}
                        helperText={errors[prop]}
                        name={prop}
                        key={prop}
                        autoFocus={true}
                        label={arrayOfProps.length > 1 ? capitalize(prop) : label}
                        value={values[prop]}
                        maxLength={140}
                        onChange={handleChange}
                      />
                    )}
                    {type === FIELD_TYPE.BOOLEAN && (
                      <CheckboxCard
                        name={prop}
                        key={prop}
                        label={
                          prop === "is_resident"
                            ? `Is the business located in the City of ${config.cityName}?`
                            : "Home-based business"
                        }
                        cardVariant="highlight"
                        onChange={(e) => {
                          const checked = e.target.checked;
                          if (prop === "is_resident" && !checked) {
                            setFieldValue("is_resident", false);
                            setFieldValue("is_homebased", false);
                            return;
                          }
                          setFieldValue(prop, checked);
                        }}
                        value={values[prop]}
                      />
                    )}
                  </div>
                );
              })}
              <div
                style={{
                  display: "flex",
                  gap: 10,
                  marginTop: 10,
                }}
              >
                <Button
                  type={"submit"}
                  variant={"contained"}
                  style={{
                    width: 120,
                    height: 48,
                  }}
                  size="medium"
                  onClick={() => {
                    setValidationOnChange(true);
                  }}
                  disabled={checkChanges(values, props, subLabel)}
                >
                  Done
                </Button>
                <Button
                  onClick={() => handleClose()}
                  style={{
                    width: 120,
                    height: 48,
                    padding: 0,
                    margin: 0,
                    fontWeight: 700,
                    fontSize: 16,
                    borderRadius: 10,
                    fontFamily: "Rubik",
                  }}
                  size="medium"
                >
                  Cancel
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ListItem;
