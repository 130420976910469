export const countries = [{ label: "Canada" }, { label: "USA" }];
export const CanadaProvinces = [
  { label: "Alberta", abbrev: "AB" },
  { label: "British Columbia", abbrev: "BC" },
  { label: "Manitoba", abbrev: "MB" },
  { label: "New Brunswick", abbrev: "NB" },
  { label: "Newfoundland and Labrador", abbrev: "NL" },
  { label: "Northwest Territories", abbrev: "NT" },
  { label: "Nova Scotia", abbrev: "NS" },
  { label: "Nunavut", abbrev: "NU" },
  { label: "Ontario", abbrev: "ON" },
  { label: "Prince Edward Island", abbrev: "PE" },
  { label: "Quebec", abbrev: "QC" },
  { label: "Saskatchewan", abbrev: "SK" },
  { label: "Yukon", abbrev: "YT" },
];

export const UsStates = [
  { label: "Alabama", abbrev: "AL" },
  { label: "Alaska", abbrev: "AK" },
  { label: "Arizona", abbrev: "AZ" },
  { label: "Arkansas", abbrev: "AR" },
  { label: "California", abbrev: "CA" },
  { label: "Colorado", abbrev: "CO" },
  { label: "Connecticut", abbrev: "CT" },
  { label: "Delaware", abbrev: "DE" },
  { label: "Florida", abbrev: "FL" },
  { label: "Georgia", abbrev: "GA" },
  { label: "Hawaii", abbrev: "HI" },
  { label: "Idaho", abbrev: "ID" },
  { label: "Illinois", abbrev: "IL" },
  { label: "Indiana", abbrev: "IN" },
  { label: "Iowa", abbrev: "IA" },
  { label: "Kansas", abbrev: "KS" },
  { label: "Kentucky", abbrev: "KY" },
  { label: "Louisiana", abbrev: "LA" },
  { label: "Maine", abbrev: "ME" },
  { label: "Maryland", abbrev: "MD" },
  { label: "Massachusetts", abbrev: "MA" },
  { label: "Michigan", abbrev: "MI" },
  { label: "Minnesota", abbrev: "MN" },
  { label: "Mississippi", abbrev: "MS" },
  { label: "Missouri", abbrev: "MO" },
  { label: "Montana", abbrev: "MT" },
  { label: "Nebraska", abbrev: "NE" },
  { label: "Nevada", abbrev: "NV" },
  { label: "New Hampshire", abbrev: "NH" },
  { label: "New Jersey", abbrev: "NJ" },
  { label: "New Mexico", abbrev: "NM" },
  { label: "New York", abbrev: "NY" },
  { label: "North Carolina", abbrev: "NC" },
  { label: "North Dakota", abbrev: "ND" },
  { label: "Ohio", abbrev: "OH" },
  { label: "Oklahoma", abbrev: "OK" },
  { label: "Oregon", abbrev: "OR" },
  { label: "Pennsylvania", abbrev: "PA" },
  { label: "Rhode Island", abbrev: "RI" },
  { label: "South Carolina", abbrev: "SC" },
  { label: "South Dakota", abbrev: "SD" },
  { label: "Tennessee", abbrev: "TN" },
  { label: "Texas", abbrev: "TX" },
  { label: "Utah", abbrev: "UT" },
  { label: "Vermont", abbrev: "VT" },
  { label: "Virginia", abbrev: "VA" },
  { label: "Washington", abbrev: "WA" },
  { label: "West Virginia", abbrev: "WV" },
  { label: "Wisconsin", abbrev: "WI" },
  { label: "Wyoming", abbrev: "WY" },
];

export const ProvStates = [
  { key: ["alabama", "al"], label: "Alabama", abbrev: "AL" },
  { key: ["alaska", "ak"], label: "Alaska", abbrev: "AK" },
  { key: ["arizona", "az"], label: "Arizona", abbrev: "AZ" },
  { key: ["arkansas", "ar"], label: "Arkansas", abbrev: "AR" },
  { key: ["california", "ca"], label: "California", abbrev: "CA" },
  { key: ["colorado", "co"], label: "Colorado", abbrev: "CO" },
  { key: ["connecticut", "ct"], label: "Connecticut", abbrev: "CT" },
  { key: ["delaware", "de"], label: "Delaware", abbrev: "DE" },
  { key: ["florida", "fl"], label: "Florida", abbrev: "FL" },
  { key: ["georgia", "ga"], label: "Georgia", abbrev: "GA" },
  { key: ["hawaii", "hi"], label: "Hawaii", abbrev: "HI" },
  { key: ["idaho", "id"], label: "Idaho", abbrev: "ID" },
  { key: ["illinois", "il"], label: "Illinois", abbrev: "IL" },
  { key: ["indiana", "in"], label: "Indiana", abbrev: "IN" },
  { key: ["iowa", "ia"], label: "Iowa", abbrev: "IA" },
  { key: ["kansas", "ks"], label: "Kansas", abbrev: "KS" },
  { key: ["kentucky", "ky"], label: "Kentucky", abbrev: "KY" },
  { key: ["louisiana", "la"], label: "Louisiana", abbrev: "LA" },
  { key: ["maine", "me"], label: "Maine", abbrev: "ME" },
  { key: ["maryland", "md"], label: "Maryland", abbrev: "MD" },
  { key: ["massachusetts", "ma"], label: "Massachusetts", abbrev: "MA" },
  { key: ["michigan", "mi"], label: "Michigan", abbrev: "MI" },
  { key: ["minnesota", "mn"], label: "Minnesota", abbrev: "MN" },
  { key: ["mississippi", "ms"], label: "Mississippi", abbrev: "MS" },
  { key: ["missouri", "mo"], label: "Missouri", abbrev: "MO" },
  { key: ["montana", "mt"], label: "Montana", abbrev: "MT" },
  { key: ["nebraska", "ne"], label: "Nebraska", abbrev: "NE" },
  { key: ["nevada", "nv"], label: "Nevada", abbrev: "NV" },
  { key: ["new hampshire", "nh"], label: "New Hampshire", abbrev: "NH" },
  { key: ["new jersey", "nj"], label: "New Jersey", abbrev: "NJ" },
  { key: ["new mexico", "nm"], label: "New Mexico", abbrev: "NM" },
  { key: ["new york", "ny"], label: "New York", abbrev: "NY" },
  { key: ["north carolina", "nc"], label: "North Carolina", abbrev: "NC" },
  { key: ["north dakota", "nd"], label: "North Dakota", abbrev: "ND" },
  { key: ["ohio", "oh"], label: "Ohio", abbrev: "OH" },
  { key: ["oklahoma", "ok"], label: "Oklahoma", abbrev: "OK" },
  { key: ["oregon", "or"], label: "Oregon", abbrev: "OR" },
  { key: ["pennsylvania", "pa"], label: "Pennsylvania", abbrev: "PA" },
  { key: ["rhode island", "ri"], label: "Rhode Island", abbrev: "RI" },
  { key: ["south carolina", "sc"], label: "South Carolina", abbrev: "SC" },
  { key: ["south dakota", "sd"], label: "South Dakota", abbrev: "SD" },
  { key: ["tennessee", "tn"], label: "Tennessee", abbrev: "TN" },
  { key: ["texas", "tx"], label: "Texas", abbrev: "TX" },
  { key: ["utah", "ut"], label: "Utah", abbrev: "UT" },
  { key: ["vermont", "vt"], label: "Vermont", abbrev: "VT" },
  { key: ["virginia", "va"], label: "Virginia", abbrev: "VA" },
  { key: ["washington", "wa"], label: "Washington", abbrev: "WA" },
  { key: ["west virginia", "wv"], label: "West Virginia", abbrev: "WV" },
  { key: ["wisconsin", "wi"], label: "Wisconsin", abbrev: "WI" },
  { key: ["wyoming", "wy"], label: "Wyoming", abbrev: "WY" },
  { key: ["alberta", "ab"], label: "Alberta", abbrev: "AB" },
  { key: ["british columbia", "bc"], label: "British Columbia", abbrev: "BC" },
  { key: ["manitoba", "mb"], label: "Manitoba", abbrev: "MB" },
  { key: ["new brunswick", "nb"], label: "New Brunswick", abbrev: "NB" },
  {
    key: ["newfoundland and labrador", "nl"],
    label: "Newfoundland and Labrador",
    abbrev: "NL",
  },
  {
    key: ["northwest territories", "nt"],
    label: "Northwest Territories",
    abbrev: "NT",
  },
  { key: ["nova scotia", "ns"], label: "Nova Scotia", abbrev: "NS" },
  { key: ["nunavut", "nu"], label: "Nunavut", abbrev: "NU" },
  { key: ["ontario", "on"], label: "Ontario", abbrev: "ON" },
  {
    key: ["prince edward island", "pe"],
    label: "Prince Edward Island",
    abbrev: "PE",
  },
  { key: ["quebec", "qc"], label: "Quebec", abbrev: "QC" },
  { key: ["saskatchewan", "sk"], label: "Saskatchewan", abbrev: "SK" },
  { key: ["yukon", "yt"], label: "Yukon", abbrev: "YT" },
];

export const abbreviator = (fullName: string) => {
  let searchArray = ProvStates;
  let retVal = { abbrev: "", label: "" };

  if (!fullName || (typeof fullName === "object" && Object.keys(fullName).length === 0)) {
    return retVal;
  }

  searchArray.forEach((sub) => {
    const found = sub.key.find((e) => e === fullName?.toLowerCase());
    if (found) {
      retVal = { abbrev: sub.abbrev, label: sub.label };
    }
  });

  return retVal;
};
