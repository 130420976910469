import { BLACK_LOW_EMPHASIS, GREEN, GREY_MEDIUM, PRIMARY_700, WHITE } from "@mui-theme/theme";
import Typography from "@mui/material/Typography";
import { capitalize } from "../index";
import React, { ReactElement } from "react";
import classes from "./index.module.scss";
import {
  ACTIVE,
  ANY,
  APPROVED,
  CANCELLED,
  DECLINED,
  EXPIRED,
  EXPIRING,
  ISSUED,
  OUTDATED,
  RENEWING,
  REVIEWING,
  REVOKED,
  SUBMITTED,
} from "@core/constants/licences";
import styled from "@emotion/styled";
import {
  AccessAlarmRounded,
  AlarmOffOutlined,
  Approval,
  CancelOutlined,
  CheckRounded,
  DeleteOutline,
  EditRounded,
  EventOutlined,
  FindInPageOutlined,
  Inbox,
  ReportOutlined,
  UpdateDisabled,
  WebOutlined,
} from "@mui/icons-material";

export const OPEN = "open";
export const VOID = "void";
export const PAID = "paid";

const StatusContainer = styled.div`
  display: inline-block;
  border: 1px solid ${({ theme }) => theme.palette.nonPalette.OUTLINE};
  border-radius: 100px;
  padding: 0.3rem 0.7rem;
`;

const Dot = styled.div<any>`
  height: 15px;
  width: 15px;
  background-color: ${({ color }) => color};
  border-radius: 50%;
  display: inline-block;
  ${({ borderColor }: any) => `border: 2px solid ${borderColor}`};
  margin-right: 10px;
`;

const showGreenStatus = ["active", PAID];
const showWhiteStatus = ["inactive", OPEN];
const showRedStatus = [VOID];

//Returns a coloured dot in front of the status. Green if active, grey if inactive
export const statusFormat = (val: string) => {
  const getColor = () => {
    if (val) {
      const status = val.toLowerCase();

      switch (status) {
        case showGreenStatus.find((x) => x === val):
          return "#34C300";
        case showWhiteStatus.find((x) => x === val):
          return WHITE;
        case showRedStatus.find((x) => x === val):
          return "#F00F3C";
        default:
          return WHITE;
      }
    }
  };

  const getBorderColor = () => {
    if (val && typeof val === "string") {
      const status = val.toLowerCase();

      switch (status) {
        case showGreenStatus.find((x) => x === val):
          return "#34C300";
        case showWhiteStatus.find((x) => x === val):
          return BLACK_LOW_EMPHASIS;
        case showRedStatus.find((x) => x === val):
          return "#F00F3C";
        default:
          return BLACK_LOW_EMPHASIS;
      }
    }
  };

  return (
    <StatusContainer>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Dot color={getColor()} borderColor={getBorderColor()} />
        <Typography variant={"body2"}>{capitalize(val)}</Typography>
      </div>
    </StatusContainer>
  );
};

export const invoiceStatusFormatter = (val: string, isRefund?: boolean) => {
  const getColor = () => {
    if (val) {
      const status = val.toLowerCase();

      switch (status) {
        case showGreenStatus.find((x) => x === val):
          return isRefund ? GREY_MEDIUM : GREEN;
        case showWhiteStatus.find((x) => x === val):
          return GREY_MEDIUM;
        case showRedStatus.find((x) => x === val):
          return "#F00F3C";
        default:
          return WHITE;
      }
    }
  };

  return (
    <div
      style={{
        borderRadius: "20px",
        backgroundColor: getColor(),
        padding: "0.4rem 1.2rem",
        display: "flex",
        alignItems: "center",
        width: "fit-content",
      }}
    >
      <p
        style={{
          color: showWhiteStatus.includes(val.toLowerCase()) ? PRIMARY_700 : isRefund ? PRIMARY_700 : WHITE,
          margin: 0,
        }}
      >
        {capitalize(isRefund ? "Refund" : val)}
      </p>
    </div>
  );
};

export const licenceStatusIcon = (val: string): React.JSX.Element | undefined => {
  switch (val) {
    case "created":
    case "draft":
      return <EditRounded />;
    case SUBMITTED:
      return <Inbox />;
    case REVIEWING:
    case APPROVED:
      return <FindInPageOutlined />;
    case ISSUED:
      return <Approval />;
    case ACTIVE:
      return <CheckRounded />;
    case DECLINED:
      return <DeleteOutline />;
    case RENEWING:
      return <AccessAlarmRounded />;
    case EXPIRING:
      return <UpdateDisabled />;
    case CANCELLED:
      return <CancelOutlined />;
    case REVOKED:
      return <ReportOutlined />;
    case OUTDATED:
      return <EventOutlined />;
    case EXPIRED:
      return <AlarmOffOutlined />;
    case ANY:
      return <WebOutlined />;
    case "placeHolder":
      return <WebOutlined sx={{ color: "transparent" }} />;
    default:
      return undefined;
  }
};

export const licenseStatusFormat = (val: any) => {
  return (
    <div className={classes.status_container}>
      <div style={{ display: "flex", alignItems: "center" }}>
        {React.isValidElement(licenceStatusIcon(val))
          ? React.cloneElement(licenceStatusIcon(val) as ReactElement, {
              color: "primary",
              style: {
                height: "18px",
                width: "18px",
                marginRight: "0.5rem",
              },
            })
          : null}
        <Typography variant={"body2"}>{capitalize(val)}</Typography>
      </div>
    </div>
  );
};

export const applicantFormat = (val: any) => {
  const [name, address] = val.split("<br/>");
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
        <Typography variant={"body2"}>{name}</Typography>
      </div>
      <div>
        <Typography className={"label"} variant="caption">
          {address}
        </Typography>
      </div>
    </div>
  );
};
