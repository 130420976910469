import React from "react";
import { Box, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { getDocCountDisplay, roundMb } from "@utils/index";

/**
 * Displays file size compared to the max file limit, and changes
 * color to show errors.
 * @param fileLength
 * @param fileSize - byte
 * @param maxFileSize - mb
 * @param justifyContent
 * @returns {JSX.Element}
 * @constructor
 */
export default function MaxDocumentDisplay({ fileLength, fileSize, justifyContent = "right", maxFileSize }) {
  const theme = useTheme();
  return (
    <Box display={"flex"} style={{ justifyContent: justifyContent }}>
      <Typography
        style={{
          color: roundMb(fileSize) > maxFileSize ? theme.palette.nonPalette.RED : theme.palette.blacks.BLACK_LOW_EMPHASIS,
          marginTop: 10,
        }}
        variant={"caption"}
      >
        {`${getDocCountDisplay(fileLength)} - ` + roundMb(fileSize) + `/${maxFileSize} MB`}
      </Typography>
    </Box>
  );
}
