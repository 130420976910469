// css imports
import "./assets/styles/index.scss";

import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import Application from "./ui";
import ReactGA from "react-ga4";
import { GA_TRACKING_ID } from "./config";
import Hotjar from "@hotjar/browser";

/**
 * Checks if the current environment is not "local" or "qa" does not exist in current app url:
 * - Initializes the GA and Hotjar
 * - Otherwise, if the environment is qa, Initializes Hotjar for QA
 */
if (process.env.REACT_APP_STAGE !== "local" && !window.location.href.includes("qa")) {
  // Initializing Google Analytics in prod environment
  ReactGA.initialize(GA_TRACKING_ID);

  // Initializing Hotjar in prod environment
  const siteId = 3595253;
  const hotjarVersion = 6;
  Hotjar.init(siteId, hotjarVersion, {
    debug: true,
  });
} else if (window.location.href.includes("qa")) {
  const siteId = 3595526;
  const hotjarVersion = 6;

  Hotjar.init(siteId, hotjarVersion, {
    debug: true,
  });
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <Application />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
