const STRINGS = {
  ERROR_FAILED_DATA: "There was an issue with retrieving your data. Please retry or refresh the page.",
  ERROR_FAILED_PROFILE: "There was an issue with getting your profile. Please retry or refresh the page.",
  ERROR_FAILED_PROFILE_ADDRESS: "There was an issue with getting your address information. Please try again.",
  ERROR_FAILED_PROFILE_PAYMENT: "There was an issue with getting your payment profile. Please try again.",
  ERROR_FAILED_RESOURCE: "Something went wrong. Please try again.",
  ERROR_FAILED_SUBMIT_CHANGES: "We were unable to save your changes. Please retry or refresh the page.",
};

export default STRINGS;

export const ownerTenant = [
  { label: "I'm the owner", value: true },
  { label: "I'm the tenant", value: false },
];

export const billingMethodOptions = [
  { label: "Email only", value: "Email only" },
  { label: "Email + Print", value: "Email + Print" },
  { label: "Print only", value: "Print only" },
];
