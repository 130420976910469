import React, { useMemo } from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import Divider from "../Divider";
import { BLACK_LOW_EMPHASIS, BLACK_MEDIUM_EMPHASIS, GREEN, GREY_DARK, ORANGE, PRIMARY_700, WHITE } from "@mui-theme/theme";
import classes from "./index.module.scss";
import { currency } from "@utils/index";
import { ACTIVE, INACTIVE_GROUP, ISSUED } from "@core/constants/licences";
import { abbreviator } from "@core/constants/province-states";
import { ApprovalOutlined } from "@mui/icons-material";

const Dot = styled.div`
  height: 15px;
  width: 15px;
  background-color: ${({ color }) => color};
  border-radius: 50%;
  display: inline-block;
  ${({ borderColor }) => `border: 2px solid ${borderColor}`};
  margin-right: 10px;
`;

const StyledCardContainer = styled.div`
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.OUTLINE};
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "480px")};
`;

const StyledCardHeader = styled.div`
  display: flex;
  padding: 1rem;
`;

const StyledCardBody = styled.div`
  padding: 2rem;
`;

const active = ["active", "issued"];
const inactive = ["expired", "cancelled", "declined"];
const inProgress = ["submitted", "reviewing", "approved"];
const draft = ["created", "draft", "saved"];
const theme = {
  active: {
    iconColor: GREEN,
    iconBorderColor: GREEN,
    label: "Active",
    labelColor: BLACK_MEDIUM_EMPHASIS,
  },
  inactive: {
    iconColor: WHITE,
    iconBorderColor: BLACK_LOW_EMPHASIS,
    label: "Inactive",
    labelColor: GREY_DARK,
  },
  inProgress: {
    iconColor: WHITE,
    iconBorderColor: BLACK_LOW_EMPHASIS,
    label: "Under review",
    labelColor: BLACK_MEDIUM_EMPHASIS,
  },
  draft: {
    iconColor: ORANGE,
    iconBorderColor: BLACK_LOW_EMPHASIS,
    label: "Created",
    labelColor: BLACK_MEDIUM_EMPHASIS,
  },
};

export const LicenseCard = ({ data, hideFee, originalData, fullWidth }) => {
  /**
   * Memo version of the status of BL
   * @type {string}
   */
  const status = useMemo(() => {
    if (data.status && typeof data.status === "string") {
      const status = data.status.toLowerCase();

      switch (true) {
        case active.indexOf(status) !== -1:
          return "active";
        case inactive.indexOf(status) !== -1:
          return "inactive";
        case inProgress.indexOf(status) !== -1:
          return "inProgress";
        case draft.indexOf(status) !== -1:
          return "draft";
        default:
          return "inactive";
      }
    }
    return "inactive";
  }, [data.status]);

  /**
   * Memo version of residency status
   * @type {unknown}
   */
  const residencyStatus = useMemo(() => {
    if (!data) return "Unknown";

    let status = "Non-resident";

    if (Object.keys(originalData ?? {}).includes("is_resident")) {
      if (originalData.is_resident) {
        status = "Resident";

        if (originalData.is_homebased) {
          status = "Home-based";
        }
      }
      return status;
    }

    if (data.is_resident) {
      status = "Resident";

      if (data.is_homebased) {
        status = "Home-based";
      }
    }

    return status;
  }, [data, originalData]);

  /**
   * Memo version of address section
   * @type {string}
   */
  const address = useMemo(() => {
    if (
      originalData?.business_address_street ||
      originalData?.business_address_street2 ||
      originalData?.business_address_city ||
      originalData?.business_address_province ||
      originalData?.business_address_country ||
      originalData?.business_address_postal_code ||
      originalData?.business_address_country
    ) {
      return (
        (originalData?.business_address_street ?? data.business_address_street) +
        ", " +
        ((originalData?.business_address_street2 ?? "") !== "" ? originalData?.business_address_street2 + ", " : "") +
        (originalData?.business_address_city ?? data.business_address_city) +
        ", " +
        abbreviator(originalData?.business_address_province ?? data.business_address_province)?.abbrev +
        ", " +
        (originalData?.business_address_postal_code ?? data.business_address_postal_code) +
        " " +
        ((originalData?.business_address_country ?? data.business_address_country) === "CA" ? "Canada" : "USA")
      );
    }
    return (
      data.business_address_street +
      ", " +
      (data.business_address_street2 !== "" ? data.business_address_street2 + ", " : "") +
      data.business_address_city +
      ", " +
      abbreviator(data.business_address_province)?.abbrev +
      ", " +
      data.business_address_postal_code +
      " " +
      (data.business_address_country === "CA" ? "Canada" : "USA")
    );
  }, [
    data.business_address_city,
    data.business_address_country,
    data.business_address_postal_code,
    data.business_address_province,
    data.business_address_street,
    data.business_address_street2,
    originalData?.business_address_city,
    originalData?.business_address_country,
    originalData?.business_address_postal_code,
    originalData?.business_address_province,
    originalData?.business_address_street,
    originalData?.business_address_street2,
  ]);

  const currTheme = theme[status];
  return (
    <StyledCardContainer fullWidth={fullWidth}>
      <StyledCardHeader>
        <ApprovalOutlined
          style={{
            marginTop: "auto",
            marginBottom: "auto",
            fontSize: "48px",
          }}
        />
        <div
          style={{
            marginTop: "auto",
            marginBottom: "auto",
            marginLeft: "0.5rem",
            overflow: "hidden",
          }}
        >
          <Typography variant={"h5"} color={PRIMARY_700} noWrap>
            {!!originalData?.business_name ? originalData?.business_name : data.business_name}
          </Typography>
        </div>
      </StyledCardHeader>
      <Divider />
      <StyledCardBody>
        <div style={{ display: "flex", marginRight: "0.5rem" }}>
          <Dot color={currTheme.iconColor} borderColor={currTheme.iconBorderColor} />
          <Typography color={currTheme.labelColor} style={{ marginTop: "auto", marginBottom: "auto" }}>
            {currTheme.label}
          </Typography>
        </div>
        <br />
        <div style={{ display: "flex" }}>
          <div
            style={{
              marginTop: "auto",
              marginBottom: "auto",
              overflow: "hidden",
              paddingRight: "0.5rem",
            }}
          >
            <Typography fontWeight={"500 !important"}>{"Business Licence"}</Typography>
            <Typography fontWeight={"500 !important"} noWrap>
              {residencyStatus}
            </Typography>
          </div>
          {!INACTIVE_GROUP.includes(data.status) ? (
            !hideFee && (
              <div className={classes.price_tag}>
                <Typography
                  variant="overline"
                  style={{
                    color: BLACK_LOW_EMPHASIS,
                    marginLeft: "auto !important",
                  }}
                >
                  {[ISSUED, ACTIVE].includes(data.status) ? "Balance" : "Estimate"}
                </Typography>
                <Typography fontWeight={"700 !important"}>{currency(data.balance)}</Typography>
              </div>
            )
          ) : (
            <></>
          )}
        </div>
        <br />
        <Typography>{address}</Typography>
      </StyledCardBody>
    </StyledCardContainer>
  );
};
