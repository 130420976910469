import React, { useEffect, useMemo, useState } from "react";
import typographyClasses from "@assets/styles/typography/typography.module.scss";
import Spacer from "@components/ui-kit/Spacer";
import Alerts from "@components/ui-kit/Alert";
import ManageAccountServiceContainer from "./widgets/ManageAccountServiceContainer";
import { useHistory } from "react-router-dom";
import { routes } from "@routes/routesData";

const ManageAccountTab = ({ id }: { id: string }) => {
  const [servicesDisabled, setServiceDisabled] = useState(true);
  const [showAlert, setShowAlert] = useState(false);

  const history = useHistory();

  const availableServices = useMemo(
    () => [
      {
        title: "Moving within the city",
        description:
          "If your home or business has an existing water " +
          "or waste utility account and you are moving to a new locat" +
          "ion in the City of Lacombe, you can send a request to chan" +
          "ge your service address.",
        neededItems: ["New service address", "Service end date at the current address", "Service start date at the new address"],
        callToActionTitle: "Move service address",
        callToAction: () => {
          history.push(routes.UTILITY_MOVING_CITY.path.replace(":id", id));
        },
      },
      {
        title: "Temporary disconnect",
        description:
          "You can request a temporary hold on your utilities " +
          "account by specifying the start and end dates. Minimum " +
          "account disconnection time is 60 days.",
        neededItems: [
          "Start and end dates of the vacancy",
          "Reason for the temporary hold",
          "Note: There may be a $35 fee for disconnecting and reconnection",
        ],
        callToActionTitle: "Request temporary disconnect",
        callToAction: () => {
          history.push(routes.UTILITY_DISCONNECT.path.replace(":id", id));
        },
      },
      {
        title: "Cancel your account",
        description: "You can request to cancel your utilities account, but please be aware that this action cannot be undone.",
        neededItems: ["End date at your service address", "Reason for cancellation"],
        callToActionTitle: "Cancel utilities account",
        callToAction: () => {
          history.push(routes.UTILITY_CANCEL_ACCOUNT.path.replace(":id", id));
        },
      },
    ],
    [history, id],
  );

  useEffect(() => {
    //Todo - Check if services are available to use
    setServiceDisabled(false);
    //Todo - Show the alert if there are services under review
    setShowAlert(false);
  }, []);

  return (
    <>
      <p className={typographyClasses.h1}>Manage Account</p>
      <Spacer />
      {showAlert ? (
        <>
          <Alerts
            title={"Your request is under review"}
            body={
              "Your submitted request is being reviewed by City Staff. " +
              "If you have any questions, please don't hesitate to call (403) 782-6666."
            }
            variant={"info"}
          />
          <Spacer />
        </>
      ) : null}
      <ManageAccountServiceContainer availableServices={availableServices} servicesDisabled={servicesDisabled} />
    </>
  );
};

export default ManageAccountTab;
