import React from "react";
import Routes from "./routes/Routes";
import { StyledEngineProvider, ThemeProvider as MuiThemeProvider } from "@mui/material";
import { muiV5, theme } from "@mui-theme/theme";
import { ThemeProvider } from "styled-components";
import { AuthContextProvider } from "@contexts/AuthContext";
import { NotificationContextProvider } from "@contexts/NotificationContext";
import "@assets/styles/extended-theme";
import { SidebarContextProvider } from "@contexts/SidebarContext";
import { FilterContextProvider } from "@contexts/FilterContext";
import { TableSettingContextProvider } from "@contexts/TableSettingContext";
import { PaymentProfileContextProvider } from "@contexts/PaymentProfileContext";
import { BrowserRouter as Router } from "react-router-dom";
import NotificationBarPlaceholder from "./components/ui-kit/NotificationBarPlaceholder";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Provider as ReduxProvider } from "react-redux";
import ReduxStore from "./redux/store";

const compose = (providers: any[], children: any) =>
  providers.reduce((acc, [Provider, value]) => {
    if (value?.isRedux) {
      return <Provider store={value.store}>{acc}</Provider>;
    }
    return <Provider theme={value}>{acc}</Provider>;
  }, children);

const providers = [
  [ThemeProvider, theme],
  [MuiThemeProvider, muiV5],
  [AuthContextProvider, null],
  [NotificationContextProvider, null],
  [SidebarContextProvider, null],
  [FilterContextProvider, null],
  [TableSettingContextProvider, null],
  [PaymentProfileContextProvider, null],
  [ReduxProvider, { isRedux: true, store: ReduxStore }],
];

/**
 * Root component of the application
 * @constructor
 */
function Application() {
  return compose(
    providers,
    <NotificationContextProvider>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <StyledEngineProvider injectFirst={true}>
          <Router>
            <NotificationBarPlaceholder />
            <Routes />
          </Router>
        </StyledEngineProvider>
      </LocalizationProvider>
    </NotificationContextProvider>,
  );
}

export default Application;
