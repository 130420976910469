import React, { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import { Box, useTheme } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { addEllipses, getDocCountDisplay, roundMb } from "@utils/index";
import IconButton from "@mui/material/IconButton";
import { CloseRounded } from "@mui/icons-material";

//Constants
const TOTAL_LIMIT = 64 * 1024 * 1024; // 64MB

//Styles
const Container = styled(Box)`
  -webkit-transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  -moz-transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  border: 2px dashed ${({ error, theme }) => (!error ? theme.palette.primary[200] : theme.palette.warning)};
  border-radius: 10px;
  background-color: ${({ theme }) => theme && theme.palette.primary[50]};
  justify-content: center;
  align-items: center;
  padding: 2.6rem;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme && theme.palette.primary[100]};
  }

  &.dragged-file-over {
    background-color: ${({ theme }) => theme && theme.palette.primary[100]};
  }
`;

const FileContainer = styled.div`
  margin: 1rem 0;
`;

const dropZoneBoxId = "drop-zone-box";

const draggedFileOverClassName = "dragged-file-over";

/**
 * File uploader that supports drag and drop
 * @param watchError
 * @param numOfFiles
 * @param fileTitle
 * @param files
 * @param setFiles
 * @param maxFiles
 * @param maxSize
 * @returns {JSX.Element}
 * @constructor
 */
export default function FileUpload({ numOfFiles, fileTitle, files, setFiles, error, setErrorFiles, setError }) {
  //Holds a reference to the input component
  const fileInputRef = useRef(null);

  const theme = useTheme();

  const [fileCount, setFileCount] = useState(numOfFiles);

  //Checks file list for any errors such as exceeding the max file size or attempting
  //to upload more files than allowed
  const hasErrors = (newFiles) => {
    //Toggles to true if an error is found
    let err = false;

    let count = fileCount;

    //Increments the count so the component keeps track of the number of files being added
    newFiles.forEach(() => {
      count++;
    });

    setFileCount(count);

    //checks all files to see if they exceed the max file size
    // newFiles.forEach((file) => {
    //   if (file.size > maxSize) {
    //     setError(`File size cannot exceed ${roundMb(maxSize)} MB`);
    //     err = true;
    //   }
    // });
    if (Number(getNewMbFiles(newFiles)) + Number(getMb()) > Number(roundMb(TOTAL_LIMIT))) {
      setErrorFiles(`Total file size cannot exceed ${roundMb(TOTAL_LIMIT)} MB`);
      err = true;
    }

    //If a file was not added due to an error, updates the count to represent the correct number of currently selected files
    if (err) {
      newFiles.forEach(() => {
        count--;
      });
    }

    setFileCount(count);

    return err;
  };

  const getMb = () => {
    let size = 0;
    files.forEach(({ file }) => {
      size += file.size;
    });
    return roundMb(size);
  };

  const getNewMbFiles = (newFiles) => {
    let size = 0;
    newFiles.forEach((file) => {
      size += file.size;
    });
    return roundMb(size);
  };

  //Final step once a file has been added from the handle change method or the handle drop method
  const confirmChange = (newFiles) => {
    let err = hasErrors(newFiles);

    //If there is no errors, proceed
    if (!err) {
      //Convert the file array to an array of objects to fix issues with uploading to formik
      const objectFiles = newFiles.map((file) => ({ file: file }));

      setFiles([...files, ...objectFiles]);
      setErrorFiles(null);
      setError(null);
    }
  };

  useEffect(() => {
    setFileCount(numOfFiles);
  }, [numOfFiles]);

  const handleChange = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let newFiles = [...e.currentTarget.files];

    confirmChange(newFiles);
  };

  /**
   * Handles drag enter event over the drop-zone:
   * - attaches and detaches [draggedFileOverClassName] class to the box element for changing the background color
   * @type {(function(DragEvent<HTMLLabelElement>): void)|*}
   */
  const handleDragEnter = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    let dropZoneBoxElement = document.getElementById(dropZoneBoxId);
    dropZoneBoxElement.classList.add(draggedFileOverClassName);
    setTimeout(() => {
      dropZoneBoxElement.classList.remove(draggedFileOverClassName);
    }, 2000);
  }, []);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    //Copies the file data
    e.dataTransfer.dropEffect = "copy";
  }, []);

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    //Files taken from drop effect
    let newFiles = [...e.dataTransfer.files];

    confirmChange(newFiles);
  };

  //Removes a file from the local files array
  const handleFileRemove = (index) => {
    const newFileArray = files.filter((value, i) => index !== i);

    setFiles([...newFileArray]);

    //Updates the count, so we get the correct number of selected files
    let count = fileCount - 1;
    setFileCount(count);
  };

  return (
    <div>
      <label
        htmlFor={"mandatoryFile"}
        tabIndex={0}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            fileInputRef.current.click();
          }
        }}
        onDrop={(e) => handleDrop(e)}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
      >
        <Container error={error} id={dropZoneBoxId}>
          <Typography color={theme.palette.blacks.BLACK_MEDIUM_EMPHASIS} align={"center"}>
            Drop here or <span style={{ color: theme.palette.primary[200] }}>browse a file</span>
          </Typography>
          <input type={"file"} id={"mandatoryFile"} multiple hidden onChange={handleChange} ref={fileInputRef} />
        </Container>
        {error && <Typography color={theme.palette.nonPalette.RED}>{error}</Typography>}
      </label>
      <FileContainer>
        {files &&
          files.map((value, index) => (
            <div key={index} style={{ marginTop: 5 }}>
              <Tooltip title={`${value.file.name}`} placement="top">
                <ItemFile fileTitle={fileTitle} value={value} index={index} handleFileRemove={handleFileRemove} />
              </Tooltip>
            </div>
          ))}
      </FileContainer>
      <div
        style={{
          display: "flex",
          justifyContent: "right",
          marginTop: 5,
          marginRight: 5,
        }}
      >
        <Typography style={{ color: theme.palette.blacks.BLACK_LOW_EMPHASIS }} variant={"caption"}>
          {`${getDocCountDisplay(files.length)} - ` + getMb() + "/" + roundMb(TOTAL_LIMIT, 0) + " MB"}
        </Typography>
      </div>
    </div>
  );
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Column = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

//Displays the file as a list item
const ItemFile = forwardRef(({ handleFileRemove, value, index }, ref) => {
  const theme = useTheme();

  return (
    <Row>
      <IconButton
        style={{ width: 40, height: 40, marginRight: "1rem" }}
        onClick={() => {
          handleFileRemove(index);
        }}
        size="large"
      >
        <CloseRounded />
      </IconButton>
      <Column>
        <Typography
          style={{
            wordWrap: "break-word",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          fontWeight={"500"}
        >
          {addEllipses(value.file.name, 65)}
        </Typography>
        <Typography variant={"body2"} style={{ color: theme.palette.blacks.BLACK_MEDIUM_EMPHASIS }}>
          {roundMb(value.file.size) + " MB"}
        </Typography>
      </Column>
    </Row>
  );
});
