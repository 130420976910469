import React from "react";
import styled from "styled-components";
import { default as MuiTypography } from "@mui/material/Typography";
import PropTypes from "prop-types";

//TODO: remove this component so that Typography is imported from MUI and styles are override by theme
const StyledTypography = styled(MuiTypography)`
  & {
    color: ${({ theme }) => theme.textColor};
  }

  && {
    color: ${({ color }) => color};
    margin: ${({ margin }) => margin};
    padding: ${({ padding }) => padding};
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    font-size: ${({ fontSize }) => fontSize};
    font-weight: ${({ fontWeight }) => fontWeight};
    white-space: ${({ whiteSpace }) => whiteSpace};
    overflow: ${({ overflow }) => overflow};
    text-transform: ${({ textTransform }) => textTransform};
    text-overflow: ${({ textOverflow }) => textOverflow};
  }
`;

const Decimals = styled.span`
  font-size: 75%;
  margin-bottom: auto;
`;

const FloatContainer = styled.div`
  align-items: flex-start;
  display: flex;
`;

const boldVariants = ["h1", "h2", "h3", "h4", "h5", "h6", "button"];

export default function Typography({ children, color, variant, fontWeight, ...rest }) {
  const isBold = boldVariants.includes(variant);
  let splitFloatStrings = null;

  if (variant === "float" && (typeof children === "number" || typeof children === "string")) {
    splitFloatStrings = children.toString().split(".");
  }

  return (
    <StyledTypography
      color={color}
      variant={variant === "float" ? undefined : variant}
      fontWeight={fontWeight || (isBold ? "bold" : "normal")}
      {...rest}
    >
      {!splitFloatStrings ? (
        children
      ) : (
        <FloatContainer>
          {splitFloatStrings[0]}
          <Decimals>.{splitFloatStrings[1] || "00"}</Decimals>{" "}
        </FloatContainer>
      )}
    </StyledTypography>
  );
}

Typography.propTypes = {
  align: PropTypes.oneOf(["inherit", "left", "center", "right", "justify"]),
  color: PropTypes.string,
  display: PropTypes.oneOf(["initial", "block", "inline"]),
  noWrap: PropTypes.bool, // truncate with a text overflow ellipsis
  gutterBottom: PropTypes.bool, // Add margin to bottom
  paragraph: PropTypes.bool, // Add margin to bottom
  variant: PropTypes.oneOf([
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "subtitle1",
    "subtitle2",
    "body1",
    "body2",
    "caption",
    "button",
    "overline",
    "float",
    "inherit",
  ]),
  padding: PropTypes.string,
  margin: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
