import base from "./axiosInstance";
import { manageSession } from "@utils/manageSession";

export const getLicenceReports = async (type: string, param: any) => {
  const token = manageSession.getSessionInfo()?.userToken;
  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    const params = {
      ...param,
      subtype: type,
      filters: JSON.stringify(param.filters),
    };
    const response = await base.get(`/reporting/licences`, { params });
    return response.data;
  } catch (error) {
    throw new Error("Something went wrong.");
  }
};

export const getTransactionReports = async (param: any) => {
  const token = manageSession.getSessionInfo()?.userToken;
  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    const params = {
      ...param,
      filters: JSON.stringify(param.filters),
    };
    const response = await base.get(`/reporting/transactions`, { params });
    return response.data;
  } catch (error) {}
};

export const getInvoiceReports = async (param: any) => {
  const token = manageSession.getSessionInfo()?.userToken;
  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    const params = {
      ...param,
      filters: JSON.stringify(param.filters),
    };
    const response = await base.get(`/reporting/invoices`, { params });
    return response.data;
  } catch (error) {}
};
