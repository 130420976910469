import React from "react";
import Typography from "@mui/material/Typography";
import Modal from "@components/ui-kit/Modal";

const OnContactCustomerModal = ({ open, setOpen }) => {
  return (
    <Modal title={"Contact customer"} open={open} onClose={() => setOpen(false)}>
      <div style={{ maxWidth: 400, padding: "2rem" }}>
        <Typography variant={"body1"} style={{ lineHeight: "22.4px" }}>
          This account is currently not linked to a personal account and can’t be reached by email. You can link it to a personal
          account or contact the customer by phone via their account details.
        </Typography>
      </div>
    </Modal>
  );
};

export default OnContactCustomerModal;
