export const AddressCountry = {
  US: "US",
  CA: "CA",
};

export const LOWER = "lower";
export const UPPER = "upper";

export const LACOMBE_CITY_ID = "dc9a56b4-f11c-4d71-89df-91ac898b2ee6";

export const DEMO_CITY_ID = "e15d7817-c970-40a0-825a-8b74f07c3645";
