import React, { useContext, useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import SessionWatcher from "@components/ui-kit/SessionWatcher";
import { routes } from "./routesData";
import Main from "@components/ui-kit/Main";
import { AuthContext } from "@contexts/AuthContext";
import Header from "@components/ui-kit/Header";
import Sidebar from "@components/ui-kit/left-side-bar/Sidebar";
import { EditingProvider } from "@contexts/EditingContext";
import ReactGA from "react-ga4";
import ConfirmationDialog from "@components/modals/confirmation-dialog";

export default function Routes() {
  const { authenticate, getDefaultURL } = useContext(AuthContext);
  const location = useLocation();

  /**
   * With each change in the location, sends a page-view type report to GA
   */
  useEffect(() => {
    if (ReactGA.isInitialized) {
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname + location?.search,
        title: "Page View",
      });
    }
  }, [location]);

  return (
    <EditingProvider>
      <Sidebar showHeader={true} />
      <Header />
      <Main>
        <Switch>
          {Object.entries(routes).map(([key, route]) => {
            return (
              <Route
                key={key}
                path={route.path}
                exact={route.isExact}
                render={(routeProps) => {
                  return route.skipAuth || authenticate(route.authType !== undefined ? route.authType : null, false) ? (
                    <route.component {...routeProps} routeData={route} />
                  ) : authenticate(null, true) ? (
                    <Redirect to={getDefaultURL()} />
                  ) : (
                    <Redirect
                      to={{
                        pathname: "/login",
                        search: "?redirect=" + encodeURI(location.pathname + location.search),
                      }}
                    />
                  );
                }}
              />
            );
          })}
        </Switch>
        <ConfirmationDialog />
      </Main>
      <SessionWatcher />
      {/*Un-comment when mobile app is ready*/}
      {/*<MobileAppModal />*/}
    </EditingProvider>
  );
}
