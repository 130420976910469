import * as yup from "yup";
import { formattingRegex, validationRegex } from "@utils/regexes";

const OWNER_MANAGER_ERROR = "Must have at least one owner/manager.";

export const licenceValidations = {
  business_name: yup.string().required("Business name is required."),
  business_operation_description: yup
    .string()
    .max(140, "Cannot exceed 140 characters.")
    .required("Cannot leave description empty."),
  is_homebased: yup.bool(),
  is_included_in_online_business_directory: yup.bool(),
  is_property_owner: yup.bool(),
  number_of_vehicles: yup.number().when(["is_resident", "has_parking_for_customers", "is_homebased"], {
    is: (is_resident, has_parking_for_customers, is_homebased) => is_resident && has_parking_for_customers && is_homebased,
    then: yup.number().optional(),
  }),
  parking_place: yup.string().when(["is_resident", "has_parking_for_customers", "is_homebased"], {
    is: (is_resident, has_parking_for_customers, is_homebased) => is_resident && has_parking_for_customers && is_homebased,
    then: yup.string().optional(),
  }),
  property_owner_name: yup.string().when(["is_resident", "is_homebased"], {
    is: (is_resident, is_homebased) => is_resident && is_homebased,
    then: yup.string().optional(),
  }),
  business_address_street: yup.string().required("Address is required."),
  business_address_city: yup.string().required("City is required."),
  business_address_province: yup
    .string()
    .when(["business_address_country"], {
      is: "CA",
      then: yup.string().required("Province is required."),
    })
    .when(["business_address_country"], {
      is: "US",
      then: yup.string().required("State is required."),
    }),
  business_address_country: yup.string().required("Country is required."),
  business_address_postal_code: yup
    .string()
    .when(["business_address_country"], {
      is: "CA",
      then: yup
        .string()
        .required("Postal code is required.")
        .max(10, "Postal code is too long.")
        .matches(/^[a-zA-Z][0-9][a-zA-Z]\s?[0-9][a-zA-Z][0-9]$/, "Must be valid postal code."),
    })
    .when(["business_address_country"], {
      is: "US",
      then: yup
        .string()
        .required("ZIP code is required.")
        .max(10, "ZIP code is too long.")
        .matches(/^\d{5}(-\d{4})?$/, "Must be valid zip code."),
    }),
  is_business_address_same_as_mailing_address: yup.bool(),
  mailing_address_street: yup.string().required("Mailing address is required."),
  mailing_address_city: yup.string().required("City is required."),
  mailing_address_province: yup
    .string()
    .when(["mailing_address_country"], {
      is: "CA",
      then: yup.string().required("Province is required."),
    })
    .when(["mailing_address_country"], {
      is: "US",
      then: yup.string().required("State is required."),
    }),
  mailing_address_country: yup.string().required("Country is required.").oneOf(["CA", "US"], "Country must be CA or US."),
  mailing_address_postal_code: yup
    .string()
    .when(["mailing_address_country"], {
      is: "CA",
      then: yup
        .string()
        .required("Postal code is required.")
        .max(10, "Postal code is too long.")
        .matches(/^[a-zA-Z][0-9][a-zA-Z]\s?[0-9][a-zA-Z][0-9]$/, "Must be valid postal code."),
    })
    .when(["mailing_address_country"], {
      is: "US",
      then: yup
        .string()
        .required("ZIP code is required.")
        .max(10, "ZIP code is too long.")
        .matches(/^\d{5}(-\d{4})?$/, "Must be valid zip code."),
    }),
  phone_number: yup
    .string()
    .required("Phone number is required.")
    .matches(formattingRegex.phone, "Phone number must be 10 digits."),
  email: yup.string().matches(validationRegex.email, "Must be a valid email.").required("Email is required."),
  business_manager: yup.array().min(1, OWNER_MANAGER_ERROR).required(OWNER_MANAGER_ERROR),
};
