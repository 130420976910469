import { formatCurrency, formatDate, formatDateShort, formatDateTime } from "@utils/index";

export const ActionItemTypes = {
  SHIP_DOG_TAG: "Ship dog tag",
  CUSTOMER_VERIFICATION: "Customer verification",
  LINK_ACCOUNT: "Account link",
  LICENSE_TASK: "License task",
  PAY_BY_INVOICE: "Pay by invoice",
};

export const FilterType = {
  SEARCH: "SEARCH",
  CHECKBOX: "CHECKBOX",
  RADIO: "RADIO",
  DATE_PICKER: "DATE_PICKER",
  CHIP: "CHIP",
  SELECT: "SELECT",
  CURRENCY_TEXT_FIELD: "CURRENCY_TEXT_FIELD",
  BOOLEAN: "BOOLEAN",
};

export const DataFormat = {
  CURRENCY: formatCurrency,
  DATE: formatDate,
  DATE_SHORT: formatDateShort,
  DATETIME: formatDateTime,
};

export const INPUT_FORMATS = {
  EMAIL: "EMAIL",
  NAME: "NAME",
};

export const ModuleTypes = {
  businessLicence: "businessLicence",
  utilities: "utilities",
  propertyTaxes: "propertyTaxes",
};

export const CREDIT_CARD_TYPES = {
  VISA: "visa",
  MASTERCARD: "mastercard",
};
