import React, { useContext, useEffect, useState } from "react";
import { getTransactionReports } from "@core/apis/report";
import {
  DataGrid,
  gridClasses,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { PaymentsOutlined } from "@mui/icons-material";
import { Box, FormControl, InputAdornment, MenuItem, OutlinedInput, SelectChangeEvent, Skeleton } from "@mui/material";
import { BLUE, PRIMARY_200, PRIMARY_50 } from "mui-theme/theme";
import Select from "@mui/material/Select";
import { formatCurrency } from "@utils/index";
import { NotificationContext } from "ui/contexts/NotificationContext";

const columns: GridColDef[] = [
  {
    field: "name",
    headerName: "NAME",
    editable: false,
    flex: 1,
    filterable: false,
    valueGetter: ({ value }) => value.value,
  },
  {
    field: "amount",
    headerName: "AMOUNT",
    editable: false,
    flex: 1,
    filterable: false,
    valueFormatter: ({ value }) => formatCurrency(value),
  },
  {
    field: "payment_method",
    headerName: "PAYMENT METHOD",
    editable: false,
    flex: 1,
    filterable: false,
  },
  {
    field: "created_at",
    headerName: "TRANSACTION DATE",
    editable: false,
    flex: 1,
    type: "date",
    renderCell: (params) => {
      if (params.value) {
        const date = new Date(params.value);
        const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(
          date.getDate(),
        ).padStart(2, "0")}`;
        return formattedDate;
      }
      return null;
    },
    valueGetter: ({ value }) => value && new Date(value),
  },
  {
    field: "transaction_number",
    headerName: "TRANSACTION NUMBER",
    editable: false,
    flex: 1,
  },
];

const Report = () => {
  const [data, setData] = useState([]);
  const [dataLoading, setDataLoadng] = useState(false);
  const [paymentMethodValue, setPaymentMethodValue] = useState("");
  const { handleError } = useContext(NotificationContext) || {};
  const [filters, setFilters] = useState({
    total: true,
    sortBy: "lastUpdated",
    ascending: false,
    filters: {},
    offset: 0,
    limit: 1000000,
  });
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    setFilters((prevState) => ({
      ...prevState,
      filters: {
        payment_method: paymentMethodValue,
      },
    }));
    setRefresh(!refresh);
    //eslint-disable-next-line
  }, [paymentMethodValue]);

  function CustomToolbar() {
    const handlePaymentMethodChange = (event: SelectChangeEvent<unknown>) => {
      if (event.target.value === "any") {
        setPaymentMethodValue("");
        return;
      }
      setPaymentMethodValue(event.target.value as string);
    };

    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton
          style={{
            color: PRIMARY_200,
          }}
        />
        <Box>
          <FormControl
            sx={{
              ".MuiOutlinedInput-root": {
                paddingLeft: "0.3rem !important",
              },
            }}
          >
            <Select
              size="small"
              input={
                <OutlinedInput
                  startAdornment={
                    <InputAdornment position="start">
                      <PaymentsOutlined
                        sx={{
                          color: BLUE,
                        }}
                      />
                    </InputAdornment>
                  }
                />
              }
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  border: 0,
                },

                ".MuiInputBase-input ": {
                  padding: "0.3rem !important",
                  color: BLUE,
                },
                ".MuiSelect-iconOutlined": {
                  display: "none",
                },
                ":hover": {
                  backgroundColor: PRIMARY_50,
                },
              }}
              value={paymentMethodValue === "" ? "Payment Method" : paymentMethodValue}
              labelId="demo-simple-select-label"
              id="demo-select-small"
              onChange={handlePaymentMethodChange}
            >
              <MenuItem disabled value="Payment Method">
                Payment Method
              </MenuItem>
              <MenuItem value={"CC"}>CC</MenuItem>
              <MenuItem value={"MAN"}>MAN</MenuItem>
              <MenuItem value={"IMP"}>IMP</MenuItem>
              <MenuItem value={"RFD"}>RFD</MenuItem>
              <MenuItem value={"any"}>Any</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <GridToolbarFilterButton
          style={{
            color: PRIMARY_200,
          }}
        />
        <GridToolbarExport
          style={{
            color: PRIMARY_200,
          }}
          csvOptions={{
            fileName: "Cocoflo Business Transaction Report",
          }}
        />
      </GridToolbarContainer>
    );
  }

  const fetchData = async () => {
    try {
      setDataLoadng(true);
      setData(await getTransactionReports(filters));
    } catch (error) {
      handleError("Something went wrong.");
    } finally {
      setDataLoadng(false);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchData();
    })();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  return dataLoading ? (
    <Skeleton variant={"rectangular"} height={500} />
  ) : (
    <DataGrid
      loading={dataLoading}
      rows={data ? data : []}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 10,
          },
        },
      }}
      pageSizeOptions={[10, 25]}
      disableRowSelectionOnClick
      style={{ borderColor: "transparent" }}
      slots={{ toolbar: CustomToolbar }}
      localeText={{
        toolbarFilters: "Time",
      }}
      sx={{
        [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
          outline: "none",
        },
        [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
          outline: "none",
        },
      }}
    />
  );
};

export default Report;
