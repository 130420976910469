import React from "react";
import Modal from "@components/ui-kit/Modal";
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import { Form, Formik } from "formik";
import TextField from "@mui/material/TextField";
import { Box, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import * as yup from "yup";
import Spacer from "@components/ui-kit/Spacer";

const ItemModal = styled(Modal)`
  && {
    max-width: 400px;
  }

  .MuiDialog-paperScrollPaper {
    padding: 2rem 2rem 1rem 2rem;
  }

  .MuiPaper-rounded {
    border-radius: 10px;
  }
`;

const ConfirmButton = styled(Button)`
  &&:hover {
    background-color: ${({ theme }) => theme.palette.primary[100]};
  }

  && .MuiTouchRipple-child {
    background-color: ${({ theme }) => theme.palette.primary[100]};
  }
`;

const validationSchema = yup.object({
  name: yup.string().required("Must have a valid name"),
});

export default function RenameModal({ data, renameFile, handleClose, open }) {
  const theme = useTheme();

  if (!data) return null;

  const handleSubmit = (values) => {
    renameFile(data.id, values.name);
    handleClose();
  };

  return (
    <ItemModal open={open} onClose={handleClose}>
      <Typography variant={"body1"} fontWeight={"700"}>
        Rename file
      </Typography>
      <Spacer amount={0.5} />
      <Formik
        enableReinitialize={true}
        initialValues={{ name: data.name }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ errors, values, handleChange }) => {
          return (
            <Form noValidate>
              <TextField
                label={"Name"}
                style={{ width: 295 }}
                name={"name"}
                value={values.name}
                error={Boolean(errors.name)}
                helperText={errors.name}
                onChange={handleChange}
              />
              <Box style={{ display: "flex", justifyContent: "right" }} mt={1}>
                <Button
                  variant={"text"}
                  onClick={() => {
                    handleClose();
                  }}
                >
                  <Typography fontWeight={"700"} color={theme.palette.primary[500]}>
                    Cancel
                  </Typography>
                </Button>
                <ConfirmButton variant={"text"} type={"submit"}>
                  <Typography color={"primary"} fontWeight={"700"}>
                    Rename
                  </Typography>
                </ConfirmButton>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </ItemModal>
  );
}
