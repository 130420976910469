import base from "./axiosInstance";
import { manageSession } from "@utils/manageSession";
import { jwtDecode } from "jwt-decode";
import config from "./_config";

export const userLogout = async () => {
  try {
    const response = await base.delete("/user/logout", {});
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const forgot_password = async (email: string) => {
  try {
    const data = {
      email: email,
      cityId: config.cityId,
    };
    const response = await base.post(`/user/forgot-password`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const reset_password = async (token: string, newPassword: string) => {
  try {
    const response = await base.post("/user/reset-password", {
      token,
      newPassword,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

//TODO: create type for the input params as well as the decoded
export const get_users = async (params: any) => {
  const token = manageSession.getSessionInfo()?.userToken;
  const decoded: any = jwtDecode(token);
  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    const response = await base.get(`/user/${decoded.cityId}/summaries`, {
      params,
    });
    return response.data;
  } catch (error) {}
};

// TODO: check the url of this api
export const get_users_export = async (params: any) => {
  const token = manageSession.getSessionInfo()?.userToken;

  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    const response = await base.get("/userexport", { params });
    return response.data;
  } catch (error) {}
};

export const user_register = async ({ type, name, firstName, lastName, email, phone, password, termIds }: any) => {
  try {
    const response = await base.post("/user/register", {
      cityId: config.cityId,
      type,
      name,
      firstName: firstName,
      lastName: lastName,
      phone,
      email,
      password,
      termIds,
    });
    return response.data;
  } catch (error) {
    // @ts-ignore
    if (error.response.status === 409) {
      throw new Error("This email address has already been registered. Please use a different email.");
    }
    throw error;
  }
};

export const getUser = async (id: string) => {
  const token = manageSession.getSessionInfo()?.userToken;
  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    const response = await base.get(`/user/${id}`);
    return response.data;
  } catch (error) {}
};

export const addAdmin = async (data: object) => {
  const token = manageSession.getSessionInfo()?.userToken;
  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    const response = await base.post(`/user/admin`, data);
    return response.data;
  } catch (error: any) {
    throw new Error(error.response.data.message || error.message);
  }
};

export const addUser = async (data: object) => {
  try {
    const response = await base.post(`/user/create`, data);
    return response.data;
  } catch (error: any) {
    throw new Error(error.response.data.message || error.message);
  }
};
