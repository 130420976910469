import React from "react";
import MissingScreen from "@screens/sample-screen";
import Dashboard from "@screens/admin/dashboard";
import LoginScreen from "@screens/general/Login";
import TwoFactorAuthentication from "@screens/general/TwoFactorAuthentication/TwoFactorAuthentication";
import ResetPasswordScreen from "@screens/general/ResetPassword";
import SendResetLink from "@screens/general/SendResetLink";
import Admins from "@screens/admin/people/admins";
import Users from "@screens/admin/people/users";
import { AdminLicences } from "@screens/admin/licences-list";
import LicenseDetail from "@screens/admin/licence-details";
import { ADMIN_AUTH_TYPE, USER_AUTH_TYPE } from "@core/constants/authTypes";
import Register from "@screens/general/Register";
import BusinessLicenseCreation from "@screens/my-city-hall/licences/business-licences/business-licence-creation";
import LicenseDetails from "@screens/my-city-hall/licences/business-licences/business-licence-details-screen";
import Checkout from "@screens/my-city-hall/payment/checkout";
import CreditCardAddEdit from "@screens/my-city-hall/payment/credit-card/CreditCardAddEdit";
import LandingPage from "@screens/my-city-hall/landing-page";
import LinkUtility from "@screens/my-city-hall/utilities/link-utility";
import UtilitiesScreen from "@screens/admin/utilities";
import UtilityDetails from "@screens/my-city-hall/utilities/utility-details";
import Welcome from "@screens/my-city-hall/welcome";
import LinkLicence from "@screens/my-city-hall/licences/link-licence";
import UtilitiesDetailScreen from "@screens/admin/utilities-detail";
import {
  AccountCircleOutlined,
  Approval,
  DynamicFeedOutlined,
  GiteOutlined,
  SummarizeOutlined,
  SupervisorAccountOutlined,
  WaterDamageOutlined,
} from "@mui/icons-material";
import Missing from "@screens/general/Missing";
import Unauthorized from "@screens/general/Unauthorized";
import InvoiceEditorScreen from "@screens/admin/invoice-editor";
import { matchPath } from "react-router-dom";
import PropertyTaxesScreen from "@screens/admin/property-taxes";
import PropertyTaxDetails from "@screens/my-city-hall/property-tax/property-tax-details";
import TemporaryDisconnect from "@screens/my-city-hall/utilities/temporary-disconnect";
import MoveTransferUtilityWizard from "@screens/my-city-hall/utilities/move-transfer-utility";
import Reporting from "@screens/admin/reporting";
import CancelUtilityAccountWizard from "@screens/my-city-hall/utilities/cancel-account";
import LinkPropertyTax from "@screens/my-city-hall/property-tax/link-property-tax";
import PropertyTaxesDetailScreen from "@screens/admin/property-taxes-detail";
import EditUtilityServicesWizard from "@screens/my-city-hall/utilities/edit-services-wizard";
import ManagePaymentMethods from "@screens/my-city-hall/manage-payment-methods";

interface Route {
  authType?: string;
  component: React.ComponentType<any>;
  hideBreadcrumbs?: boolean;
  hideHeader?: boolean;
  hideSidebar?: boolean;
  isExact: boolean;
  path: string;
  title: string;
  skipAuth?: boolean;
  codePath?: string;
  hideIcons?: boolean;
  hideBreadCrumbs?: boolean;
  icon?: string | JSX.Element;
}

interface Routes {
  [key: string]: Route;
}

export const routes: Routes = {
  DEFAULT: {
    authType: USER_AUTH_TYPE,
    component: LandingPage,
    hideBreadcrumbs: true,
    isExact: true,
    path: "/",
    title: "Home",
  },
  REGISTER: {
    path: "/register",
    title: "Registration",
    isExact: true,
    component: Register,
    skipAuth: true,
    hideHeader: true,
  },
  RESET_PASSWORD: {
    path: "/reset-password",
    title: "Reset Password",
    isExact: true,
    component: ResetPasswordScreen,
    skipAuth: true,
    hideHeader: true,
  },
  TWO_FACTOR_AUTHENTICATION: {
    path: "/two-factor-authentication",
    title: "Two Factor Authentication",
    isExact: true,
    hideHeader: true,
    component: TwoFactorAuthentication,
    skipAuth: true,
  },
  BUSINESS_LICENCE_CREATION: {
    authType: USER_AUTH_TYPE,
    path: "/licences/business-licence/create",
    title: "Business Licence",
    isExact: false,
    hideHeader: false,
    component: MissingScreen,
  },
  CHECKOUT: {
    authType: USER_AUTH_TYPE,
    path: "/checkout",
    title: "Checkout",
    isExact: false,
    hideHeader: false,
    component: Checkout,
  },
  MANAGE_PAYMENT_METHODS: {
    authType: USER_AUTH_TYPE,
    path: "/manage-payment-methods",
    title: "Manage Payment Methods",
    isExact: true,
    hideHeader: false,
    component: ManagePaymentMethods,
  },
  CREDIT_CARD_ADD_EDIT: {
    authType: USER_AUTH_TYPE,
    path: "/cc-add",
    title: "Credit card add/edit",
    isExact: false,
    hideHeader: false,
    component: CreditCardAddEdit,
  },
  INVOICE_DETAILS: {
    authType: USER_AUTH_TYPE,
    path: "/invoices/:id",
    title: "Invoice Details",
    isExact: false,
    hideHeader: false,
    component: MissingScreen,
  },
  LICENCES: {
    authType: USER_AUTH_TYPE,
    path: "/portal",
    title: "Licences",
    isExact: false,
    component: LandingPage,
  },
  LICENSE_CREATION: {
    authType: USER_AUTH_TYPE,
    path: "/business-licence/form/:id",
    title: "Create licence",
    isExact: false,
    hideHeader: false,
    component: BusinessLicenseCreation,
  },
  LICENSE_DETAILS: {
    authType: USER_AUTH_TYPE,
    path: "/business-licence/:id",
    title: "Licence Details",
    isExact: false,
    hideHeader: false,
    component: LicenseDetails,
  },
  LINK_LICENCE: {
    authType: USER_AUTH_TYPE,
    path: "/link-licence",
    codePath: "/link-licence",
    title: "Link a licence",
    isExact: false,
    hideHeader: false,
    component: LinkLicence,
  },
  LINK_UTILITY: {
    authType: USER_AUTH_TYPE,
    path: "/utility/link",
    title: "Link a licence",
    isExact: false,
    hideHeader: false,
    component: LinkUtility,
  },
  LINK_PROPERTY_TAX: {
    authType: USER_AUTH_TYPE,
    path: "/property-tax/link",
    title: "Link a property",
    isExact: false,
    hideHeader: false,
    component: LinkPropertyTax,
  },
  UTILITY_DETAILS: {
    authType: USER_AUTH_TYPE,
    path: "/utility/:id",
    codePath: "/utility/",
    title: "Utilities details",
    isExact: true,
    hideHeader: false,
    component: UtilityDetails,
  },
  UTILITY_EDIT_SERVICES: {
    authType: USER_AUTH_TYPE,
    path: "/utility/edit-services/:id",
    title: "Utilities edit services",
    isExact: true,
    hideHeader: false,
    component: EditUtilityServicesWizard,
  },
  UTILITY_MOVING_CITY: {
    authType: USER_AUTH_TYPE,
    path: "/utility/move-transfer/:id",
    title: "Moving city",
    isExact: false,
    hideHeader: false,
    component: MoveTransferUtilityWizard,
  },
  UTILITY_DISCONNECT: {
    authType: USER_AUTH_TYPE,
    path: "/utility/:id/disconnect",
    title: "Temporary disconnect",
    isExact: false,
    hideHeader: false,
    component: TemporaryDisconnect,
  },
  UTILITY_CANCEL_ACCOUNT: {
    authType: USER_AUTH_TYPE,
    path: "/utility/cancel/:id",
    title: "Cancel account",
    isExact: false,
    hideHeader: false,
    component: CancelUtilityAccountWizard,
  },
  PRIVACY_POLICY: {
    path: "/privacy-policy",
    title: "Privacy Policy Agreement",
    isExact: false,
    component: MissingScreen,
    skipAuth: true,
    hideIcons: true,
    hideHeader: true,
  },
  PROPERTY_TAX: {
    authType: USER_AUTH_TYPE,
    path: "/property-tax/:id",
    codePath: "/property-tax/",
    title: "Property tax",
    isExact: false,
    hideHeader: false,
    component: PropertyTaxDetails,
  },
  PROPERTY_TAXES: {
    authType: USER_AUTH_TYPE,
    path: "/property-tax",
    title: "Property tax",
    isExact: false,
    hideHeader: false,
    component: MissingScreen,
  },
  PRIVACY_POLICY_RECONFIRM: {
    path: "/privacy-policy-reconfirm",
    title: "Privacy Policy Reconfirmation",
    isExact: false,
    hideBreadCrumbs: true,
    component: MissingScreen,
    skipAuth: true,
    hideIcons: true,
    hideHeader: true,
  },
  TERMS_OF_SERVICE: {
    authType: USER_AUTH_TYPE,
    path: "/terms-of-service",
    title: "Terms of Service",
    isExact: false,
    hideHeader: false,
    component: MissingScreen,
  },
  TRANSACTION_AND_INVOICE_SUMMARY: {
    authType: USER_AUTH_TYPE,
    path: "/transactions-invoices",
    title: "Transactions & Invoices",
    isExact: false,
    hideHeader: false,
    component: MissingScreen,
  },
  TRANSACTION_DETAILS: {
    authType: USER_AUTH_TYPE,
    path: "/transactions/:id",
    title: "Transaction Details",
    isExact: false,
    hideHeader: false,
    component: MissingScreen,
  },
  UTILITY: {
    authType: USER_AUTH_TYPE,
    path: "/utilities/:id",
    title: "Utilities",
    isExact: false,
    hideHeader: false,
    component: MissingScreen,
  },
  UTILITIES: {
    authType: USER_AUTH_TYPE,
    path: "/utilities",
    title: "Utilities",
    isExact: false,
    hideHeader: false,
    component: MissingScreen,
  },
  WELCOME: {
    authType: USER_AUTH_TYPE,
    path: "/welcome",
    title: "Welcome page",
    isExact: false,
    hideHeader: false,
    component: Welcome,
  },
  ADMIN_LICENCES: {
    authType: ADMIN_AUTH_TYPE,
    component: AdminLicences,
    icon: <Approval />,
    isExact: true,
    path: "/admin/licences",
    title: "Licences",
  },
  LICENCES_DETAIL: {
    component: LicenseDetail,
    authType: ADMIN_AUTH_TYPE,
    isExact: true,
    path: "/admin/licences/:id",
    title: "Licences and permits",
  },
  ADMIN_UTILITIES: {
    component: UtilitiesScreen,
    authType: ADMIN_AUTH_TYPE,
    icon: <WaterDamageOutlined />,
    isExact: true,
    path: "/admin/utilities",
    title: "Utilities",
  },
  ADMIN_UTILITY_DETAIL: {
    component: UtilitiesDetailScreen,
    authType: ADMIN_AUTH_TYPE,
    isExact: true,
    path: "/admin/utilities/:id",
    title: "Utilities Detail",
  },
  ADMIN_PROPERTY_TAXES: {
    component: PropertyTaxesScreen,
    authType: ADMIN_AUTH_TYPE,
    icon: <GiteOutlined />,
    isExact: true,
    path: "/admin/property-taxes",
    title: "Property Tax",
  },
  ADMIN_PROPERTY_TAXES_DETAIL: {
    component: PropertyTaxesDetailScreen,
    authType: ADMIN_AUTH_TYPE,
    icon: <GiteOutlined />,
    isExact: true,
    path: "/admin/property-taxes/:id",
    title: "Property Tax Detail",
  },
  ADMIN_REPORTING: {
    component: Reporting,
    authType: ADMIN_AUTH_TYPE,
    icon: <SummarizeOutlined />,
    isExact: true,
    path: "/admin/reporting",
    title: "Reporting",
  },
  INVOICE_EDITOR: {
    authType: ADMIN_AUTH_TYPE,
    component: InvoiceEditorScreen,
    isExact: true,
    path: "/admin/invoice-editor/:id",
    title: "Invoice Editor",
    hideSidebar: true,
    hideHeader: true,
  },
  LOGIN: {
    component: LoginScreen,
    hideBreadcrumbs: true,
    hideHeader: true,
    hideSidebar: true,
    isExact: true,
    path: "/login",
    title: "Index",
    skipAuth: true,
  },
  LOGOUT: {
    component: LoginScreen,
    hideBreadcrumbs: true,
    hideHeader: true,
    hideSidebar: true,
    isExact: true,
    path: "/logout",
    title: "Index",
    skipAuth: true,
  },
  SEND_RESET_LINK: {
    component: SendResetLink,
    hideBreadcrumbs: true,
    hideHeader: true,
    hideSidebar: true,
    isExact: true,
    path: "/send-reset-link",
    title: "Reset Password",
    skipAuth: true,
  },
  ADMINS: {
    authType: ADMIN_AUTH_TYPE,
    component: Admins,
    icon: <SupervisorAccountOutlined />,
    isExact: true,
    path: "/admin/admins",
    title: "Admins",
  },
  USERS: {
    authType: ADMIN_AUTH_TYPE,
    component: Users,
    icon: <AccountCircleOutlined />,
    isExact: true,
    path: "/admin/users",
    title: "Users",
  },
  DASHBOARD: {
    authType: ADMIN_AUTH_TYPE,
    component: Dashboard,
    icon: <DynamicFeedOutlined />,
    isExact: true,
    path: "/admin",
    title: "Action Centre",
  },
  NOTFOUND: {
    component: Missing,
    isExact: true,
    skipAuth: true,
    path: "/404",
    title: "Not Found",
  },
  UNAUTHORIZED: {
    component: Unauthorized,
    isExact: true,
    skipAuth: true,
    path: "/403",
    title: "Unauthorized",
  },
};

export const getAuthType = (path: string) => {
  return path.split("/")[1] === "admin" ? ADMIN_AUTH_TYPE : USER_AUTH_TYPE;
};

export const getScreenOptions = (path: string) => {
  const result = Object.entries(routes).find(([_, screen]) =>
    matchPath(path, {
      path: screen.path,
      exact: true,
    }),
  );
  return result ? result[1] : {};
};
