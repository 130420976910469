import { DataFormat, FilterType } from "./enums";
import { convertLicenseNumber, formatLicenceType } from "@utils/index";
import { applicantFormat, licenceStatusIcon, licenseStatusFormat } from "@utils/data-table-format-helpers";

export const APPROVED = "approved";
export const ACTIVE = "active";
export const DECLINED = "declined";
export const CANCELLED = "cancelled";
export const ISSUED = "issued";
export const EXPIRED = "expired";
export const REVIEWING = "reviewing";
export const REVOKED = "revoked";
export const SAVED = "saved";
export const SUBMITTED = "submitted";
export const OUTDATED = "outdated";
export const CREATED = "created";
export const DRAFT = "draft";
export const DISCARDED = "discarded";
export const RENEWING = "renewing";
export const EXPIRING = "expiring";

export const ANY = "any";

export const EDIT = "edit";
export const ADD = "add";

export const ACTIVE_GROUP = [ACTIVE, ISSUED, RENEWING, EXPIRING];
export const REVIEWING_GROUP = [APPROVED, REVIEWING, SUBMITTED];
export const INACTIVE_GROUP = [CANCELLED, REVOKED, DECLINED, EXPIRED];
export const ROOT_LICENCE_TYPES = {
  active: ACTIVE_GROUP,
  reviewing: REVIEWING_GROUP,
  inactive: INACTIVE_GROUP,
};

export const LICENCES_TABLE_IDS = {
  REVIEW: "REVIEW",
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  ALL: "ALL",
};

export const tabs = ["General Info", "Documents", "Invoices & Payments", "History"];

export const BUSINESS_NAME_LENGTH = 30;
export const MAX_FILES_SIZE = 64; //mb
export const BYLAW_SEARCH_DROP_DOWN_LENGTH = 50;
export const REVIEWER_NAME_SEARCH_DROP_DOWN_LENGTH = 20;

export const licenseFieldsV2 = {
  ref_number: {
    id: "ref",
    label: "LIC. #",
    disableSort: false,
    filterType: FilterType.SEARCH,
    format: convertLicenseNumber,
  },
  project: {
    id: "projectName",
    label: "PROJECT",
    disableSort: true,
    filterType: null,
  },
  type: {
    id: "category",
    label: "Category",
    type: "tags",
    filterType: FilterType.CHIP,
    disableSort: true,
    format: formatLicenceType,
    options: [
      { label: "Business", value: "BUSINESS_LICENSE" },
      { label: "Pet", value: "DOG_LICENSE" },
    ],
  },
  address: {
    id: "address",
    label: "Address",
    disableSort: true,
    filterType: null,
  },
  applicant: {
    id: "applicant",
    label: "Applicant",
    disableSort: false,
    filterType: null,
    format: applicantFormat,
  },
  name: {
    id: "name",
    label: "Applicant",
    disableSort: true,
    filterType: null,
  },
  last_update: {
    id: "lastUpdated",
    label: "Updated",
    disableSort: false,
    filterType: null,
    format: DataFormat.DATE_SHORT,
  },
  balance: {
    id: "balance",
    label: "Balance",
    filterType: null,
    disableSort: true,
    format: (val: any, data: any) => {
      if (INACTIVE_GROUP.includes(data?.status)) {
        return DataFormat.CURRENCY(0);
      } else if (REVIEWING_GROUP.includes(data?.status)) {
        return "-";
      } else {
        return DataFormat.CURRENCY(val);
      }
    },
  },
  estimate: {
    id: "balance",
    label: "Estimate",
    filterType: null,
    disableSort: true,
    format: DataFormat.CURRENCY,
  },
  status: {
    id: "status",
    label: "Status",
    filterType: null,
    disableSort: true,
    format: (val: any) => {
      return licenseStatusFormat(val);
    },
  },
  residency: {
    id: "is_resident",
    label: "Residency",
    filterType: FilterType.SELECT,
    hideFromTable: true,
    defaultValue: "",
    options: [
      { id: "resident", label: "Resident", value: "true" },
      { id: "non-resident", label: "Non-resident", value: "false" },
      { id: "any-resident", label: "Any", value: "" },
    ],
  },
  location: {
    id: "is_homebased",
    label: "Location",
    filterType: FilterType.SELECT,
    hideFromTable: true,
    defaultValue: "",
    options: [
      { id: "storefront", label: "Storefront", value: "false" },
      { id: "homebased", label: "Home-based", value: "true" },
      { id: "any-location", label: "Any", value: "" },
    ],
  },
  statuses: {
    id: "status",
    label: "Status",
    filterType: FilterType.SELECT,
    hideFromTable: false,
    defaultValue: "",
    disableSort: true,
    format: (val: any) => licenseStatusFormat(val),
    options: [
      {
        label: "Reviewing Group",
        value: ROOT_LICENCE_TYPES.reviewing,
        icon: licenceStatusIcon(APPROVED),
      },
      {
        label: "Approved",
        value: APPROVED,
        icon: licenceStatusIcon("placeHolder"),
      },
      {
        label: "Reviewing",
        value: REVIEWING,
        icon: licenceStatusIcon("placeHolder"),
      },
      {
        label: "Submitted",
        value: SUBMITTED,
        icon: licenceStatusIcon("placeHolder"),
      },
      {
        label: "Active Group",
        value: ROOT_LICENCE_TYPES.active,
        icon: licenceStatusIcon(ACTIVE),
      },
      {
        label: "Active",
        value: ACTIVE,
        icon: licenceStatusIcon("placeHolder"),
      },
      {
        label: "Issued",
        value: ISSUED,
        icon: licenceStatusIcon("placeHolder"),
      },
      {
        label: "Renewing",
        value: RENEWING,
        icon: licenceStatusIcon("placeHolder"),
      },
      {
        label: "Expiring",
        value: EXPIRING,
        icon: licenceStatusIcon("placeHolder"),
      },
      {
        label: "Inactive Group",
        value: ROOT_LICENCE_TYPES.inactive,
        icon: licenceStatusIcon(CANCELLED),
      },
      {
        label: "Cancelled",
        value: CANCELLED,
        icon: licenceStatusIcon("placeHolder"),
      },
      {
        label: "Revoked",
        value: REVOKED,
        icon: licenceStatusIcon("placeHolder"),
      },
      {
        label: "Declined",
        value: DECLINED,
        icon: licenceStatusIcon("placeHolder"),
      },
      {
        label: "Expired",
        value: EXPIRED,
        icon: licenceStatusIcon("placeHolder"),
      },
    ],
  },
};

/**
 * Different costs of business licences
 */
export const prices = {
  localBusiness: 147,
  nonLocalBusiness: 412,
  discountedLocalBusiness: 73.5,
  discountedNonLocalBusiness: 206,
};
