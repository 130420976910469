import React from "react";
import styled from "@emotion/styled";
import Divider from "./Divider";
import Typography from "@mui/material/Typography";
import { Box, Dialog as MuiDialog, IconButton, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import { CloseRounded } from "@mui/icons-material";

const useStyles = styled(() => ({
  root: {
    "& .MuiPaper-root": {
      boxSizing: "border-box",
      borderRadius: "20px",
    },
  },
}));

const TitleActions = styled.div``;

const ModalDialog = styled(MuiDialog)`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  && .MuiPaper-root {
    max-width: ${({ maxwidth }) => (maxwidth ? maxwidth : "unset")};
    width: ${({ maxwidth }) => (maxwidth ? "100%" : "unset")};
  }
`;

const StyledDialogTitle = styled.div`
  && {
    display: flex;
    border-bottom: 1px solid ${({ theme }) => theme.palette.nonPalette.OUTLINE};
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
  }

  && .MuiTypography-root {
    align-items: center;
    display: flex;
    justify-content: space-between;
    font-weight: 500;
  }
`;

const CloseIconWrapper = styled(Grid)`
  border-left: 2px solid ${({ theme }) => theme.palette.nonPalette.OUTLINE}
  padding-left: 1rem;
  margin-left: 1rem;
`;

const Modal = ({
  open,
  color = "",
  children,
  avatarLabel = "",
  avatarImg = "",
  selected = null,
  className = null,
  title = "",
  body = null,
  maxWidth = null,
  primaryButtonLabel = "",
  secondaryButtonLabel = "",
  onClose = () => {},
  titleActions = null,
  ...rest
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const primary = color ? color : theme.palette.primary[500];

  return (
    <ModalDialog
      open={open}
      className={className ? className : classes.root}
      primary={primary}
      PaperProps={{
        style: { borderRadius: "20px" },
      }}
      onClose={onClose}
      fullWidth
      maxwidth={maxWidth}
      {...rest}
    >
      {title && (
        <>
          <StyledDialogTitle>
            <Typography variant="h4">{title}</Typography>
            <Box justify="flex-end">
              {titleActions ? (
                <Grid>
                  <TitleActions>{titleActions}</TitleActions>
                </Grid>
              ) : null}
              {onClose ? (
                <CloseIconWrapper>
                  <IconButton onClick={onClose} size="large">
                    <CloseRounded />
                  </IconButton>
                </CloseIconWrapper>
              ) : null}
            </Box>
          </StyledDialogTitle>
          <Divider />
        </>
      )}
      {children}
    </ModalDialog>
  );
};

export default Modal;
