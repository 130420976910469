export const typeOptions = [
  {
    value: "residential",
    label: "Residential",
  },
  {
    value: "Commercial, Industrial, Institutional",
    label: "Commercial, Industrial, Institutional",
  },
];

export const metreSizeOptions = [
  {
    label: "Up to 25 mm (1 inch)”",
    value: `Up to 25 mm (1 inch)`,
  },
  {
    label: "30 mm to 50 mm (1 ¼ “ to 2“)",
    value: "30 mm to 50 mm (1 ¼ “ to 2“)",
  },
  {
    label: "75 mm to 100 mm (3” to 4”)",
    value: "75 mm to 100 mm (3” to 4”)",
  },
  {
    label: `150 mm and greater (6”+)`,
    value: "150 mm and greater (6”+)",
  },
];

export const wasteMetreOptions = [
  {
    label: "100 mm (4”)",
    value: "100 mm (4”)",
  },
  {
    label: "150 mm (6”)",
    value: "150 mm (6”)",
  },
  {
    label: "200 mm (8”)",
    value: "200 mm (8”)",
  },
  {
    label: "250 mm (10”+)",
    value: "250 mm (10”+)",
  },
];

export const collectionServiceOptions = [
  {
    label: "Residential",
    value: "Residential",
  },
  {
    label: "Apartment/Condo",
    value: "Apartment/Condo",
  },
  {
    label: "Manufactured Home Park",
    value: "Manufactured home park",
  },
];

export const pickupOptions = [
  {
    label: "Scheduled Bi-weekly pickup per bin",
    value: "Scheduled Bi-weekly pickup per bin",
  },
  {
    label: "Scheduled Weekly pickup per bin",
    value: "Scheduled Weekly pickup per bin",
  },
  {
    label: "Scheduled weekly pickup per shared bin",
    value: "Scheduled weekly pickup per shared bin",
  },
  {
    label: "Non-scheduled extra pickup per bin",
    value: "Non-scheduled extra pickup per bin",
  },
];

export const disposableServiceSizeOptions = [
  {
    label: "Up to 20 yd3",
    value: "Up to 20 yd3",
  },
  {
    label: "21 yd3 to 50 yd3",
    value: "21 yd3 to 50 yd3",
  },
  {
    label: "51 yd3 to 100 yd3",
    value: "51 yd3 to 100 yd3",
  },
  {
    label: "Over 100 yds",
    value: "Over 100 yds",
  },
];

//Constant - Used when a "I don't know" checkbox is placed under a field
// and the value needs to be seperated from the main field.
export const I_DONT_KNOW = "I don't know";
