import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Form, Formik } from "formik";
import { Box, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Switch from "@mui/material/Switch";
import styled from "@emotion/styled";
import * as yup from "yup";
import TextField from "@components/ui-kit/TextField";
import Alerts from "@components/ui-kit/Alert";
import Spacer from "@components/layout-helpers/Spacer";
import ListItem from "@components/ui-kit/ListItem";
import { NotificationContext } from "@contexts/NotificationContext";
import { PaymentProfileContext } from "@contexts/PaymentProfileContext";
import { INPUT_FORMATS } from "@utils/cleaveOptions";
import { normalizeOnSubmit } from "@utils/normalize-on-submit";
import * as v from "@utils/yupValidators";
import { CloseRounded, CreditScoreOutlined } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { closeDialog, setState as setConfirmationDialogState } from "../../../../redux/slices/confrmation-dialog";
import classes from "./index.module.scss";
import { ReactComponent as Visa } from "@assets/images/credit-cards/Visa.svg";
import { ReactComponent as Mastercard } from "@assets/images/credit-cards/Mastercard.svg";
import { LOCAL_STORAGE_KEYS } from "@core/constants/local-storage";
import { CREDIT_CARD_TYPES } from "@core/constants/enums";

//Constants
const ADD = "ADD";
const EDIT = "EDIT";

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1350px;
  padding-top: 1rem;
  margin-left: 18px;
  margin-right: 18px;
  width: 100%;
`;

const Content = styled.div`
  max-width: 375px;
  margin: 1rem auto 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const emptyInitialValues = {
  autoPay: false,
  name: "",
  ccnumber: "",
  expiry: "",
  cvc: "",
};

const addValidation = yup.object({
  name: v.validName,
  ccnumber: v.validNumber,
  expiry: v.validExpiry,
  cvc: v.validCvc,
  autoPay: yup.boolean(),
});

const editValidation = yup.object({
  expiry: v.validExpiry,
  cvc: v.validCvc,
  autoPay: yup.boolean(),
});

export default function CreditCardAddEdit() {
  const [validateOnChange, setValidateOnChange] = useState(false);
  const [initialValues, setInitialValues] = useState(emptyInitialValues);
  const [loading, setLoading] = useState(false);
  const [loadingState, setLoadingState] = useState(true);
  const { createProfile, editProfile, deleteProfile } = useContext(PaymentProfileContext);
  const { handleSuccess, handleError } = useContext(NotificationContext);
  const [type, setType] = useState(ADD);
  const [profileId, setProfileId] = useState(null);
  const history = useHistory();
  const formikRef = useRef();
  const dispatch = useDispatch();

  const handleOpenDeleteModal = () => {
    dispatch(
      setConfirmationDialogState({
        open: true,
        title: "Delete Card",
        body: "Are you sure you want to delete your card? If you want to use it later you'll have to add it again",
        onConfirm: _onDeleteCard,
      }),
    );
  };

  const _onDeleteCard = () => {
    onDelete().then();
    dispatch(closeDialog());
  };

  const onDelete = async () => {
    setLoading(true);
    try {
      await deleteProfile(profileId);
      handleSuccess("Card successfully deleted");
      history.goBack();
    } catch (e) {
      handleError(e.message);
    } finally {
      setLoading(false);
    }
  };

  const location = useLocation();
  const theme = useTheme();

  const scrollToBottom = () => {
    const error_component = document.getElementById("__bottom_scroll");
    if (error_component) {
      error_component.scrollIntoView(false);
    }
  };

  const handleCreate = async (values) => {
    const cleanedValues = normalizeOnSubmit(values);
    setLoading(true);
    try {
      await createProfile(cleanedValues);
      handleSuccess("Payment Method Added");
      history.goBack();
    } catch (err) {
      formikRef.current.setFieldError("backend", "There's an issue with your card details.");
      err.data?.forEach((e) => {
        formikRef.current.setFieldError(e.type, e.message);
      });
      setTimeout(() => {
        scrollToBottom();
      }, 0);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = async (values) => {
    const cleanedValues = normalizeOnSubmit(values);
    const tokenObj = {
      expiry: cleanedValues.expiry,
    };

    if (values.cvc !== "***") {
      tokenObj.cvc = values.cvc;
    }
    setLoading(true);
    try {
      await editProfile(tokenObj, cleanedValues.autoPay, profileId);
      handleSuccess("Payment Method updated");
      history.goBack();
    } catch (err) {
      formikRef.current.setFieldError("backend", "There's an issue with your card details.");
      err.data?.forEach((e) => {
        if (e.type !== "ccnumber") {
          formikRef.current.setFieldError(e.type, e.message);
        }
      });
      setTimeout(() => {
        scrollToBottom();
      }, 0);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (values) => {
    if (type === ADD) {
      handleCreate(values);
      return;
    }

    if (type === EDIT && profileId) {
      handleEdit(values, profileId);
    }
  };

  const btnBackNavigate = () => {
    history.goBack();
  };

  useEffect(() => {
    if (location.state && location.state.profileId) {
      const profId = location.state.profileId;
      setProfileId(profId);
      const profData = location.state.profileData;

      let newValues = {
        name: profData.name,
        ccnumber: `**** **** **** ${profData.number}`,
        expiry: `${profData.expiryMonth}/${profData.expiryYear}`,
        cvc: "",
        autoPay: profData.autoPay || false,
      };
      setInitialValues(newValues);

      setType(EDIT);
    }

    setLoadingState(false);
  }, [location.state]);

  const acceptedCreditCards = useMemo(() => {
    const _acceptableCreditCards = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.acceptedCreditCards));
    if (!Array.isArray(_acceptableCreditCards)) {
      return null;
    }
    return _acceptableCreditCards;
  }, []);

  return (
    <Container>
      <InnerContainer>
        <Box p={1}>
          <Button onClick={btnBackNavigate} variant={"outlined"} startIcon={<CloseRounded />} size={"medium"}>
            Cancel
          </Button>
          <Content>
            {loadingState ? null : (
              <Formik
                innerRef={formikRef}
                validationSchema={type === EDIT ? editValidation : addValidation}
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validateOnChange={validateOnChange}
              >
                {(props) => {
                  return (
                    <Form>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <p className={classes.main_title}>Card details</p>
                        </Grid>
                        <Grid item xs={12}>
                          <p style={{ margin: 0 }}>
                            {`Only ${
                              acceptedCreditCards?.map((e) => e.substring(0, 1).toUpperCase() + e.substring(1)).join(" and ") ??
                              "Visa and Mastercard"
                            } are accepted. All transactions are secure and encrypted.`}
                          </p>
                        </Grid>
                        <Grid item xs={12} />
                        <Grid item xs={12}>
                          <div style={{ display: "flex" }}>
                            {acceptedCreditCards?.map((e) => {
                              if (e === CREDIT_CARD_TYPES.VISA)
                                return (
                                  <div style={{ marginRight: "0.5rem" }}>
                                    <Visa />
                                  </div>
                                );
                              if (e === CREDIT_CARD_TYPES.MASTERCARD)
                                return (
                                  <div style={{ marginRight: "0.5rem" }}>
                                    <Mastercard />
                                  </div>
                                );
                              return <div />;
                            }) ?? (
                              <>
                                <div style={{ marginRight: "0.5rem" }}>
                                  <Visa />
                                </div>
                                <div style={{ marginRight: "0.5rem" }}>
                                  <Mastercard />
                                </div>
                              </>
                            )}
                          </div>
                        </Grid>
                        <Grid item xs={12} />
                        <Grid item xs={12}>
                          <TextField
                            inputProps={{ maxLength: 50 }}
                            fullWidth
                            helperText={props.errors.name}
                            name={"name"}
                            value={props.values.name}
                            onChange={props.handleChange}
                            error={Boolean(props.errors.name)}
                            label={"Name on card"}
                            disabled={type === EDIT}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            format={type === EDIT ? null : INPUT_FORMATS.CC}
                            disabled={type === EDIT}
                            helperText={props.errors.ccnumber}
                            type={"CREDIT_CARD"}
                            fullWidth
                            name={"ccnumber"}
                            value={props.values.ccnumber}
                            onChange={props.handleChange}
                            error={Boolean(props.errors.ccnumber)}
                            label={"Card number"}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            format={INPUT_FORMATS.EXPIRY}
                            error={Boolean(props.errors.expiry)}
                            helperText={props.errors.expiry}
                            type={"EXPIRY"}
                            fullWidth
                            name={"expiry"}
                            value={props.values.expiry}
                            onChange={props.handleChange}
                            label={"Expiration"}
                            placeholder={"MM/YY"}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            format={INPUT_FORMATS.CVC}
                            inputProps={{ maxLength: 4 }}
                            helperText={props.errors.cvc}
                            fullWidth
                            name={"cvc"}
                            value={props.values.cvc}
                            onChange={props.handleChange}
                            error={Boolean(props.errors.cvc)}
                            label={"CVC"}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Spacer amount={2} />
                          <p className={classes.title}>Auto-pay</p>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            variant={"body1"}
                            style={{
                              color: theme.palette.blacks.BLACK_HIGH_EMPHASIS,
                            }}
                          >
                            Don’t miss a payment with auto-pay! Your dues for any resource linked to your MyCityHall account will
                            be automatically charged to your preferred payment method.
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <ListItem
                            onClick={() => {
                              props.setFieldValue("autoPay", !props.values.autoPay);
                            }}
                            label={"Auto-pay"}
                            labelFontWeight={"700"}
                            subLabel={`Dues will be charged to **** ${props.values.ccnumber.substr(
                              props.values.ccnumber.length - 4,
                            )}`}
                            startOrnament={<CreditScoreOutlined sx={{ width: "32px", height: "32px" }} />}
                            endOrnament={<Switch checked={props.values.autoPay} />}
                            style={{
                              outline: `1px solid ${theme.palette.nonPalette.OUTLINE}`,
                            }}
                          />
                        </Grid>
                        {props.errors.backend ? (
                          <Grid item xs={12}>
                            <Spacer />
                            <Alerts variant={"error"} title={"Card details error"} body={props.errors.backend} />
                          </Grid>
                        ) : null}
                        <Grid item xs={12} id={"__bottom_scroll"}>
                          <Button
                            type={"submit"}
                            fullWidth
                            variant={"contained"}
                            size={"large"}
                            onClick={() => {
                              setValidateOnChange(true);
                            }}
                            startIcon={
                              loading && (
                                <CircularProgress
                                  style={{
                                    position: "absolute",
                                    top: 10,
                                    left: 40,
                                    color: theme.palette.primary[50],
                                  }}
                                />
                              )
                            }
                          >
                            {type === ADD ? "Add card" : "Update card"}
                          </Button>
                        </Grid>
                        {type === EDIT ? (
                          <Grid item xs={12}>
                            {/*<Spacer amount={2} />*/}
                            <Button
                              sx={{
                                backgroundColor: "transparent",
                                color: theme.palette.nonPalette.RED,
                                ".MuiTouchRipple-child": {
                                  color: `${theme.palette.nonPalette.RED} !important`,
                                },
                                "&:hover": {
                                  backgroundColor: theme.palette.nonPalette.RED_BACKGROUND,
                                  ".MuiTouchRipple-child": {
                                    color: theme.palette.nonPalette.RED,
                                  },
                                },
                                "&:active": {
                                  backgroundColor: theme.palette.nonPalette.RED_BACKGROUND,
                                  ".MuiTouchRipple-child": {
                                    color: theme.palette.nonPalette.RED,
                                  },
                                },
                              }}
                              onClick={() => {
                                handleOpenDeleteModal();
                              }}
                              size={"small"}
                              variant={"text"}
                            >
                              Delete Card
                            </Button>
                          </Grid>
                        ) : null}
                      </Grid>
                    </Form>
                  );
                }}
              </Formik>
            )}
          </Content>
        </Box>
      </InnerContainer>
    </Container>
  );
}
