import React, { useRef, useState } from "react";
import typographyClasses from "@assets/styles/typography/typography.module.scss";
import Spacer from "@components/ui-kit/Spacer";
import { Form, Formik } from "formik";
import Grid from "@mui/material/Unstable_Grid2";
import Button from "@mui/material/Button";
import * as yup from "yup";
import Alerts from "@components/ui-kit/Alert";
import MaskedTextField from "@components/ui-kit/masked-text-field";
import MaskedTextFieldTypes from "@components/ui-kit/masked-text-field/maskedTextFieldTypes";

const validationSchema = yup.object({
  accountNumber: yup.string().required("Account number is a required field"),
  accessCode: yup.string().required("Access code is a required field"),
});

export default function Step1({ onCheckPropertyAvailable = async () => {} }) {
  const formikRef = useRef(null);
  const [showWrongNumbersAlert, setShowWrongNumbersAlert] = useState(false);

  const handleSubmit = async (values) => {
    try {
      //TODO - gather details about the utility account.
      await onCheckPropertyAvailable(values);
    } catch (e) {
      //If the account number isn't found
      if (e.response.status === 404 || e.response.status === 403) {
        setShowWrongNumbersAlert(true);
      }
      if (e.response.status === 409) {
        await onCheckPropertyAvailable(values, true, e.response.data.service_address);
      }
    }
  };

  return (
    <>
      <p className={typographyClasses.h1}>Link your account</p>
      <p className={typographyClasses.body}>
        When you link a pre-existing property taxes, you will be able to view account details, assessments, services, and more.
      </p>
      {/*<InfoButton*/}
      {/*  onClick={() => {*/}
      {/*  }}*/}
      {/*  title={"Where is my roll or pin number?"}*/}
      {/*/>*/}
      <Spacer />
      <Formik
        innerRef={formikRef}
        initialValues={{ accountNumber: "", accessCode: "" }}
        validationSchema={validationSchema}
        validateOnChange={true}
        onSubmit={handleSubmit}
      >
        {(props) => {
          return (
            <Form>
              <Grid container className={"flex-column"} spacing={1}>
                <Grid xs={12}>
                  <MaskedTextField
                    type={MaskedTextFieldTypes.NUMBER}
                    name={"accountNumber"}
                    error={Boolean(props.errors.accountNumber) && props.touched.accountNumber}
                    helperText={props.touched.accountNumber ? props.errors.accountNumber : null}
                    value={props.values.accountNumber}
                    onChange={(event) => {
                      props.handleChange(event);
                      setShowWrongNumbersAlert(false);
                    }}
                    label={"Roll number"}
                    fullWidth
                  />
                </Grid>
                <Grid xs={12}>
                  <MaskedTextField
                    type={MaskedTextFieldTypes.NUMBER}
                    name={"accessCode"}
                    error={Boolean(props.errors.accessCode) && props.touched.accessCode}
                    helperText={props.touched.accessCode ? props.errors.accessCode : null}
                    value={props.values.accessCode}
                    onChange={(event) => {
                      props.handleChange(event);
                      setShowWrongNumbersAlert(false);
                    }}
                    label={"Access Code/Pin"}
                    fullWidth
                  />
                </Grid>
                {showWrongNumbersAlert && (
                  <Grid xs={12}>
                    <Alerts
                      variant={"error"}
                      title={"Number not found"}
                      body={"The provided numbers could not be found. Please double-check the number and try again."}
                    />
                  </Grid>
                )}
                <Grid xs={12}>
                  <Spacer />
                  <Button
                    disabled={!props.isValid || !props.dirty}
                    size={"large"}
                    fullWidth
                    type={"submit"}
                    variant={"contained"}
                  >
                    Search number
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
