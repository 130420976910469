//Field Ids
import uuid from "react-uuid";
import { cleanPhoneNumber } from "@utils/masked-input-formatters";

export const business_name = "0d8cabbd-a7c2-4fa9-9d88-8a3cd3797f25";
const category = "c4d441d6-c131-46ec-8914-fc054e530367";
export const business_operation_description = "03a26768-8625-4fa3-89f0-4523ebf3a13b";
export const is_resident = "d4d8dd92-ac6f-494a-980f-1b43345314b3";
export const is_included_in_online_business_directory = "0ae2aa2c-9c14-4f8f-86df-a2aeeb6b0c32";
const number_of_vehicles = "ca2ac712-95c8-470a-8fb6-270d3f90add6";
const parking_place = "ce3cea5a-8c2c-4a95-9983-ab7fb74bb72a";
const truck_size = "2c9c91f4-1d54-4804-b4d8-e9e0150c9957";
const is_property_owner = "90ff7878-f801-4de3-8e8c-22c5aff2b78b";
const property_owner_name = "ec229d4d-a7e9-4529-acb3-271f3a39a44a";
const business_address_street = "22ac4bd7-45b9-4291-9fd9-96fa47e44360";
const business_address_unit_suite = "569e0449-a097-44ed-a5fe-7a7818c8e8da";
const business_address_postal_code = "c6d08345-bb6f-4dd8-84da-9de4c263f753";
const is_business_address_same_as_mailing_address = "1f563af0-2665-48a6-b6eb-d5a157fbe6b5";
const phone_number = "2bc0e093-af4f-468c-937c-4a8da54eaaaa";
const email = "50367b51-1734-4e9b-91b0-fd4272c2cf4a";
const fax_number = "2ad91424-2357-41fe-be36-f2854ce367ad";
const secondary_phone_number = "cde51638-d3b4-445d-b09a-0828e4baaad7";
const website_address = "e3ad1c69-8ade-436c-a48b-e2c51646e598";
const business_manager = "385f4438-6c5e-4cd7-8bde-89ec40407132";
const is_homebased = "1cbdbcbb-e6d6-4803-98ad-2fabb0b6a379";
const mailing_address_street = "c92c1584-fa1a-4fb4-9d6c-ae6d7bb35264";
const mailing_address_unit_suite = "99f2e096-7c95-4b1b-aa3a-6ae5e6e66d53";
const mailing_address_postal_code = "a8553fbc-5970-4a12-ab82-69d9021d7913";
const attachments = "ea9be6af-70f1-408d-8b4f-e01b15046842";
const previous_license_id = "6f6b5803-ac0c-45a3-b2fd-961d42c741a0";
const updated_license_id = "d505700c-6395-4f77-b841-42f8a54e69f4";
const has_parking_for_customers = "8792addb-effc-4958-97b0-1a602b50d2e7";
const license_number = "62a224c7-38f2-4738-832f-52d419c1e9df";
const mailing_address_province = "52b8f9e3-36c6-4e64-bf9a-403146cd80ed";
const business_address_province = "8843b29c-bbd1-442f-8766-a8d3d7cb6a78";
const business_address_city = "824e90a5-1acb-406f-85b2-0c4cf596b0f9";
const mailing_address_city = "817b6945-35a3-4e91-9d31-20fd85372f1c";
const business_address_country = "e8b2a5f5-a2c0-463d-beaa-35aef1299b4b";
const mailing_address_country = "e2abf94b-fb56-46c3-970b-9b55d00a111c";

export const mapStep1 = (key, value) => {
  switch (key) {
    case "businessName":
      return { id: business_name, value: { value: value } };
    case "attachments":
      return { id: attachments, value: { value: value } };
    case "businessDescription":
      return {
        id: business_operation_description,
        value: { value: value },
      };
    case "businessType":
      const newVal = [value.value];
      return { id: category, value: { value: newVal } };
    case "include":
      return {
        id: is_included_in_online_business_directory,
        value: { value: value },
      };
    case "isOwner":
      return { id: is_property_owner, value: { value: value } };
    case "isResident":
      return { id: is_resident, value: { value: value } };
    case "numVehicles":
      return { id: number_of_vehicles, value: { value: value } };
    case "parkingPlaceType":
      return { id: parking_place, value: { value: value } };
    case "propertyOwnerName":
      return { id: property_owner_name, value: { value: value } };
    case "purpose":
      return null;
    case "truckSize":
      return { id: truck_size, value: { value: value } };
    case "businessAddress":
      return { id: business_address_street, value: { value: value } };
    case "businessAddressProvince":
      return { id: business_address_province, value: { value: value } };
    case "businessAddressCity":
      return { id: business_address_city, value: { value: value } };
    case "unit":
      return { id: business_address_unit_suite, value: { value: value } };
    case "code":
      return { id: business_address_postal_code, value: { value: value } };
    case "businessCountry":
      return { id: business_address_country, value: { value: value } };
    case "mailingCountry":
      return { id: mailing_address_country, value: { value: value } };
    case "phoneNumber":
      return { id: phone_number, value: { value: value } };
    case "email":
      return { id: email, value: { value: value } };
    case "faxNumber":
      return { id: fax_number, value: { value: value } };
    case "secondPhone":
      return { id: secondary_phone_number, value: { value: value } };
    case "websiteAddress":
      return { id: website_address, value: { value: value } };
    case "tmpOwners":
      let fullArray = [];
      value.owners.forEach((owner) => {
        fullArray.push({
          full_name: owner.name,
          phone: cleanPhoneNumber(owner.phone),
          is_owner: owner.isOwner,
        });
      });
      value.managers.forEach((manager) => {
        fullArray.push({
          full_name: manager.name,
          phone: cleanPhoneNumber(manager.phone),
          is_owner: manager.isOwner,
        });
      });
      return { id: business_manager, value: { value: fullArray } };
    case "homeBased":
      return { id: is_homebased, value: { value: value } };
    case "mailingAddress":
      return { id: mailing_address_street, value: { value: value } };
    case "mailingAddressProvince":
      return { id: mailing_address_province, value: { value: value } };
    case "mailingAddressCity":
      return { id: mailing_address_city, value: { value: value } };
    case "mailingUnit":
      return { id: mailing_address_unit_suite, value: { value: value } };
    case "mailingCode":
      return { id: mailing_address_postal_code, value: { value: value } };
    case "hasParking":
      return { id: has_parking_for_customers, value: { value: value } };
    default:
      return null;
  }
};

export const mapInto = (value) => {
  switch (value.name) {
    case "autopay":
      return { property: "autoPay", value: value.value.value };
    case "expiry":
      return { property: "expiry", value: value.value.value };
    case "change_set_status":
      return {
        property: "changeSetStatus",
        value: value.value.value,
      };
    case "change_set_id":
      return {
        property: "changeSetId",
        value: value.value.value,
      };
    default:
      break;
  }

  switch (value.id) {
    case attachments:
      return { property: "attachments", value: value.value.value, originalValue: value?.originalValue?.value };
    case business_name:
      return {
        property: "businessName",
        value: value.value.value,
        originalValue: value?.originalValue?.value,
      };
    case business_operation_description:
      return {
        property: "businessDescription",
        value: value.value.value,
        originalValue: value?.originalValue?.value,
      };
    case category:
      return {
        property: "businessType",
        value: value.value.value,
        categories: value.default,
      };
    case is_included_in_online_business_directory:
      const newValueIsIncluded = value.value.value.length < 1 ? false : value.value.value;
      return { property: "include", value: newValueIsIncluded };
    case is_property_owner:
      const newValueIsPropertyOwner = value.value.value.length < 1 ? false : value.value.value;
      return { property: "isOwner", value: newValueIsPropertyOwner };
    case is_resident:
      const newValueIsResident = value.value.value.length < 1 ? true : value.value.value;
      return {
        property: "isResident",
        value: newValueIsResident,
        originalValue: value?.originalValue?.value,
      };
    case number_of_vehicles:
      return {
        property: "numVehicles",
        value: value.value.value,
        originalValue: value?.originalValue?.value,
      };
    case parking_place:
      return {
        property: "parkingPlaceType",
        value: value.value.value,
        originalValue: value?.originalValue?.value,
      };
    case property_owner_name:
      return {
        property: "propertyOwnerName",
        value: value.value.value,
        originalValue: value?.originalValue?.value,
      };
    case truck_size:
      return {
        property: "truckSize",
        value: value.value.value,
        originalValue: value?.originalValue?.value,
      };
    case business_address_street:
      return {
        property: "businessAddress",
        value: value.value.value,
        originalValue: value?.originalValue?.value,
      };
    case business_address_province:
      return {
        property: "businessAddressProvince",
        value: value.value.value,
        originalValue: value?.originalValue?.value,
      };
    case business_address_city:
      return {
        property: "businessAddressCity",
        value: value.value.value,
        originalValue: value?.originalValue?.value,
      };
    case business_address_unit_suite:
      return {
        property: "unit",
        value: value.value.value,
        originalValue: value?.originalValue?.value,
      };
    case business_address_postal_code:
      return {
        property: "code",
        value: value.value.value,
        originalValue: value?.originalValue?.value,
      };
    case business_address_country:
      return {
        property: "businessCountry",
        value: value.value.value,
        originalValue: value?.originalValue?.value,
      };
    case is_business_address_same_as_mailing_address:
      const newValue = value.value.value.length < 1 ? true : value.value.value;
      return {
        property: "mailingAddressCheckedAsBusinessAddress",
        value: newValue,
      };
    case phone_number:
      return {
        property: "phoneNumber",
        value: value.value.value,
        originalValue: value?.originalValue?.value,
      };
    case email:
      return {
        property: "email",
        value: value.value.value,
        originalValue: value?.originalValue?.value,
      };
    case fax_number:
      return {
        property: "faxNumber",
        value: value.value.value,
        originalValue: value?.originalValue?.value,
      };
    case secondary_phone_number:
      return {
        property: "secondPhone",
        value: value.value.value,
        originalValue: value?.originalValue?.value,
      };
    case website_address:
      return {
        property: "websiteAddress",
        value: value.value.value,
        originalValue: value?.originalValue?.value,
      };
    case business_manager:
      const fullArray = value.value.value;
      const fullOrgArray = value?.originalValue?.value;
      let obj = {
        owners: [],
        managers: [],
        orgOwners: [],
        orgManagers: [],
      };
      for (let i = 0; i < fullArray.length; i++) {
        if (fullArray[i].is_owner) {
          obj.owners.push({
            name: fullArray[i].full_name,
            phone: fullArray[i].phone,
            isOwner: fullArray[i].is_owner,
            id: uuid(),
          });
        } else {
          obj.managers.push({
            name: fullArray[i].full_name,
            phone: fullArray[i].phone,
            isOwner: fullArray[i].is_owner,
            id: uuid(),
          });
        }
      }
      if (fullOrgArray) {
        for (let i = 0; i < fullOrgArray.length; i++) {
          if (fullOrgArray[i].is_owner) {
            obj.orgOwners.push({
              name: fullOrgArray[i].full_name,
              phone: fullOrgArray[i].phone,
              isOwner: fullOrgArray[i].is_owner,
              id: uuid(),
            });
          } else {
            obj.orgManagers.push({
              name: fullOrgArray[i].full_name,
              phone: fullOrgArray[i].phone,
              isOwner: fullOrgArray[i].is_owner,
              id: uuid(),
            });
          }
        }
      }

      return { property: "tmpOwners", value: obj };
    case is_homebased:
      const newValueIsHomeBased = value.value.value.length < 1 ? false : value.value.value;
      return {
        property: "homeBased",
        value: newValueIsHomeBased,
        originalValue: value?.originalValue?.value,
      };
    case mailing_address_street:
      return {
        property: "mailingAddress",
        value: value.value.value,
        originalValue: value?.originalValue?.value,
      };
    case mailing_address_province:
      return {
        property: "mailingAddressProvince",
        value: value.value.value,
        originalValue: value?.originalValue?.value,
      };
    case mailing_address_city:
      return {
        property: "mailingAddressCity",
        value: value.value.value,
        originalValue: value?.originalValue?.value,
      };
    case mailing_address_postal_code:
      return {
        property: "mailingCode",
        value: value.value.value,
        originalValue: value?.originalValue?.value,
      };
    case mailing_address_unit_suite:
      return {
        property: "mailingUnit",
        value: value.value.value,
        originalValue: value?.originalValue?.value,
      };
    case mailing_address_country:
      return {
        property: "mailingCountry",
        value: value.value.value,
        originalValue: value?.originalValue?.value,
      };
    case previous_license_id:
      return { property: "previousLicenceId", value: value.value.value };
    case updated_license_id:
      return {
        property: "updatedLicenceId",
        value: value.value.value,
      };
    case has_parking_for_customers:
      return {
        property: "hasParking",
        value: value.value.value,
        originalValue: value?.originalValue?.value,
      };
    case license_number:
      return { property: "licenceNumber", value: value.value.value };
    default:
      switch (value.name) {
        case "status":
          return { property: "currentStatus", value: value.value.value };
        case "expiry":
          return { property: "expiresOn", value: value.value.value };
        case "lastUpdated":
          return { property: "lastUpdated", value: value.value.value };
        case "balance":
          return { property: "balanceOwing", value: value.value.value };
        default:
          return null;
      }
  }
};
