import React, { useState } from "react";
import { Box, css, Link, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import styled from "@emotion/styled";
import Spacer from "@components/ui-kit/Spacer";
import Button from "@mui/material/Button";
import Alerts from "@components/ui-kit/Alert";
import { formatCurrency, truncate } from "@utils/index";
import ApplicantDeclarationModal from "./ApplicantDeclarationModal";
import { getPDF, updateChangeSetStatus } from "@core/apis/licence";
import FileDownload from "js-file-download";
import {
  ACTIVE,
  APPROVED,
  CANCELLED,
  DRAFT,
  EXPIRING,
  ISSUED,
  RENEWING,
  REVIEWING,
  SAVED,
  SUBMITTED,
} from "@core/constants/statuses";
import { BUSINESS_NAME_LENGTH } from "@core/constants/licences";
import { DeleteOutline, EditOutlined, SendRounded } from "@mui/icons-material";
import config from "@core/apis/_config";
import { useDispatch } from "react-redux";
import { closeDialog, setState as setConfirmationDialogState } from "../../../../../redux/slices/confrmation-dialog";
import { getExpiryRenewDate } from "../functions/business_licence_functions";

const submitModalBodyTextDuplicate =
  "Are you sure you want to submit your changes for City Staff to review? Please be aware changes may be subject to a $20 amendment fee.";

/**
 * The notification and call to action on the right sidebar.
 * @returns {JSX.Element}
 * @constructor
 */
const SideBar = ({
  licence,
  status,
  id,
  onDiscard,
  onSubmit,
  onPayDues,
  onAcknowledge,
  onCancel,
  onExpire,
  onUpdateAndRenew,
  totalDues,
  editPage,
  setRefresh,
  refresh,
  versionStatus,
  canEditLicense,
  originalValues,
  marginTop,
}) => {
  const getSidebarContent = () => {
    switch (status) {
      case DRAFT:
      case SAVED:
        return (
          <DraftLicence
            licence={licence}
            onDiscard={onDiscard}
            onSubmit={onSubmit}
            onAcknowledge={onAcknowledge}
            versionStatus={versionStatus}
          />
        );
      case REVIEWING:
      case SUBMITTED:
      case APPROVED:
        return <UnderReview showChanges={versionStatus === "duplicate"} original={licence["original"]} />;
      case ISSUED:
        return <PaymentDue onPayDues={onPayDues} totalDue={totalDues} />;
      case RENEWING:
      case ACTIVE:
      case EXPIRING:
        return (
          <ActiveSideBar
            onCancel={onCancel}
            id={id}
            editPage={editPage}
            setRefresh={setRefresh}
            refresh={refresh}
            versionStatus={versionStatus}
            totalDues={totalDues}
            canEditLicense={canEditLicense}
            licence={licence}
            originalValues={originalValues}
            onAcknowledge={onAcknowledge}
            onUpdateAndRenew={onUpdateAndRenew}
            onPayDues={onPayDues}
            onExpire={onExpire}
            status={status}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <div
      style={{
        marginTop: marginTop ? marginTop : "1rem",
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      {getSidebarContent()}
    </div>
  );
};

const InfoSubmitting = styled(Box)(
  ({ theme }) => css`
    border: 1px solid ${theme.palette.nonPalette.OUTLINE};
    border-radius: 10px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 1.2rem;
    box-shadow: 0 3px 10px 0 rgba(1, 48, 78, 0.15);

    && .material-symbols-rounded {
      color: ${theme.palette.primary[200]};
    }

    .MuiTypography-root {
      margin: 0.5rem 0;
    }

    .MuiTypography-body1 {
      margin-bottom: 1rem;
    }

    .MuiButton-root {
      .material-symbols-rounded {
        color: ${theme.palette.whites.WHITE};
        position: absolute;
        left: 20px;
        top: 19px;
      }
    }
  `,
);

const SmallLinkButton = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary[200]} !important;
  margin-top: 1rem !important;

  :hover {
    color: ${({ theme }) => theme.palette.primary[400]} !important;
    cursor: pointer;
    text-decoration: underline;
  }
`;

/**
 * Displays if the licence has an active status
 * @returns {JSX.Element}
 * @constructor
 */

const ActiveSideBar = ({
  id,
  totalDues,
  onPayDues,
  editPage,
  setRefresh,
  refresh,
  canEditLicense,
  originalValues,
  licence,
  onAcknowledge,
  onUpdateAndRenew,
  status,
}) => {
  const dispatch = useDispatch();
  const [openApplicantModal, setOpenApplicantModal] = useState(false);
  const [hasAutoPay] = useState(licence.autoPay);
  const [isEditing] = useState(licence.changeSetId !== undefined && licence.changeSetStatus !== ISSUED);

  const handleEdit = async () => {
    await editPage();
    setRefresh(!refresh);
  };

  const handleOnSubmit = async () => {
    dispatch(closeDialog());
    await updateChangeSetStatus(id, licence.changeSetId, SUBMITTED, status === RENEWING ? false : "send");
    setRefresh(!refresh);
  };

  const handleOnDiscard = async () => {
    await updateChangeSetStatus(id, licence.changeSetId, CANCELLED, false);
    setRefresh(!refresh);
  };

  const handleApplicantModalOpen = () => {
    setOpenApplicantModal(true);
  };

  const handleApplicantModalClose = () => {
    setOpenApplicantModal(false);
  };

  const handleDownload = async (id) => {
    const license = await getPDF(id);
    if (license) {
      FileDownload(license, `${config.cityName} Business Licence.pdf`, "application/pdf");
    }
  };

  const showSubmitChangesConfirmationDialog = () => {
    dispatch(
      setConfirmationDialogState({
        open: true,
        title: "Submit changes to review",
        body: submitModalBodyTextDuplicate,
        onConfirm: handleOnSubmit,
      }),
    );
  };

  return (
    <>
      {isEditing && (
        <>
          <ChangeWidget
            licence={licence}
            originalValues={originalValues}
            openApplicantModal={handleApplicantModalOpen}
            handleOnDiscard={handleOnDiscard}
            openSubmitChangesModal={showSubmitChangesConfirmationDialog}
          />
          <Spacer />
        </>
      )}
      {!isEditing && totalDues > 0 && status !== RENEWING && status !== EXPIRING && (
        <>
          <PaymentDue onPayDues={onPayDues} totalDue={totalDues} />
          <Spacer />
        </>
      )}
      {!isEditing && status === RENEWING && (
        <InfoSubmitting>
          <h2>Licence renewal</h2>
          <p className={"body"}>
            {hasAutoPay
              ? "Your Licence is up for renewal. Update your details before the renewal date. And you can either make a payment now or wait for autopay."
              : `Your business licence expires on ${getExpiryRenewDate(
                  licence?.expiry,
                  false,
                )}, and it's time to renew. You can update your business information during the renewal process.`}
          </p>
          <Typography fontSize={38} fontWeight={500}>
            ${Number(licence.balanceOwing).toFixed(2)}
          </Typography>
          {hasAutoPay ? (
            <Button onClick={onPayDues} size={"large"} fullWidth variant={"contained"}>
              Pay Now
            </Button>
          ) : (
            <Button onClick={onUpdateAndRenew} size={"large"} fullWidth variant={"contained"}>
              Update & Renew
            </Button>
          )}
          {canEditLicense && hasAutoPay && (
            <>
              {licence.changeSetId === undefined && (
                <>
                  <Spacer />
                  <Button onClick={handleEdit} size={"small"} fullWidth variant={"outlined"} startIcon={<EditOutlined />}>
                    Edit details
                  </Button>
                </>
              )}
            </>
          )}
        </InfoSubmitting>
      )}
      {!isEditing && totalDues === 0 && ![RENEWING, EXPIRING].includes(status) && (
        <InfoSubmitting>
          <h2>Business licence</h2>
          <p className={"body"}>Don’t forget to print and display it.</p>
          <Button
            onClick={() => {
              handleDownload(id);
            }}
            size={"large"}
            fullWidth
            variant={"contained"}
          >
            Download PDF
          </Button>
          {canEditLicense && licence.balanceOwing !== 20 && licence.changeSetId === undefined && (
            <>
              <p className={"body"}>Any changes to your details may be subject to a $20 amendment fee.</p>
              <Button onClick={handleEdit} size={"small"} fullWidth variant={"outlined"} startIcon={<EditOutlined />}>
                Edit details
              </Button>
            </>
          )}
        </InfoSubmitting>
      )}
      <ApplicantDeclarationModal
        handleClose={handleApplicantModalClose}
        onAcknowledge={onAcknowledge}
        open={openApplicantModal}
      />
    </>
  );
};

const submitModalBodyText =
  "Please ensure that you have read the applicant declaration and reviewed your application before submitting it.";

const ChangeWidget = ({ licence, originalValues, openApplicantModal, openSubmitChangesModal, handleOnDiscard }) => {
  const theme = useTheme();

  const checkChanges = () => {
    const phoneKeys = ["phoneNumber", "secondPhone", "faxNumber"];
    for (const key in originalValues) {
      if (phoneKeys.includes(key)) {
        if (originalValues[key].replace(/\D/g, "") !== licence[key].replace(/\D/g, "")) {
          return false;
        }
      } else if (key === "orgManagers") {
        if (
          originalValues.orgManagers.length !== licence.managers.length ||
          !originalValues.orgManagers.every(
            (obj1, index) =>
              obj1.name === licence.managers[index].name &&
              obj1.phone.replace(/\D/g, "") === licence.managers[index].phone.replace(/\D/g, ""),
          )
        ) {
          return false;
        }
      } else if (key === "orgOwners") {
        if (
          originalValues.orgOwners.length !== licence.owners.length ||
          !originalValues.orgOwners.every(
            (obj1, index) =>
              obj1.name === licence.owners[index].name &&
              obj1.phone.replace(/\D/g, "") === licence.owners[index].phone.replace(/\D/g, ""),
          )
        ) {
          return false;
        }
      } else if (originalValues[key] !== licence[key]) {
        return false;
      }
    }
    return true;
  };

  return (
    <>
      {licence.changeSetStatus === DRAFT ? (
        <InfoSubmitting>
          <h2>Edit your details</h2>
          <p className={"body"}>
            Edit your licence, review it, and then submit it. City Staff will review your changes. Note changes may be subject to
            a $20 amendment fee.
          </p>
          <p className={"body"}>
            Read the{" "}
            <Link
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.keyCode === "13") {
                  openApplicantModal();
                }
              }}
              onClick={() => {
                openApplicantModal();
              }}
              style={{ color: theme.palette.primary[200] }}
            >
              Applicant declaration
            </Link>
          </p>
          <Spacer amount={0.5} />
          <Button
            disabled={checkChanges()}
            onClick={() => {
              openSubmitChangesModal();
            }}
            size={"large"}
            variant={"contained"}
            fullWidth
          >
            Submit to review
          </Button>
          <Spacer amount={0.5} />
          <SmallLinkButton onClick={handleOnDiscard} variant="body1" align="center">
            Cancel editing
          </SmallLinkButton>
        </InfoSubmitting>
      ) : (
        <Alerts
          variant={"find_in_page"}
          title={"Your changes are under review"}
          body={
            <Typography>
              City staff is currently reviewing the changes you submitted. If you have any questions or issues,{" "}
              <Link
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.keyCode === "13") {
                    openApplicantModal();
                  }
                }}
                onClick={() => {
                  window.open("https://www.lacombe.ca/Directory.aspx?DID=42", "_blank", "noopener,noreferrer");
                }}
                style={{ color: theme.palette.primary[200] }}
              >
                contact City Hall
              </Link>
              .
            </Typography>
          }
          fill={1}
        />
      )}
    </>
  );
};

/**
 * Displays if the licence has a draft status
 * @param onSubmit
 * @param onDiscard
 * @param onAcknowledge
 * @param isDuplicate
 * @returns {JSX.Element}
 * @constructor
 */
const DraftLicence = ({ onSubmit, onDiscard, onAcknowledge }) => {
  const theme = useTheme();
  const [openApplicantModal, setOpenApplicantModal] = useState(false);
  const dispatch = useDispatch();

  const handleApplicantModalClose = () => {
    setOpenApplicantModal(false);
  };

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <InfoSubmitting>
        <h2>Before submitting...</h2>
        <p className={"body"}>
          Please carefully review all the points to ensure their accuracy. After your application is approved, you will be
          required to pay the Licence fee.
        </p>
        <Button
          size={"large"}
          variant={"contained"}
          fullWidth
          startIcon={<SendRounded sx={{ paddingRight: "1rem" }} />}
          onClick={() => {
            dispatch(
              setConfirmationDialogState({
                open: true,
                title: "Submitting application",
                body: submitModalBodyText,
                onConfirm: onSubmit,
              }),
            );
          }}
        >
          Submit Application
        </Button>
        <Typography
          variant={"body1"}
          style={{
            color: theme.palette.blacks.BLACK_HIGH_EMPHASIS,
            marginTop: "1.5rem",
            marginBottom: "1.5rem",
            marginLeft: 10,
          }}
        >
          Read the{" "}
          <Link
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.keyCode === "13") {
                setOpenApplicantModal(true);
              }
            }}
            onClick={() => {
              setOpenApplicantModal(true);
            }}
            style={{ color: theme.palette.primary[200] }}
          >
            Applicant declaration
          </Link>
        </Typography>
        <Button
          startIcon={<DeleteOutline />}
          variant={"outlined"}
          size={"small"}
          fullWidth
          onClick={() => {
            dispatch(
              setConfirmationDialogState({
                open: true,
                title: "Discarding draft",
                body: "All the information contained in this draft will be deleted. A new application will require all information to be re-input from the beginning.",
                onConfirm: onDiscard,
              }),
            );
          }}
        >
          Discard application
        </Button>
      </InfoSubmitting>
      <ApplicantDeclarationModal
        handleClose={handleApplicantModalClose}
        onAcknowledge={onAcknowledge}
        open={openApplicantModal}
      />
    </Box>
  );
};

/**
 * Displays if the licence has an under review status
 * @returns {JSX.Element}
 * @constructor
 */
const UnderReview = ({ showChanges, original }) => {
  return (
    <>
      {showChanges ? (
        <Alerts
          variant={"find_in_page"}
          title={"Changes under review"}
          body={
            "The changes you submitted are currently under review. " +
            "City staff might contact you if they require more information."
          }
          body2={"This is a copy of " + truncate(original["name"], BUSINESS_NAME_LENGTH) + "."}
          fill={1}
        />
      ) : (
        <Alerts
          variant={"find_in_page"}
          title={"Under review"}
          body={
            "This licence application is currently under review." +
            " Processing time for an application is approximately" +
            " two weeks. We’ll contact you in case we need more" +
            " information."
          }
        />
      )}
    </>
  );
};

const PaymentDue = ({ onPayDues, totalDue }) => {
  const theme = useTheme();

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <InfoSubmitting>
        <h2>Payment is due</h2>
        <p className={"body"}>Pay your licence fee the sooner the better to avoid incurring in penalties.</p>
        <Button size={"large"} variant={"contained"} fullWidth onClick={onPayDues}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography
              style={{
                fontWeight: 700,
                color: theme.palette.whites.WHITE,
                margin: 0,
              }}
            >
              Pay dues
            </Typography>
            <Typography
              style={{
                fontWeight: 700,
                color: theme.palette.whites.WHITE,
                margin: 0,
              }}
            >
              {formatCurrency(totalDue)}
            </Typography>
          </Box>
        </Button>
      </InfoSubmitting>
    </Box>
  );
};

export default SideBar;
