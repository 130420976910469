import classes from "../index.module.scss";
import React, { useEffect, useMemo, useState } from "react";
import Typography from "@mui/material/Typography";
import { Chip, useTheme } from "@mui/material";
import Spacer from "@components/ui-kit/Spacer";
import { capitalize } from "@utils/index";

const ServiceItem = ({ service, isAdminSide = false, setIsMissingData }: any) => {
  const theme = useTheme();
  const [showOriginal, setShowOriginal] = useState(false);
  const currentValue = capitalize(service?.value?.value);

  const originalValueClass = useMemo(() => {
    return service.originalValue
      ? `${classes.original_background} ${isAdminSide ? classes.yellow_background : classes.blue_background}`
      : null;
  }, [service.originalValue, isAdminSide]);

  useEffect(() => {
    if (!service?.value?.value && setIsMissingData) {
      setIsMissingData(true);
    }
  }, [service, setIsMissingData]);

  return (
    <>
      <div className={`${classes.item} ${originalValueClass}`}>
        <p className={classes.label}>{service?.label}</p>
        <p className={currentValue ? classes.value : classes.value_unknown}>
          {currentValue ? (currentValue === "Idk" ? "I don't know" : currentValue) : "Unknown"}
        </p>
        {service?.originalValue && service?.originalValue !== "-" && (
          <Typography
            style={{
              fontSize: 14,
              fontWeight: 400,
              lineHeight: 1,
              marginBottom: "0.1rem",
              marginTop: "0.1rem",
            }}
            sx={{
              "&:hover": {
                color: theme.palette.primary[500],
                cursor: "pointer",
              },
            }}
            color={theme.palette.primary[200]}
            onClick={() => {
              setShowOriginal(!showOriginal);
            }}
          >
            {showOriginal ? "Hide original" : "View original"}
          </Typography>
        )}
        {showOriginal && (
          <>
            <Spacer amount={0.5} />
            <Chip
              label="Original"
              style={{
                width: "fit-content",
                background: theme.palette.greys.GREY_MEDIUM,
                marginBottom: "0.25rem",
              }}
              size="small"
            />
            <p className={classes.label}>{service.label}</p>
            <Typography color={theme.palette.primary[700]} variant="body1">
              {(typeof service?.originalValue?.value === "object"
                ? "-"
                : service?.originalValue?.value === "IDK"
                  ? "I don't know"
                  : capitalize(service?.originalValue?.value)) ||
                (service?.originalValue === "IDK" ? "I don't know" : capitalize(service?.originalValue)) ||
                "-"}
            </Typography>
          </>
        )}
      </div>
    </>
  );
};

export default ServiceItem;
