import React, { useContext, useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import { RED, WHITE } from "@mui-theme/theme";
import { DataFormat, INPUT_FORMATS } from "@core/constants/enums";
import { capitalize } from "@utils/index";
import { validEmail, validFirstName, validLastName, validRole } from "@utils/yupValidators";
import { NotificationContext } from "@contexts/NotificationContext";
import { FilterContext } from "@contexts/FilterContext";
import { get_admins } from "@core/apis/admin";
import { statusFormat } from "@utils/data-table-format-helpers";
import { TableSettingContext } from "@contexts/TableSettingContext";
import { AddOutlined, Storefront, SupervisorAccountOutlined } from "@mui/icons-material";
import CreateModal from "@components/modals/CreateModal";
import { addAdmin } from "@core/apis/user";
import * as yup from "yup";
import { DataGrid, gridClasses, GridColDef, GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import { Chip } from "@mui/material";
import classes from "./index.module.scss";
import Typography from "@mui/material/Typography";

const validationSchema = yup.object({
  email: validEmail,
  role: validRole,
  firstName: validFirstName,
  lastName: validLastName,
});

const createFields = [
  {
    label: "Email",
    id: "email",
    size: 25,
    type: "email",
    validator: validEmail,
    inputFormat: INPUT_FORMATS.EMAIL,
  },
  {
    label: "First Name",
    id: "firstName",
    size: 12,
    type: "string",
  },
  {
    label: "Last Name",
    id: "lastName",
    size: 12,
    type: "string",
  },
  {
    label: "Role",
    id: "role",
    size: 12,
    type: "select",
    values: [
      { id: "reviewer", label: "Reviewer" },
      { id: "supervisor", label: "Supervisor" },
    ],
    validator: validRole,
  },
];

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  role: "",
};

const columns: GridColDef[] = [
  {
    field: "fullName",
    headerName: "Name",
    editable: false,
    flex: 1,
    filterable: false,
    renderCell: (params) => {
      const initials = [params.row.firstName, params.row.lastName];
      const isUserNameNull = params.row.firstName === null && params.row.lastName === null;
      const displayInitials =
        params.value === isUserNameNull ? "-" : initials.length === 2 && initials[0].charAt(0) + initials[1].charAt(0);
      const displayVal = params.value === isUserNameNull ? "-" : params.value;
      return (
        <div className={classes.avatar_container}>
          <Avatar
            style={{
              background: RED,
              color: WHITE,
              width: "2rem",
              height: "2rem",
              fontSize: "1rem",
              marginRight: "0.5rem",
            }}
          >
            {displayInitials}
          </Avatar>
          {displayVal}
        </div>
      );
    },
  },
  {
    field: "roles",
    headerName: "Roles",
    type: "tags",
    editable: false,
    flex: 1,
    filterable: false,
    renderCell: (params) => {
      return params.value.map((role: any) => (
        <Chip
          style={{
            margin: "0 0.3rem",
            backgroundColor: "#fff",
            paddingLeft: "10px",
          }}
          variant={"outlined"}
          icon={<Storefront color={"primary"} sx={{ width: "16px", height: "16px" }} />}
          label={capitalize(role.roleName)}
        />
      ));
    },
  },
  {
    field: "disabled",
    headerName: "Status",
    editable: false,
    flex: 1,
    filterable: false,
    renderCell: (params) => {
      return statusFormat(params.value ? "inactive" : "active");
    },
  },
  {
    field: "lastLoggedIn",
    headerName: "Last Logged In",
    editable: false,
    flex: 1,
    filterable: false,
    valueFormatter: (params) => DataFormat.DATE(params.value),
  },
];

const Admins = (...props: any) => {
  const [tableIdentifier] = useState("adminList");
  const { handleError } = useContext(NotificationContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [openCreate, setOpenCreate] = useState(false);
  const { filters, setFilters } = useContext(FilterContext);
  // @ts-ignore
  const [currentFilters] = useState(filters["admin"] || {});
  const { tableSettings, setTableSettings } = useContext(TableSettingContext);
  const [currentTableSettings] = useState(tableSettings[tableIdentifier] || props.initialFilters || {});

  function CustomToolbar() {
    return (
      <GridToolbarContainer style={{ justifyContent: "space-between" }}>
        <Tooltip title="Search by: Name">
          <div>
            <GridToolbarQuickFilter />
          </div>
        </Tooltip>
        <Button startIcon={<AddOutlined />} variant={"outlined"} onClick={() => setOpenCreate(true)}>
          New Admin
        </Button>
      </GridToolbarContainer>
    );
  }

  async function fetchData(queryOptions: any) {
    setLoading(true);
    try {
      const data = await get_admins(queryOptions);

      if (data?.result) {
        data.result.forEach((user: any) => (user["roleLabels"] = user.roles?.map((role: any) => role.name)));

        createFields[
          createFields.findIndex((x) => x.id === "email")
          // @ts-ignore
        ].validator = createFields[createFields.findIndex((x) => x.id === "email")].validator.notOneOf(
          // @ts-ignore
          data.result.map((admin) => admin.email),
          "User already exists",
        );
      }

      setData(data);
    } catch (err: any) {
      handleError(err.response?.data?.message || err?.message);
    } finally {
      setLoading(false);
    }
  }

  const saveNewAdmin = async (data: any) => {
    const admin = { newAdmin: data };
    await addAdmin(admin);
    fetchData({
      total: true,
      sortBy: "",
      ascending: true,
      filters: {},
      offset: 0,
      limit: 20000,
    }).then();
  };

  useEffect(() => {
    // @ts-ignore
    filters["admin"] = currentFilters;
    setFilters(filters);
    tableSettings[tableIdentifier] = currentTableSettings;
    setTableSettings(tableSettings);
  }, [currentFilters, filters, currentTableSettings, tableSettings, tableIdentifier, setFilters, setTableSettings]);

  useEffect(() => {
    fetchData({
      total: true,
      sortBy: "",
      ascending: true,
      filters: {},
      offset: 0,
      limit: 20000,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ display: "flex" }}>
      <div className={classes.styled_container}>
        <div className={classes.fixed_header}>
          <>
            <div className={classes.title_container}>
              <SupervisorAccountOutlined
                sx={{
                  height: "45px",
                  width: "45px",
                  marginRight: "0.5rem",
                }}
              />
              <Typography variant={"h3"} className={classes.table_title}>
                Admins
              </Typography>
            </div>
          </>
        </div>
        <DataGrid
          loading={loading}
          density={"comfortable"}
          // @ts-ignore
          rows={data?.result || []}
          columns={columns}
          initialState={{
            columns: {
              columnVisibilityModel: {
                address: false,
              },
            },
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10, 25]}
          disableRowSelectionOnClick
          style={{ borderColor: "transparent" }}
          slots={{ toolbar: CustomToolbar }}
          sx={{
            [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
              outline: "none",
            },
            [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
              outline: "none",
            },
          }}
        />
        <CreateModal
          title="New Admin"
          open={openCreate}
          setOpen={setOpenCreate}
          fetchData={fetchData}
          fields={createFields}
          initialValues={initialValues}
          create_function={saveNewAdmin}
          successMessage="User created"
          validation={validationSchema}
          bodyText={undefined}
          submitButtonText={undefined}
          contentStyle={undefined}
          CustomForm={undefined}
          maxWidth={undefined}
        />
      </div>
      <div className={"secondary-sidebar"} />
    </div>
  );
};

export default Admins;
