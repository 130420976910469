import React, { useContext } from "react";
import { Form, Formik } from "formik";
import moment from "moment/moment";
import DatePicker from "@components/ui-kit/DatePicker";
import Spacer from "@components/ui-kit/Spacer";
import Button from "@mui/material/Button";
import * as yup from "yup";
import { Context } from "../index";

const validationSchema = yup.object().shape(
  {
    startDate: yup
      .date()
      .required()
      .test("beforeDate", "Select a future date", (value) => {
        return !(moment(value).diff(moment(), "day") < 0);
      })
      .test("farDate", "Selected year must be before 3000", (value) => {
        return !(Number(moment(value).format("YYYY")) >= 3000);
      }),
    endDate: yup
      .date()
      .required()
      .when(["startDate", "endDate"], {
        is: (startDate, endDate) => {
          const end = moment(endDate).endOf("day");
          const start = moment(startDate).endOf("day");
          return end.diff(start, "days") < 60;
        },
        then: yup.date().test("minEndDays", "Minimum of 60 days", () => {
          return false;
        }),
      })
      .test("farEndDate", "Selected year must be before 3000", (value) => {
        return !(Number(moment(value).format("YYYY")) >= 3000);
      }),
  },
  [
    ["startDate", "endDate"],
    ["endDate", "endDate"],
  ],
);

const Step1 = () => {
  const { formValues, setFormValues, setActiveStep } = useContext(Context);

  const onSubmit = (values: typeof formValues) => {
    setFormValues(values);
    setActiveStep(1);
  };

  return (
    <>
      <h2>Request temporary disconnect</h2>
      <p className={"body"}>
        Please provide the beginning and ending dates for the disconnection period, along with the reason for the temporary hold.
        Note that the disconnection period must be at least 60 days, and there is a $35 fee for disconnecting and reconnecting
        services.
      </p>
      <Formik initialValues={formValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        {({ setFieldValue, values, isValid, dirty, validateField }) => {
          return (
            <Form
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <p className={"overline_light"}>start date</p>
              <DatePicker
                label={"Add dates"}
                onChange={(value: any) => {
                  setFieldValue("startDate", value);
                  const end = moment(values?.endDate).endOf("day");
                  const start = moment(value).endOf("day");
                  if (values.endDate && end.diff(start, "days") < 60) {
                    setFieldValue("endDate", start.add(60, "day"));
                  }
                  validateField("endDate");
                }}
                value={values.startDate}
              />
              <Spacer amount={2} />
              <p className={"overline_light"}>End date (60 day minimum)</p>
              <DatePicker
                label={"Add dates"}
                onChange={(value: any) => {
                  setFieldValue("endDate", value);
                }}
                value={values.endDate}
                minDate={moment(values.startDate).add(60, "day")}
              />
              <Spacer amount={2} />
              <Button variant={"contained"} fullWidth size={"large"} disabled={!dirty || !isValid} type={"submit"}>
                Next
              </Button>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default Step1;
