import React, { createRef, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Box, Link, useTheme } from "@mui/material";
import Spacer from "@components/ui-kit/Spacer";
import { Form, Formik } from "formik";
import Banner from "@components/ui-kit/Banner";
import { findLicenceByReference, linkLicence } from "@core/apis/licence";
import { LINK_CONTACT_CITY_HALL } from "@core/constants/links";
import * as yup from "yup";
import { NotificationContext } from "@contexts/NotificationContext";
import { InfoOutlined, LinkRounded, Storefront } from "@mui/icons-material";
import Divider from "@components/ui-kit/Divider";
import WizardStepTracker from "@components/ui-kit/wizard-step-tracker";
import { useDispatch } from "react-redux";
import { closeDialog, setState as setConfirmationDialogState } from "../../../../redux/slices/confrmation-dialog";
import { EditingContext } from "@contexts/EditingContext";
import MaskedTextField from "@components/ui-kit/masked-text-field";
import MaskedTextFieldTypes from "@components/ui-kit/masked-text-field/maskedTextFieldTypes";
import { getGlobal } from "@utils/index";
import { LACOMBE_CITY_ID } from "@core/constants";

const steps = [
  { id: "step1", label: "Link your account" },
  { id: "step2", label: "Confirm your account" },
];

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1350px;
  padding-top: 1rem;
  margin-left: 18px;
  margin-right: 18px;
  width: 100%;
`;

const Content = styled.div`
  max-width: 375px;
  margin: 2rem auto 0;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const initialValues = {
  refNumber: "",
};
const validation = yup.object({
  refNumber: yup.string().required("Licence number is required."),
});

/**
 * Page for linking a licence to the user.
 * @returns {JSX.Element}
 * @constructor
 */
const LinkLicence = () => {
  const history = useHistory();
  const [licence, setLicence] = useState(null);
  const { handleError, handleSuccess } = useContext(NotificationContext);
  const dispatch = useDispatch();
  const { setIsEditing, setModalData } = useContext(EditingContext);

  /**
   * If user is in step 2, shows a confirmation dialog.
   * Otherwise, navigates user to landing page
   */
  const onWizardClose = () => {
    if (!!licence) {
      dispatch(
        setConfirmationDialogState({
          open: true,
          title: "You have unsaved changes",
          body: "Closing this will result in losing all your changes. Are you sure you want to close?",
          onConfirm: _onWizardClosedWithChangeSet,
        }),
      );
      return;
    }
    history.replace("/portal");
  };

  /**
   * Callback passed to the confirmation dialog
   * @returns void
   * @private
   */
  const _onWizardClosedWithChangeSet = () => {
    dispatch(closeDialog());
    history.replace("/portal");
  };

  const linkAccount = async () => {
    try {
      const response = await linkLicence(licence.id);
      if (response.status === 401) {
        handleError("Error processing your request");
        return;
      }
      if (response.status === 403) {
        handleError("Licence has been already linked to an account");
      }
      if (response.status === 404) {
        handleError("Licence could not be found");
      }

      if (response.data.licenseId) {
        history.push(`business-licence/${response.data.licenseId}`);
      } else {
        handleSuccess("Licence has been linked");
      }
    } catch (e) {
      handleError("Error linking licence");
    }
  };

  useEffect(() => {
    if (!!licence) {
      setModalData({
        title: "You have unsaved changes",
        body: "Closing this will result in losing all your changes. Are you sure you want to close?",
        actionButtonText: "Confirm",
        variant: "updated",
        buttonTwoVariant: "contained",
        buttonTwoColor: undefined,
      });
      setIsEditing(true);
    } else {
      setIsEditing(false);
    }
  }, [licence, setIsEditing, setModalData]);

  return (
    <Container>
      <div style={{ width: "100%" }}>
        <WizardStepTracker
          steps={steps}
          activeStep={!!licence ? 1 : 0}
          setActiveStep={() => setLicence(null)}
          onClose={onWizardClose}
        />
      </div>
      <Divider width={"100%"} />
      <InnerContainer>
        <Box p={1}>
          <Content>
            {licence ? <LicenceFound licence={licence} linkAccount={linkAccount} /> : <NoLicence setLicence={setLicence} />}
          </Content>
        </Box>
      </InnerContainer>
    </Container>
  );
};

const NoLicence = ({ setLicence }) => {
  const theme = useTheme();
  const formRef = createRef();
  const [validateOnChange, setValidateOnChange] = useState(false);

  const onSubmit = async (values) => {
    try {
      const response = await findLicenceByReference(values.refNumber);

      if (response.status === 401 || response.status === 404) {
        formRef.current.setFieldError("refNumber", "This licence does not exist. Please verify the number");
        return;
      }

      if (response.data) {
        let licenceData = response.data;
        if (response.status === 409) {
          licenceData.isLinked = true;
        } else if (response.status === 406) {
          licenceData.isInactive = true;
        }
        setLicence(licenceData);
        return;
      }

      if (!response.data) {
        formRef.current.setFieldError("refNumber", "This licence does not exist. Please, verify the number.");
        return;
      }

      const licenceData = response.data;
      setLicence(licenceData);
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <>
      <LinkRounded color={"primary"} sx={{ height: "64px", width: "64px" }} />
      <Spacer amount={3} />
      <div>
        <Typography variant={"h4"}>Linking an existing licence</Typography>
        <Spacer />
        <Typography variant={"body1"} color={theme.palette.blacks.BLACK_HIGH_EMPHASIS}>
          When you link a pre-existing licence, you will be able to view it, print it, edit its information, and pay any fees.{" "}
        </Typography>
      </div>
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validation}
        validateOnChange={validateOnChange}
      >
        {({ values, handleChange, errors }) => {
          return (
            <Form
              noValidate
              style={{
                width: "100%",
              }}
            >
              <Spacer amount={2} />
              <MaskedTextField
                type={MaskedTextFieldTypes.NUMBER}
                fullWidth
                label={"Licence number"}
                name={"refNumber"}
                value={values.refNumber}
                error={Boolean(errors.refNumber)}
                helperText={errors.refNumber}
                onChange={handleChange}
              />
              <Spacer amount={0.5} />
              {/* This is temporary until we build out how-to screens */}
              {getGlobal("CCFG_CITY_ID") === LACOMBE_CITY_ID && (
                <Link
                  color={theme.palette.primary[200]}
                  href={"https://lacombeca.sharepoint.com/:x:/g/public/EQkzAXVplb1Il6yUDX3En4sB2RviTNqMq2_0v1FMY4ZiBw?e=qyU9RL"}
                  target={"_blank"}
                >
                  Where do I find my licence number?
                </Link>
              )}
              <Spacer amount={2} />
              <Button size={"large"} variant={"contained"} type={"submit"} onClick={() => setValidateOnChange(true)} fullWidth>
                Continue
              </Button>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

//Licence found functions
const getIcon = (isLinked, isInactive) => (isLinked || isInactive ? "info" : "link");
const getHeader = (isLinked, isInactive) => {
  if (isInactive) {
    return "This licence is inactive";
  }

  return isLinked ? "Is this your licence?" : "Confirm linking";
};
const getBodyText = (isLinked, isInactive) => {
  if (isInactive) {
    return (
      <>
        The licence you are attempting to link is currently inactive. If this is your licence and you want to re-activate it,
        please {/* This is temporary until we build out how-to screens */}
        {getGlobal("CCFG_CITY_ID") === LACOMBE_CITY_ID ? (
          <a rel="noopener noreferrer" target={"_blank"} href={LINK_CONTACT_CITY_HALL}>
            contact City Hall
          </a>
        ) : (
          <>contact City Hall</>
        )}
        .
      </>
    );
  }

  return isLinked ? (
    <>
      This licence is already linked to a user account. Double-check this is indeed your business licence. If you didn't link this
      licence to any other account, please {/* This is temporary until we build out how-to screens */}
      {getGlobal("CCFG_CITY_ID") === LACOMBE_CITY_ID ? (
        <a rel="noopener noreferrer" target={"_blank"} href={LINK_CONTACT_CITY_HALL}>
          contact City Hall
        </a>
      ) : (
        <>contact City Hall</>
      )}
      .
    </>
  ) : (
    "Please, review the information below and confirm this is the licence you want to be linked to."
  );
};

const LicenceFound = ({ licence, linkAccount }) => {
  const theme = useTheme();
  const isLinked = licence?.isLinked;
  const isInactive = licence?.isInactive;

  return (
    <>
      {getIcon(isLinked, isInactive) === "info" ? (
        <InfoOutlined color={"primary"} sx={{ height: "64px", width: "64px" }} />
      ) : (
        <LinkRounded color={"primary"} sx={{ height: "64px", width: "64px" }} />
      )}
      <Spacer amount={3} />
      <div>
        <Typography variant={"h4"}>{getHeader(isLinked, isInactive)}</Typography>
        <Spacer />
        <Typography variant={"body1"} color={theme.palette.blacks.BLACK_HIGH_EMPHASIS}>
          {getBodyText(isLinked, isInactive)}
        </Typography>
      </div>
      <Spacer amount={3} />
      <Banner
        title={`Business licence - ${licence.ref}`}
        body={
          <>
            {licence.address} <br />
            {licence.city}, {licence.province} {licence.postalCode}
          </>
        }
        styles={{ backgroundColor: theme.palette.primary[50] }}
        Icon={<Storefront color={"primary"} sx={{ width: "36px", height: "36px" }} />}
      />
      {isLinked || isInactive ? null : (
        <>
          <Spacer amount={0.5} />
          <Button fullWidth variant={"contained"} size={"large"} onClick={linkAccount}>
            Link licence
          </Button>
        </>
      )}
    </>
  );
};

export default LinkLicence;
