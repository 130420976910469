import React from "react";
import Button from "@mui/material/Button";
import { CloseRounded } from "@mui/icons-material";

export default function CloseButton({ onClick }) {
  return (
    <Button variant={"outlined"} size={"medium"} startIcon={<CloseRounded />} onClick={onClick}>
      Close
    </Button>
  );
}
