import base from "./axiosInstance";
import config from "../apis/_config";

export const get_privacy_policies = async () => {
  try {
    const termsId = "64feda3d-525f-495a-b294-a7597a72fa7a";
    const response = await base.get(`/terms/${termsId}`);
    return [response.data];
  } catch (error) {
    console.log(error);
  }
};

export const get_applicant_declaration = async () => {
  try {
    const id = config.applicantDeclarationId;

    return await base.get(`/terms/${id}`);
  } catch (e) {
    console.log(e);
  }
};
