import React from "react";
import { Skeleton } from "@mui/material";
import Spacer from "@components/ui-kit/Spacer";

const RightSidebarSkeleton = () => {
  return (
    <div className={"admin-detail-sidebar"}>
      <Skeleton variant={"rectangular"} height={60} />
      <Spacer />
      <Skeleton variant={"rectangular"} height={170} />
      <Spacer />
      <Skeleton height={50} />
      <Skeleton height={50} />
    </div>
  );
};

export default RightSidebarSkeleton;
