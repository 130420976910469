import React from "react";

const MissingScreen = () => {
  return (
    <div className={"sample-screen"}>
      <h1>404</h1>
    </div>
  );
};

export default MissingScreen;
