import React, { useContext, useState } from "react";
import Spacer from "@components/ui-kit/Spacer";
import ListItem from "@components/ui-kit/list-item";
import moment from "moment";
import { Context } from "../index";
import { EventOutlined, LocationOnOutlined } from "@mui/icons-material";
import LinkButton from "@components/ui-kit/LinkButton";
import { CheckboxCard } from "@components/ui-kit/CheckboxCard";
import Button from "@mui/material/Button";
import Modal from "@components/ui-kit/Modal";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useHistory } from "react-router-dom";

const Step2 = () => {
  const { formValues, serviceAddress } = useContext(Context);
  const [accepted, setAccepted] = useState(false);
  const [showModalTos, setShowModalTos] = useState(false);
  const [showModalConfirmed, setShowModalConfirmed] = useState(false);
  const history = useHistory();

  const clickableTextFunction = (e: any) => {
    e.stopPropagation();
    setShowModalTos(true);
  };

  const checkBoxClick = (e: any) => {
    e.stopPropagation();
    setAccepted(!accepted);
  };

  const confirmDisconnect = () => {
    setShowModalConfirmed(true);
  };

  const handleConfirmModalClose = () => {
    setShowModalConfirmed(false);
    history.goBack();
  };

  return (
    <>
      <h2>Review before submitting</h2>
      <p className={"body_regular"}>
        Before proceeding with temporarily disconnecting your utilities account, carefully review your information.
      </p>
      <Spacer />
      <p className={"overline_light"}>Review information</p>
      <div className={"outlined_container"}>
        {/*// @ts-ignore*/}
        <ListItem
          startOrnament={<LocationOnOutlined />}
          variant={"detail"}
          label={"Your service address"}
          subLabel={serviceAddress?.value?.value}
        />
        {/*// @ts-ignore*/}
        <ListItem
          startOrnament={<EventOutlined />}
          variant={"detail"}
          label={"Disconnection start date"}
          subLabel={moment(formValues.startDate).format("MMMM D, YYYY")}
        />
        {/*// @ts-ignore*/}
        <ListItem
          startOrnament={<EventOutlined />}
          variant={"detail"}
          label={"Disconnection end date"}
          subLabel={moment(formValues.endDate).format("MMMM D, YYYY")}
        />
      </div>
      <Spacer />
      <p className={"body_regular"}>
        <span className={"bold"}>Please note:</span> City Staff will review your request before processing the temporary hold.
        You'll receive an email once your request is approved. For any questions, call City Hall at (403) 782-6666.
      </p>
      <Spacer />
      <p className={"overline_light"}>Service Agreement</p>
      <CheckboxCard
        label={
          <div style={{ display: "flex" }}>
            <p>I agree to the </p>
            <LinkButton onClick={clickableTextFunction}>Terms of Service</LinkButton>
          </div>
        }
        value={accepted}
        onChange={(e: any) => checkBoxClick(e)}
      />
      <Spacer />
      <Button onClick={confirmDisconnect} variant={"contained"} disabled={!accepted} size={"large"} fullWidth>
        Request Disconnection
      </Button>
      <Spacer />
      <Modal open={showModalTos} title={"Term of Service"} onClose={() => setShowModalTos(false)}>
        <></>
      </Modal>
      <Modal open={showModalConfirmed} onClose={() => setShowModalConfirmed(false)}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            maxWidth: 750,
            width: "100%",
            boxSizing: "border-box",
            padding: "1.5rem 3rem",
          }}
        >
          <div
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              maxWidth: 453,
              width: "100%",
            }}
          >
            <CheckCircleOutlineIcon style={{ fontSize: 72, marginBottom: "1rem" }} color={"primary"} />
            <h2>Your temporary disconnect request has been sent</h2>
            <p className={"body"} style={{ lineHeight: "22.4px" }}>
              Your service disconnection request has been submitted to City Hall. Please allow a few days for review and
              processing. Once approved, you'll receive a confirmation email. Thank you for your patience!
            </p>
            <Button style={{ width: 300 }} size={"large"} variant={"contained"} onClick={handleConfirmModalClose}>
              Continue
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Step2;
