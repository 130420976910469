import React, { createContext, useContext, useEffect, useMemo, useRef } from "react";
import styled from "@emotion/styled";
import { SidebarContext } from "@contexts/SidebarContext";
import { useHistory, useLocation } from "react-router-dom";
import { getAuthType, getScreenOptions, routes } from "@routes/routesData";
import { manageSession } from "@utils/manageSession";
import { ADMIN_AUTH_TYPE, USER_AUTH_TYPE } from "@core/constants/authTypes";

const StyledMain = styled.div`
  height: ${({ hideHeader, header }) => (hideHeader ? "100vh" : `calc(100vh - ${header})`)};
  margin-top: ${({ header }) => (header ? header : "0")};
  margin-left: ${({ hideSidebar, sidebarWidth }) => (hideSidebar ? 0 : `calc(${sidebarWidth} + 1rem)`)};
  overflow: auto;
  position: relative;
`;

export const checkAdminName = async (history) => {
  const token = manageSession.getSessionInfo()?.userToken;
  if (!token) {
    history.push(routes.LOGIN.path);
  }
};

export function mainEffect(history) {
  switch (history.location.pathname) {
    case routes.REGISTER.path:
      return;
    case routes.RESET_PASSWORD.path:
      return;
    case routes.SEND_RESET_LINK.path:
      return;
    case routes.TWO_FACTOR_AUTHENTICATION.path:
      return;
    default:
      checkAdminName(history);
  }
}

export const ScrollContext = createContext(null);

export default function Main({ children, ...rest }) {
  const history = useHistory();
  const bodyRef = useRef();

  const scrollToTop = () => {
    bodyRef.current.scrollTop = 0;
  };

  const scrollToTopContext = useMemo(() => {
    return {
      scrollToTop,
    };
  }, []);

  useEffect(() => {
    const unlisten = history.listen(() => {
      scrollToTop();
    });
    return () => {
      unlisten();
    };
  }, [history]);

  useEffect(() => {
    mainEffect(history);
  }, [history]);

  const location = useLocation();
  const screenOptions = getScreenOptions(location.pathname);
  const authType = getAuthType(location.pathname);
  // title attribute on main element generates a default HTML5 tooltip
  const { title, hideSidebar, hideHeader, ...displayOptions } = screenOptions;
  const { sidebarWidth } = useContext(SidebarContext);

  return (
    <ScrollContext.Provider value={scrollToTopContext}>
      <StyledMain
        {...rest}
        ref={bodyRef}
        header={!hideHeader ? (authType === USER_AUTH_TYPE ? "90px" : "64px") : 0}
        hideSidebar={hideSidebar || authType !== ADMIN_AUTH_TYPE}
        {...displayOptions}
        sidebarWidth={sidebarWidth}
      >
        {children}
      </StyledMain>
    </ScrollContext.Provider>
  );
}
