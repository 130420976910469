import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import { css, MenuItem, Select, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import amexIcon from "@assets/creditCard/AmEx.svg";
import discoverIcon from "@assets/creditCard/Discover.svg";
import mastercardIcon from "@assets/creditCard/MasterCard.svg";
import visaIcon from "@assets/creditCard/Visa.svg";
import { PaymentProfileContext } from "@contexts/PaymentProfileContext";
import Spacer from "../layout-helpers/Spacer";
import { AddRounded, ExpandLessRounded, ExpandMoreRounded } from "@mui/icons-material";

//Constants
const CARD_LIMIT = 5;

const InnerContainer = styled.div`
  align-items: center;
  display: flex;
`;

const StyledSelect = styled(Select)`
  &&.MuiOutlinedInput-root {
    min-height: 69px;
    margin-right: 1rem;
    border-radius: 10px;
  }

  & .material-symbols-rounded {
    margin-right: 10px;
  }
`;

const EditButton = styled(Button)(
  ({ theme }) => css`
    &:hover {
      background-color: ${theme.palette.whites.WHITE};
    }
  `,
);

const AutoPayChip = styled(Chip)(
  ({ theme }) => css`
    background-color: ${theme.palette.primary[100]};

    & * {
      color: ${theme.palette.primary[200]};
      font-weight: 500;
    }
  `,
);

const ExpiredChip = styled(Chip)(
  ({ theme }) => css`
    background-color: ${theme.palette.nonPalette.RED_BACKGROUND};

    & * {
      color: ${theme.palette.nonPalette.RED};
      font-weight: 500;
    }
  `,
);

const getIconFromShortName = (shortName) => {
  let icon;
  switch (shortName) {
    case "PV": // debit
    case "VI": // credit
      icon = "visa";
      break;
    case "MD": // debit
    case "MC": // credit
      icon = "mastercard";
      break;
    case "AM":
      icon = "amex";
      break;
    case "NN":
      icon = "discover";
      break;
    default:
      icon = "visa";
      break;
  }
  return icon;
};

const getSrcIcon = (icon) => {
  let srcIcon;
  switch (icon) {
    case "mastercard":
      srcIcon = mastercardIcon;
      break;
    case "visa":
      srcIcon = visaIcon;
      break;
    case "discover":
      srcIcon = discoverIcon;
      break;
    case "amex":
      srcIcon = amexIcon;
      break;
    default:
      srcIcon = "";
  }
  return srcIcon;
};

const initialValue = (theme, history) => {
  return {
    id: "ADD",
    value: "ADD",
    label: (
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          color: theme.palette.primary[200],
          justifyContent: "space-between",
        }}
        onClick={(e) => {
          e.stopPropagation();
          history.push("/cc-add");
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <AddRounded sx={{ marginRight: "0.75rem" }} />
          <Typography variant={"body1"} color={theme.palette.primary[200]}>
            New payment method
          </Typography>
        </div>
      </div>
    ),
  };
};

const StyledDropdown = ({
  selectedPaymentMethod,
  paymentProfiles,
  setSelectedPaymentMethod,
  setPaymentProfileId,
  setLastFourDigits,
  getDefaultValue,
  setIsSelectedCardExpired,
}) => {
  const [selectOpen, setSelectOpen] = useState(false);
  const theme = useTheme();
  const history = useHistory();
  const [value, setValue] = useState(initialValue(theme, history));
  const [options, setOptions] = useState([]);

  const handleChange = useCallback(
    (selectedId) => {
      if (!paymentProfiles) return;
      const paymentProfile = paymentProfiles.filter(({ id }) => id === selectedId)[0];
      if (!paymentProfile) return;
      setIsSelectedCardExpired(paymentProfile.card.isExpired);
      const iconName = getIconFromShortName(paymentProfile.card.cardType);
      const icon = getSrcIcon(getIconFromShortName(paymentProfile.card.cardType));
      const lastFourDigitsCC = paymentProfile.card.number;
      setSelectedPaymentMethod({
        id: selectedId,
        icon,
        lastFourDigitsCC,
        iconName,
        isExpired: paymentProfile.card.isExpired,
      });
      // Set form value
      if (typeof setPaymentProfileId === "function") setPaymentProfileId(selectedId);
      // Set last 4 digits
      if (typeof setLastFourDigits === "function") setLastFourDigits(lastFourDigitsCC);
      // Set select component value
      setValue(selectedId);
    },
    [paymentProfiles, setIsSelectedCardExpired, setLastFourDigits, setPaymentProfileId, setSelectedPaymentMethod],
  );

  const getOptions = useCallback(
    (paymentProfiles) => {
      const limitReached = paymentProfiles?.length >= CARD_LIMIT;
      const limitReachedColor = limitReached ? theme.palette.blacks.BLACK_MEDIUM_EMPHASIS : theme.palette.primary[200];
      const addPaymentMethodOption = {
        id: "ADD",
        value: "ADD",
        disable: limitReached,
        label: (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              color: theme.palette.primary[200],
              justifyContent: "space-between",
              paddingTop: 24,
              paddingBottom: 24,
            }}
            onClick={(e) => {
              if (limitReached) return;
              e.stopPropagation();
              history.push("/cc-add");
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "1rem",
              }}
            >
              <AddRounded sx={{ marginRight: "0.75rem" }} />
              <Typography variant={"body1"} color={limitReachedColor}>
                New payment method
              </Typography>
            </div>
            <Typography variant={"body2"} color={theme.palette.blacks.BLACK_LOW_EMPHASIS}>
              {paymentProfiles?.length ? paymentProfiles?.length : 0}/{CARD_LIMIT} cards
            </Typography>
          </div>
        ),
      };

      const handleClickEdit = (e, id, card, isDefault) => {
        e.stopPropagation();
        history.push(`/cc-add`, {
          profileId: id,
          profileData: card,
          isDefault,
        });
      };

      return !paymentProfiles
        ? [addPaymentMethodOption]
        : [
            addPaymentMethodOption,
            ...paymentProfiles.map(({ id, card }) => {
              const isAutoPay = card.autoPay;
              const isExpired = card.isExpired;
              const icon = getIconFromShortName(card.cardType);
              const srcIcon = getSrcIcon(icon);
              return {
                id,
                value: id,
                label: (
                  <div
                    key={id}
                    style={{
                      padding: "1rem 1rem 1rem 2rem",
                      paddingRight: "0",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Typography
                          variant="h4"
                          margin="0 1rem 0 0"
                          whiteSpace="nowrap"
                          overflow="hidden"
                          textOverflow="ellipsis"
                        >
                          {card.number !== null ? `**** **** **** ${card.number}` : "None"}
                        </Typography>
                      </div>
                      <Spacer />
                      <div>
                        <Grid container alignItems="center" justifyContent="column" spacing={2}>
                          {isAutoPay && !isExpired ? (
                            <Grid item>
                              <AutoPayChip variant="contained" label="Auto-pay" />
                            </Grid>
                          ) : isExpired ? (
                            <Grid item>
                              <ExpiredChip variant="contained" label="Expired" />
                            </Grid>
                          ) : null}
                          <Grid item>
                            <EditButton
                              variant="outlined"
                              size={"small"}
                              onClick={(e) => handleClickEdit(e, id, card, isAutoPay)}
                            >
                              Edit
                            </EditButton>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                    <div>{icon !== null ? <img alt={""} src={srcIcon} style={{ height: 20, width: 55 }} /> : null}</div>
                  </div>
                ),
              };
            }),
          ];
    },
    [history, theme],
  );

  useEffect(() => {
    setOptions(getOptions(paymentProfiles));
    if (!paymentProfiles) {
      setSelectedPaymentMethod({
        id: "ADD",
        icon: null,
        lastFourDigitsCC: null,
        isExpired: false,
      });
      setValue(initialValue(theme, history));
    } else {
      const defaultValue = getDefaultValue(paymentProfiles);
      if (defaultValue) {
        handleChange(defaultValue.id);
      }
    }
    // eslint-disable-next-line
  }, [paymentProfiles]);

  return (
    <>
      <StyledSelect
        IconComponent={() =>
          selectOpen ? <ExpandLessRounded sx={{ marginRight: "0.5rem" }} /> : <ExpandMoreRounded sx={{ marginRight: "0.5rem" }} />
        }
        open={selectOpen}
        onClick={() => {
          setSelectOpen(!selectOpen);
        }}
        onMouseDown={(e) => {
          e.preventDefault();
        }}
        renderValue={() => (
          <InnerContainer>
            {value.id === "ADD" ? (
              value.label
            ) : selectedPaymentMethod.icon !== null ? (
              <img alt={""} src={selectedPaymentMethod.icon} style={{ height: 20, width: 55 }} />
            ) : null}
            <Typography
              color={selectedPaymentMethod.isExpired ? "error" : ""}
              variant="h6"
              margin="0 0 0 1rem"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {selectedPaymentMethod.lastFourDigitsCC !== null
                ? `**** ${selectedPaymentMethod.lastFourDigitsCC} ${selectedPaymentMethod.isExpired ? "(Expired)" : ""}`
                : value.id === "ADD"
                  ? null
                  : "None"}
            </Typography>
          </InnerContainer>
        )}
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        style={{ width: "100%" }}
      >
        {options.map((option, index) => (
          <MenuItem
            disabled={option.disable ? option.disable : false}
            key={option.value}
            value={option.value}
            divider={index < options.length - 1}
          >
            {option.label}
          </MenuItem>
        ))}
      </StyledSelect>
    </>
  );
};

const PaymentMethodBar = ({ setPaymentProfileId, setLastFourDigits, setIsSelectedCardExpired }) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({
    id: "ADD",
    icon: null,
    lastFourDigitsCC: null,
  });
  const { paymentProfiles, getPaymentProfiles, getDefaultValue } = useContext(PaymentProfileContext);

  useEffect(() => {
    getPaymentProfiles();
    // eslint-disable-next-line
  }, []);

  return (
    <StyledDropdown
      getDefaultValue={getDefaultValue}
      selectedPaymentMethod={selectedPaymentMethod}
      paymentProfiles={paymentProfiles}
      setSelectedPaymentMethod={setSelectedPaymentMethod}
      setPaymentProfileId={setPaymentProfileId}
      setLastFourDigits={setLastFourDigits}
      setIsSelectedCardExpired={setIsSelectedCardExpired}
    />
  );
};

export default PaymentMethodBar;
