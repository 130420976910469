import React, { useState } from "react";
import Button from "@mui/material/Button";
import classes from "./index.module.scss";
import typographyClasses from "@assets/styles/typography/typography.module.scss";
import { IStatementBalanceCard } from "./IStatementBalanceCard";
import Spacer from "@components/ui-kit/Spacer";
import { currency, formatCurrency } from "@utils/index";
import { Skeleton, Typography } from "@mui/material";
import { ErrorOutline } from "@mui/icons-material";
import Modal from "ui/components/ui-kit/Modal";
import { useHistory } from "react-router-dom";

export default function StatementBalanceCard({
  id,
  statementAmount,
  accountAmount,
  paidAmount,
  dueDate,
  loading,
  onClick,
}: IStatementBalanceCard) {
  const [showBalanceModal, setShowBalanceModal] = useState(false);
  const history = useHistory();

  return !loading ? (
    <div className={classes.statement_balance_card_container}>
      <p className={typographyClasses.overline_bold}>statement balance</p>
      <p className={typographyClasses.currency_text_large}>{currency(statementAmount)}</p>
      {statementAmount > 0 ? (
        <>
          <p className={typographyClasses.body}>Account Balance: {formatCurrency(accountAmount)}</p>
          <div style={{ display: "flex" }}>
            <div rel={"noreferrer"} className={classes.balance_card_links} onClick={() => history.push(`/utility/${id}#2`)}>
              <u>{"Last payment received:"}</u>
            </div>
            <Typography className={typographyClasses.body}>{"\u00A0" + formatCurrency(paidAmount)}</Typography>
          </div>
          <p className={typographyClasses.body}>{dueDate}</p>
          <Spacer />
          <Button onClick={onClick} variant={"contained"} size={"large"} fullWidth>
            Pay now
          </Button>
          <Spacer />
        </>
      ) : null}
      <div style={{ display: "flex", alignItems: "center" }}>
        <ErrorOutline className={classes.what_is_payment_icon} />
        <div
          rel={"noreferrer"}
          onClick={() => {
            setShowBalanceModal(true);
          }}
          className={classes.balance_card_links}
        >
          <u>{"Learn more about your balance."}</u>
        </div>
      </div>
      <Modal
        open={showBalanceModal}
        title="About Your Balance"
        onClose={() => {
          setShowBalanceModal(false);
        }}
      >
        <div className={classes.more_about_balance_modal_body}>
          <p className={typographyClasses.body}>
            Your statement balance is a snapshot of your charges and payments during a billing cycle - it's what you owe at the
            end of that period. Your account balance is the total amount you currently owe, which might include new charges after
            your statement was generated. Think of the statement balance as a monthly summary, and the account balance as your
            real-time amount due.
          </p>
        </div>
      </Modal>
    </div>
  ) : (
    <Skeleton height={400} />
  );
}
