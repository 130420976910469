import { useContext, useEffect } from "react";
import { AuthContext } from "@contexts/AuthContext";
import { routes } from "@routes/routesData";
import { useLocation } from "react-router-dom";

const pathsWithoutAuth = Object.values(routes)
  .filter((screen) => screen.skipAuth)
  .map((screen) => screen.path);

function SessionWatcher() {
  const { authenticate } = useContext(AuthContext);
  const location = useLocation();
  useEffect(() => {
    const interval = setInterval(() => {
      if (!pathsWithoutAuth.includes(location.pathname)) {
        authenticate("", true);
      }
    }, 500);
    return () => clearInterval(interval);
  });

  return null;
}

export default SessionWatcher;
