import base from "./axiosInstance";
import { manageSession } from "@utils/manageSession";
import { CANCELLED, DISCARDED, EXPIRING, SAVED, SUBMITTED } from "../constants/licences";
import ITag from "../interfaces/ITag";

export const getLicense = async (id: string) => {
  try {
    const response = await base.get(`/license/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const add_reviewer = async (userId: string, licenseId: string, targetType: string, section?: string) => {
  const token = manageSession.getSessionInfo()?.userToken;
  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    let response;
    if (targetType === "utility_account") {
      response = await base.post(`/account/${licenseId}/reviewer`, {
        user_id: userId,
        section: section,
      });
    } else {
      response = await base.post(`/license/${licenseId}/reviewer`, {
        user_id: userId,
      });
    }

    return response.data;
  } catch (error) {}
};

export const createLicence = async () => {
  const response = await base.post(`/license/`);
  return response.data;
};

export const updateStatusDiscarded = async (id: string) => {
  const response = await base.post(`/license/${id}/status`, {
    newState: DISCARDED,
  });
  return response.data;
};

export const updateChangeSet = async (licenceId: string, changeId: string, data: object, email: string) => {
  const dataScheme = {
    updates: data,
    sendEmail: email,
  };
  try {
    const response = await base.put(`/license/${licenceId}/change/${changeId}`, dataScheme);
    return response.data;
  } catch (error) {}
};

export const updateLicence = async (id: string, data: object) => {
  const dataScheme = {
    updates: data,
  };

  try {
    const response = await base.put(`/license/${id}`, dataScheme);
    return response.data;
  } catch (error) {}
};

export const getUserLicenses = async () => {
  const response = await base.get(`/license/mch/summary/`);
  return {
    licences: response.data?.result,
    autoPay: response.data?.autopay,
  };
};

export const remove_reviewer = async (userId: string, licenseId: string, targetType: string, section?: string) => {
  const token = manageSession.getSessionInfo()?.userToken;
  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    let response;
    if (targetType === "utility_account") {
      response = await base.delete(`/account/${licenseId}/reviewer/${userId}?section=${section}`);
    } else {
      response = await base.delete(`/license/${licenseId}/reviewer/${userId}`);
    }

    return response.data;
  } catch (error) {}
};

export const add_issuer = async (userId: string, licenseId: string, targetType: string, section?: string) => {
  const token = manageSession.getSessionInfo()?.userToken;
  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    let response;
    if (targetType === "utility_account") {
      response = await base.post(`/account/${licenseId}/issuer`, {
        user_id: userId,
        section: section,
      });
    } else {
      response = await base.post(`/license/${licenseId}/issuer`, {
        user_id: userId,
      });
    }
    return response.data;
  } catch (error) {}
};

export const remove_issuer = async (userId: string, licenseId: string, targetType: string, section?: string) => {
  const token = manageSession.getSessionInfo()?.userToken;

  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    let response;
    if (targetType === "utility_account") {
      response = await base.delete(`/account/${licenseId}/issuer/${userId}?section=${section}`);
    } else {
      response = await base.delete(`/license/${licenseId}/issuer/${userId}`);
    }

    return response.data;
  } catch (error) {}
};

export const create_business_license = async () => {
  const token = manageSession.getSessionInfo()?.userToken;
  base.defaults.headers["Authorization"] = `Bearer ${token}`;
  const { licenseId } = (await base.post(`/license/`)).data;
  if (!licenseId) throw Error();
  return licenseId;
};

// TODO: add types for params
// TODO: investigate why the filters property needs to be stringified separately
export const get_licenses_summaries = async (param: any) => {
  const token = manageSession.getSessionInfo()?.userToken;
  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    const params = { ...param, filters: JSON.stringify(param.filters) };
    const response = await base.get(`/license/summary`, { params });
    return response.data;
  } catch (error) {}
};

export const removeLinkedUsers = async (licenseId: string) => {
  const token = manageSession.getSessionInfo()?.userToken;
  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    const response = await base.put(`/license/${licenseId}/removeLinkedUsers`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const update_license = async (id: string, data: any, waive: boolean | null = false || null, email?: string) => {
  const token = manageSession.getSessionInfo()?.userToken;

  try {
    const payload: any = {
      updates: data,
      waiveFees: waive,
      sendEmail: email,
    };
    if (waive === null) {
      delete payload["waiveFees"];
    }
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    const response = await base.put(`/license/${id}`, payload);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getLinkedUsers = async (licenseId: string) => {
  const token = manageSession.getSessionInfo()?.userToken;
  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    const response = await base.get(`/license/${licenseId}/linkedUsers`);
    return response.data;
  } catch (error) {}
};

export const update_status_submitted = async (id: string) => {
  const token = manageSession.getSessionInfo()?.userToken;
  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    const response = await base.post(`/license/${id}/status`, {
      newState: "submitted",
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const get_reviewer = async (licenseId: string) => {
  const token = manageSession.getSessionInfo()?.userToken;
  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    const response = await base.get(`/license/${licenseId}/reviewer`);
    return response.data;
  } catch (error) {}
};

export const get_issuer = async (licenseId: string) => {
  const token = manageSession.getSessionInfo()?.userToken;
  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    const response = await base.get(`/license/${licenseId}/issuer`);
    return response.data;
  } catch (error) {}
};

export const get_licenses_summaries_by_id = async (id: string) => {
  const token = manageSession.getSessionInfo()?.userToken;

  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    const response = await base.get(`/license/summary/${id}`);
    return response.data;
  } catch (error) {}
};

export const applyChangesToLicence = async (licenseId: string, changesId: string, sendEmail: any) => {
  try {
    const params = new URLSearchParams();
    params.append("sendEmail", sendEmail);
    const response = await base.get(`/license/${licenseId}/change/${changesId}/apply?${params.toString()}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getPDF = async (id: string) => {
  try {
    const response = await base.get(`/license/${id}/pdf`, {
      responseType: "blob",
    });
    return response.data;
  } catch (error) {}
};

export const mark_license_paid = async (licenseId: string) => {
  const token = manageSession.getSessionInfo()?.userToken;
  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    const response = await base.put(`/license/${licenseId}/pay`);
    return response.data;
  } catch (error) {}
};

export const renewLicence = async (id: string, sendEmail = "true") => {
  try {
    const params = new URLSearchParams();
    params.append("sendEmail", sendEmail);
    const response = await base.put(`/license/${id}/renew?${params.toString()}`);
    return response.data;
  } catch (error) {}
};

export const expireLicence = async (id: string, sendEmail: string) => {
  try {
    const params = new URLSearchParams();
    params.append("sendEmail", sendEmail);
    const response = await base.put(`/license/${id}/expire?${params.toString()}`);
    return response.data;
  } catch (error) {}
};

export const restartStatus = async (id: string) => {
  try {
    const response = await base.post(`/license/${id}/status`, {
      newState: "restart",
    });
    return response.data;
  } catch (error) {}
};

export const update_reviewer_status = async (id: string, status: string) => {
  const token = manageSession.getSessionInfo()?.userToken;

  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    const response = await base.put(`/license/${id}/reviewer`, {
      status: status,
    });
    return response.data;
  } catch (error) {}
};

export const update_status_discarded = async (id: string) => {
  const token = manageSession.getSessionInfo()?.userToken;
  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    const response = await base.post(`/license/${id}/status`, {
      newState: DISCARDED,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateChangeSetStatus = async (licenceId: string, changeId: string, data: any, sendEmail: any) => {
  const dataScheme = {
    status: data,
    sendEmail: sendEmail,
  };
  try {
    const response = await base.put(`/license/${licenceId}/change/${changeId}/status`, dataScheme);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateStatusDeclined = async (id: string, voidOpenInvoices: boolean) => {
  try {
    const response = await base.post(`/license/${id}/status`, {
      newState: "declined",
      voidOpenInvoices: voidOpenInvoices,
    });
    return response.data;
  } catch (error) {}
};

export const updateStatusRevoked = async (id: string, sendEmail: boolean | null, voidOpenInvoices: boolean) => {
  try {
    const response = await base.post(`/license/${id}/status`, {
      newState: "revoked",
      sendEmail: !!sendEmail,
      voidOpenInvoices: voidOpenInvoices,
    });
    return response.data;
  } catch (error) {}
};

export const updateStatusIssued = async (id: string, waive: boolean) => {
  try {
    const response = await base.post(`/license/${id}/status`, {
      newState: "issued",
      waiveFees: waive,
    });
    return response.data;
  } catch (error) {}
};

export const getLicenceTransactions = async (id: string, param: any) => {
  const token = manageSession.getSessionInfo()?.userToken;

  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    const params = { ...param, filters: JSON.stringify(param.filters) };
    const response = await base.get(`/license/${id}/transactions`, { params });
    return response.data;
  } catch (error) {}
};

export const linkUserToLicence = async (licenseId: string, userId: string) => {
  try {
    const response = await base.put(`/license/${licenseId}/link?userId=${userId}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getStatus = async (id: string) => {
  try {
    const response = await base.get(`/license/${id}/status`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateStatusSaved = async (id: string) => {
  try {
    const response = await base.post(`/license/${id}/status`, {
      newState: SAVED,
    });
    return response.data;
  } catch (error) {}
};

export const getLicence = async (id: string) => {
  const response = await base.get(`/license/${id}`);
  return response.data;
};

export const createChangeSet = async (licenceId: string, step: string) => {
  try {
    const dataScheme = {
      step: step,
    };
    return await base.post(`/license/${licenceId}/change`, dataScheme);
  } catch (e) {
    return e;
  }
};

export const getLicenceSummary = async (id: string) => {
  try {
    const response = await base.get(`/license/summary/${id}`);
    return response.data;
  } catch (error) {}
};

export const updateStatusSubmitted = async (id: string) => {
  try {
    const response = await base.post(`/license/${id}/status`, {
      newState: SUBMITTED,
    });
    return response.data;
  } catch (error) {}
};

export const updateStatusCancelled = async (id: string) => {
  try {
    const response = await base.post(`/license/${id}/status`, {
      newState: CANCELLED,
    });
    return response.data;
  } catch (error) {}
};

export const updateStatusExpiring = async (id: string) => {
  try {
    const response = await base.post(`/license/${id}/status`, {
      newState: EXPIRING,
    });
    return response.data;
  } catch (error) {}
};

export const getLicenceInvoicePDF = async (licenseId: string, invoiceNumber: string) => {
  const token = manageSession.getSessionInfo()?.userToken;
  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    const response = await base.get(`/license/${licenseId}/invoice/${invoiceNumber}/pdf`, {
      responseType: "blob",
    });
    return response.data;
  } catch (error) {}
};

export const getLicenseInvoices = async (id: string, params: any) => {
  try {
    const response = await base.get(`/license/${id}/invoices`, { params });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const findLicenceByReference = async (ref: any) => {
  try {
    return await base.get(`/license/link?reference=${ref}`);
  } catch (e: any) {
    if (e.response !== undefined) return e.response;
    else return e;
  }
};

export const linkLicence = async (licenceId: any) => {
  try {
    return await base.put(`/license/link?licenseId=${licenceId}`);
  } catch (e) {
    return e;
  }
};

export const getLicenceTags = async (licenceId: string) => {
  return await base.get(`/license/${licenceId}/tags`);
};

export const addLicenceTag = async (licenceId: string, tags: ITag[]) => {
  return await base.post(`/license/${licenceId}/tags`, { tags: tags });
};

export const removeLicenceTag = async (licenceId: string, tags: ITag[]) => {
  const tagIds = tags.map((tag) => tag.tagId);
  return await base.delete(`/license/${licenceId}/tags`, {
    data: { tagIds: tagIds },
  });
};
