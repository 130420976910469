import React, { createContext, useCallback, useEffect, useMemo, useState } from "react";

export const NotificationContext = createContext({
  message: "",
  open: false,
  handleClose: (e, r) => {},
  handleError: (m, nextRoute = "") => {},
  handleSuccess: (m) => {},
  handleShow: (m, t, i, p, s) => {},
  title: "",
  primaryButtonLabel: "",
  secondaryButtonLabel: "Dismiss",
});

export const NotificationContextProvider = (props) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState(null);
  const [primaryButtonLabel, setPrimaryButtonLabel] = useState(null);
  const [secondaryButtonLabel, setSecondaryButtonLabel] = useState("Dismiss");

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (open) {
        handleClose();
      }
    }, 3000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [open]);

  /**
   * Resets and closes the notification bar.
   * @param event
   * @param reason
   */
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setTitle(null);
    setMessage(null);
    setPrimaryButtonLabel(null);
    setSecondaryButtonLabel("Dismiss");
  };

  /**
   * @Deprecated
   * @param message
   */
  const handleError = (message) => {
    if (open) {
      setOpen(false);
    }

    if (!message || typeof message !== "string") {
      message = "Something went wrong";
    }

    setMessage(message);
    setOpen(true);
  };

  /**
   * @Deprecated
   * @param message
   */
  const handleSuccess = (message) => {
    if (open) {
      setOpen(false);
    }

    if (!message || typeof message !== "string") {
      message = "Success!";
    }

    setMessage(message);
    setOpen(true);
  };

  /**
   * Calling this function shows the notification bar
   * @param message
   * @param title
   * @param iconName
   * @param primaryButtonLabel
   * @param secondaryButtonLabel
   */
  const handleShow = useCallback((message, title, iconName, primaryButtonLabel, secondaryButtonLabel) => {
    if (!message || typeof message !== "string") {
      message = "Success!";
    }

    setMessage(message);
    if (title) {
      setTitle(title);
    }
    if (primaryButtonLabel) {
      setPrimaryButtonLabel(primaryButtonLabel);
    }
    if (secondaryButtonLabel) {
      setSecondaryButtonLabel(secondaryButtonLabel);
    }

    setOpen(true);
  }, []);

  const notificationContext = useMemo(() => {
    return {
      message,
      open,
      handleClose,
      handleError,
      handleSuccess,
      title,
      primaryButtonLabel,
      secondaryButtonLabel,
      handleShow,
    };
    // eslint-disable-next-line
  }, [message, open]);

  return <NotificationContext.Provider value={notificationContext}>{props.children}</NotificationContext.Provider>;
};
