import axios from "axios";
import fileDownload from "js-file-download";

/**
 * Allows us to download files from an S3 download url
 * @param url
 * @param fileName
 */
export const download = async (url: string, fileName: string) => {
  await axios
    .get(url, {
      responseType: "blob",
    })
    .then((res: { data: string | ArrayBuffer | ArrayBufferView | Blob }) => {
      fileDownload(res.data, fileName, "application/pdf");
    });
};
