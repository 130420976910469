import base from "./axiosInstance";
import { manageSession } from "@utils/manageSession";
import { jwtDecode } from "jwt-decode";
import { generateAvatarColor } from "@utils/index";

export const get_admin_by_id = async (id: string) => {
  const token = manageSession.getSessionInfo()?.userToken;
  if (!id) {
    // @ts-ignore
    id = jwtDecode(token).id;
  }
  try {
    const response = await base.get(`/admin/${id}`);
    return response.data;
  } catch (error) {}
};

//TODO: add the type interfaces for params, decoded result, and the admin object
export const get_admins = async (params?: any) => {
  const token = manageSession.getSessionInfo()?.userToken;
  const decoded: any = jwtDecode(token);
  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    const response = await base.get(`/admin/${decoded.cityId}/summaries`, {
      params,
    });
    response.data.result.forEach(
      (admin: { color: string; firstName: any; lastName: any }) =>
        (admin.color = generateAvatarColor(admin.firstName + admin.lastName)),
    );
    return response.data;
  } catch (error) {}
};

// TODO: add the types and check the base url for this api call
export const get_admins_export = async (params: any) => {
  const token = manageSession.getSessionInfo()?.userToken;

  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    const response = await base.get("adminexport", { params });
    return response.data;
  } catch (error) {}
};
