import base from "./axiosInstance";
import { manageSession } from "@utils/manageSession";
import axios from "axios";

export const download_attachment = async (id: string, licenceId: string) => {
  const token = manageSession.getSessionInfo()?.userToken;
  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    const response = await base.get(`/attachment/${id}?licenseId=${licenceId}`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const get_attachment_info = async (id: string, licenceId: string) => {
  const token = manageSession.getSessionInfo()?.userToken;
  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    const response = await base.get(`/attachment/${id}/info?licenseId=${licenceId}`);
    return response.data;
  } catch (error) {}
};

export const upload_file = async (file: any) => {
  const token = manageSession.getSessionInfo()?.userToken;
  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    const setupResponse = await base.post(`/attachment/?filename=${file.name}`);
    const attachmentId = setupResponse.data.attachmentId;
    const s3Url = setupResponse.data.s3TargetUrl;

    await axios.put(`${s3Url}`, file, {
      headers: {
        "Content-Type": file.type,
      },
    });

    await base.put(`/attachment/${attachmentId}/`, {
      originalName: file.name,
      fileSize: Number(file.size),
      fileType: file.type,
      fileLabel: file.name,
      uploadStatus: "completed",
    });
    return attachmentId;
  } catch (error) {
    return error;
  }
};

export const getAttachment = async (id: string, licenceId: string) => {
  try {
    const response = await base.get(`/attachment/${id}/info?licenseId=${licenceId}`);
    return response.data;
  } catch (error) {}
};

export const editAttachment = async (attachmentId: string, fileName: string, licenceId: string) => {
  try {
    const oldFile = await base.get(`/attachment/${attachmentId}/info?licenseId=${licenceId}`);

    const response = await base.put(`/attachment/${attachmentId}?licenseId=${licenceId}`, {
      fileSize: Number(oldFile.data.fileSize),
      fileLabel: fileName,
      uploadStatus: "completed",
    });

    return response.data;
  } catch (error) {}
};

export const uploadFile = async (file: File) => {
  try {
    const setupResponse = await base.post(`/attachment?filename=${file.name}`);
    const attachmentId = setupResponse.data.attachmentId;
    const s3Url = setupResponse.data.s3TargetUrl;

    await axios.put(`${s3Url}`, file, {
      headers: {
        "Content-Type": file.type,
      },
    });

    await base.put(`/attachment/${attachmentId}/`, {
      originalName: file.name,
      fileSize: Number(file.size),
      fileType: file.type,
      fileLabel: file.name,
      uploadStatus: "completed",
    });

    return attachmentId;
  } catch (error) {}
};
