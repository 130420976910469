import React, { useMemo } from "react";
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";
import {
  CheckCircleRounded,
  DangerousRounded,
  EditRounded,
  FindInPageRounded,
  InfoRounded,
  WarningRounded,
} from "@mui/icons-material";

const AlertContainer = styled.div`
  ${({ width }) => {
    switch (width) {
      case width:
        return `
        width: ${width};
      `;
      default:
        return `
          width: 340px;
          `;
    }
  }}
  ${({ backgroundColor }) => `background-color: ${backgroundColor};`}
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 10px !important;
}
`;

const StyledAlert = styled.div`
  display: flex;
  width: 100% !important;
`;

const Alerts = ({ variant, title, body, width = undefined, style = {}, bodyStyle = undefined }) => {
  const theme = useTheme();

  /**
   * Memo version of alert background color
   * @type {unknown}
   */
  const backgroundColor = useMemo(() => {
    switch (variant) {
      case "warning":
      case "find_in_page":
        return theme.palette.nonPalette.ORANGE_BACKGROUND;
      case "error":
        return theme.palette.nonPalette.RED_BACKGROUND;
      case "success":
        return theme.palette.nonPalette.GREEN_BACKGROUND;
      default:
        return theme.palette.nonPalette.BLUE_BACKGROUND;
    }
  }, [
    theme.palette.nonPalette.BLUE_BACKGROUND,
    theme.palette.nonPalette.GREEN_BACKGROUND,
    theme.palette.nonPalette.ORANGE_BACKGROUND,
    theme.palette.nonPalette.RED_BACKGROUND,
    variant,
  ]);

  /**
   * Memo version of the alert icon
   * @type {JSX.Element}
   */
  const alertIcon = useMemo(() => {
    switch (variant) {
      case "warning":
        return (
          <WarningRounded
            color={"warning"}
            sx={{
              marginRight: "0.5rem",
              marginTop: "auto",
              marginBottom: "auto",
            }}
          />
        );
      case "info-check":
        return (
          <CheckCircleRounded
            color={"primary"}
            sx={{
              marginRight: "0.5rem",
              marginTop: "auto",
              marginBottom: "auto",
            }}
          />
        );
      case "error":
        return (
          <DangerousRounded
            color={"error"}
            sx={{
              marginRight: "0.5rem",
              marginTop: "auto",
              marginBottom: "auto",
            }}
          />
        );
      case "success":
        return (
          <CheckCircleRounded
            color={"success"}
            sx={{
              marginRight: "0.5rem",
              marginTop: "auto",
              marginBottom: "auto",
            }}
          />
        );
      case "info":
        return (
          <InfoRounded
            color={"primary"}
            sx={{
              marginRight: "0.5rem",
              marginTop: "auto",
              marginBottom: "auto",
            }}
          />
        );
      case "edit":
        return (
          <EditRounded
            color={"primary"}
            sx={{
              marginRight: "0.5rem",
              marginTop: "auto",
              marginBottom: "auto",
            }}
          />
        );
      case "find_in_page":
        return (
          <FindInPageRounded
            color={"warning"}
            sx={{
              marginRight: "0.5rem",
              marginTop: "auto",
              marginBottom: "auto",
            }}
          />
        );
      default:
        return <></>;
    }
  }, [variant]);

  return (
    <AlertContainer backgroundColor={backgroundColor} variant={variant} width={width} style={{ ...style }}>
      <StyledAlert>
        <div style={{ marginBottom: "auto" }}>{alertIcon}</div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography
            fontWeight="500"
            style={{
              marginTop: "auto",
              marginBottom: "0.5rem",
              wordWrap: "break-word",
            }}
          >
            {title}
          </Typography>
          {React.isValidElement(body) ? (
            body
          ) : (
            <Typography
              variant={"body2"}
              style={
                !!bodyStyle
                  ? bodyStyle
                  : {
                      marginTop: "0.5rem",
                      color: theme.palette.primary[700],
                    }
              }
            >
              {body}
            </Typography>
          )}
        </div>
      </StyledAlert>
    </AlertContainer>
  );
};

export default Alerts;
