import base from "./axiosInstance";

export const post_linking_request = async (data, userId) => {
  try {
    const response = await base.post("/linkedaccounts/requestLinking", {
      data,
      userId,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
