import React from "react";
import { CheckRounded } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import Chip from "../../Chip";
import { truncate } from "@utils/index";
import { BYLAW_SEARCH_DROP_DOWN_LENGTH } from "@core/constants/licences";
import { PRIMARY_200 } from "@mui-theme/theme";
import { ClickableArea, Row, StyledGrid } from "./StyledComponents";

const CategoryItem = ({ option, state, props }) => {
  return (
    <ClickableArea component={"li"} key={state.index} {...props}>
      <Row>
        <CheckRounded color={"primary"} id={"hover-icon"} />
        <Tooltip title={option} placement="right-start">
          <StyledGrid>
            <Chip label={truncate(option, BYLAW_SEARCH_DROP_DOWN_LENGTH)} variant="active" iconColor={PRIMARY_200} />
          </StyledGrid>
        </Tooltip>
      </Row>
    </ClickableArea>
  );
};

export default CategoryItem;
