import React, { useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import * as yup from "yup";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { Chip, useTheme } from "@mui/material";
import { Form, Formik } from "formik";
import { RED, RED_BACKGROUND } from "@mui-theme/theme";
import Typography from "@components/ui-kit/typography";
import ListItem from "@components/ui-kit/list-item";
import TextField from "@components/ui-kit/TextField";
import { CheckboxCard } from "@components/ui-kit/CheckboxCard";
import { removeLinkedUsers } from "@core/apis/licence";
import { validPhoneFormatted } from "@utils/yupValidators";
import AccountBar from "@components/ui-kit/account-bar";
import HistoryTab from "../history-tab";
import AddressDisplayEditor from "@components/ui-kit/address-display-editor";
import { capitalize, formatPhoneNumber } from "@utils/masked-input-formatters";
import LinkUserToAccountModal from "@components/modals/link-user-to-account-modal";
import {
  AddRounded,
  AlternateEmail,
  ArticleOutlined,
  CallOutlined,
  CheckBoxOutlined,
  DirectionsCarOutlined,
  DriveFileRenameOutline,
  EventOutlined,
  FaxOutlined,
  LanguageOutlined,
  LocationOnOutlined,
  MailOutline,
  PersonOutline,
  PersonPinOutlined,
  Tag,
} from "@mui/icons-material";
import Spacer from "@components/ui-kit/Spacer";
import { useDispatch } from "react-redux";
import { closeDialog, setState as setConfirmationDialogState } from "../../../../redux/slices/confrmation-dialog";
import TagEditor from "@components/ui-kit/tag-editor";
import useTags from "@hooks/useTags";
import { ACTIVE } from "core/constants/licences";

const SmallEditButton = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.blacks.BLACK_MEDIUM_EMPHASIS};
    margin-left: 1rem;
    margin-top: 1rem;

    :hover {
      color: ${({ theme }) => theme.palette.primary[200]};
      cursor: pointer;
    }
  }
`;

const EndOrnamentContainer = styled.div`
  display: flex;

  .material-symbols-rounded {
    font-size: 32px;
  }
`;

const DeleteButton = styled(Button)`
  && {
    :hover {
      color: ${RED};
      background: ${RED_BACKGROUND};
      cursor: pointer;
    }
  }
`;

export const managerOwnerValidations = () =>
  yup.object().shape({
    full_name: yup.string().max(35, "Full name is too long.").required("Full name is required."),
    phone: validPhoneFormatted,
  });

const ManagersAndOwnersItem = ({
  index,
  type,
  element,
  count,
  handleSubmit,
  handleRemove,
  isOwner,
  editing,
  isOriginalValue = false,
  adding,
  setAddingOwner,
  licenceData,
  errors,
  setErrors,
}) => {
  const [caseEditing, setEditing] = useState(false);
  const [isOwnerVal, setIsOwnerVal] = useState(element.is_owner);
  const [refreshElement, setRefreshElement] = useState(false);
  const [validateOnChange, setValidateOnChange] = useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();

  const initialValues = {
    full_name: element.full_name,
    phone: element.phone,
    is_owner: element.is_owner,
  };

  const handleCancel = () => {
    setEditing(false);
    setRefreshElement(!refreshElement);
  };

  const checkBoxClick = (e) => {
    e.stopPropagation();
    if (errors.managers || errors.owners) {
      setErrors((prev) => ({ ...prev, managers: null, owners: null }));
    }
    setIsOwnerVal(e.target.checked);
  };

  const deleteOwner = () => {
    handleRemove(index);
    dispatch(closeDialog());
    setEditing(false);
  };

  const submit = (event, index) => {
    let changedData = event;
    changedData.is_owner = isOwnerVal;
    handleSubmit(changedData, index);
    setEditing(false);
  };

  const add = (event) => {
    let changedData = event;
    changedData.is_owner = isOwnerVal;
    handleSubmit(changedData);
    setAddingOwner(false);
  };

  useEffect(() => {
    setIsOwnerVal(element.is_owner);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element.is_owner, refreshElement]);

  return adding && editing ? (
    <Formik
      initialValues={initialValues}
      validationSchema={managerOwnerValidations(licenceData.is_resident)}
      onSubmit={(event) => {
        setValidateOnChange(false);
        add(event, index);
      }}
      validateOnChange={validateOnChange}
    >
      {(formikProps) => {
        return (
          <Form style={{ marginTop: "1rem" }}>
            <Typography variant="caption" color={theme.palette.blacks.BLACK_MEDIUM_EMPHASIS} style={{ marginLeft: "1rem" }}>
              Add owner/manager
            </Typography>
            <div
              style={{
                width: "95%",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: 15,
                marginBottom: "0.5rem",
                marginTop: "1rem",
              }}
            >
              <EndOrnamentContainer style={{ paddingTop: 20 }}>
                <PersonOutline />
              </EndOrnamentContainer>
              <TextField
                label="Full Name"
                fullWidth
                name="full_name"
                value={formikProps.values.full_name}
                touched={formikProps.touched.full_name ? "true" : "false"}
                onChange={formikProps.handleChange}
                error={Boolean(formikProps.errors.full_name)}
                helperText={formikProps.errors.full_name}
              />
            </div>
            <div
              style={{
                width: "95%",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: 15,
              }}
            >
              <EndOrnamentContainer style={{ paddingTop: 20 }}>
                <CallOutlined />
              </EndOrnamentContainer>
              <TextField
                label="Phone Number"
                fullWidth
                name="phone"
                format={"PHONE"}
                value={formikProps.values.phone}
                touched={formikProps.touched.phone ? "true" : "false"}
                onChange={formikProps.handleChange}
                error={Boolean(formikProps.errors.phone)}
                helperText={formikProps.errors.phone}
              />
            </div>
            <div
              style={{
                width: "85%",
                marginLeft: "3rem",
                marginTop: "0.5rem",
              }}
            >
              <CheckboxCard
                onChange={(e) => {
                  checkBoxClick(e);
                }}
                label={"This person is an owner"}
                value={isOwnerVal}
              />
            </div>
            <div
              style={{
                width: "95%",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: 15,
                marginTop: "0.5rem",
                marginLeft: "3rem",
              }}
            >
              <Button variant={"contained"} size={"medium"} type={"submit"} onClick={() => setValidateOnChange(true)}>
                Done
              </Button>
              <Button
                onClick={() => {
                  setValidateOnChange(false);
                  setAddingOwner(false);
                }}
              >
                Cancel
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  ) : !adding && editing && caseEditing ? (
    <Formik
      validationSchema={managerOwnerValidations(licenceData.is_resident)}
      initialValues={initialValues}
      onSubmit={(event) => {
        setValidateOnChange(false);
        submit(event, index);
      }}
      validateOnChange={validateOnChange}
    >
      {(formikProps) => {
        return (
          <Form>
            <Typography variant="caption" color={theme.palette.blacks.BLACK_MEDIUM_EMPHASIS} style={{ marginLeft: "1rem" }}>
              {type} {count}
            </Typography>
            <div
              style={{
                width: "95%",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: 15,
                marginBottom: "0.5rem",
                marginTop: "1rem",
              }}
            >
              <EndOrnamentContainer style={{ paddingTop: 20 }}>
                <PersonOutline />
              </EndOrnamentContainer>
              <TextField
                label="Full Name"
                fullWidth
                name="full_name"
                value={formikProps.values.full_name}
                touched={formikProps.touched.full_name ? "true" : "false"}
                onChange={formikProps.handleChange}
                error={Boolean(formikProps.errors.full_name)}
                helperText={formikProps.errors.full_name}
              />
            </div>
            <div
              style={{
                width: "95%",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: 15,
              }}
            >
              <EndOrnamentContainer style={{ paddingTop: 20 }}>
                <CallOutlined />
              </EndOrnamentContainer>
              <TextField
                label="Phone Number"
                fullWidth
                name="phone"
                format={"PHONE"}
                value={formikProps.values.phone}
                touched={formikProps.touched.phone ? "true" : "false"}
                onChange={formikProps.handleChange}
                error={Boolean(formikProps.errors.phone)}
                helperText={formikProps.errors.phone}
              />
            </div>
            <div
              style={{
                width: "85%",
                marginLeft: "3rem",
                marginTop: "0.5rem",
              }}
            >
              <CheckboxCard
                onChange={(e) => {
                  checkBoxClick(e);
                }}
                label={"This person is an owner"}
                value={isOwnerVal}
              />
            </div>

            <div
              style={{
                width: "95%",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: 15,
                marginTop: "0.5rem",
                marginLeft: "3rem",
                marginBottom: "1rem",
              }}
            >
              <Button
                variant={"contained"}
                size={"medium"}
                type={"submit"}
                onClick={() => setValidateOnChange(true)}
                disabled={
                  formatPhoneNumber(initialValues.phone) === formatPhoneNumber(formikProps.values.phone) &&
                  initialValues.full_name === formikProps.values.full_name &&
                  element.is_owner === isOwnerVal
                }
              >
                Done
              </Button>
              <DeleteButton
                sx={{
                  ".MuiTouchRipple-child": {
                    color: `${theme.palette.nonPalette.RED} !important`,
                  },
                  "&:hover": {
                    backgroundColor: theme.palette.nonPalette.RED_BACKGROUND,
                    ".MuiTouchRipple-child": {
                      color: theme.palette.nonPalette.RED,
                    },
                  },
                  "&:active": {
                    backgroundColor: theme.palette.nonPalette.RED_BACKGROUND,
                    ".MuiTouchRipple-child": {
                      color: theme.palette.nonPalette.RED,
                    },
                  },
                }}
                onClick={() => {
                  dispatch(
                    setConfirmationDialogState({
                      open: true,
                      title: `Remove ${isOwner ? "owner" : "manager"}?`,
                      body: `This ${isOwner ? "owner" : "manager"} will be removed along with all their information.`,
                      onConfirm: deleteOwner,
                    }),
                  );
                }}
              >
                Delete
              </DeleteButton>
              <Button
                onClick={() => {
                  setValidateOnChange(false);
                  handleCancel();
                }}
              >
                Cancel
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  ) : (
    isOwner === element.is_owner && (
      <div>
        <Typography variant="caption" color={theme.palette.blacks.BLACK_MEDIUM_EMPHASIS} style={{ marginLeft: "1rem" }}>
          {type} {count}
        </Typography>
        <ListItem
          editing={editing}
          props={"manager"}
          label={element.full_name || "-"}
          startOrnament={<PersonOutline />}
          characterLimit={80}
        />
        <ListItem
          editing={editing}
          props={"manager"}
          label={formatPhoneNumber(element.phone) || "-"}
          startOrnament={<CallOutlined />}
          characterLimit={80}
        />
        {editing && !isOriginalValue && (
          <SmallEditButton
            onClick={() => {
              setEditing(true);
            }}
            style={{ marginBottom: "2rem" }}
          >
            Edit {type}
          </SmallEditButton>
        )}
      </div>
    )
  );
};

const GeneralTab = ({
  data,
  fetchData,
  originalData,
  setData,
  initialData,
  allHistory,
  addComment,
  setTab,
  managersAndOwners,
  originalManagers,
  editing,
  editData,
  setEditData,
  linkedAccounts,
  refresher,
  refreshValue,
  changedFields,
  isDuplicate,
  fetchHistories,
  errors,
  setErrors,
  showOriginalData,
  setShowOriginalData,
}) => {
  const theme = useTheme();
  const [openLinkUserModal, setOpenLinkUserModal] = useState(false);
  const [addingOwner, setAddingOwner] = useState(false);
  const { tags, addTag, removeTag, tagOptions } = useTags("licence", data.id);
  const dispatch = useDispatch();
  const onRemoveOwner = (index) => {
    managersAndOwners.splice(index, 1);
    const fields = [];
    const [field] = Object.values(initialData).filter((item) => item.name === "business_manager");
    let tmpData = editData;
    for (let i = 0; i < tmpData.length; i++) {
      if (tmpData[i].id === field.id) {
        tmpData = tmpData.splice(i, 1);
        setEditData(tmpData);
      }
    }
    fields.push({
      id: field.id,
      value: { value: managersAndOwners },
      name: field.name,
    });
    const edittedData = fields.concat(editData);
    setEditData(edittedData);
  };

  const onAddOwner = (data) => {
    managersAndOwners.push(data);
    const fields = [];
    const [field] = Object.values(initialData).filter((item) => item.name === "business_manager");
    let tmpData = editData;
    for (let i = 0; i < tmpData.length; i++) {
      if (tmpData[i].id === field.id) {
        tmpData = tmpData.splice(i, 1);
        setEditData(tmpData);
      }
    }
    fields.push({
      id: field.id,
      value: { value: managersAndOwners },
      name: field.name,
    });
    const edittedData = fields.concat(editData);
    setEditData(edittedData);
    const { business_manager, ...newErrors } = errors;
    setErrors(newErrors);
  };

  const onEditOwner = (data, index) => {
    managersAndOwners[index] = data;
    const fields = [];
    const [field] = Object.values(initialData).filter((item) => item.name === "business_manager");
    let tmpData = editData;
    for (let i = 0; i < tmpData.length; i++) {
      if (tmpData[i].id === field.id) {
        tmpData = tmpData.splice(i, 1);
        setEditData(tmpData);
      }
    }
    fields.push({
      id: field.id,
      value: { value: managersAndOwners },
      name: field.name,
    });
    const edittedData = fields.concat(editData);
    setEditData(edittedData);
  };

  const onSaveEdit = async (values) => {
    const arrayOfFields = [];
    for (let prop in values) {
      if (prop === "property_owner_name" && values[prop] !== "I'm the owner") {
        let tmpData = editData;
        tmpData = tmpData.push({
          id: "90ff7878-f801-4de3-8e8c-22c5aff2b78b",
          value: {
            value: false,
          },
          name: "is_property_owner",
        });
        setEditData(tmpData);
      }
      const [field] = Object.values(initialData).filter((item) => item.name === prop);
      const formattedVal = values[prop];
      arrayOfFields.push({
        id: field.id,
        value: { value: formattedVal },
        name: field.name,
      });
      let tmpData = editData;
      for (let i = 0; i < tmpData.length; i++) {
        if (tmpData[i].id === field.id) {
          tmpData = tmpData.splice(i, 1);
          setEditData(tmpData);
        }
      }
      let tmp = data;
      tmp[prop] = values[prop];
      setData(tmp);
    }
    const edittedData = arrayOfFields.concat(editData);
    setEditData(edittedData);
  };

  const handleUnLink = async () => {
    dispatch(closeDialog());
    await removeLinkedUsers(data.id);
    refresher(!refreshValue);
  };

  const writeResidency = () => {
    if ("is_resident" in originalData || "is_homebased" in originalData) {
      return originalData?.is_resident || (originalData?.is_resident === undefined && originalData?.is_homebased)
        ? `Resident${originalData?.is_homebased ? " (Home-based)" : ""}`
        : "Non-resident";
    } else {
      return undefined;
    }
  };

  let managersCount = 1;
  let ownersCount = 1;
  let originalManagersCount = 1;
  let orignalOwnersCount = 1;
  if (typeof managersAndOwners !== "object") {
    managersAndOwners = [];
  }

  return (
    <Grid container>
      {!editing && (
        <Grid item xs={12}>
          <Spacer />
          <h2 style={{ fontWeight: 500 }}>Account info</h2>
        </Grid>
      )}
      <Grid container spacing={2}>
        <Grid item xs={6} style={{ marginTop: editing ? "2rem" : "1rem" }}>
          <div className={"outlined_container"}>
            <h2>Licence details</h2>
            <Spacer />
            <ListItem
              editing={editing}
              label="Status"
              subLabel={capitalize(data.status)}
              variant="detail"
              startOrnament={<CheckBoxOutlined />}
              characterLimit={80}
              type={0}
              props={"status"}
              license={data}
            />
            <ListItem
              editing={editing}
              label="Licence #"
              subLabel={data.license_number || "-"}
              variant="detail"
              startOrnament={<Tag />}
              characterLimit={80}
              type={0}
              props={"license_number"}
              license={data}
            />
            <ListItem
              editing={editing}
              label="Business Name"
              subLabel={data.business_name || "-"}
              originalValue={originalData?.business_name}
              variant="detail"
              startOrnament={<DriveFileRenameOutline />}
              characterLimit={40}
              type={0}
              props={"business_name"}
              error={errors.business_name}
              isFormField={editing}
              license={data}
              onSave={onSaveEdit}
            />
            <ListItem
              editing={editing}
              label="Describe business operation"
              subLabel={data.business_operation_description || "-"}
              originalValue={originalData?.business_operation_description}
              variant="detail"
              startOrnament={<ArticleOutlined />}
              characterLimit={80}
              type={1}
              props={"business_operation_description"}
              error={errors.business_operation_description}
              isFormField={editing}
              license={data}
              onSave={onSaveEdit}
            />
            <ListItem
              editing={editing}
              label="Residency status"
              subLabel={data?.is_resident ? `Resident${data?.is_homebased ? " (Home-based)" : ""}` : "Non-resident"}
              originalValue={writeResidency()}
              variant="detail"
              startOrnament={<PersonPinOutlined />}
              characterLimit={80}
              type={2}
              props={["is_resident", "is_homebased"]}
              isFormField={editing}
              license={data}
              onSave={onSaveEdit}
            />
            {data.is_homebased && (
              <>
                {data.has_parking_for_customers === "true" || editing ? (
                  <Typography
                    variant="overline"
                    color={theme.palette.blacks.BLACK_MEDIUM_EMPHASIS}
                    style={{ marginLeft: "1rem" }}
                  >
                    Parking capacity
                  </Typography>
                ) : (
                  <></>
                )}
                {editing ? (
                  <>
                    <CheckboxCard
                      label={"Business has parking for customers"}
                      cardVariant={"highlight"}
                      onChange={(e) => {
                        onSaveEdit({
                          has_parking_for_customers: e.target.checked,
                        });
                      }}
                      value={data?.has_parking_for_customers}
                    />
                  </>
                ) : (
                  <ListItem
                    editing={editing}
                    label="Parking"
                    subLabel={data?.has_parking_for_customers ? "Parking available" : "Parking unavailable"}
                    originalValue={
                      originalData?.has_parking_for_customers !== undefined
                        ? originalData?.has_parking_for_customers === true
                          ? "Parking available"
                          : "Parking unavailable"
                        : undefined
                    }
                    variant="detail"
                    startOrnament={<DirectionsCarOutlined />}
                    characterLimit={80}
                    type={0}
                    license={data}
                  />
                )}
                <Typography variant="overline" color={theme.palette.blacks.BLACK_MEDIUM_EMPHASIS} style={{ marginLeft: "1rem" }}>
                  Property owner
                </Typography>
                <ListItem
                  editing={editing}
                  subLabel={data.property_owner_name || "-"}
                  originalValue={originalData.property_owner_name}
                  startOrnament={<PersonOutline />}
                  characterLimit={80}
                  type={0}
                  props={"property_owner_name"}
                  validationProps={["is_resident", "is_homebased"]}
                  error={errors.property_owner_name}
                  variant="detail"
                  isFormField={editing}
                  license={data}
                  onSave={onSaveEdit}
                />
              </>
            )}
            {data.expiry && data.status === ACTIVE && (
              <ListItem
                editing={editing}
                label="Auto-renew date"
                subLabel={
                  data.expiry
                    ? moment(data.expiry).add(1, "d").format("MMM DD, YYYY")
                    : moment(moment().format("YYYY") + "/12/31")
                        .add(1, "d")
                        .format("MMM DD, YYYY")
                }
                variant="detail"
                startOrnament={<EventOutlined />}
                characterLimit={80}
                type={0}
                props={"expiry"}
                license={data}
                style={{ marginBottom: "1rem" }}
              />
            )}
          </div>
          <Spacer />
          <div className={"outlined_container"}>
            <h2>Business contact information</h2>
            <Spacer />
            <AddressDisplayEditor
              errors={[
                errors.business_address_street,
                errors.business_address_street2,
                errors.business_address_city,
                errors.business_address_province,
                errors.business_address_postal_code,
                errors.business_address_country,
              ]}
              editing={editing}
              onSaveEdit={(values) => {
                const mapped = {
                  business_address_street: values.streetAddress,
                  business_address_street2: values.streetAddress2,
                  business_address_city: values.city,
                  business_address_province: values.province,
                  business_address_postal_code: values.postalzip,
                  business_address_country: values.country,
                };
                const {
                  business_address_street,
                  business_address_city,
                  business_address_country,
                  business_address_postal_code,
                  business_address_province,
                  ...newErrors
                } = errors;
                setErrors(newErrors);
                onSaveEdit(mapped);
              }}
              isChanged={
                (changedFields?.includes("business_address_street") ||
                  changedFields?.includes("business_address_street2") ||
                  changedFields?.includes("business_address_city") ||
                  changedFields?.includes("business_address_province") ||
                  changedFields?.includes("business_address_postal_code") ||
                  changedFields?.includes("business_address_country")) &&
                isDuplicate
              }
              address_street={data.business_address_street}
              address_street2={data.business_address_street2}
              address_city={data.business_address_city}
              address_province_state={data.business_address_province}
              address_zip_postal_code={data.business_address_postal_code}
              address_country={data.business_address_country}
              original_address_street={originalData.business_address_street}
              original_address_street2={originalData.business_address_street2}
              original_address_city={originalData.business_address_city}
              original_address_province_state={originalData.business_address_province}
              orginal_address_zip_postal_code={originalData.business_address_postal_code}
              original_address_country={originalData.business_address_country}
              icon={<LocationOnOutlined />}
              widget_label="Business Address"
            />
            <AddressDisplayEditor
              errors={[
                errors.mailing_address_street,
                errors.mailing_address_street2,
                errors.mailing_address_city,
                errors.mailing_address_province,
                errors.mailing_address_postal_code,
                errors.mailing_address_country,
              ]}
              editing={editing}
              onSaveEdit={(values) => {
                const mapped = {
                  mailing_address_street: values.streetAddress,
                  mailing_address_street2: values.streetAddress2,
                  mailing_address_city: values.city,
                  mailing_address_province: values.province,
                  mailing_address_postal_code: values.postalzip,
                  mailing_address_country: values.country,
                };
                const {
                  mailing_address_street,
                  mailing_address_city,
                  mailing_address_country,
                  mailing_address_postal_code,
                  mailing_address_province,
                  ...newErrors
                } = errors;
                setErrors(newErrors);
                onSaveEdit(mapped);
              }}
              isChanged={
                (changedFields?.includes("mailing_address_street") ||
                  changedFields?.includes("mailing_address_street2") ||
                  changedFields?.includes("mailing_address_city") ||
                  changedFields?.includes("mailing_address_province") ||
                  changedFields?.includes("mailing_address_postal_code") ||
                  changedFields?.includes("mailing_address_country")) &&
                isDuplicate
              }
              address_street={data.mailing_address_street}
              address_street2={data.mailing_address_street2}
              address_city={data.mailing_address_city}
              address_province_state={data.mailing_address_province}
              address_zip_postal_code={data.mailing_address_postal_code}
              address_country={data.mailing_address_country}
              original_address_street={originalData.mailing_address_street}
              original_address_street2={originalData.mailing_address_street2}
              original_address_city={originalData.mailing_address_city}
              original_address_province_state={originalData.mailing_address_province}
              orginal_address_zip_postal_code={originalData.mailing_address_postal_code}
              original_address_country={originalData.mailing_address_country}
              icon={<MailOutline />}
              widget_label="Mailing Address"
            />
            <ListItem
              editing={editing}
              label="Phone number"
              subLabel={formatPhoneNumber(data.phone_number) || "-"}
              originalValue={formatPhoneNumber(originalData?.phone_number)}
              variant="detail"
              startOrnament={<CallOutlined />}
              characterLimit={80}
              type={0}
              props={"phone_number"}
              error={errors.phone_number}
              isFormField={editing}
              license={data}
              onSave={onSaveEdit}
            />
            <ListItem
              editing={editing}
              label="Email address"
              subLabel={data.email || "-"}
              originalValue={originalData?.email}
              variant="detail"
              startOrnament={<AlternateEmail />}
              characterLimit={80}
              type={0}
              props={"email"}
              error={errors.email}
              isFormField={editing}
              license={data}
              onSave={onSaveEdit}
            />
            <ListItem
              editing={editing}
              label="Secondary phone number"
              subLabel={formatPhoneNumber(data.secondary_phone_number) || "-"}
              originalValue={formatPhoneNumber(originalData?.secondary_phone_number)}
              variant="detail"
              startOrnament={<CallOutlined />}
              characterLimit={80}
              type={0}
              props={"secondary_phone_number"}
              isFormField={editing}
              license={data}
              onSave={onSaveEdit}
            />
            <ListItem
              editing={editing}
              label="Fax number"
              subLabel={formatPhoneNumber(data?.fax_number) || "-"}
              originalValue={formatPhoneNumber(originalData?.fax_number)}
              variant="detail"
              startOrnament={<FaxOutlined />}
              characterLimit={80}
              type={0}
              props={"fax_number"}
              isFormField={editing}
              license={data}
              onSave={onSaveEdit}
            />
            <ListItem
              editing={editing}
              label="Website address"
              subLabel={data.website_address || "-"}
              originalValue={originalData?.website_address}
              variant="detail"
              startOrnament={<LanguageOutlined />}
              characterLimit={80}
              type={0}
              props={"website_address"}
              isFormField={editing}
              license={data}
              onSave={onSaveEdit}
            />
          </div>
        </Grid>
        <Grid item xs={6} style={{ marginTop: "1rem" }}>
          {!editing && (
            <>
              <Grid item xs={12}>
                {linkedAccounts[0]?.exists === false ? (
                  <AccountBar
                    hideLinkButton
                    variant={"LINK"}
                    name={"Link to account..."}
                    onBarClick={() => {
                      setOpenLinkUserModal(true);
                    }}
                    disablebarclick={false}
                  />
                ) : (
                  <>
                    {linkedAccounts.map((acc, index) => (
                      <Grid key={index} item xs={12}>
                        <AccountBar
                          variant={"USER"}
                          name={acc?.email}
                          isLinked={true}
                          onUnlink={() => {
                            dispatch(
                              setConfirmationDialogState({
                                open: true,
                                title: "Unlinking account",
                                body: `${acc?.email}'s account will be unlinked from this business licence account and will lose access to it on MyCityHall. You can re-link the accounts later.`,
                                onConfirm: handleUnLink,
                              }),
                            );
                          }}
                        />
                      </Grid>
                    ))}
                  </>
                )}
              </Grid>
            </>
          )}
          <Spacer />
          <div className={"outlined_container"}>
            <h2>Business management</h2>
            <Spacer />
            {errors.business_manager ? (
              <Typography
                className={"bl-error-message"}
                style={{ marginLeft: "1rem", marginBottom: "0.5rem" }}
                variant={"body2"}
                color={theme.palette.nonPalette.RED}
              >
                {errors.business_manager}
              </Typography>
            ) : null}
            <div
              style={{
                background: changedFields.length > 0 ? theme.palette.nonPalette.YELLOW_BACKGROUND : "transparent",
                paddingTop: "0.5rem",
                paddingBottom: "0.5rem",
                borderRadius: "10px",
              }}
            >
              {managersAndOwners.length === 0 && (
                <div
                  style={{
                    marginLeft: "1rem",
                    marginBottom: "1rem",
                    width: "500px",
                  }}
                >
                  <Typography>There are no managers/owners attached to this licence.</Typography>
                </div>
              )}
              {managersAndOwners.map((element, i) => (
                <ManagersAndOwnersItem
                  key={i}
                  index={i}
                  isOwner={true}
                  type={"Owner"}
                  element={element}
                  count={element.is_owner === true ? ownersCount++ : 0}
                  handleSubmit={onEditOwner}
                  handleRemove={onRemoveOwner}
                  editing={editing}
                  edittedManagers={changedFields}
                  isDuplicate={isDuplicate}
                  licenceData={data}
                  errors={errors}
                  setErrors={setErrors}
                />
              ))}
              {managersAndOwners?.map((element, i) => (
                <ManagersAndOwnersItem
                  licenceData={data}
                  key={i}
                  index={i}
                  isOwner={false}
                  type={"Manager"}
                  element={element}
                  count={element.is_owner === false ? managersCount++ : 0}
                  handleSubmit={onEditOwner}
                  handleRemove={onRemoveOwner}
                  editing={editing}
                  edittedManagers={changedFields}
                  isDuplicate={isDuplicate}
                  errors={errors}
                  setErrors={setErrors}
                />
              ))}
              {changedFields.length > 0 && (
                <Typography
                  style={{
                    fontSize: 14,
                    fontWeight: 400,
                    lineHeight: 1,
                    marginTop: "0.5rem",
                    marginLeft: "1rem",
                  }}
                  sx={{
                    "&:hover": {
                      color: theme.palette.primary[500],
                      cursor: "pointer",
                    },
                  }}
                  color={theme.palette.primary[200]}
                  onClick={() => {
                    setShowOriginalData(!showOriginalData);
                  }}
                >
                  {showOriginalData ? "Hide original" : "View original"}
                </Typography>
              )}
              {originalManagers.length > 0 && showOriginalData && (
                <div style={{ marginTop: "1.5rem" }}>
                  <Chip
                    label="Original"
                    style={{
                      width: "fit-content",
                      background: theme.palette.greys.GREY_MEDIUM,
                      marginLeft: "0.5rem",
                      marginBottom: "0.25rem",
                    }}
                  />
                  {managersAndOwners.length === 0 && (
                    <div
                      style={{
                        marginLeft: "1rem",
                        marginBottom: "1rem",
                        width: "500px",
                      }}
                    >
                      <Typography>There are no managers/owners attached to this licence.</Typography>
                    </div>
                  )}
                  {originalManagers.map((element, i) => (
                    <ManagersAndOwnersItem
                      key={i}
                      index={i}
                      isOwner={true}
                      isOriginalValue={true}
                      type={"Owner"}
                      element={element}
                      count={element.is_owner === true ? orignalOwnersCount++ : 0}
                      handleSubmit={onEditOwner}
                      handleRemove={onRemoveOwner}
                      editing={editing}
                      edittedManagers={changedFields}
                      isDuplicate={isDuplicate}
                      licenceData={data}
                      errors={errors}
                      setErrors={setErrors}
                    />
                  ))}
                  {originalManagers?.map((element, i) => (
                    <ManagersAndOwnersItem
                      licenceData={data}
                      key={i}
                      index={i}
                      isOwner={false}
                      isOriginalValue={true}
                      type={"Manager"}
                      element={element}
                      count={element.is_owner === false ? originalManagersCount++ : 0}
                      handleSubmit={onEditOwner}
                      handleRemove={onRemoveOwner}
                      editing={editing}
                      edittedManagers={changedFields}
                      isDuplicate={isDuplicate}
                      errors={errors}
                      setErrors={setErrors}
                    />
                  ))}
                </div>
              )}
            </div>

            {editing &&
              (addingOwner ? (
                <ManagersAndOwnersItem
                  licenceData={data}
                  index={0}
                  isOwner={false}
                  type={"Manager"}
                  element={{ is_owner: false, phone: "", full_name: "" }}
                  count={0}
                  handleSubmit={onAddOwner}
                  handleRemove={onRemoveOwner}
                  editing={editing}
                  adding={addingOwner}
                  setAddingOwner={setAddingOwner}
                  errors={errors}
                  setErrors={setErrors}
                />
              ) : (
                <Button
                  size={"small"}
                  variant="newOptional"
                  startIcon={<AddRounded />}
                  style={{ marginLeft: "1rem", marginTop: "0.5rem" }}
                  onClick={() => {
                    if (errors.managers || errors.owners) {
                      setErrors((prev) => ({
                        ...prev,
                        managers: null,
                        owners: null,
                      }));
                    }
                    setAddingOwner(true);
                  }}
                >
                  Add owner/manager
                </Button>
              ))}
          </div>
          <Spacer />
          <Grid item xs={12}>
            <TagEditor value={tags} label={"Tags"} options={tagOptions} addTag={addTag} removeTag={removeTag} />
            <Spacer />
          </Grid>
        </Grid>
      </Grid>
      {!editing && (
        <>
          <Grid item xs={12} style={{ marginBottom: "1rem" }}>
            <Spacer />
            <div id={"__history-section"} />
            <HistoryTab
              usedInInfoTab={true}
              fetchHistories={fetchHistories}
              maxToShow={2}
              history={allHistory}
              _addComment={addComment}
              data={data}
              setTab={setTab}
              originalAccountName={originalData?.business_name}
            />
          </Grid>
        </>
      )}
      <LinkUserToAccountModal
        open={openLinkUserModal}
        handleClose={() => {
          setOpenLinkUserModal(false);
        }}
        data={data}
        fetchData={fetchData}
      />
    </Grid>
  );
};

export default GeneralTab;
