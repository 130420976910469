import React, { createRef, useCallback, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import {
  formatCreditCard,
  formatCurrency,
  formatDate,
  formatExpiry,
  formatNumber,
  formatPhoneNumber,
  formatPostalCode,
  formatZipCode,
} from "@utils/masked-input-formatters";
import maskedTextFieldTypes from "./maskedTextFieldTypes";

/**
 * Formats a user input into various types
 * @param value
 * @param initialValue
 * @param onChange
 * @param type - options : phone, currency
 * @param handleClear
 * @param InputProps
 * @param inputProps
 * @param endAdornment
 * @param maxAmount
 * @param setFieldValue
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function MaskedTextField({
  value,
  onChange,
  type,
  handleClear,
  InputProps,
  inputProps,
  endAdornment,
  maxAmount,
  setFieldValue,
  disabled = false,
  ...props
}: any): JSX.Element {
  const format = useCallback(
    (value: any) => {
      let formattedValue = value;
      switch (type) {
        case maskedTextFieldTypes.CURRENCY:
          formattedValue = formatCurrency(formattedValue, maxAmount);
          break;
        case maskedTextFieldTypes.DATE:
          formattedValue = formatDate(formattedValue);
          break;
        case maskedTextFieldTypes.PHONE:
          formattedValue = formatPhoneNumber(formattedValue);
          break;
        case maskedTextFieldTypes.POSTAL:
          formattedValue = formatPostalCode(formattedValue);
          break;
        case maskedTextFieldTypes.NUMBER:
          formattedValue = formatNumber(formattedValue);
          break;
        case maskedTextFieldTypes.CREDIT_CARD:
          formattedValue = formatCreditCard(formattedValue);
          break;
        case maskedTextFieldTypes.EXPIRY:
          formattedValue = formatExpiry(formattedValue);
          break;
        case maskedTextFieldTypes.ZIP:
          formattedValue = formatZipCode(formattedValue);
          break;
        default:
          formattedValue = value;
      }
      return formattedValue;
    },
    [maxAmount, type],
  );

  const updateValue = useCallback(() => {
    return format(value);
  }, [format, value]);

  const [inputValue, setInputValue] = useState(updateValue() || "");

  const ref = createRef();

  const handleInput = (e: any) => {
    let formattedValue = format(e.target.value);

    if (formattedValue !== inputValue) {
      e.target.value = formattedValue;
      onChange(e);
      if (!!setFieldValue) {
        setFieldValue(formattedValue);
      }
    }

    setInputValue(formattedValue);
  };

  useEffect(() => {
    if (!value) {
      setInputValue("");
    }
  }, [value]);

  useEffect(() => {
    setInputValue(updateValue());
  }, [updateValue]);

  return (
    <TextField
      disabled={disabled}
      inputRef={ref}
      {...props}
      InputProps={{
        disableUnderline: true,
        endAdornment: endAdornment,
        ...InputProps,
      }}
      inputProps={inputProps}
      value={inputValue}
      onChange={(e) => {
        handleInput(e);
      }}
    />
  );
}
