import React from "react";
import Spacer from "@components/ui-kit/Spacer";
import Button from "@mui/material/Button";
import classes from "../../../utilities/link-utility/index.module.scss";
import { LINK_CONTACT_CITY_HALL } from "@core/constants/links";
import PropertyTaxInfoCard from "../property-tax-info-card";

export default function Step2({ account, linkAccount = () => {} }) {
  const isInactive = account.isInactive || false;
  const getTitle = () => {
    return isInactive ? "This account is inactive" : "Confirm your account";
  };
  const getBody = () => {
    return isInactive ? (
      <>
        The account you are attempting to link is currently inactive. If this is your account and you want to re-activate it,
        please{" "}
        <a rel="noopener noreferrer" target={"_blank"} href={LINK_CONTACT_CITY_HALL}>
          contact City Hall
        </a>
        .
      </>
    ) : (
      "Please review the information below and confirm this is the Property Tax Account number you would like to link to your profile."
    );
  };

  return (
    <>
      <p className={classes.h1}>{getTitle()}</p>
      <p className={classes.body}>{getBody()}</p>
      <Spacer amount={0.1} />
      <p className={classes.overline}>ACCOUNT FOUND</p>
      <PropertyTaxInfoCard account={account} />
      <Spacer amount={2} />
      {!isInactive && (
        <Button size={"large"} variant={"contained"} fullWidth onClick={linkAccount}>
          Link account
        </Button>
      )}
    </>
  );
}
