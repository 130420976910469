import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@components/ui-kit/Dialog";
import { closeDialog } from "../../../redux/slices/confrmation-dialog";

const ConfirmationDialog = () => {
  const { title, body, open, onConfirm } = useSelector((state) => state?.confirmationDialog);
  const dispatch = useDispatch();

  return (
    <>
      <Dialog
        variant={"updated"}
        title={title}
        body={body}
        open={open}
        handleClose={() => dispatch(closeDialog())}
        handleButtonOne={() => dispatch(closeDialog())}
        buttonOneText={"Cancel"}
        buttonTwoText={"Confirm"}
        buttonFlexDirection={"column-reverse"}
        buttonTwoVariant={"contained"}
        handleButtonTwo={onConfirm}
      />
    </>
  );
};

export default ConfirmationDialog;
