import { useState } from "react";

const setInitialValue = (names: string[]) => {
  let states = {} as any;
  names.forEach((name) => {
    states[name] = true;
  });
  return states;
};

//Organizes multiple loading states into a single hook.
const useLoading = (names: string[]) => {
  const [loadingStates, setLoadingState] = useState(setInitialValue(names));

  const setLoadingStates = (name: string | string[], value: boolean) => {
    if (typeof name === "string") {
      setLoadingState((prev: any) => ({ ...prev, [name]: value }));
    } else {
      const newState = {} as any;
      name.forEach((item) => {
        newState[item] = value;
      });
      setLoadingState((prev: any) => ({ ...prev, ...newState }));
    }
  };

  return { loadingStates, setLoadingStates };
};

export default useLoading;
