import React, { createContext, useMemo, useState } from "react";

export const FilterContext = createContext({
  filters: [],
  setFilters: (f) => {},
  resetFilters: () => {},
});

export const FilterContextProvider = (props) => {
  const [filters, setFilters] = useState({});

  const filterContext = useMemo(() => {
    return {
      filters,
      setFilters,
      resetFilters: () => {
        setFilters({});
      },
    };
  }, [filters]);

  return <FilterContext.Provider value={filterContext}>{props.children}</FilterContext.Provider>;
};
