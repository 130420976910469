import React, { useContext, useRef, useState } from "react";
import styled from "styled-components";
import Grid from "@mui/material/Grid";
import TextInput, { RadioInput, SelectInput } from "@components/ui-kit/text-input";
import { Form, Formik } from "formik";
import { fieldValidate } from "@utils/yupValidators";
import TextFieldWithErrorList from "@components/ui-kit/text-field-with-error-list";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@components/ui-kit/Modal";
import Button from "@mui/material/Button";
import Typography from "@components/ui-kit/typography";
import DialogContent from "@mui/material/DialogContent";
import { NotificationContext } from "@contexts/NotificationContext";

const InputGrid = styled(Grid)`
  margin: 1rem 0.5rem 1rem 0;
`;

const ModalDetailsContainer = styled(Grid)`
  margin: 1rem;
  padding: 1rem;
`;

const CreateModal = ({
  open,
  setOpen,
  fetchData,
  title,
  fields,
  validation,
  initialValues,
  create_function,
  successMessage,
  bodyText,
  submitButtonText,
  contentStyle,
  CustomForm,
  maxWidth,
}) => {
  const [validateOnChange, setValidateOnChange] = useState(false);
  const { handleError, handleSuccess } = useContext(NotificationContext);
  const [loading, setLoading] = useState(false);
  const formikRef = useRef();

  if (!contentStyle) {
    contentStyle = {
      width: "400px",
      margin: "0 auto",
    };
  }

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const data = { ...values };
      await create_function(data);
      handleSuccess(successMessage);
      fetchData();
    } catch (err) {
      handleError(err.response?.data?.message || err?.message);
    } finally {
      handleClose();
    }
  };

  const handleClose = () => {
    if (formikRef.current) {
      formikRef.current.resetForm();
    }
    setValidateOnChange(false);
    setOpen(false);
    setLoading(false);
  };

  return (
    <Modal
      fullWidth
      open={open}
      title={title || "Create"}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth={maxWidth}
    >
      <DialogContent style={contentStyle}>
        <ModalDetailsContainer size={12}>
          {CustomForm ? (
            <CustomForm
              open={open}
              setOpen={setOpen}
              fetchData={fetchData}
              title={title}
              fields={fields}
              validation={validation}
              initialValues={initialValues}
              create_function={create_function}
              successMessage={successMessage}
              bodyText={bodyText}
              submitButtonText={submitButtonText}
            />
          ) : (
            <Formik
              innerRef={formikRef}
              initialValues={initialValues}
              validationSchema={validation}
              onSubmit={handleSubmit}
              validateOnChange={validateOnChange}
              validateOnBlur={false}
            >
              {(props) => {
                return (
                  <Form noValidate>
                    {bodyText ? <Typography gutterBottom>{bodyText}</Typography> : null}
                    <Grid container direction="row" xs={12}>
                      {fields.map((field) => {
                        const isPassword = field.type === "password";
                        const required = field?.required ? field.required(props.values) : true;
                        switch (field.type) {
                          case "select":
                            if (field?.show && !field.show(props.values)) return null;
                            return (
                              <InputGrid container xs={field.size}>
                                {validation ? (
                                  <SelectInput
                                    key={field.id}
                                    label={field.label}
                                    name={field.id}
                                    required
                                    type={field.type}
                                    values={field.values}
                                    variant="standard"
                                  />
                                ) : (
                                  <SelectInput
                                    key={field.id}
                                    label={field.label}
                                    name={field.id}
                                    required
                                    type={field.type}
                                    values={field.values}
                                    variant="standard"
                                    validate={(value) => fieldValidate(value, field.validator, !isPassword, required)}
                                  />
                                )}
                              </InputGrid>
                            );
                          case "radio": {
                            if (field?.show && !field.show(props.values)) return null;
                            return (
                              <InputGrid container xs={field.size}>
                                <RadioInput
                                  isSubmitting={props.isSubmitting}
                                  values={field.values}
                                  key={field.id}
                                  label={field.label}
                                  name={field.id}
                                  form={props}
                                  required={field?.required ? field.required(props.values) : true}
                                  showRequirementsOnFocus={isPassword}
                                  type={field.type}
                                  validate={(value) => fieldValidate(value, field.validator, !isPassword, required)}
                                />
                              </InputGrid>
                            );
                          }
                          default:
                            if (field?.show && !field.show(props.values)) return null;
                            return (
                              <InputGrid container xs={field.size}>
                                {validation ? (
                                  <TextInput
                                    key={field.id}
                                    label={field.label}
                                    name={field.id}
                                    required
                                    inputFormat={field.inputFormat}
                                    type={field.type}
                                  />
                                ) : (
                                  <TextInput
                                    as={isPassword && TextFieldWithErrorList}
                                    key={field.id}
                                    label={field.label}
                                    name={field.id}
                                    required
                                    inputFormat={field.inputFormat}
                                    showRequirementsOnFocus={isPassword}
                                    type={field.type}
                                    validate={(value) => fieldValidate(value, field.validator, !isPassword, required)}
                                  />
                                )}
                              </InputGrid>
                            );
                        }
                      })}
                      <InputGrid container>
                        {loading ? (
                          <CircularProgress />
                        ) : (
                          <>
                            <Button
                              size={"large"}
                              type="submit"
                              variant="contained"
                              iconName="add"
                              fullWidth
                              onClick={() => {
                                setValidateOnChange(true);
                              }}
                            >
                              {submitButtonText || "Save"}
                            </Button>
                          </>
                        )}
                      </InputGrid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          )}
        </ModalDetailsContainer>
      </DialogContent>
    </Modal>
  );
};

export default CreateModal;
