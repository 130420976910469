import React from "react";
import styled from "styled-components";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TextField from "../TextField";

const StyledArrowIcon = styled(KeyboardArrowDownIcon)`
  && {
    color: ${({ theme }) => theme.textColor};
  }
`;

export default function Select({ placeholder = "", options = [], SelectProps = {}, menuItemSx = {}, disabled = false, ...rest }) {
  return (
    <TextField
      {...rest}
      disabled={disabled}
      select
      SelectProps={{
        ...SelectProps,
        IconComponent: disabled ? null : StyledArrowIcon,
        displayEmpty: true,
      }}
    >
      {options.map(({ value, label, icon }) => (
        <MenuItem key={value} value={value} sx={menuItemSx}>
          {React.isValidElement(icon) ? React.cloneElement(icon, { style: { marginRight: "0.5rem" } }) : null}
          {label}
        </MenuItem>
      ))}
    </TextField>
  );
}
