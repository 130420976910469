import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import { SummarizeOutlined } from "@mui/icons-material";
import styled from "@emotion/styled";
import { Chip, FormControlLabel, Popover, Radio, RadioGroup } from "@mui/material";
import { borderColor } from "mui-theme/theme";
import InvoiceReport from "./invoice";
import LicenceReport from "./licence";
import TransactionReport from "./transaction";

export const StyledPopover = styled(Popover)(
  `
    border: 0px;
    .MuiPaper-root {
      border-radius: 20px;
    }
    `,
);

export const StyledFormControlLabel = styled(FormControlLabel)(
  `
  background: transparent;
  :hover {
    background: transparent;
  }
    `,
);

const Reporting = () => {
  const [selectedValue, setSelectedValue] = useState("businessLicence");
  const [reportType, setReportType] = useState("all");

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const getReport = () => {
    switch (selectedValue) {
      case "invoice":
        return <InvoiceReport />;
      case "transaction":
        return <TransactionReport />;
      default:
        return <LicenceReport reportType={reportType} />;
    }
  };

  // TODO leave to be reused
  // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        marginLeft: "3rem",
        marginRight: "3rem",
        marginTop: "2rem",
        marginBottom: "auto",
      }}
    >
      <div style={{ display: "flex" }}>
        <SummarizeOutlined style={{ width: "35px", height: "45px" }} />
        <Typography fontSize={"32px"} fontWeight={500} style={{ marginLeft: "0.5rem", marginTop: "0.75rem" }}>
          Reports
        </Typography>
      </div>
      <div>
        <Chip
          label="All Licences"
          color={reportType === "all" ? "success" : "primary"}
          variant={reportType !== "all" ? "outlined" : "filled"}
          onClick={() => {
            setReportType("all");
            setSelectedValue("all");
          }}
          style={{ margin: "5px" }}
        />
        <Chip
          label="Unpaid 2023"
          color={reportType === "unpaid2023" ? "success" : "primary"}
          variant={reportType !== "unpaid2023" ? "outlined" : "filled"}
          onClick={() => {
            setReportType("unpaid2023");
            setSelectedValue("all");
          }}
          style={{ margin: "5px" }}
        />
        <Chip
          label="Unpaid 2024"
          color={reportType === "unpaid2024" ? "success" : "primary"}
          variant={reportType !== "unpaid2024" ? "outlined" : "filled"}
          onClick={() => {
            setReportType("unpaid2024");
            setSelectedValue("all");
          }}
          style={{ margin: "5px" }}
        />
        <Chip
          label="Early 2024 Paid"
          color={reportType === "early2024" ? "success" : "primary"}
          variant={reportType !== "early2024" ? "outlined" : "filled"}
          onClick={() => {
            setReportType("early2024");
            setSelectedValue("all");
          }}
          style={{ margin: "5px" }}
        />
        <Chip
          label="Transactions"
          color={reportType === "transaction" ? "success" : "primary"}
          variant={reportType !== "transaction" ? "outlined" : "filled"}
          onClick={() => {
            setReportType("transaction");
            setSelectedValue("transaction");
          }}
          style={{ margin: "5px" }}
        />
        <Chip
          label="Invoices"
          color={reportType === "invoice" ? "success" : "primary"}
          variant={reportType !== "invoice" ? "outlined" : "filled"}
          onClick={() => {
            setReportType("invoice");
            setSelectedValue("invoice");
          }}
          style={{ margin: "5px" }}
        />
      </div>
      <div
        style={{
          marginTop: "2rem",
          border: `1px solid ${borderColor}`,
          borderRadius: "10px",
        }}
      >
        {/* /// TODO leave this feature for future */}
        {/* <StyledButton startIcon={<ViewModuleIcon />} onClick={handleClick}>
          MODULE
        </StyledButton> */}
        {getReport()}
      </div>
      <StyledPopover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div style={{ width: "200px", padding: "0.5rem" }}>
          <RadioGroup aria-label="options" name="options" value={selectedValue} onChange={handleChange}>
            <StyledFormControlLabel value="businessLicence" control={<Radio />} label="Business Licence" />
            <StyledFormControlLabel value="utility" control={<Radio />} label="Utility" />
          </RadioGroup>
        </div>
      </StyledPopover>
    </div>
  );
};

export default Reporting;
