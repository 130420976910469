import { formattingRegex } from "./regexes";
import { startCase, toLower } from "lodash";

export const INPUT_FORMATS = {
  AGE: "AGE",
  CC: "CC",
  CURRENCY: "CURRENCY",
  CVC: "CVC",
  EMAIL: "EMAIL",
  EXPIRY: "EXPIRY",
  NAME: "NAME",
  PHONE: "PHONE",
  POSTAL_CODE: "POSTAL_CODE",
  PET_BIRTH_DATE: "PET_BIRTH_DATE",
};

export const getCleaveFormatOptions = (format: any) => {
  switch (format) {
    case INPUT_FORMATS.PHONE:
      // strictly allow 10 digits
      return { numericOnly: true, blocks: [3, 3, 4] };
    case INPUT_FORMATS.CC:
      return { creditCard: true };
    case INPUT_FORMATS.EXPIRY:
      return { date: true, datePattern: ["m", "y"] };
    case INPUT_FORMATS.CVC:
      return { numericOnly: true, blocks: [4] };
    case INPUT_FORMATS.AGE:
      return { numericOnly: true, blocks: [3] };
    case INPUT_FORMATS.CURRENCY:
      return { numeral: true, numeralThousandsGroupStyle: "thousand" };
    case INPUT_FORMATS.EMAIL:
      return { lowercase: true };
    default:
      return null;
  }
};

export const customFormat = (format: any, value: string, formValues: { [x: string]: any }) => {
  if (format === INPUT_FORMATS.POSTAL_CODE) {
    value = value.toUpperCase();
    const countryValue = formValues["country"];
    const isCA = countryValue !== "US"; // Thus when no value, default to IsCA: true
    /*
     CA: X1X 1X1
     US: 12345 or 12345-1234
     Feature: append "space, -" if not provided between char groups
    */
    const regex = isCA ? formattingRegex.postalCode : formattingRegex.zipCode;
    const match = regex.exec(value);
    if (match) {
      if (isCA) {
        return value.replace(regex, "$1 $2");
      } else {
        if (!match[2]) {
          return value.replace(regex, "$1");
        } else {
          return value.replace(regex, "$1-$2");
        }
      }
    }
  } else if (format === INPUT_FORMATS.NAME) {
    const split = value.split(" ");
    const capitalized = split.map((x) => startCase(toLower(x)));
    value = capitalized.join(" ");
  }
  return value;
};
