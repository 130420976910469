import React, { useContext, useState } from "react";
import { getLicenceInvoicePDF, getLicenceTransactions } from "@core/apis/licence";
import { NotificationContext } from "@contexts/NotificationContext";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import classes from "../../../../../admin/utilities-detail/billing-payment-tab/index.module.scss";
import LinkButton from "@components/ui-kit/link-button";
import { currency, truncate } from "@utils/index";
import typographyClasses from "@assets/styles/typography/typography.module.scss";
import { invoiceStatusFormatter } from "@utils/data-table-format-helpers";
import { DataFormat } from "@core/constants/enums";
import FileDownload from "js-file-download";
import { EMPTY_TABLE_VARIANTS } from "@core/constants/general";
import DataTable from "@components/ui-kit/data-table";
import { TableSettingContext } from "@contexts/TableSettingContext";

const BLInvoicePaymentTab = ({ licenceId }) => {
  const [data, setData] = useState({});
  const { handleError } = useContext(NotificationContext);
  const [loading, setLoading] = useState(true);
  const { tableSettings, setTableSettings } = useContext(TableSettingContext);
  const [currentTableSettings] = useState(tableSettings["invoices"] || {});

  const downloadInvoicePdf = async (e, invoiceNumber, invoicePdf) => {
    e.stopPropagation();
    e.preventDefault();
    const invoice = await getLicenceInvoicePDF(licenceId, invoiceNumber);
    if (invoice) {
      FileDownload(invoice, invoicePdf, "application/pdf");
    }
  };

  const fields = [
    {
      id: "id",
      hideFromTable: true,
    },
    {
      id: "date",
      label: "Invoice Date",
      format: (val) => moment(val).format("YYYY-MM-DD"),
    },
    {
      id: "title",
      label: "Description",
      disableSort: true,
      format: (val, { payment, subTitle, invoiceNumber, pdfLink }) =>
        payment ? (
          <Tooltip children={<p className={classes.payment_description}>{val}</p>} title={val} />
        ) : (
          <>
            <LinkButton
              tooltip={val}
              color={"blue"}
              onClick={async (e) => {
                e.stopPropagation();
                e.preventDefault();
                await downloadInvoicePdf(e, invoiceNumber, pdfLink);
              }}
            >
              {truncate(val, 35)}
            </LinkButton>
            <p className={`${typographyClasses.body2} ${typographyClasses.small_font}`}>{subTitle}</p>
          </>
        ),
    },
    {
      id: "status",
      label: "Status",
      disableSort: true,
      format: (val, { payment, isRefund }) => {
        return payment ? <div /> : invoiceStatusFormatter(val, isRefund);
      },
    },
    {
      id: "payment",
      label: "Payment",
      align: "right",
      disableSort: true,
      format: (val, { amount }) => {
        return <p className={classes.payment}>{!!val ? currency(amount) : ""}</p>;
      },
    },
    {
      id: "amount",
      label: "Amount",
      disableSort: true,
      align: "right",
      format: (val, { payment }) => <p>{!payment ? DataFormat.CURRENCY(val) : ""}</p>,
    },
  ];

  const fetchData = async (queryOptions) => {
    try {
      let res = await getLicenceTransactions(licenceId, queryOptions);
      setData({
        total: res?.total ?? 0,
        result: res?.result?.map((e, index) => ({
          ...e,
          index: index,
        })),
        licenceId: licenceId,
      });
    } catch (err) {
      handleError(err.response?.data?.message || err?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <DataTable
        id={"invoices"}
        fields={fields}
        data={data}
        dataLoading={loading}
        loadData={fetchData}
        tableSettings={currentTableSettings}
        setTableSettings={setTableSettings}
        emptyTableVariant={EMPTY_TABLE_VARIANTS.BASIC}
      />
    </>
  );
};

export default BLInvoicePaymentTab;
