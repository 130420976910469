import React from "react";
import TextField from "@mui/material/TextField";
import { Autocomplete, createFilterOptions } from "@mui/material";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { CloseRounded, ExpandMoreRounded } from "@mui/icons-material";

/**
 * The highlighted version of the auto complete component.
 * Make sure that a label is supplied for the options, otherwise it
 * will throw an error.
 *
 * WARNING - Formik's handleChange function won't work on the auto
 * complete component. Please use setFieldValue to modify the value
 * and pass the function to this component's onChange prop
 *
 * @param value
 * @param onChange
 * @param options
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ProvinceAutoComplete({ value, onChange, options, ...props }) {
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => option.label,
  });

  return (
    <Autocomplete
      autoHighlight={true}
      value={value}
      filterOptions={filterOptions}
      disableClearable
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          {...props}
          {...params}
          InputProps={{
            ...params.InputProps,
            ...props.InputProps,
            disableUnderline: true,
          }}
        />
      )}
      noOptionsText={"No results. Try typing something else."}
      options={options}
      renderOption={(props, option, { inputValue }) => {
        if (!option.label) {
          throw new Error("options require a label. (option.label !== null)");
        }

        const matches = match(option.label, inputValue);
        const parts = parse(option.label, matches);

        return (
          <li {...props}>
            <div>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{
                    fontWeight: part.highlight ? 700 : 400,
                  }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          </li>
        );
      }}
      clearIcon={<CloseRounded />}
      popupIcon={<ExpandMoreRounded />}
    />
  );
}
