import React from "react";
import typographyClasses from "@assets/styles/typography/typography.module.scss";
import Spacer from "@components/ui-kit/Spacer";
import Grid from "@mui/material/Unstable_Grid2";
import DetailsContainer from "../../../../utilities/utility-details/widgets/details-container";
import ListItem from "@components/ui-kit/list-item";
import { useTheme } from "@mui/material";
import { formatCurrency } from "@utils/index";
import moment from "moment";
import classes from "./index.module.scss";
import { ChevronRightRounded } from "@mui/icons-material";
import { useHistory, useLocation } from "react-router-dom";
import AnnualPropertyAssessmentGraph from "ui/components/property-tax/annual-property-assessment-graph";

const OverviewTab = ({ loading, accountDetailsItem, assessmentInfo, taxNotice, assessments }) => {
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();

  return (
    <>
      <p className={typographyClasses.h1}>Overview</p>
      <Spacer />
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={6}>
          <DetailsContainer
            loading={loading}
            title={"Account Holder"}
            buttonLabel={"Edit details"}
            isEditable={false}
            buttonDisable={true}
          >
            {accountDetailsItem.map((item) => {
              return (
                <ListItem
                  key={item.id}
                  variant="detail"
                  label={item.label}
                  subLabel={item.subLabel}
                  site={"portal"}
                  startOrnament={item.icon}
                />
              );
            })}
          </DetailsContainer>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <div>
            <AnnualPropertyAssessmentGraph loading={loading} assessments={assessments} />
            <div style={{ height: "10px" }} />
          </div>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <div className={classes.details_container}>
            <p className={typographyClasses.h2}>{"Property Assessment"}</p>
            <p className={classes.top_sub_title}>
              {`Property Value as of ${moment(assessmentInfo?.date).format("MMM DD, YYYY")}`}{" "}
            </p>
            <Spacer />
            <p className={classes.sub_title}>Total Assessed Value</p>
            <p className={classes.main_value}>{formatCurrency(assessmentInfo.assessedValue)}</p>
            <Spacer />
            <p className={classes.sub_title}>Assessment Class</p>
            <p style={{ margin: "0" }}>{assessmentInfo.assessmentClass}</p>
            <Spacer />
            <p className={classes.sub_title}>Improvement</p>
            <p style={{ margin: "0" }}>{formatCurrency(assessmentInfo.improvement)}</p>
            <Spacer />
            <p className={classes.sub_title}>Land</p>
            <p style={{ margin: "0" }}>{formatCurrency(assessmentInfo.land)}</p>
            <Spacer />
            <p className={classes.sub_title}>Final date of Complaint</p>
            <p style={{ margin: "0" }}>{moment(assessmentInfo?.complaintDate).format("MMM DD, YYYY")}</p>
            <Spacer />
            <div className={classes.text_button} onClick={() => history.push(`${location.pathname}#${1}`)}>
              <p>See previous assessments</p>
              <ChevronRightRounded />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <div className={classes.details_container}>
            <p className={typographyClasses.h2}>{"Property Tax Notice"}</p>
            <p className={classes.top_sub_title}>{`Due Date is ${moment(taxNotice?.dueDate).format("MMM DD, YYYY")}`} </p>
            <Spacer />
            <div className={classes.table_header}>
              <div style={{ width: "45%" }}>
                <p>DESCRIPTION</p>
              </div>
              <div style={{ width: "25%" }}>
                <p>RATE</p>
              </div>
              <div
                style={{
                  width: "30%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <p>AMOUNT</p>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                height: "2px",
                backgroundColor: "black",
              }}
            />
            {taxNotice.items.map((e, index, arr) => (
              <>
                <div className={classes.table_content}>
                  <div style={{ width: "45%" }}>
                    <p>{e.description}</p>
                  </div>
                  <div style={{ width: "25%" }}>
                    <p>{e.rate ?? ""}</p>
                  </div>
                  <div
                    style={{
                      width: "30%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <p>{formatCurrency(e.amount)}</p>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "1px",
                    backgroundColor: index === arr.length - 1 ? "white" : theme.palette.nonPalette.OUTLINE,
                  }}
                />
              </>
            ))}
            <div
              style={{
                width: "100%",
                height: "2px",
                backgroundColor: "black",
              }}
            />
            <div className={classes.table_footer}>
              <p>Total Amount Due</p>
              <p>{formatCurrency(taxNotice.items.reduce((prev, current) => prev + current.amount, 0))}</p>
            </div>
            <div className={classes.text_button} onClick={() => history.push(`${location.pathname}#${2}`)}>
              <p>See previous notices</p>
              <ChevronRightRounded />
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default OverviewTab;
