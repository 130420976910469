import React, { useContext, useEffect, useState } from "react";
import { NotificationContext } from "@contexts/NotificationContext";
import { FilterContext } from "@contexts/FilterContext";
import { DataFormat, INPUT_FORMATS } from "@core/constants/enums";
import { statusFormat } from "@utils/data-table-format-helpers";
import { addUser, get_users } from "@core/apis/user";
import { TableSettingContext } from "@contexts/TableSettingContext";
import { AccountCircleOutlined, AddOutlined } from "@mui/icons-material";
import CreateModal from "@components/modals/CreateModal";
import { validEmail, validFirstName, validLastName } from "@utils/yupValidators";
import * as yup from "yup";
import { DataGrid, gridClasses, GridColDef, GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import classes from "./index.module.scss";

const createFields = [
  {
    label: "Email",
    id: "email",
    size: 25,
    type: "email",
    validator: validEmail,
    inputFormat: INPUT_FORMATS.EMAIL,
  },
  {
    label: "First Name",
    id: "firstName",
    size: 12,
    type: "string",
  },
  {
    label: "Last Name",
    id: "lastName",
    size: 12,
    type: "string",
  },
];

const columns: GridColDef[] = [
  {
    field: "fullName",
    headerName: "Name",
    editable: false,
    flex: 1,
    filterable: false,
  },
  {
    field: "email",
    headerName: "Email",
    editable: false,
    flex: 1,
    filterable: false,
  },
  {
    field: "disabled",
    headerName: "Status",
    editable: false,
    flex: 1,
    filterable: false,
    renderCell: (params) => {
      return statusFormat(params.value ? "inactive" : "active");
    },
  },
  {
    field: "lastLoggedIn",
    headerName: "Last Logged In",
    editable: false,
    flex: 1,
    filterable: false,
    valueFormatter: (params) => DataFormat.DATE(params.value),
  },
  {
    field: "createDateTime",
    headerName: "Created On",
    editable: false,
    flex: 1,
    filterable: false,
    valueFormatter: (params) => DataFormat.DATE(params.value),
  },
];

const validationSchema = yup.object({
  email: validEmail,
  firstName: validFirstName,
  lastName: validLastName,
});

const initialValues = { email: "", firstName: "", lastName: "" };

const Users = (...props: any) => {
  const [tableIdentifier] = useState("userList");
  const { handleError } = useContext(NotificationContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const { filters, setFilters } = useContext(FilterContext);
  // @ts-ignore
  const [currentFilters] = useState(filters["users"] || {});
  const { tableSettings, setTableSettings } = useContext(TableSettingContext);
  const [currentTableSettings] = useState(tableSettings[tableIdentifier] || props.initialFilters || {});
  const [openCreate, setOpenCreate] = useState(false);

  function CustomToolbar() {
    return (
      <GridToolbarContainer style={{ justifyContent: "space-between" }}>
        <Tooltip title="Search by: Name, Email">
          <div>
            <GridToolbarQuickFilter />
          </div>
        </Tooltip>
        <Button startIcon={<AddOutlined />} variant={"outlined"} onClick={() => setOpenCreate(true)}>
          New User
        </Button>
      </GridToolbarContainer>
    );
  }

  const fetchData = async (queryOptions: any) => {
    setLoading(true);
    try {
      const data = await get_users(queryOptions);
      setData(data);
    } catch (err: any) {
      handleError(err.response?.data?.message || err?.message);
    } finally {
      setLoading(false);
    }
  };

  const saveNewUser = async (data: any) => {
    await addUser(data);
    fetchData({
      total: true,
      sortBy: "",
      ascending: true,
      filters: {},
      offset: 0,
      limit: 20000,
    }).then();
  };

  useEffect(() => {
    // @ts-ignore
    filters["users"] = currentFilters;
    setFilters(filters);
    tableSettings[tableIdentifier] = currentTableSettings;
    setTableSettings(tableSettings);
  }, [currentFilters, filters, currentTableSettings, tableSettings, tableIdentifier, setFilters, setTableSettings]);

  useEffect(() => {
    fetchData({
      total: true,
      sortBy: "",
      ascending: true,
      filters: {},
      offset: 0,
      limit: 20000,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={"users-screen"}>
      <div className={"users-screen-content"}>
        <div className={classes.fixed_header}>
          <>
            <div className={classes.title_container}>
              <AccountCircleOutlined
                sx={{
                  height: "45px",
                  width: "45px",
                  marginRight: "0.5rem",
                }}
              />
              <Typography variant={"h3"} className={classes.table_title}>
                Users
              </Typography>
            </div>
          </>
        </div>
        <DataGrid
          loading={loading}
          density={"comfortable"}
          // @ts-ignore
          rows={data?.result || []}
          columns={columns}
          initialState={{
            columns: {
              columnVisibilityModel: {
                address: false,
              },
            },
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10, 25]}
          disableRowSelectionOnClick
          style={{ borderColor: "transparent" }}
          slots={{ toolbar: CustomToolbar }}
          sx={{
            [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
              outline: "none",
            },
            [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
              outline: "none",
            },
          }}
        />
      </div>
      <CreateModal
        title="New User"
        open={openCreate}
        setOpen={setOpenCreate}
        fetchData={fetchData}
        fields={createFields}
        create_function={saveNewUser}
        successMessage="User created"
        validation={validationSchema}
        initialValues={initialValues}
        bodyText={undefined}
        submitButtonText={undefined}
        contentStyle={undefined}
        CustomForm={undefined}
        maxWidth={undefined}
      />
      <div className={"secondary-sidebar"} />
    </div>
  );
};

export default Users;
