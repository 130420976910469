import React, { useContext, useState } from "react";
import Grid from "@mui/material/Grid";
import DocumentThumbnail from "@components/ui-kit/mch/DocumentThumbnail";
import { editAttachment } from "@core/apis/attachment";
import { saveFormValues } from "../../../my-city-hall/licences/business-licences/functions/business_licence_functions";
import { NotificationContext } from "@contexts/NotificationContext";
import RenameModal from "@components/modals/RenameModal";
import { handleDownloadDoc, handlePreviewDoc } from "@utils/attachmentHelpers";
import Spacer from "@components/ui-kit/Spacer";
import { useDispatch } from "react-redux";
import { closeDialog, setState as setConfirmationDialogState } from "../../../../redux/slices/confrmation-dialog";

export default function DocumentTab({ documents, fetchLicence, id, item, handleUploadDocumentModalOpen, uploadInProgress }) {
  const [renameLoading, setRenameLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [modalData, setModalData] = useState(null);
  const renameModalOpen = Boolean(modalData?.type === "rename");
  const { handleError } = useContext(NotificationContext);
  const dispatch = useDispatch();

  const onModalOpen = (name, id, type) => {
    setModalData({
      name,
      id,
      type,
    });
  };

  /**
   * Handles renaming of attachments
   * @param attachmentId
   * @param fileName
   * @returns {Promise<void>}
   */
  const onRename = async (attachmentId, fileName) => {
    setRenameLoading(id);
    try {
      await editAttachment(attachmentId, fileName, id);
      await fetchLicence();
    } catch (e) {
      console.log(e.message);
      handleError("Unable to rename file, please try again.");
    } finally {
      setRenameLoading(false);
    }
  };

  /**
   * Handles the deletion of an attachment
   * @param fileId
   * @returns {Promise<void>}
   */
  const _onDeleteFile = async (fileId) => {
    setDeleteLoading(fileId);
    try {
      let fileArray = item.attachments;
      fileArray = fileArray.filter((x) => x !== fileId);
      let newValues = { attachments: fileArray };
      await saveFormValues(id, newValues);
      await fetchLicence();
    } catch (e) {
      console.log(e.message);
      handleError("Unable to delete file, please try again.");
    } finally {
      setDeleteLoading(false);
      dispatch(closeDialog());
    }
  };

  const closeModal = () => {
    setModalData(null);
  };

  return (
    <>
      <Grid container spacing={0} style={{ minWidth: "984px" }}>
        <Grid item xs={12}>
          <Spacer />
          <h2 style={{ fontWeight: 500 }}>Documents</h2>
          <Spacer />
        </Grid>
        <Grid container spacing={2}>
          <Grid item>
            <DocumentThumbnail
              onClick={handleUploadDocumentModalOpen}
              variant={"upload"}
              loading={uploadInProgress}
              style={{ marginBottom: "1rem" }}
            />
          </Grid>
          {documents.map((doc) => {
            let loading = false;
            if (deleteLoading && deleteLoading === doc?.attachmentId) {
              loading = true;
            }
            if (renameLoading && renameLoading === doc?.attachmentId) {
              loading = true;
            }
            return (
              <Grid item key={doc?.attachmentId}>
                <DocumentThumbnail
                  loading={loading}
                  onClick={() => handlePreviewDoc(doc?.attachmentId, id, handleError)}
                  canEdit={true}
                  title={doc?.fileLabel || "-"}
                  style={{ marginBottom: "1rem" }}
                  onDownload={() => handleDownloadDoc(doc?.attachmentId, doc?.fileLabel || "-", id, handleError)}
                  onDelete={() => {
                    dispatch(
                      setConfirmationDialogState({
                        open: true,
                        title: "Delete file",
                        body: `Are you sure you want to delete this file? ${doc?.fileLabel}`,
                        onConfirm: () => _onDeleteFile(doc?.attachmentId),
                      }),
                    );
                  }}
                  onRename={() => {
                    onModalOpen(doc?.fileLabel, doc?.attachmentId, "rename");
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      <RenameModal open={renameModalOpen} data={modalData} handleClose={closeModal} renameFile={onRename} />
    </>
  );
}
