import React from "react";
import { Skeleton } from "@mui/material";

function PaginationSkeleton() {
  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <Skeleton variant={"rectangular"} height={40} width={200} />
    </div>
  );
}

export default PaginationSkeleton;
